import { useEffect } from "react";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import { usePageNameContext } from "../../contexts/PageTitleContext";


export default function AuthConnections() {
  const { setInfo } = usePageNameContext();

  useEffect(() => {
    setInfo({
      name: "Auth Connections",
      desc: "View and manage integrations with other sites",
    });
  }, []);

  const handleEbayAuth = () => {
    console.log("eBay Auth");
  }

  return (
    <div>
      <ButtonInput label={"Connect to eBay OAuth"} isSubmit={false} onClick={() => handleEbayAuth()} classes={undefined} />
    </div>
  )
}