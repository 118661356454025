import { IPart } from "../../models/Part";


export default function OrderStatus({ part, width = "w-full" }: { part: IPart, width?: string }) {

  let style = "px-3 mx-auto text-center rounded-full h-10 flex justify-center items-center";
  let colour = "bg-yellow-200 text-yellow-500";
  let status = "Not Picked"

  if (part.pickedOn) {
    colour = "bg-blue-200 text-blue-500";
    status = "Picked";
  }

  if (part.shippedOn) {
    colour = "bg-green-200 text-green-500";
    status = "Shipped";
  }

  return (
    <div className={`${colour} ${style} ${width}`}>
      <p className="">{status}</p>
    </div>
  )

}