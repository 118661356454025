import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead, IPaymentsLeadsResponse } from "../models/Lead";

export default function usePayments() {
  const { get, post, del } = useApiHelper();
  const queryClient = useQueryClient();

  const raisedPayments = useQuery({
    queryKey: ["raisedPayments"],
    queryFn: () => get<IPaymentsLeadsResponse>("/payment"),
    staleTime: 1000 * 60, // 1 minute
    refetchInterval: 1000 * 60, // 1 minute
  });

  //Admin only
  const approvePayments = useMutation(
    (body: any) => post(`/payment/approve`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["raisedPayments"]);
        queryClient.invalidateQueries(["leads"]);
      },
    }
  );

  //Admin only
  const cancelPayments = useMutation(
    (body: any) => del(`/payment`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["raisedPayments"]);
        queryClient.invalidateQueries(["leads"]);
      },
    }
  );

  const sendPayment = useMutation(
    (leadId: string) => post(`/payment/${leadId}`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["lead"]});
        queryClient.invalidateQueries(["leads"]);
        queryClient.invalidateQueries(["raisedPayments"]);
      },
    }
  );

  const syncPayment = useMutation(
    (leadId: string) => post(`/payment/${leadId}/sync`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["leads"]);
        queryClient.invalidateQueries(["raisedPayments"]);
      },
    }
  );

  return {
    raisedPayments,
    approvePayments,
    cancelPayments,
    sendPayment,
    syncPayment,
  };
}
