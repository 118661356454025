import { HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { createColumnHelper } from "@tanstack/react-table";

import PagedTable from "../shared/Tables/PagedTable";
import { IEbayReturn } from "../../models/EbayReturns";
import EbayReturnsModal from "./EbayReturnsModal";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer'}
      {...rest}
    />
  )
}

const colHelper = createColumnHelper<IEbayReturn>();

const cols = [
  {
    id: 'select',
    // header: ({ table }: any) => (
    //   <IndeterminateCheckbox
    //     {...{
    //       checked: table.getIsAllRowsSelected(),
    //       indeterminate: table.getIsSomeRowsSelected(),
    //       onChange: table.getToggleAllRowsSelectedHandler(),
    //     }}
    //   />
    // ),
    cell: ({ row }: any) => (
      <div className="flex justify-center items-center flex-1 p-2" onClick={(e) => e.stopPropagation()}>
        {/* <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        /> */}
        <button
          onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
          style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
          className="p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
        >
          {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    ),
  },
  colHelper.accessor("sellerResponseDue", {
    header: "Respond On or By",
    sortingFn: "auto",
    cell: (info) => <span>{info.getValue() !== null ? date.format(new Date(info.getValue()!.respondByDate.value), "DD/MM/YYYY") : "Unknown"}</span>,
  }),
  colHelper.accessor("buyerLoginName", {
    header: "Buyer",
    sortingFn: "auto",
  }),
  // colHelper.accessor("creationInfo", {
  //   header: "Creation Date",
  //   sortingFn: "auto",
  //   cell: (info) => <span>{info.getValue() !== null ? date.format(new Date(info.getValue()!.creationDate.value), "DD/MM/YYYY hh:mm") : "Not Set"}</span>,
  // }),
  colHelper.accessor("state", {
    header: "State",
    sortingFn: "auto",
  }),
  colHelper.accessor("creationInfo", {
    header: "Reason",
    sortingFn: "auto",
    cell: (info) => <span>{info.getValue().reasonType} - {info.getValue().reason}</span>,
  }),
];

export default function EbayReturnsTable() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReturn, setSelectedReturn] = useState<IEbayReturn | undefined>();

  const rowSelected = (row: IEbayReturn) => {
    setSelectedReturn(row);
    setModalOpen(true);
  }

  return (<>
    <EbayReturnsModal open={modalOpen} setOpen={setModalOpen} ebayReturn={selectedReturn} />
    <PagedTable
      url="/ebaysafe/returns/search"
      customUrlQuery=""
      columns={cols}
      queryLabel="Search order details"
      onRowClick={(x: IEbayReturn) => rowSelected(x)}
    />
  </>
  );
}
