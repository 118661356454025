import { useState } from "react";
import classNames from "../../../utils/classNames"

export const TextInputHidden = ({ label, register, registerName, disabled = false }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="sm:col-span-1">
      {label && (
        <label className="text-sm font-medium text-gray-500">
          {label}
        </label>
      )}
      {!showPassword ? (
        <button
          type="button"
          className="w-full mt-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gp-blue-500"
          onClick={() =>
            showPassword
              ? setShowPassword(false)
              : setShowPassword(true)
          }
        >
          Show
        </button>
      ) : (
        <>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              disabled={disabled}
              {...register(registerName)}
              type="text"
              // className={classNames(disabled ? "bg-gray-300" : "", "w-full mt-1 text-sm text-gray-900")}
              className={classNames(disabled ? "bg-gray-500" : "", "shadow-sm focus:ring-gp-blue-500 focus:border-gp-blue-500 block w-full sm:text-sm border-gray-300 rounded-l-md")}
              placeholder=""
            />
            <span
              onClick={() => setShowPassword(false)}
              className="w-3/12 md:w-2/12 px-8 inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:cursor-pointer hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gp-blue-500"
            >
              Hide
            </span>
          </div>
        </>
      )}
      {/* <input disabled={disabled} {...register(registerName)} type="text" className={classNames(disabled ? "bg-gray-300" : "", "w-full mt-1 text-sm text-gray-900")} /> */}
    </div>
  )
}