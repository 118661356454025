import { DateTime } from "luxon";
import Datepicker from "react-tailwindcss-datepicker";

export default function CustomDatePicker({ dates, handleDatesChange }) {

  //**  This library is broken - can't use custom shortcuts in a .ts file!  **/

  return (
    <Datepicker
      inputClassName="text-sm border-gray-500 rounded-none w-64"
      toggleClassName="absolute text-black font-bold right-0 h-full px-3"
      containerClassName=""
      placeholder="Filter by date range"
      showShortcuts={true}
      useRange={false}
      value={dates}
      onChange={handleDatesChange}
      displayFormat="DD/MM/YYYY"
      configs={{
        shortcuts: {
          today: "Today",
          tomorrow: {
            text: "Tomorrow",
            daysNumber: 1,
            period: {
              start: DateTime.now().plus({ days: 1 }).toISODate(),
              end: DateTime.now().plus({ days: 1 }).toISODate()
            },
          },
          yesterday: "Yesterday",
          past: period => `Past ${period} days`,
          nextThreeDays: {
            text: "Next 3 Days",
            daysNumber: 3,
            period: {
              start: DateTime.now().toISODate(),
              end: DateTime.now().plus({ days: 3 }).toISODate()
            },
          },
          nextSevenDays: {
            text: "Next 7 Days",
            daysNumber: 7,
            period: {
              start: DateTime.now().toISODate(),
              end: DateTime.now().plus({ days: 7 }).toISODate()
            },
          },
          currentMonth: "This Month",
          pastMonth: "Last Month",
          nextMonth: {
            text: "Next Month",
            period: {
              start: DateTime.now().plus({ months: 1 }).startOf("month").toISODate(),
              end: DateTime.now().plus({ months: 1 }).endOf("month").toISODate()
            },
          },
        }
      }}
    />
  )
}