import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartResponse } from "../models/Part";
import { IAutoDapResponse } from "../models/AutoDap";

export default function useAutoDap() {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60 * 10; //10 minutes

  const autoDapLookup = useMutation(
    (queries: any) => get<IAutoDapResponse>(`/part/lookup?vin=${queries.vin}&category=${queries.category}`),
    {
      onSuccess: (data) => {
        return data;
        // queryClient.invalidateQueries(["parts", leadId]);
      },
    }

  );

  return {
    autoDapLookup
  };
}
