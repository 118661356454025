import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead } from "../models/Lead";

export default function useCod() {
  const { post } = useApiHelper();
  const queryClient = useQueryClient();

  const runCod = useMutation(
    (leadId: any) => post(`/lead/${leadId}/cod`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["leads"]);
        queryClient.invalidateQueries(["vehicles"]);
      },
    }
  );


  return {
    runCod,
  };
}
