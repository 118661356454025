import { IEbayOffer, OrderPaymentStatus } from "../../models/EbayOffer";

export default function EbayPaymentStatus({ offers }: { offers: IEbayOffer[] }) {

  const offer = offers.find(x => x.paymentStatus !== null);

  if (!offer) {
    return <span>Not Found</span>;
  }

  return (
    <span>{OrderPaymentStatus[offer?.paymentStatus!]}</span>
  );
}