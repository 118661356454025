import { IPart } from "../models/Part";
import { DateTime } from "luxon";

const styleConstants = {
  gpBlue: "#0359A6",
  tableCols: 4,
};

export function usePartDescriptionGenerator() {
  const styles = `
    .wrapper {
      border: 1px solid ${styleConstants.gpBlue};
    }

    .header {
      background-color: ${styleConstants.gpBlue};
      color: white;
      padding: 8px 16px 8px 16px;
      font-size: 16pt;
    }

    .content {
      padding: 8px;
    }

    .subtitle {
      font-size: 16pt;
      font-weight: 600;
      margin: 16px 0 16px 0;
    }

    .table {
      width: 100%;
      border-color: ${styleConstants.gpBlue};
    }

    .table-border {
      border-color: ${styleConstants.gpBlue} !important;
    }

    .table-header {
      background-color: ${styleConstants.gpBlue};
      color: white;
    }

    .table-content {
      text-align: center;
    }
  `;

  const getHTML = (descContent: string, quickSpecs: string) => `
    <html>
      <head>
        <style>
          ${styles}
        </style>
      </head>
      <body>
        <div class='wrapper'>
          <div class='header'>
            Description
          </div>
          <div class='content'>
            ${descContent}
            <h2 class='subtitle'>Quick Specs</h2>
            ${quickSpecs}
          </div>
        </div>
      </body>
    </html>
  `;

  const getDescription = (part: IPart) =>
    `<p>For sale is a ${part.category?.name} from a ${part.vehicle?.combinedMake} ${part.vehicle?.combinedModel}.</p>
  ${part.notes.length > 0 ? (
      `<br />
      <p>Notes: </p>
      <p>${part.notes.map((note, i) => {
        return `<span> ${note.message}</span>`;
      })}</p>`
    ) : ""}
  `;

  const getQuickSpecs = (part: IPart) => {
    const tableHtml = (rows: string) => `
    <table class='table'>
      <tbody>
        ${rows}
      </tbody>
    </table>`;

    const rowHtml = (cells: string) => `
      <tr>
        ${cells}
      </tr>
    `;

    const headerCellHtml = (title: string, colSpan = 1) => `
      <th width='${100 / styleConstants.tableCols
      }%' class='table-header table-border' ${colSpan !== 1 && `colspan='${colSpan}'`
      }>${title}</th>
    `;

    const contentCellHtml = (value: string, colSpan = 1) => `
      <td class='table-content table-border' ${colSpan !== 1 && `colspan='${colSpan}'`
      }>${value}</td>
    `;

    const fields = [
      { label: "Engine Size", value: `${part.vehicle?.engineCapacity}cc` },
      { label: "Fuel Type", value: part.vehicle?.fuelType },
      { label: "Trim", value: part.vehicle?.modelVariantDescription },
      { label: "Doors", value: part.vehicle?.numberOfDoors },
      { label: "Style", value: part.vehicle?.bodyStyleDescription },
      {
        label: "Gearbox",
        value: `${part.vehicle?.combinedForwardGears} Speed ${part.vehicle?.transmission}`,
      },
      { label: "Colour", value: part.vehicle?.colourCurrent },
      {
        label: "Drive Type",
        value: `${part.vehicle?.driveAxle} ${part.vehicle?.driveType}`,
      },
      { label: "VIN", value: part.vehicle?.combinedVIN },
      { label: "Grade", value: part.grade ?? "N/A" },
    ];

    let i = 1;
    let innerHtmlTable = "";
    let headerString = "";
    let valuesString = "";

    fields.forEach((field, j) => {
      headerString += headerCellHtml(field.label);
      valuesString += contentCellHtml(String(field.value));

      let mod4 = i % styleConstants.tableCols;

      if (mod4 === 0) {
        //Was a multiple of 5, add to rows
        innerHtmlTable += rowHtml(headerString);
        innerHtmlTable += rowHtml(valuesString);
        headerString = "";
        valuesString = "";
      } else if (j + 1 === fields.length) {
        //End of data, fill row with blanks
        let remaining = styleConstants.tableCols - mod4;

        Array(remaining)
          .fill("")
          .forEach(() => {
            headerString += headerCellHtml("");
            valuesString += contentCellHtml("");
          });

        innerHtmlTable += rowHtml(headerString);
        innerHtmlTable += rowHtml(valuesString);
      }

      i++;
    });

    headerString = headerCellHtml("Fitment", styleConstants.tableCols);
    valuesString = contentCellHtml(
      `${part.vehicle?.combinedModel} ${part.vehicle?.bodyStyle} / ${DateTime.fromISO(part.vehicle?.visibilityDate!).year
      } - ${DateTime.fromISO(part.vehicle?.terminateDate!).year}`,
      styleConstants.tableCols
    );

    innerHtmlTable += rowHtml(headerString);
    innerHtmlTable += rowHtml(valuesString);

    return tableHtml(innerHtmlTable);
  };

  const generate = (part: IPart) => {
    return getHTML(
      getDescription(part),
      getQuickSpecs(part)
    );
  };

  return generate;
}
