import { useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IEbayAspectsResponse } from "../models/EbayOffer";

export default function useEbayAspects(categoryId: string) {
  const { get } = useApiHelper();

  const aspects = useQuery({
    queryKey: ["ebayAspects", categoryId],
    queryFn: () =>
      get<IEbayAspectsResponse>(
        `/ebay/aspects?${categoryId ? `query=${categoryId}` : ""}`
      ).then((res) => res.data),
      enabled: !!categoryId,
  });

  return {
    aspects,
  };
}
