import { useQueryClient } from "@tanstack/react-query";

import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { IPart, PartStatus } from "../../models/Part";
import usePartMutations from "../../data/usePartMutations";

export default function PartStatusDropdown({ part, classes = "" }: { part: IPart, classes?: string }) {
  const queryClient = useQueryClient();
  const { patchPart } = usePartMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Part Status");

  const handleStatusUpdate = (status: string) => {

    const updatedPart: IPart = {
      ...part,
      partStatus: parseInt(status),
    };

    patchPart.mutate(updatedPart, {
      onSuccess: () => {
        queryClient.refetchQueries(["pagedTable"]);
        saveHandlers.onSuccess();
      },
      onError: (error) => {
        saveHandlers.onError();
      }
    });
  }

  return (
    <div className="w-full h-full flex items-center" onClick={(e) => e.stopPropagation()}>
      <select value={part.partStatus} className={`${classes} border p-1`} onChange={(e) => handleStatusUpdate(e.target.value)}>
        {Object.values(PartStatus).filter((ct) => isNaN(Number(ct))).map((status, i) => (
          <option key={status} value={i}>
            {status}
          </option>
        ))}
      </select>
    </div>
  )
}
