import { useQueryClient } from "@tanstack/react-query";

import useVehiclesMutations from "../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { IVehicle } from "../../models/Vehicle";

export default function AwaitingInventoryVfePrice({ vehicle, leadId = null }: { vehicle: IVehicle, leadId?: string | null }) {
  const queryClient = useQueryClient();
  const { update } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Engine Export Price");

  // useEffect(() => {

  // }, [])

  const handlePriceUpdate = (price: string) => {

    const updatedVehicle: IVehicle = {
      ...vehicle!,
      enginesWorldwideVfeOffer: price,
    };

    update.mutate(updatedVehicle, {
      onSuccess: () => {
        saveHandlers.onSuccess();

        setTimeout(() => {
          leadId ? queryClient.refetchQueries(["lead", leadId]) :
            queryClient.refetchQueries(["leads"]);
        }, 200);
      },
      onError: (error) => {
        saveHandlers.onError();
      }
    });
  }

  return (
    <div className="w-full h-full flex items-center" onClick={(e) => e.stopPropagation()}>
      £ <input
        key={vehicle.id}
        defaultValue={parseFloat(vehicle!.enginesWorldwideVfeOffer!).toFixed(2)}
        className="h-8 w-full border p-1 ml-2 text-sm" type="number"
        onBlur={(e) => handlePriceUpdate(e.target.value)}
      />
    </div>
  )
}
