import React, { useCallback, useEffect, useRef, useState } from "react";

import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { toJpeg, toPng } from "html-to-image";

import { RadioGroup } from "@headlessui/react";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";

import Card from "../shared/Card/Card";
import Modal from "../../layouts/Modal";
import { IPart } from "../../models/Part";
import { FedexShipping } from "./FedexShipping";
import classNames from "../../utils/classNames";
import useShipping from "../../data/useShipping";
import { ResourceType } from "../../models/Note";
import LoadingWheel from "../shared/LoadingWheel";
import NotesOutput from "../shared/Notes/NotesOutput";
import { TextInput } from "../shared/Inputs/TextInput";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { NumberInput } from "../shared/Inputs/NumberInput";
import usePartMutations from "../../data/usePartMutations";
import { ProoviaShipping } from "./shipping/ProoviaShipping";
import { useLabelGenerator } from "../../hooks/useLabelGenerator";
import PinProtect, { PinProtectState } from "../shared/PinProtect";
import { ManageShippingModal } from "./shipping/ManageShippingModal";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import usePartDefaultCategories from "../../data/usePartDefaultCategories";
import {
  IShippingCreateRequest,
  IShippingLabelCreateFormFields,
  IShippingQuotes,
  IShippingQuotesRequest,
} from "../../models/Shipping";

type FormValues = {
  weight: number | null;
  height: number | null;
  length: number | null;
  width: number | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  county: string | null;
  postcode: string | null;
  stateOrProvince: string | null;
  fullName: string | null;
};

export default function StockPrintLabels({ part }: { part: IPart }) {
  const { defaultPartCategory } = usePartDefaultCategories({ part: part });
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Shipping");
  const { generatePartLabel } = useLabelGenerator();
  const { shippingInfo, quotes, shippingLabel, customShipping } =
    useShipping(part);
  const { patchPart } = usePartMutations();
  const [loading, setLoading] = useState(true);
  const [customLoading, setCustomLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [shippingModalOpen, setShippingModalOpen] = useState(false);
  const [shippingQuotes, setShippingQuotes] = useState<
    IShippingQuotes | undefined
  >();
  const [selectedQuoteIdx, setSelectedQuoteIdx] = useState(-1);

  const [customShippingOpen, setCustomShippingOpen] = useState(false);
  const [customTrackingNum, setCustomTrackingNum] = useState("");
  const [customTrackingCarrier, setCustomTrackingCarrier] = useState("FedEx");

  const [addressEditable, setAddressEditable] = useState(false);

  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [pinState, setPinState] = useState<PinProtectState>();

  const [error, setError] = useState<string>("");

  const [labelHtml, setLabelHtml] = useState("");

  const [selectedShippingService, setSelectedShippingService] = useState(0);
  const [prooviaDetails, setProoviaDetails] = useState();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    generatePartLabel(part).then((html) => {
      setLabelHtml(html);
    });
  }, [part]);

  const { register, getValues, setValue, handleSubmit, watch } =
    useForm<IShippingLabelCreateFormFields>({
      defaultValues: {
        weight: part.weight,
        height: part.height,
        length: part.length,
        width: part.width,
        addressLine1:
          part.addressLine1 ??
          shippingInfo.data?.data.contactAddress?.addressLine1,
        addressLine2:
          part.addressLine2 ??
          shippingInfo.data?.data.contactAddress?.addressLine2,
        city: part.city ?? shippingInfo.data?.data.contactAddress?.city,
        county: part.county ?? shippingInfo.data?.data.contactAddress?.county,
        postcode:
          part.postcode ?? shippingInfo.data?.data.contactAddress?.postalCode,
        stateOrProvince:
          part.stateOrProvince ??
          shippingInfo.data?.data.contactAddress?.stateOrProvince,
        fullName: part.fullName ?? shippingInfo.data?.data.fullName,
      },
    });

  const printPartLabel = async () => {
    const html = await generatePartLabel(part);

    var iframe = document?.getElementById("frame") as HTMLIFrameElement;
    var pri = iframe.contentWindow;
    pri?.document.open();
    pri?.document.write(html);
    pri?.document.close();
    pri?.focus();

    //QR code doesn't show up without a timeout
    setTimeout(async () => {
      pri?.print();
    }, 1);
  };

  const printPartLabelImage = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current)
      .then((dataUrl) => {
        // console.log(dataUrl)

        var iframe = document?.getElementById("frame") as HTMLIFrameElement;
        var pri = iframe.contentWindow;
        pri?.document.open();
        pri?.document.write(`<img src=${dataUrl} />`);
        pri?.document.close();
        pri?.focus();

        //QR code doesn't show up without a timeout
        setTimeout(async () => {
          pri?.print();
        }, 1);
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  }, [ref]);

  const getShippingRequest = () => {
    let data = getValues();
    return {
      ...data,
      partDataId: part.id,
    } as any as IShippingQuotesRequest;
  };

  useEffect(() => {
    if (
      !shippingInfo.isLoading &&
      shippingInfo.data?.data.contactAddress?.postalCode &&
      !defaultPartCategory.isLoading
    ) {
      let shipping = shippingInfo.data.data;

      let addressLine2 =
        part.addressLine2 ?? shipping.contactAddress?.addressLine2;
      //regex to remove ebay tracking numbers
      addressLine2 = addressLine2 = addressLine2?.replace(
        /(^|[^a-zA-Z0-9])ebay[a-zA-Z0-9]{7}($|[^a-zA-Z0-9])/g,
        ""
      );

      setValue(
        "addressLine1",
        part.addressLine1 ?? shipping.contactAddress?.addressLine1
      );
      setValue("addressLine2", addressLine2);

      setValue("city", part.city ?? shipping.contactAddress?.city);
      setValue("county", part.county ?? shipping.contactAddress?.county);
      setValue(
        "postcode",
        part.postcode ?? shipping.contactAddress?.postalCode
      );
      setValue(
        "stateOrProvince",
        part.stateOrProvince ?? shipping.contactAddress?.stateOrProvince
      );
      setValue("fullName", part.fullName ?? shipping.fullName);

      if (defaultPartCategory.data?.data) {
        setValue("weight", defaultPartCategory.data.data.weight ?? 0);
        setValue("height", defaultPartCategory.data.data.height ?? 0);
        setValue("length", defaultPartCategory.data.data.length ?? 0);
        setValue("width", defaultPartCategory.data.data.width ?? 0);
      }
    }
  }, [shippingInfo.isLoading, defaultPartCategory.isLoading]);

  // useEffect(() => {
  //   setShippingQuotes(undefined);

  //   if (!getValues("addressLine1") || getValues("addressLine1")!.length < 4) {
  //     setError(
  //       "Please fill in the address line 1. Must be at least 4 characters."
  //     );
  //     return;
  //   }

  //   if (!getValues("postcode") || getValues("postcode")!.length < 5) {
  //     setError(
  //       "Please fill in the address postcode. Must be at least 5 characters."
  //     );
  //     return;
  //   }

  //   if (
  //     !getValues("height") ||
  //     getValues("height") == 0 ||
  //     !getValues("length") ||
  //     getValues("length") == 0 ||
  //     !getValues("width") ||
  //     getValues("width") == 0 ||
  //     !getValues("weight") ||
  //     getValues("weight") == 0
  //   ) {
  //     setError("Please fill in the parcel size and weight.");
  //     return;
  //   }

  //   setError("");
  //   setLoading(true);
  //   quotes.mutate(getShippingRequest(), {
  //     onSuccess: (d) => {
  //       setShippingQuotes(d as any as IShippingQuotes);
  //       setLoading(false);
  //     },
  //   });
  // }, [formChanged, shippingInfo.isLoading]);

  const sendCustomShipping = () => {
    setCustomLoading(true);

    setPinModalOpen(true);
    setPinState({
      onPinSuccess: () => {
        customShipping.mutate(
          {
            partDataId: part.id,
            trackingNumber: customTrackingNum,
            carrier: customTrackingCarrier,
          },
          {
            onSuccess: () => {
              saveHandlers.onSuccess();
              setCustomShippingOpen(false);
            },
            onError: (res: any) => {
              saveHandlers.onError();
            },
            onSettled: () => {
              setCustomLoading(false);
            },
          }
        );
      },
      onPinFailed: () => {},
      pinLabel: "Submit Tracking Information",
    });
  };

  return (
    <>
      <PinProtect
        open={pinModalOpen}
        setOpen={setPinModalOpen}
        pinState={pinState}
      />
      <Modal
        open={customShippingOpen}
        setOpen={setCustomShippingOpen}
        width="max-w-4xl mx-4"
      >
        <div>
          <div className="mb-4">
            <p className="text-lg font-bold mb-2">Custom Tracking Details</p>

            {customLoading ? (
              <LoadingWheel />
            ) : (
              <>
                <div className="flex flex-col gap-4 mb-2">
                  <div>
                    <label className="text-lg font-bold">
                      Tracking Number:{" "}
                    </label>
                    <input
                      type="text"
                      value={customTrackingNum}
                      onChange={(x) => setCustomTrackingNum(x.target.value)}
                    />
                  </div>
                  <div>
                    <label className="text-lg font-bold">
                      Shipping Carrier:{" "}
                    </label>
                    <select
                      value={customTrackingCarrier}
                      onChange={(x) => setCustomTrackingCarrier(x.target.value)}
                    >
                      <option value={"FedEx"}>Fedex</option>
                      <option value={"RoyalMail"}>Royal Mail</option>
                      <option value={"Other"}>Other</option>
                    </select>
                  </div>
                </div>
                <div className="float-right">
                  <ButtonInput
                    label="Submit Tracking Infomation"
                    isSubmit={false}
                    onClick={() => sendCustomShipping()}
                    classes={"mr-2"}
                    disabled={customTrackingNum.length < 5}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={shippingModalOpen}
        setOpen={setShippingModalOpen}
        width="max-w-4xl mx-4"
      >
        <ManageShippingModal part={part} setOpen={setShippingModalOpen} />
      </Modal>

      <Card title="Print Labels" bodyClassName="p-4" containerClassName="mt-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <ButtonInput
              label="Print Part Label"
              isSubmit={false}
              onClick={() => printPartLabel()}
              classes={undefined}
            />
          </div>
          <div>
            <ButtonInput
              label="Print Part Label Image"
              isSubmit={false}
              onClick={() => printPartLabelImage()}
              classes={undefined}
            />
          </div>
          <div>
            <ButtonInput
              label="Manage Shipping Label"
              isSubmit={false}
              disabled={part.orderId == null}
              onClick={() => setShippingModalOpen(true)}
              classes={"mb-2"}
            />
          </div>
          <div>
            <ButtonInput
              label="Set Custom Shipping Info"
              isSubmit={false}
              onClick={() => setCustomShippingOpen(true)}
              //disabled when the part has been shipped
              disabled={part.shippedOn !== null}
              classes={"mb-2"}
            />
          </div>
        </div>
      </Card>
      <Card title="Part Label" bodyClassName="p-4" containerClassName="mt-4">
        <div className="overflow-x-auto">
          {labelHtml && (
            <div ref={ref} dangerouslySetInnerHTML={{ __html: labelHtml }} />
          )}
        </div>
      </Card>
      <Card title="Part Notes" bodyClassName="px-2 pb-4">
        <div>
          <NotesOutput
            resourceId={part.id}
            resourceType={ResourceType.Part}
            shouldScroll={false}
            notes={part.notes ? part.notes : []}
          />
        </div>
      </Card>
      <iframe id="frame" className="h-0 w-0 absolute"></iframe>
    </>
  );
}
