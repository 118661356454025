import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";
import { useNavigate } from "react-router-dom";

import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import useLeads from "../../data/useLeads";
import useMessages from "../../data/useMessages";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { ILead, LeadQualified, LeadStatus } from "../../models/Lead";
import { CollectionReminder, QualifyLead } from "../../services/TemplateService";
import classNames from "../../utils/classNames";
import useNotification from "../notifications/useNotifications";
import Pagination from "../shared/Tables/Pagination";

import openInNewTab from "../../utils/openInNewTab";
import BulkCompleteLeads from "../leads/LeadTable/BulkCompleteLeads";
import LeadsTableSubComponent from "../leads/LeadTable/TableSubComponents/LeadsTableSubComponent";
import ContactFromId from "../contacts/ContactFromId";
import PaymentBubble from "../../utils/paymentBubble";
import AwaitingInventoryStatusDropdown from "../awaitingInventory/AwaitingInventoryStatusDropdown";
import VehicleLocationDropdown from "../vehicles/VehicleLocationDropdown";
import YardStatusDropdown from "../awaitingInventory/YardStatusDropdown";
import VehicleIcons from "../shared/VehicleIcons/VehicleIcons";
import CollectedTableSubComponent from "./CollectedTableSubComponent";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer'}
      {...rest}
    />
  )
}

const colHelper = createColumnHelper<ILead>();

const cols = [
  {
    id: 'select',
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div className="flex justify-center items-center flex-1 p-4" onClick={(e) => e.stopPropagation()}>
        <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
        <button
          onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
          style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
          className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
        >
          {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    ),
  },
  colHelper.display({
    id: "image",
    cell: (info) => (
      <span className="flex justify-center relative" >
        {info.row.original.vehicle?.vehicleImageUrl ? (
          <>
            <img
              className="w-32 h-20 object-contain"
              src={`${info.row.original.vehicle != null ? info.row.original.vehicle.vehicleImageUrl : null}`}
            />
            {info.row.original.vehicle?.vehiclePremium && (
              <StarIcon className="w-8 h-8 text-amber-500 absolute -top-2 -right-2" />
            )}

          </>
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
      </span>
    ),
  }),
  colHelper.accessor("vehicle.bookedCollectionOn", {
    header: "Booked On",
    sortingFn: "auto",
    cell: (info) => <span>{info.getValue() !== null ? date.format(new Date(info.getValue()!), "DD/MM/YYYY") : "Not Set"}</span>,
  }),
  colHelper.accessor("vehicle.collectedOn", {
    id: "vehicle.collectedOn",
    header: "Collected On",
    sortingFn: "auto",
    cell: (info) => <span>{info.getValue() !== null ? date.format(new Date(info.getValue()!), "DD/MM/YYYY") : "Not Set"}</span>,
  }),
  colHelper.accessor("vehicle.vrm", {
    header: "VRM",
    enableSorting: false,
  }),
  colHelper.accessor("vehicle.combinedMake", {
    header: "Make & Model",
    enableSorting: false,
    cell: (info) => <div>
      <p>{info.row.original.vehicle?.combinedMake}</p>
      <p>{info.row.original.vehicle?.combinedModel}</p>
    </div>,
  }),
  colHelper.accessor("contact.fullname", {
    header: "Name & Number",
    sortingFn: "auto",
    cell: (info) => <div>
      <p>{info.getValue()}</p>
      <p>{info.row.original.contact?.telephone1}</p>
    </div>,
  }),
  colHelper.accessor("vehicle.collectedBy", {
    header: "Collected By",
    enableSorting: false,
    cell: (info) => <ContactFromId contactId={info.getValue()!} />,
  }),
  colHelper.accessor("vehicle.vehicleStarting", {
    header: "Vehicle",
    enableSorting: false,
    cell: (info) => <div className="w-24"><VehicleIcons vehicle={info.row.original.vehicle!} /></div>,
  }),
  colHelper.accessor("vehicle.codConfirmedOn", {
    header: "COD Confirmed",
    enableSorting: false,
    cell: (info) => <span className="flex justify-center text-xl">{info.getValue() ? "✔" : "✘"}</span>,
  }),
  colHelper.accessor("paymentStatus", {
    header: "Payment Status",
    enableSorting: false,
    cell: (info) => <span className="flex items-center text-center">{PaymentBubble(info.getValue()!)}</span>,
  }),
  colHelper.accessor("status", {
    header: "Lead Status",
    enableSorting: false,
    cell: (info) => <span>{LeadStatus[info.getValue()]}</span>,
  }),
  colHelper.accessor("vehicle.yardStatus", {
    header: "Status",
    enableSorting: false,
    cell: (info) => <div id={info.row.original.id}>
      <p>Parts Status</p>
      <AwaitingInventoryStatusDropdown vehicle={info.row.original.vehicle!} classes="h-8 w-40 text-sm" />
      <p className="mt-2">Yard Status</p>
      <YardStatusDropdown vehicle={info.row.original.vehicle!} classes="h-8 w-40 text-sm" />
    </div>
  }),
];


export default function VehiclesCollectedTable({ data }: { data: ILead[] }) {
  const [completeOpen, setCompleteOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([{ id: "vehicle.collectedOn", desc: true}]);

  useEffect(() => {
    table.setPageSize(50);
  }, []);

  const table = useReactTable({
    data: data,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <BulkCompleteLeads leads={data} table={table} open={completeOpen} setOpen={setCompleteOpen} />

      <div className="flex justify-between">
        <div>
          <button
            className={classNames(table.getSelectedRowModel().flatRows.length === 0 ? "bg-gray-200" : "bg-gp-blue-600 hover:bg-gp-blue-700", "mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm")}
            type="button"
            disabled={table.getSelectedRowModel().flatRows.length === 0}
            onClick={() => setCompleteOpen(true)}
          >
            Bulk Complete
          </button>
        </div>
      </div>
      <div className="mt-4 bg-white overflow-auto">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-gp-blue-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ▲',
                            desc: ' ▼',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>

                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    onClick={() => openInNewTab(`leads/${row.original.id}`)}
                    className={classNames("hover:bg-gray-100 hover:cursor-pointer")}
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-3 py-3 border text-sm">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <td colSpan={row.getVisibleCells().length}>
                        <CollectedTableSubComponent lead={row.original} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
        <Pagination table={table} />
      </div>
    </>
  );
}
