import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IDashboardStatsResponse } from "../models/Dashboard";

export default function useDashboardStats() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const stats = useQuery({
    queryKey: ["dashboardStats"],
    queryFn: () => get<IDashboardStatsResponse>("/stats"),
    refetchInterval: 1000 * 60,
  });

  return {
    stats,
  };
}
