import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { faCheck, faPencil, faPlus, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import { CheckboxInput } from "../../components/shared/Inputs/CheckboxInput";
import FileUpload from "../../components/shared/Inputs/FileUpload";
import SelectInput from "../../components/shared/Inputs/SelectInput";
import { TextInput } from "../../components/shared/Inputs/TextInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import usePartImageRequirement from "../../data/usePartImageRequirement";
import Modal from "../../layouts/Modal";
import { IPartCategory, IPartImageRequirementGroup, IPartRequiredImage } from "../../models/Part";

export interface IPartCategoryRequiredImagesEditorProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  category: IPartCategory | undefined;
  jumpToSecuritySeal?: boolean;
}

interface IPartCategoryRequiredImageEditorProps {
  group: IPartImageRequirementGroup;
  image: IPartRequiredImage;
  category: IPartCategory | undefined;
  hideEditor: () => void;
}

export interface IGroupPickerProps {
  category: IPartCategory | undefined;
}

const imageTypeOptions = [
  { id: 0, name: "Label" },
  { id: 1, name: "Top" },
  { id: 2, name: "Bottom" },
  { id: 3, name: "Left" },
  { id: 4, name: "Right" },
  { id: 5, name: "Front" },
  { id: 6, name: "Back" },
  { id: 7, name: "Misc" },
  { id: 8, name: "Security Seal" },
  { id: 9, name: "MPN" },
];

function RequiredImageEditor(props: IPartCategoryRequiredImageEditorProps) {
  const { register, handleSubmit, reset, setValue, watch, getValues } =
    useForm<IPartRequiredImage>({ defaultValues: { ...props.image } });
  const { updateImage, createImage, uploadImage } = usePartImageRequirement(
    props.category?.id
  );

  const [toUpload, setToUpload] = useState<any>([]);
  const updateToUploadFiles = (files: any) => setToUpload([...files]);

  const doUploadImage = (img: IPartRequiredImage) => {
    if (toUpload.length == 0) {
      return;
    }
    const file = toUpload[0];

    const formData = new FormData();
    const editedFile = new File(
      [file],
      `${Math.floor(Date.now() / 100)}-edited.jpeg`,
      { type: file.type }
    );
    formData.append(img.id, editedFile);

    uploadImage.mutate(
      { imgId: img.id, data: formData },
      {
        onSuccess: () => {
          setToUpload([]);
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };

  useEffect(() => {
    reset(props.image);
  }, [props.image]);

  useEffect(() => {
    if (watch("type") == 8) {
      setValue("required", true);
      setValue("canHaveMultiple", false);
    }
  }, [watch("type")]);

  const onSubmit = (data: IPartRequiredImage) => {
    if (props.image.id == undefined)
      createImage.mutate({
        group: props.group,
        image: data,
        cback: (d: IPartRequiredImage) => {
          doUploadImage(d);
        },
      });
    else
      updateImage.mutate({
        group: props.group,
        image: data,
        cback: (d: IPartRequiredImage) => {
          doUploadImage(d);
        },
      });

    props.hideEditor();
  };

  const isRequired = getValues("required") ? "Required" : "Optional";

  const labelStr = `${isRequired}, image the part ${getValues("label")}`;

  return (
    <div className="mt-4" id="required-image-editor">
      <p className="text-lg font-semibold pb-2">
        {props.image.id != undefined ? "Edit" : "Create"} Required Image
      </p>
      <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="py-2 px-3 rounded-md border border-gray-300">
          Example App Image Label:
          <br />
          {labelStr}
        </div>
        <CheckboxInput
          label="Image Required"
          register={register}
          registerName="required"
          disabled={props.image.type == 8}
        />
        <CheckboxInput
          label="Can Have Multiple"
          register={register}
          registerName="canHaveMultiple"
          disabled={props.image.type == 8}
        />
        <TextInput
          label="Image Label"
          register={register}
          registerName="label"
        />
        {/* <TextInput label="Image Url" register={register} registerName="exampleUrl"/> */}
        <SelectInput
          label="Image Type"
          register={register}
          registerName="type"
          setValue={setValue}
          watch={watch}
          options={imageTypeOptions}
        />
        <FileUpload
          label={"Upload Photo"}
          accept=".jpg,.png,.jpeg"
          desc="Add pictures to this part..."
          multiple
          updateFilesCb={updateToUploadFiles}
        />
        <ButtonInput
          label={props.image.id != undefined ? "Save Image" : "Create Image"}
          isSubmit={true}
          onClick={undefined}
          classes={"mt-2"}
        />
      </form>
    </div>
  );
}

function GroupPicker(props: IGroupPickerProps) {
  const { register, handleSubmit, reset, setValue, watch } =
    useForm<IPartImageRequirementGroup>({
      defaultValues: { id: props.category?.partImageRequirementGroup?.id },
    });
  const { allGroups, useGroup } = usePartImageRequirement(props.category?.id);
  const [groupOptions, setGroupOptions] = useState<
    { id: string; name: string }[]
  >([]);

  useEffect(() => {
    if (!allGroups.isLoading && allGroups.data != null) {
      setGroupOptions(allGroups.data.map((x) => ({ id: x.id, name: x.name })));
    }
  }, [allGroups.isLoading, allGroups.data]);

  const onSubmit = (data: IPartImageRequirementGroup) => {
    useGroup.mutate(data);
  };

  return (
    <div className="mt-4 border-t border-slate-400 pt-2">
      <p className="text-lg font-semibold pb-2">Change Requirement Group</p>
      <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <SelectInput
          label="Image Type"
          register={register}
          registerName="id"
          setValue={setValue}
          watch={watch}
          options={groupOptions}
        />
        <ButtonInput
          label={"Use Group"}
          isSubmit={true}
          onClick={undefined}
          classes={"mt-2"}
        />
      </form>
    </div>
  );
}

export default function PartCategoryRequiredImagesEditor(
  props: IPartCategoryRequiredImagesEditorProps
) {
  const {
    imageRequirements,
    updateGroup,
    createGroup,
    deleteGroup,
    deleteImage,
  } = usePartImageRequirement(props.category?.id);
  const { register, handleSubmit, reset } = useForm<IPartImageRequirementGroup>(
    {}
  );
  const [selectedImage, setSelectedImage] = useState<
    IPartRequiredImage | undefined
  >(undefined);
  const [confirmDelete, setConfirmDelete] = useState<number>(-1);
  const [confirmGroupDelete, setGroupConfirmDelete] = useState(false);
  const [makeNew, setMakeNew] = useState(false);

  useEffect(() => {
    setSelectedImage(undefined);
    setConfirmDelete(-1);
    setMakeNew(false);
    setGroupConfirmDelete(false);
    if (!imageRequirements.isLoading && imageRequirements.data != null) {
      reset(imageRequirements.data);
    }

    if (props.jumpToSecuritySeal) {
      const securitySeal = imageRequirements.data?.partRequiredImages.find(x=>x.type == 8);

      if (securitySeal != undefined) {
        setSelectedImage(securitySeal);
        setTimeout(() => {
          const el = document.getElementById("required-image-"+securitySeal.id);
          if (el != null) {
            el.scrollIntoView();
          }
        }, 100);
      }
      else{
        setSelectedImage({
          label: "Security Seal",
          type: 8,
          required: true,
          canHaveMultiple: false,
        } as IPartRequiredImage);
        setTimeout(() => {
          const el = document.getElementById("required-image-editor");
          if (el != null) {
            el.scrollIntoView();
            window.scrollBy(0, -100);
          }
        }, 100);
      }
    }

  }, [imageRequirements.isLoading, imageRequirements.data, props.open]);

  const onSubmitUpdate = (data: IPartImageRequirementGroup) => {
    updateGroup.mutate(data);
  };

  const onSubmitNew = (data: IPartImageRequirementGroup) => {
    createGroup.mutate(data);
  };

  const onDeleteGroup = () => {
    deleteGroup.mutate(imageRequirements.data!);
    props.setOpen(false);
  };

  const onDeleteImage = (img: IPartRequiredImage) => {
    deleteImage.mutate(
      { group: imageRequirements.data!, image: img },
      {
        onSuccess: () => {
          setConfirmDelete(-1);
          setSelectedImage(undefined);
        },
      }
    );
  };

  const hideEditor = () => {
    setSelectedImage(undefined);
    if (props.jumpToSecuritySeal) {
      props.setOpen(false);
    }
  };

  return (
    <Modal open={props.open} setOpen={props.setOpen} width="max-w-3xl">
      <p className="text-xl font-semibold">{props.category?.name}</p>
      {imageRequirements.isLoading ? (
        <LoadingWheel />
      ) : imageRequirements.data == null || makeNew ? (
        <>
          <p>Creating image requirements group</p>
          <div className="mt-4">
            <form
              onSubmit={handleSubmit(onSubmitNew)}
              className="flex flex-col gap-y-4"
            >
              <TextInput
                label="Image Requirement Group Name"
                register={register}
                registerName="name"
              />
              <ButtonInput
                label={"Create"}
                isSubmit={true}
                onClick={undefined}
                classes={"mt-2"}
              />
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="mt-4">
            <form
              onSubmit={handleSubmit(onSubmitUpdate)}
              className="flex flex-col gap-y-4"
            >
              <TextInput
                label="Image Requirement Group Id"
                register={register}
                registerName="id"
                disabled
              />
              <TextInput
                label="Image Requirement Group Name"
                register={register}
                registerName="name"
              />
              <div className="flex flex-row justify-between">
                {confirmGroupDelete ? (
                  <>
                    <ButtonInput
                      label={"Delete"}
                      isSubmit={false}
                      onClick={() => onDeleteGroup()}
                      classes={"mt-2 w-[49%]"}
                    />
                    <ButtonInput
                      label={"Cancel"}
                      isSubmit={false}
                      onClick={() => setGroupConfirmDelete(false)}
                      classes={"mt-2 w-[49%]"}
                    />
                  </>
                ) : (
                  <>
                    <ButtonInput
                      label={"Save"}
                      isSubmit={true}
                      onClick={undefined}
                      classes={"mt-2 w-1/3"}
                    />
                    <ButtonInput
                      label={"Create New"}
                      isSubmit={false}
                      onClick={() => setMakeNew(true)}
                      classes={"mt-2 w-1/3 mx-2"}
                    />
                    <ButtonInput
                      label={"Delete"}
                      isSubmit={false}
                      onClick={() => setGroupConfirmDelete(true)}
                      classes={"mt-2 w-1/3"}
                    />
                  </>
                )}
              </div>
            </form>
          </div>
          <div className="mt-4 relative pt-2 border-t border-slate-400">
            <p className="text-lg font-semibold pb-2">
              Required Images
              <FontAwesomeIcon
                className="absolute right-5 rounded-full cursor-pointer hover:ring-2 hover:ring-blue-500"
                icon={faPlus}
                onClick={() =>
                  setSelectedImage({ type: 0 } as IPartRequiredImage)
                }
              />
            </p>
            <div className="flex flex-row overflow-x-scroll gap-x-2 pb-2">
              {imageRequirements.data.partRequiredImages.map((x, i) => (
                <div
                  key={x.id}
                  id={"required-image-"+x.id}
                  className={
                    "relative min-w-fit p-2 border border-slate-400 rounded-md shadow-md" +
                    (selectedImage?.id == x.id ? " bg-blue-200" : "")
                  }
                >
                  <div className="absolute pr-2 pb-2 bg-white flex flex-row gap-x-2 rounded-br-lg">
                    {confirmDelete == i ? (
                      <>
                        <p>Delete?</p>
                        <FontAwesomeIcon
                          className="border border-slate-400 p-1 rounded-full cursor-pointer hover:ring-2 hover:ring-blue-500"
                          icon={faCheck}
                          onClick={() => onDeleteImage(x)}
                        />
                        <FontAwesomeIcon
                          className="border border-slate-400 p-1 rounded-full cursor-pointer hover:ring-2 hover:ring-blue-500"
                          icon={faXmark}
                          onClick={() => setConfirmDelete(-1)}
                        />
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          className="border border-slate-400  p-1 rounded-full cursor-pointer hover:ring-2 hover:ring-blue-500"
                          icon={faPencil}
                          onClick={() => setSelectedImage(x)}
                        />
                        <FontAwesomeIcon
                          className="border border-slate-400 p-1 rounded-full cursor-pointer hover:ring-2 hover:ring-blue-500"
                          icon={faTrashCan}
                          onClick={() => setConfirmDelete(i)}
                        />
                      </>
                    )}
                  </div>
                  {/* <FontAwesomeIcon className="absolute bg-white p-1 rounded-full cursor-pointer hover:ring-2 hover:ring-blue-500" icon={faPencil} onClick={()=>setSelectedImage(x)}/> */}
                  <img src={x.exampleUrl} className="h-[20rem]" />
                  <p className="text-center pt-2 text-wrap">{x.label}</p>
                  <p className="text-center italic text-sm">
                    {imageTypeOptions.find((y) => y.id == x.type)?.name}
                    <span className="text-red-500">
                      {x.required ? " *required" : ""}{" "}
                      {x.canHaveMultiple ? " *multiple" : ""}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
          {selectedImage != undefined && (
            <RequiredImageEditor
              category={props.category}
              hideEditor={() => hideEditor()}
              group={imageRequirements.data}
              image={selectedImage}
            />
          )}
        </>
      )}
      <GroupPicker category={props.category} />
    </Modal>
  );
}
