import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IContact } from "../models/Contact";
import { IEbayOffer } from "../models/EbayOffer";

export default function useOffers() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const create = useMutation(
    (part: IEbayOffer) => post(`/part/offers`, part),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts"]);
        queryClient.invalidateQueries(["pagedTable"]);
      },
    }
  );

  const update = useMutation(
    (offer: IEbayOffer) => put(`/part/offers/${offer.id}`, offer),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts"]);
        queryClient.refetchQueries(["pagedTable"]);
      },
    }
  );

  return {
    create,
    update,
  };
}
