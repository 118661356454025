import { useEffect, useState } from "react";
import useSinglePart from "../../../data/useSinglePart";
import { IPart } from "../../../models/Part";
import LoadingWheel from "../../shared/LoadingWheel";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import { useQueryClient } from "@tanstack/react-query";
import { IPartAspect } from './../../../models/EbayOffer';
import classNames from "../../../utils/classNames";
import usePartAspectsMutations from "../../../data/usePartAspectsMutations";

interface ebayTableAspectsProps {
  part: IPart;
  button?: boolean;
}

export default function EbayTableAspects({ part, button = true }: ebayTableAspectsProps) {
  // const { singlePart } = useSinglePart(part.id); //Inefficient to use this here
  const { createCustomAspects } = usePartAspectsMutations();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [aspects, setAspects] = useState<IPartAspect[]>(part.aspects);

  // useEffect(() => {
  //   if (!singlePart.isLoading && singlePart.data) {
  //     setIsLoading(false);

  //     setAspects(singlePart.data.data.aspects.filter((aspect) => aspect.value));
  //   }
  // }, [singlePart.isLoading, singlePart.data])

  const generateAspects = () => {
    setIsLoading(true);

    // addDefaultCustomAspects();
    createCustomAspects.mutate(part.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", part.id]);
      },
      onSettled: () => setIsLoading(false)
    });

  };


  if (isLoading) {
    return (
      <LoadingWheel />
    )
  }

  return (
    <div className="flex gap-x-4 items-center">
      <div className={classNames(aspects.length <= 5 ? "font-bold text-red-500" : "")}>
        Number of Aspects: {aspects.length}
      </div>

      {button &&
        <div>
          <ButtonInput label="Generate Aspects" isSubmit={false} onClick={() => generateAspects()} classes={undefined} />
        </div>
      }
    </div>
  )
}