import { useEffect, useState } from "react";
import date from "date-and-time";
import Card from "../../components/shared/Card/Card";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useLeads from "../../data/useLeads";
import { ILead } from "../../models/Lead";
import CalendarDayMap from "../../components/calendar/CalendarDayMap";
import MonthView from "../../components/calendar/MonthView";



export default function Calendar({ vrm, editable = true, waiting = true }: { vrm?: string, editable?: boolean, waiting?: boolean }) {
  const { setInfo } = usePageNameContext();
  const { leads: allLeads } = useLeads();
  const [dayLeads, setDayLeads] = useState<ILead[] | undefined>(undefined);
  const [leadOnMap, setLeadOnMap] = useState<ILead | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const handleSelectedDate = (date: Date) => {
    // console.log("date changed");
    setSelectedDate(date);
  }

  useEffect(() => {
    setInfo({
      name: "Calendar",
      desc: "View and manage your lead collection dates",
    });
  }, []);

  useEffect(() => {
    if (!allLeads.isLoading) {

      let dayLeads = allLeads.data?.data.leads.filter((lead) =>
        lead.vehicle?.scheduledCollectionOn !== null &&
        lead.vehicle?.collectedOn === null &&
        date.format(new Date(lead.vehicle?.scheduledCollectionOn!), "YYYY-MM-DD") === date.format(selectedDate, "YYYY-MM-DD")
      )

      setDayLeads(dayLeads);
      setIsLoading(false);
    }
  }, [allLeads.isLoading, allLeads.data, selectedDate]);

  return allLeads.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>

      <Card containerClassName="mt-6">
        <div className="p-2">
          <div>

            {/* Calendar */}
            <MonthView selectedDate={selectedDate} setSelectedDate={handleSelectedDate} setLeadOnMap={setLeadOnMap} vrm={vrm} editable={editable} waiting={waiting} />

            {/* Map */}
            <CalendarDayMap leads={dayLeads!} selectedLead={leadOnMap} />

            {/* Timeline */}
            {/* {waiting &&
              <JobTimelineView selectedDate={selectedDate} setSelectedDate={handleSelectedDate} />
            } */}
          </div>
        </div>
      </Card>
    </>
  )

}