import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { RadioGroup } from "@headlessui/react";

import DriverLeadDetails from "../../components/driver/DriverLeadDetails";
import DriverPaymentDetails from "../../components/driver/DriverPaymentDetails";
import DriverUpload from "../../components/driver/DriverUpload";
import DriverNotes from "../../components/leads/LeadInformation/DriverNotes";
import useNotification from "../../components/notifications/useNotifications";
import ConfirmModal from "../../components/shared/Confirm/ConfirmModal";
import LoadingWheel from "../../components/shared/LoadingWheel";
import VehicleDetails from "../../components/vehicles/VehicleDetails";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useSingleLead from "../../data/useSingleLead";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../models/Lead";
import classNames from "../../utils/classNames";
import getAddress from "../../utils/getAddress";

export default function DriverLead() {
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const { saveHandlers } = useDefaultCRUDHandlers("Lead");
  const { setInfo } = usePageNameContext();
  const { leadId } = useParams();
  const { lead: singleLead, remove } = useSingleLead(leadId!);
  const [lead, setLead] = useState<ILead | undefined>();
  const [loading, setLoading] = useState(true);
  const [leadName, setLeadName] = useState<string | undefined>("");
  const [open, setOpen] = useState(false);
  const [viewType, setViewType] = useState(1);
  const viewTypes = [
    { value: 0, label: "Lead Information" },
    { value: 1, label: "Upload Pictures" },
    // { value: 2, label: "SMS" },
  ];

  const handleDelete = () => {
    remove.mutate(leadId!, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        navigate(-1);
      },
      onError: (error) => {
        console.log(error);
        addNotification({
          variant: "error",
          primaryText: error,
        });
      },
    });
  };

  useEffect(() => {
    if (leadName) {
      setInfo({
        name: `${leadName}'s Lead ${lead?.vehicle?.vrm ? ` - ${lead.vehicle.vrm}` : ""
          }`,
        desc: `View and manage ${leadName}'s Lead`,
      });
    }
  }, [setInfo, leadId, setLeadName, leadName]);

  useEffect(() => {
    if (!singleLead.isLoading) {

      if (singleLead.data?.data) {
        // console.log(singleLead.data?.data);
        setLead(singleLead.data?.data);
        setLeadName(singleLead.data?.data.contact?.fullname);
        setLoading(false);
      } else {
        addNotification({
          variant: "error",
          primaryText: "Lead not found",
        });
        navigate(-1);
      }
    }
  }, [leadId, singleLead.isLoading, singleLead.data?.data, navigate]);

  const pages = [
    { to: "..", label: "Leads", current: false },
    {
      to: leadId && lead ? `/leads/${leadId}` : "",
      label: leadId && lead ? `Lead for ${lead.contact?.fullname}` : "New Lead",
      current: true,
    },
  ];

  return loading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <ConfirmModal
        title="Delete Lead?"
        message="Are you sure you want to delete this lead? This is a permanent action."
        confirmButtonText={null}
        onConfirm={handleDelete}
        open={open}
        setOpen={setOpen}
        isLoading={false}
      />
      {/* <Breadcrumbs pages={pages} /> */}

      <RadioGroup value={viewType} onChange={setViewType} className="my-4">
        <RadioGroup.Label className="sr-only">
          Choose which view to display
        </RadioGroup.Label>
        <div className="grid grid-cols-2 gap-3">
          {viewTypes.map((type) => (
            <RadioGroup.Option
              key={`${type.value}`}
              value={type.value}
              className={({ active, checked }) =>
                classNames(
                  active ? "ring-2 ring-offset-2 ring-gp-blue-500" : "",
                  checked
                    ? "bg-gp-blue-600 border-transparent text-white hover:bg-gp-blue-700"
                    : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                  "cursor-pointer focus:outline-none border rounded-md py-1.5 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                )
              }
            >
              <RadioGroup.Label as="span">{type.label}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      {viewType === 0 && (
        <div className="mt-4 flex flex-col md:grid md:grid-cols-2 gap-6">
          
          <div className="col-span-1 bg-white rounded-lg shadow">
            <iframe
              className="min-h-[50vh]"
              title="Google map"
              style={{ border: 0, borderRadius: "0.5rem" }}
              loading="lazy"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAJWEQovZtn6KkghJ5z6maiL0G6KHjwbS4&q=${getAddress(
                lead?.vehicle
              )}&zoom=9`}
              width="100%"
              height="100%"
            ></iframe>
          </div>

          <DriverLeadDetails lead={lead!} />

          <DriverNotes lead={lead!} />

          <VehicleDetails driver={true} vehicle={lead?.vehicle!} />

          <DriverPaymentDetails lead={lead!} />
        </div>
      )}

      {viewType === 1 && (
        <div>
          <DriverUpload lead={lead!} />
        </div>
      )}

    </>
  );
}
