import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ISingleSettingResponse } from "../models/Setting";

export default function useSingleSetting(settingKey: string) {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const singleSetting = useQuery({
    queryKey: ["settingKey", settingKey],
    queryFn: () => get<ISingleSettingResponse>(`/setting/${settingKey}`),
  });

  return {
    singleSetting
  };
}
