import { resourceLimits } from "worker_threads";
import { EbayListingFormValues } from "../components/ebayListings/EditListing";
import { IPart } from "../models/Part";
import { DateTime } from "luxon";
import { FormatType, IEbayOffer } from "../models/EbayOffer";

type checkEbayDisabledProps = {
  part: IPart;
  offer: IEbayOffer;
  ignoreListingTime?: boolean;
};

export type ebayChecks = {
  error: string;
  isDisabled: boolean;
};

export default function checkEbayDisabled({ part, offer, ignoreListingTime = false }: checkEbayDisabledProps) {

  let result: ebayChecks = {
    error: "",
    isDisabled: false,
  };


  if (part.soldDate !== null) {
    result.error = "This part has been sold and cannot be edited.";
    result.isDisabled = true;
    return result;
  }

  if (part.offers.length === 0) {
    result.error = "This part has no listings created.";
    result.isDisabled = true;
    return result;
  }

  //Imported parts can't be relisted
  if (part.offers.length > 0 && part.offers[0].publishedOn !== null && part.offers[0].ebayId === null) {
    result.error = "This part has been imported and cannot be edited.";
    result.isDisabled = true;
    return result;
  }

  // Title has to be 1-80 characters
  if (!offer.title || offer.title.length < 1 || offer.title.length > 80) {
    result.error = "Title must be between 1 and 80 characters.";
    result.isDisabled = true;
    return result;
  }

  if (ignoreListingTime === false) {
    // Make sure listingStarted is after now
    if (!offer.listingStarted || DateTime.fromISO(offer.listingStarted) < DateTime.now()) {
      result.error = "Listing start date must be in the future.";
      result.isDisabled = true;
      return result;
    }

    // Listing has to start within 3 weeks
    if (DateTime.fromISO(offer.listingStarted) > DateTime.now().plus({ weeks: 3 })) {
      result.error = "Listing start date must be within 3 weeks.";
      result.isDisabled = true;
      return result;
    }
  }

  if (offer.format === FormatType.Auction) {
    // Make sure auction start price is set
    if (!offer.auctionStartPrice || offer.auctionStartPrice <= 0) {
      result.error = "Auction start price must be set.";
      result.isDisabled = true;
      return result;
    }
    // Make sure auction reserve price is set
    if (!offer.auctionReservePrice || offer.auctionReservePrice <= 0) {
      result.error = "Auction reserve price must be set.";
      result.isDisabled = true;
      return result;
    }

    // Make sure auction start price is less than reserve price
    if (offer.auctionStartPrice && offer.auctionReservePrice && offer.auctionStartPrice > offer.auctionReservePrice) {
      result.error = "Auction start price must be less than reserve price.";
      result.isDisabled = true;
      return result;
    }
  } else if (offer.format === FormatType.FixedPrice) {
    // Make sure buy it now price is set
    if (!offer.buyItNowPrice || offer.buyItNowPrice <= 0) {
      result.error = "Buy it now price must be set.";
      result.isDisabled = true;
      return result;
    }

    // Make sure best offer auto accept price is set
    if (offer.enableBestOffer && (!offer.autoAcceptPrice || offer.autoAcceptPrice <= 0)) {
      result.error = "Best offer auto accept price must be set.";
      result.isDisabled = true;
      return result;
    }

    // Make sure best offer is lower than buy it now price
    if (offer.enableBestOffer && (!offer.autoAcceptPrice || offer.autoAcceptPrice > offer.buyItNowPrice)) {
      result.error = "Best offer price must be lower than buy it now price.";
      result.isDisabled = true;
      return result;
    }

    if (offer.fulfillmentPolicyId == null || offer.fulfillmentPolicyId.length === 0 || offer.paymentPolicyId == null || offer.paymentPolicyId.length === 0) {
      result.error = "Fulfillment and payment policies must be set.";
      result.isDisabled = true;
      return result;
    }

    // if (offer.condition > 2000 && (offer.conditionDescription == null || offer.conditionDescription?.length === 0)) {
    //   result.error = "Condition description must be set.";
    //   setIsDisabled(true);
    //   return;
    // }
  }

  //Need at least thumbnail
  if (part.images.length === 0) {
    result.error = "You must upload at least one image.";
    result.isDisabled = true;
    return result;
  }

  // Make sure fields have values
  if (offer.categoryId &&
    offer.fulfillmentPolicyId &&
    offer.paymentPolicyId &&
    offer.returnPolicyId &&
    offer.locationKey
  ) {
    return result;
  } else {
    result.error = "Please fill out all required fields.";
    result.isDisabled = true;
    return result;
  }

};




// OG Code

// if (part.soldDate !== null) {
//   setError("This part has been sold and cannot be edited.");
//   setIsDisabled(true);
//   return;
// }

// //Imported parts can't be relisted
// if (part.offers.length > 0 && part.offers[0].publishedOn !== null && part.offers[0].ebayId === null) {
//   setError("This part has been imported and cannot be edited.");
//   setIsDisabled(true);
//   return;
// }

// // Title has to be 1-80 characters
// if (!offer.title || offer.title.length < 1 || offer.title.length > 80) {
//   setError("Title must be between 1 and 80 characters.");
//   setIsDisabled(true);
//   return;
// }

// // Make sure listingStarted is after now
// if (!offer.listingStarted || DateTime.fromISO(offer.listingStarted) < DateTime.now()) {
//   setError("Listing start date must be in the future.");
//   setIsDisabled(true);
//   return;
// }

// // Listing has to start within 3 weeks
// if (DateTime.fromISO(offer.listingStarted) > DateTime.now().plus({ weeks: 3 })) {
//   setError("Listing start date must be within 3 weeks.");
//   setIsDisabled(true);
//   return;
// }

// if (offer.format === FormatType.Auction) {
//   // Make sure auction start price is set
//   if (!offer.auctionStartPrice || offer.auctionStartPrice <= 0) {
//     setError("Auction start price must be set.");
//     setIsDisabled(true);
//     return;
//   }
//   // Make sure auction reserve price is set
//   if (!offer.auctionReservePrice || offer.auctionReservePrice <= 0) {
//     setError("Auction reserve price must be set.");
//     setIsDisabled(true);
//     return;
//   }

//   // Make sure auction start price is less than reserve price
//   if (offer.auctionStartPrice && offer.auctionReservePrice && offer.auctionStartPrice > offer.auctionReservePrice) {
//     setError("Auction start price must be less than reserve price.");
//     setIsDisabled(true);
//     return;
//   }
// } else if (offer.format === FormatType.FixedPrice) {
//   // Make sure buy it now price is set
//   if (!offer.buyItNowPrice || offer.buyItNowPrice <= 0) {
//     setError("Buy it now price must be set.");
//     setIsDisabled(true);
//     return;
//   }

//   // Make sure best offer auto accept price is set
//   if (offer.enableBestOffer && (!offer.autoAcceptPrice || offer.autoAcceptPrice <= 0)) {
//     setError("Best offer auto accept price must be set.");
//     setIsDisabled(true);
//     return;
//   }

//   // Make sure best offer is lower than buy it now price
//   if (offer.enableBestOffer && (!offer.autoAcceptPrice || offer.autoAcceptPrice > offer.buyItNowPrice)) {
//     setError("Best offer price must be lower than buy it now price.");
//     setIsDisabled(true);
//     return;
//   }

//   if (offer.fulfillmentPolicyId == null || offer.fulfillmentPolicyId.length === 0 || offer.paymentPolicyId == null || offer.paymentPolicyId.length === 0) {
//     setError("Fulfillment and payment policies must be set.");
//     setIsDisabled(true);
//     return;
//   }

//   // if (offer.condition > 2000 && (offer.conditionDescription == null || offer.conditionDescription?.length === 0)) {
//   //   setError("Condition description must be set.");
//   //   setIsDisabled(true);
//   //   return;
//   // }
// }

// //Need at least thumbnail
// if (part.images.length === 0) {
//   setError("You must upload at least one image.");
//   setIsDisabled(true);
//   return;
// }

// // Make sure fields have values
// if (offer.categoryId &&
//   offer.fulfillmentPolicyId &&
//   offer.paymentPolicyId &&
//   offer.returnPolicyId &&
//   offer.locationKey
// ) {
//   setError("");
//   setIsDisabled(false);
//   return;
// } else {
//   setError("Please fill out all required fields.");
//   setIsDisabled(true);
//   return;
// }