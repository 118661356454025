import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllContactsResponse, IContact } from "../models/Contact";
import { IAllTrucksResponse, ITruck } from "../models/Truck";

export default function useTrucks(includeInactive = false) {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const trucks = useQuery({
    queryKey: ["trucks", includeInactive],
    queryFn: () => get<IAllTrucksResponse>(`/truck?includeInactive=${includeInactive}`),
    refetchInterval: 1000 * 60,
  });

  const create = useMutation(
    (truck: ITruck) => post(`/truck`, truck),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trucks"]);
      },
    }
  );

  const update = useMutation(
    (truck: ITruck) => put(`/truck/${truck.id}`, truck),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["trucks"]);
      },
    }
  );

  return {
    trucks,
    create,
    update,
  };
}
