import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import useCategories from "../../data/useCategories";
import Modal from "../../layouts/Modal";
import { IPart, IPartCategory } from "../../models/Part";
import useNotification from "../notifications/useNotifications";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import ComboBoxInput from "../shared/Inputs/ComboBoxInput";
import LoadingWheel from "../shared/LoadingWheel";
interface IFormData {
  category: IPartCategory;
}

export default function MovePartsModal({ open, setOpen, parts }: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, parts: IPart[] }) {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<IFormData>({});
  const { addNotification } = useNotification();
  const { categories, bulkUpdatePartCategories } = useCategories();
  const [isLoading, setIsLoading] = useState(true);

  const onSubmit = (data: IFormData) => {
    
    if (!data.category) {
      addNotification({
        primaryText: "Could not find category",
        variant: "error",
      });
      return;
    };

    const partIds = parts.map(part => part.id);

    const body = {
      categoryId: data.category.id,
      parts: partIds,
    };

    bulkUpdatePartCategories.mutate(body, {
      onSuccess: () => {
        addNotification({
          primaryText: "Parts moved successfully",
          variant: "success",
        });
        setOpen(false);
      },
      onError: (error) => {
        addNotification({
          primaryText: "Error moving parts",
          variant: "error",
        });
      }
    });
    
  }

  useEffect(() => {
    if (!categories.isLoading && categories.data) {
      setIsLoading(false);
    }

  }, [categories.isLoading, categories.data]);


  if (isLoading) {
    return (
      <LoadingWheel />
    )
  }

  return (
    <Modal open={open} width={"max-w-6xl"} setOpen={setOpen}>
      <h1 className="text-lg">Move Parts to a new Category</h1>
      <hr className="mb-4" />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <p>You have selected {parts.length} parts to move.</p>
          <p>Choose a category to move them to:</p>
        </div>

        <ComboBoxInput options={categories.data?.data} searchField={"name"} register={register} setValue={setValue} registerName={"category"} />

        <div className="flex justify-end">
          <ButtonInput label="Move Parts" isSubmit={true} onClick={undefined} classes={undefined} />
        </div>
      </form>
    </Modal>
  )
}