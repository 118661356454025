import { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import useVehiclesMutations from "../../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../../models/Lead";
import { IVehicle } from "../../../models/Vehicle";
import Card from "../../shared/Card/Card";

export default function VehicleNotes({ lead }: { lead: ILead }) {
  const { update } = useVehiclesMutations();
  const queryClient = useQueryClient();
  const { saveHandlers } = useDefaultCRUDHandlers("Vehicle Notes");
  const [vehicleNotes, setVehicleNotes] = useState(lead.vehicle?.notes);

  const handleSaveVehicleNotes = () => {
    const updatedVehicle: IVehicle = {
      ...lead.vehicle!,
      notes: vehicleNotes!
    };

    update.mutate(updatedVehicle, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setTimeout(() => {
          queryClient.invalidateQueries(["lead", lead.id]);
        }, 500);
      },
      onError: saveHandlers.onError
    })
  }

  return (
    <Card title="Vehicle Notes" bodyClassName="p-4">
      <div className="space-y-4">

        <textarea
          className="w-full"
          rows={5}
          defaultValue={lead.vehicle?.notes!}
          onChange={(e) => setVehicleNotes(e.target.value)}
          onBlur={() => handleSaveVehicleNotes()}
        />

        <button
          className="rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
          onClick={() => handleSaveVehicleNotes()}
        >
          Update
        </button>

      </div>
    </Card>
  );
}
