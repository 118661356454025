import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { usePageNameContext } from "../../contexts/PageTitleContext";
import useCategories from "../../data/useCategories";
import usePartAssembly from "../../data/usePartAssembly";
import usePartAssemblySingle from "../../data/usePartAssemblySingle";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { IPartAssembly, IPartAssemblyOnly } from "../../models/Part";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { TextInput } from "../shared/Inputs/TextInput";
import LoadingWheel from "../shared/LoadingWheel";
import { IPartCategory } from './../../models/Part';

interface IPartAssemblySingleEditorProps {
  assembly: IPartAssembly | undefined;
  open: boolean;
  setOpen: any;
}

export default function PartAssemblySingleEditor(props: IPartAssemblySingleEditorProps) {
  const { setInfo } = usePageNameContext();
  const { assembly } = usePartAssemblySingle(props.assembly?.id);
  const { categories: allCategories } = useCategories();
  const { update, remove, toggleCategory } = usePartAssembly();
  const { saveHandlers } = useDefaultCRUDHandlers("Part Assembly");
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState<IPartCategory[]>([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [onlySelected, setOnlySelected] = useState(false);

  const { register, handleSubmit, reset } = useForm<IPartAssemblyOnly>();

  useEffect(() => {
    setInfo({
      name: "Part Assembly Editor",
      desc: "View and edit your assemblies here",
    });
  }, []);

  useEffect(() => {
    if (!assembly.isLoading && assembly.data && !allCategories.isLoading && allCategories.data) {
      reset({ ...assembly.data.data });

      var filter = allCategories.data?.data;

      if (onlySelected) {
        filter = filter.filter(x => assembly.data?.data?.partAssemblyPartCategories?.find(c => c.partCategoryId === x.id));
      }

      filter = filter.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase()));

      setCategories(filter);
      setIsLoading(false);
    }
  }, [assembly.isLoading, assembly.data, allCategories.isLoading, allCategories.data, searchTerm, onlySelected]);

  const onSubmit = (data: IPartAssemblyOnly) => {
    update.mutate(data as IPartAssembly, {
      onSuccess: () => {
        props.setOpen(false);
      }

    });
  }

  const handleToggleCategory = (categoryId: string) => {
    toggleCategory.mutate({ assemblyId: props.assembly?.id!, categoryId: categoryId }, saveHandlers);
  };

  const promptDelete = () => {
    setConfirmDelete(true);
  }

  const handleDelete = () => {
    remove.mutate(props.assembly?.id!, {
      onSuccess: () => {
        props.setOpen(false);
      }

    });
  }

  return (
    <Modal open={props.open} setOpen={props.setOpen} width="max-w-4xl">
      <div className="flex flex-col">
        <h2 className="text-2xl font-semibold mb-2">{props.assembly?.name} Assembly</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput label={"Assembly Name"} register={register} registerName={"name"} />
          <div className="flex flex-row gap-x-4 mt-4">
            {
              confirmDelete ? (
                <div className="flex flex-row gap-x-4">
                  <ButtonInput isSubmit={false} label={"Confirm Delete"} onClick={handleDelete} classes={"!bg-red-600 hover:!bg-red-700 h-fit self-end"} />
                  <ButtonInput isSubmit={false} label={"Cancel"} onClick={() => setConfirmDelete(false)} classes={"h-fit self-end"} />
                </div>
              ) : (
                <div className="flex flex-row gap-x-4">
                  <ButtonInput isSubmit={true} label={"Save"} onClick={undefined} classes={"h-fit self-end"} />
                  <ButtonInput isSubmit={false} label={"Delete"} onClick={promptDelete} classes={"h-fit self-end"} />
                </div>
              )
            }
          </div>
        </form>
      </div>

      <hr className="mt-4" />

      <div className="flex flex-col gap-y-2">
        <h2 className="text-xl font-semibold mt-4">Attached Categories</h2>

        <div className="flex items-center flex-row gap-x-4">
          <input type="text" placeholder="Search Categories" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="p-2" />
          <label>Hide Unselected</label>
          <input type="checkbox" checked={onlySelected} onChange={(e) => setOnlySelected(e.target.checked)} className="p-2" />
        </div>

        <div className="flex flex-col max-h-[40vh] overflow-y-scroll border border-slate-300 rounded-md mt-2">
          {isLoading ? <LoadingWheel /> :
            categories.map((category) => (
              <div key={category.id} className="p-2 hover:bg-slate-200 flex items-center flex-row gap-x-4">
                {
                  assembly?.data?.data?.partAssemblyPartCategories?.find((c) => c.partCategoryId === category.id) ? (
                    <ButtonInput isSubmit={false} label={"-"} onClick={() => handleToggleCategory(category.id)} classes={"h-fit self-end"} />
                  ) : (
                    <ButtonInput isSubmit={false} label={"+"} onClick={() => handleToggleCategory(category.id)} classes={"h-fit self-end"} />
                  )
                }
                <p>{category.name}</p>
              </div>
            )
            )}
        </div>
      </div>
    </Modal>
  )
}