import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IEbayCategoryConditionResponse } from "../models/EbayOffer";

export default function useEbayConditions(categoryId: string) {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();


  const conditions = useQuery({
    queryKey: ["ebayConditions", categoryId],
    queryFn: () =>
      get<IEbayCategoryConditionResponse>(
        `/ebay/conditions?${categoryId ? `query=${categoryId}` : ""}`
      ).then((res) => res.data),
  });

  return {
    conditions,
  };
}
