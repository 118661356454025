import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";

import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import Card from "../../components/shared/Card/Card";
import ConfirmModal from "../../components/shared/Confirm/ConfirmModal";
import ReasonedModal from "../../components/shared/Confirm/ReasonedModal";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import NotesOutput from "../../components/shared/Notes/NotesOutput";
import PinProtect, { PinProtectState } from "../../components/shared/PinProtect";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useAutoDap from "../../data/useAutoDap";
import useNotes from "../../data/useNotes";
import usePartMutations from "../../data/usePartMutations";
import useSingleJob from "../../data/useSingleJob";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { useLabelGenerator } from "../../hooks/useLabelGenerator";
import { IAutoDap } from "../../models/AutoDap";
import { IJob } from "../../models/Job";
import { INewNote, ResourceType } from "../../models/Note";
import { AutoDapCategory, IPart, PartStatus } from "../../models/Part";
import classNames from "../../utils/classNames";
import { useAuth } from "../../contexts/AuthContext";

export default function ViewJob() {
  const { setInfo } = usePageNameContext();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Job");
  const { saveHandlers: partSaveHandlers } = useDefaultCRUDHandlers("Part");
  const { userId } = useAuth();
  const { jobId } = useParams();
  const { singleJob, update, remove: removeJob } = useSingleJob(jobId!);
  const { markDamaged, patchPart } = usePartMutations();
  const { autoDapLookup } = useAutoDap();
  const { generatePartLabel } = useLabelGenerator();
  const [job, setJob] = useState<IJob | undefined>();
  const [autoDapData, setAutoDapData] = useState<IAutoDap | undefined>();
  const [dapCategory, setDapCategory] = useState<AutoDapCategory>(AutoDapCategory["Spoilers/Wipers"]);
  const [completeOpen, setCompleteOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [damagedOpen, setDamagedOpen] = useState(false);
  const [damaged, setDamaged] = useState<IPart | null>();
  const [isCompleteLoading, setIsCompleteLoading] = useState(false);
  const [isDamagedLoading, setIsDamagedLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { create, remove } = useNotes();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [pinState, setPinState] = useState<PinProtectState>();

  const categories = [
    ...Object.values(AutoDapCategory).filter((ct) => isNaN(Number(ct)))
  ];

  useEffect(() => {
    if (!singleJob.isLoading) {
      setJob(singleJob.data?.data);

      setInfo({
        name: `${singleJob.data?.data.title.replace(/-[^-]*$/, "")} Job`,
        desc: `View and manage ${singleJob.data?.data.title.replace(/-[^-]*$/, "")}'s Job`,
      });

      setIsLoading(false);
    }
  }, [singleJob.isLoading, singleJob.data, jobId])

  const handleComplete = () => {
    setIsCompleteLoading(true);

    if (!userId || !job) {
      saveHandlers.onError();
      return;
    }

    const updatedJob = {
      ...job!,
      completedOn: new Date().toISOString(),
      completedBy: userId,
    }

    update.mutate(updatedJob, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setCompleteOpen(false);
      },
      onError: saveHandlers.onError,
      onSettled: () => setIsCompleteLoading(false),
    });

  }

  const handleReset = () => {
    if (!userId || !job) {
      saveHandlers.onError();
      return;
    }

    const updatedJob: IJob = {
      ...job!,
      startDate: null,
      endDate: null,
      mechanicId: null,
      completedBy: null,
      completedOn: null,
    }

    update.mutate(updatedJob, saveHandlers);
  }

  const handleReopen = () => {
    if (!userId || !job) {
      saveHandlers.onError();
      return;
    }

    const updatedJob: IJob = {
      ...job!,
      completedOn: null,
      completedBy: null,
    }

    update.mutate(updatedJob, saveHandlers);
  }

  const handlePartsLookup = () => {

    const queries = {
      category: Number.isInteger(dapCategory) ? dapCategory : AutoDapCategory[dapCategory],
      vin: job?.lead?.vehicle?.combinedVIN,
    }

    autoDapLookup.mutate(queries, {
      onSuccess: (data) => {
        console.log(data);
        setAutoDapData(data.data);
      }
    })

  };

  const handlePrint = async (part: IPart) => {
    const html = await generatePartLabel(part);

    var iframe = document?.getElementById("frame") as HTMLIFrameElement;
    var pri = iframe.contentWindow;
    pri?.document.open();
    pri?.document.write(html);
    pri?.document.close();
    pri?.focus();

    //QR code doesn't show up without a timeout
    setTimeout(async () => {
      pri?.print();
    }, 1);
  };

  const handleMultiPrint = async () => {
    var parts = new Array();

    job?.jobPartCategories.forEach(jobPartCategories => {
      parts.push(jobPartCategories.partData);
    });

    let htmlPages = [] as string[];

    // Use Promise.all to wait for all promises to resolve
    await Promise.all(parts.map(async (part) => {
      const html = await generatePartLabel(part);
      htmlPages.push(html);
    }));

    //Stitch together with a page break inbetween each
    const allHtml = htmlPages.join("<div style='page-break-after: always;'></div>");

    var iframe = document?.getElementById("frame") as HTMLIFrameElement;
    var pri = iframe.contentWindow;
    pri?.document.open();
    pri?.document.write(allHtml);
    pri?.document.close();
    pri?.focus();

    //QR code doesn't show up without a timeout
    setTimeout(async () => {
      pri?.print();
    }, 1);
  };

  const handleDamaged = (message: string) => {
    setPinModalOpen(true);
    setPinState({
      onPinSuccess: (pinUserId) => {
        let body = {
          id: damaged?.id,
          isDamaged: !damaged?.isDamaged,
          partStatus: PartStatus.Damaged,
          // damageReportedBy: pinUserId,
        };

        setIsDamagedLoading(true);

        markDamaged.mutate(body, {
          onSuccess: () => {
            setDamagedOpen(false);
            saveHandlers.onSuccess();
          },
          onError: () => {
            saveHandlers.onError();
          },
          onSettled: () => {
            setIsDamagedLoading(false);
          }
        })

        const newNote: INewNote = {
          message: `Damage Reported For ${damaged?.title} - ${damaged?.tagNumber}\n${message}`,
          resourceId: job?.id!,
          resourceType: ResourceType.Job,
          date: new Date().toISOString(),
        };

        create.mutate(newNote, {
          onSuccess: () => {
            saveHandlers.onSuccess();
            setTimeout(() => {
              queryClient.refetchQueries(["job", jobId]);
            }, 200);
          },
          onError: saveHandlers.onError
        });
      },
      onPinFailed: () => {
      },
      pinLabel: "Mark Part Damaged",
    });
  };

  const markAsRemoved = (part: IPart) => {
    if (!userId || !part) {
      partSaveHandlers.onError();
      return;
    }

    setPinModalOpen(true);
    setPinState({
      onPinSuccess: (pinUserId) => {
        const updatedPart = {
          id: part.id,
          removedOn: new Date().toISOString(),
          removedBy: pinUserId,
          partStatus: PartStatus.Dismantled,
        };
    
        patchPart.mutate(updatedPart, partSaveHandlers);
      },
      onPinFailed: () => {
      },
      pinLabel: "Mark Part Removed",
    });
    
  };

  const handleDeleteJob = () => {
    removeJob.mutate(jobId!, {
      onSuccess: () => {
        deleteHandlers.onSuccess();
        setDeleteOpen(false);
        //go back to jobs
        navigate("/jobs");
      },
      onError: deleteHandlers.onError,
    });
  };

  const pages = [
    { to: "..", label: "Jobs", current: false },
    {
      to: jobId && job ? `/jobs/${jobId}` : "",
      label: jobId && job ? `Job for ${job.title.replace(/-[^-]*$/, "")}` : "New Lead",
      current: true,
    },
  ];

  if (isLoading) {
    return <LoadingWheel />;
  }

  return (
    <>
      <PinProtect open={pinModalOpen} setOpen={setPinModalOpen} pinState={pinState} useAuthUser={false} />
      <ReasonedModal open={damagedOpen} setOpen={setDamagedOpen} onConfirm={handleDamaged} isLoading={isDamagedLoading}
        title={"Mark Part as Damaged?"}
        message={`Are you sure you want to mark this part as damaged? \nThis will remove ${damaged?.title} from the job and not allow it to be listed.`}
        confirmButtonText={"Confirm"}
      />
      <ConfirmModal open={completeOpen} setOpen={setCompleteOpen} onConfirm={handleComplete} isLoading={isCompleteLoading}
        title={"Complete Job"} message={"Are you sure you want to mark this job as completed?"} confirmButtonText={"Complete"}
      />
      <ConfirmModal open={deleteOpen} setOpen={setDeleteOpen} onConfirm={() => handleDeleteJob()} isLoading={false}
        title={"Delete Job?"}
        message={`Are you sure you want to delete this job? \nThis action cannot be undone and parts will be left in their current states.`}
        confirmButtonText={"Delete"}
      />
      <div className="flex justify-end gap-x-4">
        <ButtonInput label="Print All Part Labels" isSubmit={false} onClick={() => handleMultiPrint()} classes="" />

        {(job?.startDate && job?.endDate) && (
          <ButtonInput label={"Reset Job"} isSubmit={false} onClick={() => handleReset()} classes={""} />
        )}
        {job?.completedOn ? (
          <ButtonInput label={"Reopen Job"} isSubmit={false} onClick={() => handleReopen()} classes={"!bg-green-600 hover:!bg-green-700"} />
        ) : (
          <ButtonInput label={"Complete Job"} isSubmit={false} onClick={() => setCompleteOpen(true)} classes={"!bg-green-600 hover:!bg-green-700"} />
        )}


      </div>

      <Breadcrumbs pages={pages} />

      <div className="mt-4 space-y-4">

        <Card title="Job Details" bodyClassName="p-4" >
          <div className="space-y-4">
            <div className="grid grid-cols-2">
              <div className="px-2">
                {/* replacce is for old title that had duration baked in */}
                <h1 className="text-lg font-medium">{job?.title.replace(/-[^-]*$/, "")} - {job?.duration} mins</h1>
                <p>{job?.description}</p>
              </div>


              <div className="flex flex-col text-right">
                <h1 className="text-lg font-medium">Mechanic</h1>
                <p>{job?.mechanic?.fullname ?? "Not Allocated"}</p>
              </div>

            </div>

            {/* divider */}
            <hr className="my-2" />

            <div className="grid grid-cols-2">
              {job?.jobPartCategories?.map((partCategory, i) => (
                <div
                  key={partCategory.id}
                  className={`px-2 pb-4 space-y-2 flex-1 ${i > 1 ? 'border-t border-gray-200 pt-4' : ''} ${i % 2 !== 1 ? 'border-r border-gray-200 pr-4' : ''}`}
                >
                  <div className="flex gap-x-2 items-center">
                    <p className="text-lg font-semibold">{partCategory.partData?.title} - <span className="text-base font-medium">{partCategory.partData?.tagNumber}</span></p>

                    {partCategory.partData?.removedOn && (
                      <p className="font-semibold text-green-600">REMOVED</p>
                    )}
                    {partCategory.partData?.isDamaged && (
                      <p className="font-semibold text-red-600">DAMAGED</p>
                    )}
                  </div>

                  <div className="flex gap-x-4">

                    {!partCategory.partData?.isDamaged && (
                      <>
                        <ButtonInput
                          label={"Mark Removed"}
                          isSubmit={false}
                          onClick={() => markAsRemoved(partCategory.partData!)}
                          classes={classNames(partCategory.partData?.removedOn ? "" : "!bg-green-600 hover:!bg-green-700", "!py-1 !px-1")}
                          disabled={partCategory.partData?.removedOn !== null}
                        />
                        <ButtonInput
                          label={"Mark Damaged"}
                          isSubmit={false}
                          onClick={() => { setDamaged(partCategory.partData); setDamagedOpen(true); }}
                          classes={"!py-1 !px-1 !bg-red-600 hover:!bg-red-700"}
                        />
                      </>
                    )}

                    <ButtonInput
                      label={"Print Label"}
                      isSubmit={false}
                      onClick={() => handlePrint(partCategory.partData!)}
                      classes={"!py-1 !px-1 "}
                    />

                  </div>
                </div>
              ))}
            </div>

          </div>
        </Card>

        {/* Notes */}
        <Card title="Job Notes" bodyClassName="px-2 pb-4">
          <NotesOutput notes={job?.notes!} resourceType={ResourceType.Job} resourceId={job?.id!} refetchQuery={["job", jobId]} shouldScroll={false} />
        </Card>

        <Card title="Find Parts" bodyClassName="p-4" >
          <div className="flex gap-x-4">
            <select aria-label="Part categories"
              onChange={(e) => setDapCategory(e.target.value as unknown as AutoDapCategory)}
              // value={currentFilter}
              className="sm:text-sm">
              {categories.map((filter) => (
                <option
                  value={filter}
                  className="sm:text-sm"
                  key={filter}>
                  {String(filter)}
                </option>
              ))}
            </select>

            <ButtonInput label={"Lookup"} isSubmit={false} onClick={() => handlePartsLookup()} classes={""} />
          </div>
        </Card>

        {autoDapData && (
          <Card title="Parts" bodyClassName="p-4" >
            {autoDapData.category.map((category, i) => (
              <>
                <div key={i}>
                  <h1 className="text-2xl font-medium underline">{category.categoryname}</h1>
                </div>
                {category.assembly.map((assembly, j) => (
                  <>
                    <div key={j}>
                      <h1 className="text-lg font-medium">{assembly.assemblyname}</h1>
                      <img src={assembly.image} alt={assembly.assemblyname} useMap={`#${j}`} />

                      <map name={j.toString()}>
                        {assembly.parts.map((part, k) => (
                          part.hotspot && part.hotspot[0] && part.hotspot[0].hotspotx && part.hotspot[0].hotspoty && (
                            <area
                              shape="rect"
                              coords={`${part.hotspot[0].hotspotx[0]}, ${part.hotspot[0].hotspoty[0]}, ${part.hotspot[0].hotspotx[1]}, ${part.hotspot[0].hotspotx[1]}`}
                              alt={part.partname}
                              className="hover:cursor-pointer"
                              onClick={() => console.log(part)}
                            />
                          )
                        ))}
                      </map>

                    </div>
                  </>
                ))}
              </>
            ))}
          </Card>
        )}

        <ButtonInput label="Delete Job" isSubmit={false} onClick={() => setDeleteOpen(true)} classes={"!bg-red-600 hover:!bg-red-700"} />
      </div>
      <iframe id="frame" className="h-0 w-0 absolute"></iframe>
    </>
  )
}

