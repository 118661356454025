import { createRef, useEffect, useState } from "react";
import date from "date-and-time";
import { useNavigate } from "react-router-dom";

import FullCalendar from "@fullcalendar/react";
import adaptivePlugin from "@fullcalendar/adaptive";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

import classNames from "../../utils/classNames";
import { IJob, JobType } from "../../models/Job";
import PriceFormat from "./../../utils/priceFormat";
import { useUserLookup } from "../../hooks/useUserLookup";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useJobsByDate from "../../data/useJobsByDate";
import { DateTime } from "luxon";
import formatDateTime from "../../utils/formatDateTime";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";

export default function Jobs() {
  const { setInfo } = usePageNameContext();
  const navigate = useNavigate();
  const { saveHandlers } = useDefaultCRUDHandlers("Job");
  // const { jobs, stats, update } = useJobs();
  const [currentDate, setCurrentDate] = useState<string>(DateTime.now().toISODate());
  const { jobs, unscheduledJobs: allUnscheduledJobs, stats, update } = useJobsByDate(currentDate);
  const { isLoading: userLoading, mechanics } = useUserLookup();
  const [isLoading, setIsLoading] = useState(true);
  const [isDraggable, setIsDraggable] = useState(false);
  const [unscheduledJobs, setUnscheduledJobs] = useState<IJob[] | undefined>();
  const [scheduledJobs, setScheduledJobs] = useState<IJob[] | undefined>();
  const [currentFilter, setCurrentFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const filters = [
    "VRM A-Z",
    "Number of Parts",
    "Oldest to Newest",
    "Newest to Oldest",
  ];

  const calendarRef = createRef<FullCalendar>();

  const handleDateChange = (date: Date) => {
    const newDate = DateTime.fromJSDate(date).toISODate()!;

    setCurrentDate(newDate);

    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(newDate); // Update view
    }
  }

  const handleEventAdd = (
    id: string,
    startDate: Date,
    endDate: Date,
    mechanicId: string
  ) => {
    console.log();
    var foundJob = jobs.data?.data?.find((job) => job.id === id);

    if (endDate === null) {
      //enddate is start date plus duration
      endDate = new Date(startDate);
      endDate.setHours(endDate.getMinutes() + foundJob?.duration!);
    }

    if (foundJob === undefined) {
      saveHandlers.onError();
      return;
    }

    let updatedJob = {
      ...foundJob,
      startDate: date.format(new Date(startDate), "YYYY-MM-DDTHH:mm:ss"),
      endDate: date.format(new Date(endDate), "YYYY-MM-DDTHH:mm:ss"),
      mechanicId: mechanicId,
    };

    //Filter out from the unscheduled jobs
    var updated = unscheduledJobs?.filter((job) => job.id !== updatedJob?.id);
    setUnscheduledJobs(updated);

    update.mutate(updatedJob, {
      onSuccess: () => {
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      },
    });
  };

  const handleEventChange = (
    id: string,
    startDate: Date,
    endDate: Date,
    mechanicId: string
  ) => {
    var foundJob = jobs.data?.data?.find((job) => job.id === id);

    if (foundJob === undefined) {
      saveHandlers.onError();
      return;
    }

    let updatedJob = {
      ...foundJob,
      startDate: date.format(new Date(startDate), "YYYY-MM-DDTHH:mm:ss"),
      endDate: date.format(new Date(endDate), "YYYY-MM-DDTHH:mm:ss"),
      mechanicId: mechanicId,
    };

    //Update the scheduled jobs
    var updated = scheduledJobs?.map((job) => {
      return job.id === updatedJob?.id ? updatedJob : job;
    });
    setScheduledJobs(updated);

    update.mutate(updatedJob, {
      onSuccess: () => {
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      },
    });
  };

  const sortBy = (jobs: IJob[] | undefined) => {
    if (currentFilter === "VRM A-Z") {
      return jobs?.sort((a, b) => {
        return a.title! > b.title! ? 1 : -1;
      });
    } else if (currentFilter === "Number of Parts") {
      return jobs?.sort((a, b) => {
        return b.jobPartCategories?.length! > a.jobPartCategories?.length!
          ? 1
          : -1;
      });
    } else if (currentFilter === "Oldest to Newest") {
      return jobs?.sort((a, b) => {
        return new Date(a.created!).getTime() >= new Date(b.created!).getTime()
          ? 1
          : -1;
      });
    } else if (currentFilter === "Newest to Oldest") {
      return jobs?.sort((a, b) => {
        return new Date(a.created!).getTime() <= new Date(b.created!).getTime()
          ? 1
          : -1;
      });
    } else {
      return jobs;
    }
  };

  const getEventColour = (job: IJob) => {
    if (job.completedOn) {
      return "#16a34a"; //green
    } else {
      if (job.type === JobType.Body) {
        return "#dc2626"; //red
      } else if (job.type === JobType.Mechanical) {
        return "#ea580c"; //orange
      }
    }

    return "#3788d8"; //default blue
  };

  useEffect(() => {
    setInfo({
      name: "Jobs",
      desc: "View and manage your jobs here",
    });
  }, []);

  useEffect(() => {
    if (!userLoading && !jobs.isLoading && !allUnscheduledJobs.isLoading) {
      let unscheduled = allUnscheduledJobs.data?.data;

      unscheduled = unscheduled?.filter((job) =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      unscheduled = sortBy(unscheduled);
      setUnscheduledJobs(unscheduled);

      let scheduled = jobs.data?.data.filter((job) => job.mechanicId !== null);

      console.log(scheduled)

      setScheduledJobs(scheduled);

      setIsLoading(false);
    }
  }, [
    // mechanics,
    currentDate,
    userLoading,
    jobs.data,
    jobs.isLoading,
    allUnscheduledJobs.data,
    allUnscheduledJobs.isLoading,
    currentFilter,
    searchTerm,
  ]);

  useEffect(() => {
    //If this runs more than once then dragging on will create multiple events
    if (!isDraggable && !isLoading) {
      var containerEl = document.getElementById("job-events");
      if (containerEl) {
        new Draggable(containerEl!, {
          itemSelector: ".fc-event",
          eventData: (eventEl) => {
            return {
              title: eventEl.innerText,
              id: eventEl.id,
              //Need to make sure the minutes are always 2 digits
              duration: `${Math.floor(
                parseInt(eventEl.dataset.duration!) / 60
              )}:${(parseInt(eventEl.dataset.duration!) % 60).toLocaleString(
                "en-GB",
                { minimumIntegerDigits: 2, useGrouping: false }
              )}:00`,
            };
          },
        });
        setIsDraggable(true);
      }
    }
  }, [isLoading]);

  const getTile = (label: string, color: string, value: string) => (
    <div
      className={`border border-slate-500 px-2 py-1 shadow-md rounded bg-${color}-100 grow`}
    >
      <p className={`font-bold text-center my-2 text-2xl text-${color}-600`}>
        {value}
      </p>
      <p className={`mx-2 text-center text-${color}-600`}>{label}</p>
    </div>
  );

  return isLoading ? (
    <LoadingWheel />
  ) : (
    <>
      <div>
        {stats.isLoading ? (
          <LoadingWheel />
        ) : (
          <div className="p-4 mt-4 bg-white rounded-lg shadow flex flex-col">
            <div className="flex flex-row flex-wrap gap-4">
              {getTile(
                "Total # Jobs",
                "green",
                stats.data?.data.totalJobs.toString()!
              )}

              {getTile(
                "Total # Parts",
                "pink",
                stats.data?.data.numberOfParts.total.toString()!
              )}
              {getTile(
                "Average # Parts",
                "pink",
                stats.data?.data.numberOfParts.average.toString()!
              )}

              {getTile(
                "Total Duration",
                "blue",
                (
                  Math.round(stats.data?.data.duration.total! / 6) / 10
                ).toString() + " hrs"
              )}
              {getTile(
                "Average Duration",
                "blue",
                stats.data?.data.duration.average.toString()! + " mins"
              )}

              {getTile(
                "Total Cost",
                "purple",
                PriceFormat(stats.data?.data.totalCost.total.toString())
              )}
              {getTile(
                "Average Cost",
                "purple",
                PriceFormat(stats.data?.data.totalCost.average.toString())
              )}

              {getTile(
                "Total Postage",
                "orange",
                PriceFormat(stats.data?.data.postageCost.total.toString())
              )}
              {getTile(
                "Average Postage",
                "orange",
                PriceFormat(stats.data?.data.postageCost.average.toString())
              )}

              {getTile(
                "Total Dissasembly Cost",
                "red",
                PriceFormat(stats.data?.data.disCost.total.toString())
              )}
              {getTile(
                "Average Dissasembly Cost",
                "red",
                PriceFormat(stats.data?.data.disCost.average.toString())
              )}

              {getTile(
                "Total Labour Cost",
                "lime",
                PriceFormat(stats.data?.data.whCost.total.toString())
              )}
              {getTile(
                "Average Labour Cost",
                "lime",
                PriceFormat(stats.data?.data.whCost.average.toString())
              )}
            </div>
          </div>
        )}
      </div>
      <div className="p-4 mt-4 bg-white rounded-lg shadow">
        <div className="px-4 mb-2 flex items-center justify-between">
          <div>
            {/* Sorting */}
            <div className="">
              <select
                aria-label="Contact type filter"
                onChange={(e) => setCurrentFilter(e.target.value)}
                className="text-sm"
              >
                {filters.map((filter) => (
                  <option className="text-sm" key={filter}>
                    {String(filter)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <p className="text-center font-semibold">Disassembly Jobs</p>
          </div>

          {/* Search */}
          <div className="focus-within:z-10">
            <div className="absolute pt-2 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 sm:text-sm"
              placeholder="Make, Model, VRM, Type"
            />
          </div>
        </div>

        <div id="job-events" className="p-2 mx-auto h-auto max-h-full">
          <div className="grid lg:grid-cols-5 grid-cols-3">
            {unscheduledJobs?.length! > 0 ? (
              unscheduledJobs?.map((job) => (
                <div
                  className={classNames(
                    job.type === JobType.Body ? "bg-red-200" : "bg-orange-200",
                    `fc-event col-span-1 mx-1 text-sm p-1 rounded-md mb-2 cursor-pointer`
                  )}
                  title={job.title}
                  id={job.id}
                  key={job.id}
                  data-duration={job.duration.toString()}
                  data-description={job.description}
                  onClick={() => {
                    navigate(`${job.id}`);
                  }}
                >
                  {/* The replace is temporary as we changed the title to not include the duration */}
                  {job.title.replace(/-[^-]*$/, "")} - {job.duration} mins -{" "}
                  {job.jobPartCategories.length} Part
                  {job.jobPartCategories.length > 1 ? "s" : ""}
                </div>
              ))
            ) : (
              //Empty div to make changing the date less jumpy
              <div className="col-span-1 text-sm py-4 rounded-md mb-1 cursor-pointer"></div>
            )}
          </div>
        </div>

        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-semibold mt-4 mb-2">{formatDateTime(currentDate, DateTime.DATE_HUGE)}</h1>

          <div className="flex gap-x-4">
            <ButtonInput
              onClick={() => handleDateChange(DateTime.now().toJSDate())}
              label="Today"
              isSubmit={false}
              classes=""
            />
            <ButtonInput
              onClick={() => handleDateChange(DateTime.fromISO(currentDate).minus({ days: 1 }).toJSDate())}
              label="Previous"
              isSubmit={false}
              classes=""
            />
            <ButtonInput
              onClick={() => handleDateChange(DateTime.fromISO(currentDate).plus({ days: 1 }).toJSDate())}
              label="Next"
              isSubmit={false}
              classes=""
            />
          </div>
        </div>

        <FullCalendar
          ref={calendarRef}
          headerToolbar={false}
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          viewClassNames={"timeline"}
          plugins={[resourceTimeGridPlugin, interactionPlugin, adaptivePlugin]}
          initialView="resourceTimeGridDay"
          slotMinTime={"06:00:00"}
          slotMaxTime={"20:00:00"}
          resources={mechanics!.map((mech) => ({
            id: mech.id,
            title: `${mech.forename} ${mech.surname}`,
          }))}
          resourceOrder="title"
          titleFormat={{
            month: "long",
            year: "numeric",
            day: "numeric",
            weekday: "long",
          }}
          editable={true}

          defaultTimedEventDuration="01:00:00"
          businessHours={{
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: "08:00",
            endTime: "18:00",
          }}
          eventDurationEditable={true}
          eventStartEditable={true}
          // resourceAreaWidth="15%"
          droppable={true}
          nowIndicator={true}
          contentHeight="auto"
          allDaySlot={false}
          events={scheduledJobs?.map((job) => ({
            id: job.id,
            title: `${job.title} - ${job.jobPartCategories.length} Part${job.jobPartCategories.length > 1 ? "s" : ""
              }`,
            start: job.startDate ?? new Date(),
            end: job.endDate ?? new Date(),
            resourceId: job.mechanicId ?? "",
            backgroundColor: getEventColour(job),
            borderColor: getEventColour(job),
            // description: job.description,
            duration: job.duration,
          }))}
          eventClick={(info) => {
            navigate(`${info.event.id}`);
          }}
          eventChange={(info) => {
            handleEventChange(
              info.event.id,
              info.event.start!,
              info.event.end!,
              info.event.getResources()[0].id
            );
          }}
          eventReceive={(info) => {
            handleEventAdd(
              info.event.id,
              info.event.start!,
              info.event.end!,
              info.event.getResources()[0].id
            );
          }}
          eventsSet={(allEvents) => {
            //Duplicate checking
            allEvents.forEach((event) => {
              var duplicates = allEvents.filter((e) => e.id === event.id);
              if (duplicates.length > 1) {
                for (let i = 1; i < duplicates.length; i++) {
                  duplicates[i].remove();
                }
              }
            });
          }}
        />
      </div>
    </>
  );
}
