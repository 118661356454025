import { BaseEntity } from './system';

export interface IEnginesWorldwide extends BaseEntity {
    vehicleId?: string;
    type: OfferType;
    enginesWorldwideId: string;
    offered: string;
    pickedUp: string;
    vrm: string;
    status: string;
    statusId: string;
    price: string;
    mediaReceived: boolean;
}

export enum OfferType {
    "Engine",
    "Vfe"
}