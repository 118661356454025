import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLocationsResponse, ILocation } from "../models/Location";

export default function useLocations() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const locations = useQuery({
    queryKey: ["locations"],
    queryFn: () => get<IAllLocationsResponse>("/location"),
    refetchInterval: 1000 * 60, // 60 seconds
  });

  const create = useMutation(
    (location: ILocation) => post("/location", location),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["locations"]);
      },
    }
  );

  const update = useMutation(
    (location: ILocation) => put(`/location/${location.id}`, location),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["locations"]);
      },
    }
  );

  const remove = useMutation(
    (locationId: string) => del(`/location/${locationId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["locations"]);
      },
    }
  );

  return {
    locations,
    create,
    update,
    remove,
  };
}
