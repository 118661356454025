import { useState } from "react";

import { useForm } from "react-hook-form";

import LoadingWheel from "../../shared/LoadingWheel";
import { TextInput } from "../../shared/Inputs/TextInput";
import { IShippingAddress } from "./../../../models/Shipping";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import usePartMutations from "../../../data/usePartMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";

interface EditAddressProps {
  address: IShippingAddress;
  partId: string;
  onEditSuccess: (address: IShippingAddress) => void;
}

export function EditAddress({
  address,
  partId,
  onEditSuccess,
}: EditAddressProps) {
  const { patchPart } = usePartMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Shipping");
  const { register, getValues, handleSubmit } = useForm<IShippingAddress>({
    defaultValues: address,
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const updatePartAddress = (data: IShippingAddress) => {
    setError(undefined);

    if (!data.addressLine1 || data.addressLine1!.length < 4) {
      setError("Please fill in Address line 1. Must be at least 4 characters.");
      return;
    }

    if (!data.postcode || data.postcode!.length < 5) {
      setError("Please fill in postcode. Must be at least 5 characters.");
      return;
    }

    setSubmitting(true);

    patchPart.mutate(
      {
        ...data,
        id: partId,
      },
      {
        onSuccess: () => {
          saveHandlers.onSuccess();
          onEditSuccess(data);
        },
        onError: () => {
          saveHandlers.onError();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      }
    );
  };

  return submitting ? (
    <div>
      <LoadingWheel />
    </div>
  ) : (
    <form
      className="grid grid-cols-2 gap-2"
      onSubmit={handleSubmit(updatePartAddress)}
    >
      <TextInput
        label="Full Name"
        register={register}
        registerName={"fullName"}
      />
      <TextInput
        label={`Address Line 1 (${getValues("addressLine1")?.length}/35) `}
        register={register}
        registerName={"addressLine1"}
      />
      <TextInput
        label={`Address Line 2 (${getValues("addressLine2")?.length}/35) `}
        register={register}
        registerName={"addressLine2"}
      />
      <TextInput label="City" register={register} registerName={"city"} />
      <TextInput label="County" register={register} registerName={"county"} />
      <TextInput
        label="State/Province"
        register={register}
        registerName={"stateOrProvince"}
      />
      <TextInput
        label="Postcode"
        register={register}
        registerName={"postcode"}
      />
      <div /> {/* spacer */}
      {error && (
        <div className="col-span-2 bg-red-100 text-red-600 px-4 py-2 rounded">
          {error}
        </div>
      )}
      <ButtonInput
        label="Save Address Info"
        isSubmit={true}
        onClick={() => {}}
        classes={"mt-4"}
      />
    </form>
  );
}
