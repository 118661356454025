import { useEffect, useState } from "react";

import { usePageNameContext } from "../../contexts/PageTitleContext";

import { ContactType, IContact } from "../../models/Contact";

import LoadingWheel from "../../components/shared/LoadingWheel";

import ContactTable from "../../components/contacts/ContactTable";

import useContacts from "../../data/useContacts";
import Modal from "../../layouts/Modal";
import ExportLeads from "../../components/leads/LeadTable/ImportExport/ExportLeads";
import PillTabMenu from "../../components/shared/PillTab/PillTabMenu";
import PillTab from "../../components/shared/PillTab/PillTab";

export default function ContactList() {
  const { setInfo } = usePageNameContext();
  const { contacts: allContacts } = useContacts();
  const [contacts, setContacts] = useState<IContact[] | undefined>(undefined);
  const [currentFilter, setCurrentFilter] = useState("All");
  const filters = [
    "All",
    ...Object.values(ContactType).filter((ct) => isNaN(Number(ct))),
  ];

  const handleFilterChange = (filterValue: string) => {
    setCurrentFilter(filterValue);
    if (filterValue === "All") {
      setContacts(allContacts.data?.data);
    } else {
      setContacts(
        allContacts.data?.data.filter(
          (c) => ContactType[c.contactType] === filterValue
        )
      );
    }
  };

  useEffect(() => {
    setInfo({
      name: `Contacts`,
      desc: `View and manage all contacts here`,
    });
  }, [setInfo]);

  useEffect(() => {
    if (!allContacts.isLoading) {
      setContacts(allContacts.data?.data);
    }
  }, [allContacts.isLoading, allContacts.data]);

  return allContacts.isLoading || !contacts ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <div className="flex justify-between">
        {/* <PillTabMenu ariaLabel="Contact type filter">
          {filters.map((filter) => (
            <PillTab
              key={filter}
              label={String(filter)}
              active={filter === currentFilter}
              onClick={() => handleFilterChange(String(filter))}
            />
          ))}
        </PillTabMenu> */}
      </div>
      <ContactTable data={contacts} />
    </>
  );
}
