import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import { IPartAssemblyResponse, IPartAssembly, ISinglePartAssemblyResponse } from "../models/Part";
import { DefaultResponse } from "../models/system";

export default function usePartAssemblySingle(assemblyId?: string | undefined) {
  const { get, del } = useApiHelper();
  const queryClient = useQueryClient();

  const assembly = useQuery({
    queryKey: ["assemblies", assemblyId],
    queryFn: () => get<ISinglePartAssemblyResponse>(`/PartAssembly/${assemblyId}`),
    enabled: !!assemblyId,
  });

  return {
    assembly,
  };
}