import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead } from "../models/Lead";

export default function useEmails() {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();

  const sendEmail = useMutation(
    (email : any) => post(`/email`, email),
    {
      onSuccess: () => {
      },
    }
  );

  const sendDocumentsEmail = useMutation(
    (email : any) => post(`/email/documents`, email),
    {
      onSuccess: () => {
      },
    }
  );

  return {
    sendEmail,
    sendDocumentsEmail
  };
}
