import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import Modal from "../../layouts/Modal";
import LoadingWheel from "./LoadingWheel";
import { TextInput } from "./Inputs/TextInput";
import { ButtonInput } from "./Inputs/ButtonInput";
import { useAuth } from "../../contexts/AuthContext";
import { useAuthApi } from "../../hooks/useAuthApi";

interface PinProtectProps {
  pinState: PinProtectState | undefined;
  open: boolean;
  setOpen: any;
  useAuthUser?: boolean;
}

interface PinProtectFormFields {
  pin: string;
  username: string;
  password: string;
}

export interface PinProtectState {
  pinLabel: string;
  onPinSuccess: (userId: string | null) => void;
  onPinFailed: () => void;
}

// Open Pin Modal And Set Callbacks
// setPinModalOpen(true);
// setPinState({
//     onPinSuccess: () =>{
//     //Do Stuff On Succcess
//     },
//     onPinFailed: () => {
//     //Do Stuff On Fail
//     },
//     pinLabel: "What Is The Pin For?",
// });
// };

export default function PinProtect({
  pinState,
  open,
  setOpen,
  useAuthUser = true,
}: PinProtectProps) {
  const { validatePin, generatePin } = useAuthApi();
  const { name } = useAuth();
  const [error, setError] = useState("");
  const [failures, setFailures] = useState(0);
  const [recovery, setRecovery] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [newPin, setNewPin] = useState("");
  const [loading, setLoading] = useState(false);
  

  const { register, handleSubmit, reset } = useForm<PinProtectFormFields>({
    defaultValues: {
      pin: "",
      username: "",
      password: "",
    },
  });

  useEffect(() => {
    resetState();
  }, [open]);

  const resetState = () => {
    reset();
    setFailures(0);
    setError("");
  };

  const onSuccess = (userId: string | null) => {
    resetState();
    pinState?.onPinSuccess(userId);
    setOpen(false);
  };

  const onFail = (message: string) => {
    if (failures >= 3) {
      pinState?.onPinFailed();
      resetState();
      setOpen(false);
    } else {
      setError(`Attempt (${failures + 1}/3): ` + message);
      setFailures(failures + 1);
    }
  };

  const handlePinUpdate = async (data: PinProtectFormFields) => { // TODO: Don't let them pick a pin, generate one for them
    setLoading(true);

    // if (data.pin.length < 4) {
    //   onFail("Pin Must Be 4 Or More Characters");
    //   return;
    // }

    // if (data.pin.length > 8) {
    //   onFail("Pin Must Be 8 Or Less Characters");
    //   return;
    // }

    // if (data.pin.includes(" ")) {
    //   onFail("Pin Must Not Include Spaces");
    //   return;
    // }

    try {
      // Get new pin here
      let res = await generatePin(data.username == "" ? null : data.username, data.password);
      setNewPin(res.data);
      setShowPin(true);
      setRecovery(false)
      onSuccess(null);
    } catch (e: any) {
      if (e["response"] && e.response.body.message) {
        setError(e.response.body.message);
      } else {
        setError("An error occurred while trying to set your new pin");
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePinSubmit = async (data: PinProtectFormFields) => {
    setLoading(true);

    try {
      let res = await validatePin(data.pin, useAuthUser);
      onSuccess(res.data);
    } catch (e: any) {
      if (e["response"] && e.response.body.message) {
        setError(e.response.body.message);
      } else {
        setError("An error occurred while trying to validate your pin");
      }
    } finally {
      setLoading(false);
    }
  };   

  return (
    <>
      <Modal open={open} setOpen={(state) => {setOpen(state); setRecovery(false)}} width={"max-w-md"}>
        {loading ? (
          <LoadingWheel />
        ) : recovery ? (
          <div>
            <div className="mb-2">
              <p className="text-2xl text-center font-bold">
                Setup Pin Protection
              </p>
            </div>
            {!useAuthUser ? (
              <p className="text-center mb-4">
                Enter your details to generate a new pin.
              </p>
            ) : (
              <p className="text-center">
                {name}, so we can confirm your identity in future, please create a
                pin.
              </p>
            )}
            
            <form onSubmit={handleSubmit(handlePinUpdate)}>
              {!useAuthUser && (
                <TextInput
                  type="text"
                  label={`Username (Leave blank for ${name})`}
                  register={register}
                  registerName={"username"}
                  customClasses="min-w-full"
                />
              )}
              <TextInput
                type="password"
                label="Account Password"
                register={register}
                registerName={"password"}
                customClasses="min-w-full"
              />
              <p className="text-red-600">{error}</p>
              <ButtonInput
                label="Confirm"
                isSubmit={true}
                onClick={null}
                classes={"my-2 min-w-full"}
              />
            </form>
          </div>
        ) : (
          <div>
            <div className="mb-2">
              <p className="text-2xl text-center font-bold">
                Pin Protected Action
              </p>
              <p className="text-md text-center font-semibold">
                {pinState?.pinLabel}
              </p>
            </div>
            <p className="text-center mb-4">
              {!useAuthUser 
              ? "Please enter your pin to confirm your identity." 
              : `${name}, please enter your pin to confirm your identity.`}
            </p>

            <form onSubmit={handleSubmit(handlePinSubmit)}>
              <TextInput
                type="number"
                label="Your Pin"
                register={register}
                registerName={"pin"}
                customClasses="min-w-full !text-2xl !font-bold text-center [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-text-security]:!disc"
              />
              <p className="text-red-600">{error}</p>
              <ButtonInput
                label="Confirm"
                isSubmit={true}
                onClick={null}
                classes={"my-2 min-w-full"}
              />
              <p
                className="text-center text-sm underline cursor-pointer"
                onClick={() => setRecovery(true)}
              >
                I've forgotten my pin
              </p>
            </form>
          </div>
        )}
      </Modal>

      <Modal open={showPin} setOpen={setShowPin} width={"max-w-md"}><p className="text-center text-xl">Your new pin is: <strong>{newPin}</strong></p></Modal>
    </>
  );
}
