import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IJob, ISingleJobResponse } from "../models/Job";

export default function useSingleJob(jobId: string) {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const singleJob = useQuery({
    queryKey: ["job", jobId],
    queryFn: () => get<ISingleJobResponse>(`/job/${jobId}`),
  });

  const update = useMutation(
    (job: IJob) => put(`/job/${job.id}`, job),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["jobs"]);
      },
    }
  );

  const remove = useMutation(
    (id: string) => del(`/job/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["jobs"]);
      },
    }
  );

  return {
    singleJob,
    update,
    remove
  };
}
