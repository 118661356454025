import { useQueryClient } from "@tanstack/react-query";

import useVehiclesMutations from "../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../models/Lead";

export default function AwaitingInventoryEbayPrice({ lead }: { lead: ILead }) {
  const queryClient = useQueryClient();
  const { update } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Ebay Price");

  const handlePriceUpdate = (price: string) => {
    const doublePrice = parseFloat(price);

    if (isNaN(doublePrice)) return;

    const updatedVehicle = {
      ...lead.vehicle!,
      ebayEnginePrice: doublePrice,
    };

    update.mutate(updatedVehicle, {
      onSuccess: () => {
        saveHandlers.onSuccess();

        setTimeout(() => {
          queryClient.refetchQueries(["leads"]);
        }, 200);
      },
      onError: (error) => {
        saveHandlers.onError();
      }
    });
  }

  return (
    <div className="w-full h-full flex items-center" onClick={(e) => e.stopPropagation()}>
      £ <input
        key={lead.id}
        defaultValue={lead.vehicle!.ebayEnginePrice?.toFixed(2)}
        className="h-8 w-20 border p-1 text-sm" type="number"
        onBlur={(e) => handlePriceUpdate(e.target.value)}
      />
    </div>
  )
}
