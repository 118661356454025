import { IContact } from "./Contact";
import { ITruck } from "./Truck";
import { IWilmaUser } from "./auth/WilmaUser";
import { BaseEntity, DefaultResponseWithData } from "./system";

export interface IDriverTrackingResponse
  extends DefaultResponseWithData<IDriverTracking[]> { }

export interface IDriverTrackingMapResponse
  extends DefaultResponseWithData<{ [key: string]: IDriverTracking[] }> { }


export interface IDriverTracking extends BaseEntity {
  driverId: string | null;
  truckId: string | null;
  leadId: string | null;
  tripId: string | null;
  timestamp: string;
  longitude: number;
  latitude: number;
  speed: number;
  status: TrackingStatus;
}

export interface DriverWithTracking {
  driver: IWilmaUser;
  truck: ITruck | undefined;
  tracking: IDriverTracking[];
  colour: string;
  show: boolean;
}

export enum TrackingStatus {
  Idle,
  Collecting,
  Returning
}