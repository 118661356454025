import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import {
  ICustomShippingRequest,
  IProoviaCollectionSlotRequest,
  IProoviaItemsResponse,
  IProoviaSlot,
  IShippingCreateRequest,
  IShippingInfoResponse,
  IShippingQuote,
  IShippingQuotesRequest,
} from "../models/Shipping";
import { IPart } from "../models/Part";
import { IProoviaDeliverySlotRequest } from "./../models/Shipping";
import { DefaultResponseWithData } from "./../models/system";

export default function useShipping(part: IPart) {
  const { get, post, postRaw, del } = useApiHelper();
  const queryClient = useQueryClient();

  const shippingInfo = useQuery(
    ["shipping", part.id],
    () => get<IShippingInfoResponse>(`/shipping/${part.id}`),
    {}
  );

  const prooviaItems = useQuery(
    ["prooviaShipping", part.id],
    () => get<IProoviaItemsResponse>("/shipping/proovia/items"),
    {}
  );

  const prooviaCollectionDates = useMutation(
    (req: IProoviaCollectionSlotRequest) =>
      get<DefaultResponseWithData<IProoviaSlot[]>>(
        `/shipping/proovia/slots/collection?from=${req.from}&to=${req.to}`
      )
  );

  const prooviaDeliveryDates = useMutation((req: IProoviaDeliverySlotRequest) =>
    get<DefaultResponseWithData<IProoviaSlot[]>>(
      `/shipping/proovia/slots/delivery?from=${req.from}&to=${req.to}&collectionDate=${req.collectionDate}`
    )
  );

  const customShipping = useMutation(
    (req: ICustomShippingRequest) => post(`/shipping/custom`, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", part.id]);
      },
    }
  );

  const quotes = useMutation(
    (req: IShippingQuotesRequest) =>
      post<IShippingQuotesRequest, DefaultResponseWithData<IShippingQuote[]>>(
        `/shipping/quotes`,
        req
      ),
    {}
  );

  const shippingLabel = useMutation(
    (quote: IShippingCreateRequest) => postRaw(`/shipping`, quote),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", part.id]);
      },
    }
  );

  const cancelShipping = useMutation((_: any) => del(`/shipping/${part.id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["parts", part.id]);
    },
  });

  return {
    shippingInfo,
    prooviaItems,
    prooviaCollectionDates,
    prooviaDeliveryDates,
    quotes,
    shippingLabel,
    cancelShipping,
    customShipping,
  };
}
