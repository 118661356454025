import { useForm } from "react-hook-form";
import usePartDefaultCategories from "../../data/usePartDefaultCategories";
import { FormatType, ListingDuration } from "../../models/EbayOffer";
import { IPart, IPartCategory, IPartCategoryDefault } from "../../models/Part";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useEbay from "../../data/useEbay";
import { useEffect, useState } from "react";
import SelectInput from "../shared/Inputs/SelectInput";
import { EbayCategoryDropdown } from "../ebayListings/EbayCategoryDropdown";
import { EbayStoreCategoryDropdown } from "../ebayListings/EbayStoreCategoryDropdown";
import { NumberInput } from "../shared/Inputs/NumberInput";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import EbayListingTitle from "../ebayListings/EbayListingTitle";
import EbayCategoryDefaultTitle from "./EbayCategoryDefaultTitle";
import LoadingWheel from "../shared/LoadingWheel";

const formatOptions = [
  { id: FormatType.Auction, name: "Auction" },
  { id: FormatType.FixedPrice, name: "Buy It Now" },
];

const durationOptions = [
  // { id: ListingDuration.GTC, name: "Until Cancelled" }, //auto selected on 
  { id: ListingDuration.Days1, name: "1 Day" },
  { id: ListingDuration.Days3, name: "3 Days" },
  { id: ListingDuration.Days5, name: "5 Days" },
  { id: ListingDuration.Days7, name: "7 Days" },
  { id: ListingDuration.Days10, name: "10 Days" },
  { id: ListingDuration.Days21, name: "21 Days" },
  { id: ListingDuration.Days30, name: "30 Days" },
];


export function CategoryListingDefaults({ category }: { category: IPartCategory | undefined }) {
  const { defaultPartCategory, saveDefaultCategory } = usePartDefaultCategories({ part: { categoryId: category?.id } as IPart });
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm<IPartCategoryDefault>();
  const { saveHandlers } = useDefaultCRUDHandlers("Category Defaults");
  const { policies, locations } = useEbay();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleSetFormat = (_: string, value: any) => {
    setValue(
      "duration",
      (value === FormatType.FixedPrice
        ? ListingDuration.GTC as number
        : ListingDuration.Days30 as number)
    );
    setValue("format", value);
  };

  const onSubmit = (data: any) => {
    saveDefaultCategory.mutate({
      ...data,
      // ebayCategoryId: getValues("ebayCategoryId"),
    }, saveHandlers);
  };

  useEffect(() => {
    if (!defaultPartCategory.isLoading && defaultPartCategory.data) {
      let data = defaultPartCategory.data?.data;

      if (!data) {
        reset({
          width: 0,
          height: 0,
          length: 0,
          weight: 0,
          format: FormatType.FixedPrice,
          duration: ListingDuration.GTC,
          auctionReservePrice: 0,
          auctionStartPrice: 0,
          buyItNowPrice: 0,
          enableBestOffer: false,
          autoAcceptPrice: 0,
          fulfillmentPolicyId: "",
          paymentPolicyId: "",
          returnPolicyId: "",
          ebayCategoryId: "",
          ebayStoreCategoryId: "",
          locationKey: "",
          titleStructure: "",
        });

        setIsLoading(false);
        return;
      }

      reset({
        width: data.width ?? 0,
        height: data.height ?? 0,
        length: data.length ?? 0,
        weight: data.weight ?? 0,
        format: data.format,
        duration: data.duration,
        auctionReservePrice: data.auctionReservePrice ?? 0,
        auctionStartPrice: data.auctionStartPrice ?? 0,
        buyItNowPrice: data.buyItNowPrice ?? 0,
        enableBestOffer: data.enableBestOffer ?? false,
        autoAcceptPrice: data.autoAcceptPrice ?? 0,
        fulfillmentPolicyId: data.fulfillmentPolicyId,
        paymentPolicyId: data.paymentPolicyId,
        returnPolicyId: data.returnPolicyId,
        locationKey: data.locationKey,
        ebayCategoryId: data.ebayCategoryId,
        ebayStoreCategoryId: data.ebayStoreCategoryId,
        titleStructure: data.titleStructure ?? "{{year}} {{make}} {{model}} {{categoryName}}",
      });

      setIsLoading(false);
    }
  }, [defaultPartCategory.isLoading, defaultPartCategory.data, category]);

  if (isLoading) 
    return <LoadingWheel />;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 ">
      <h1 className="text-lg">Category Listing Defaults</h1>
      <div className="border-t border-gray-200 pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-2">

        <EbayCategoryDefaultTitle register={register} getValues={getValues} setValue={setValue} />

        <SelectInput
          label={"Listing Format"}
          register={register}
          registerName={"format"}
          options={formatOptions}
          setValue={handleSetFormat}
          watch={watch}
        />

        {getValues("format") == FormatType.Auction ? (
          <SelectInput
            label={"Duration"}
            register={register}
            registerName={"duration"}
            options={durationOptions}
            setValue={setValue}
            watch={watch}
          />
        ) : (
          <div className="sm:col-span-1 h-16">{/* Spacer */}</div>
        )}

        <EbayCategoryDropdown
          categoryId={defaultPartCategory.data?.data?.ebayCategoryId ?? ""}
          register={register}
          registerName={"ebayCategoryId"}
          setValue={setValue}
          watch={watch}
        />

        <EbayStoreCategoryDropdown
          categoryId={defaultPartCategory.data?.data?.ebayStoreCategoryId ?? ""}
          register={register}
          registerName={"ebayStoreCategoryId"}
          setValue={setValue}
          watch={watch}
        />

        {getValues("format") == FormatType.Auction ? (
          <>
            <NumberInput
              label={"Starting Price (£)"}
              register={register}
              registerName={"auctionStartPrice"}
            />
            <NumberInput
              label={"Reserve Price (£)"}
              register={register}
              registerName={"auctionReservePrice"}
            />
          </>
        ) : (
          <>
            <NumberInput
              label={"Buy It Now Price (£)"}
              register={register}
              registerName={"buyItNowPrice"}
            />
            {watch("enableBestOffer") ? (
              <NumberInput
                label={"Best Offer Auto Accept Price (£)"}
                register={register}
                registerName={"autoAcceptPrice"}
              />
            ) : (
              <div className="sm:col-span-1 h-16">{/* Spacer */}</div>
            )}
          </>
        )}

        {getValues("format") == FormatType.FixedPrice && (
          <>
            <div className="sm:col-span-1 mt-4">
              <input
                id="enableBestOffer"
                {...register("enableBestOffer")}
                type="checkbox"
                className="mr-2 h-5 w-5"
              />
              <label htmlFor="enableBestOffer">Allow Best Offer?</label>
            </div>

            <div className="sm:col-span-1 h-16">{/* Spacer */}</div>
          </>
        )}

        <SelectInput
          label={"Shipping Policy*"}
          register={register}
          registerName={"fulfillmentPolicyId"}
          options={policies.data?.data.fulfilmentPolicies ?? []}
          setValue={setValue}
          watch={watch}
        />
        <SelectInput
          label={"Payment Policy*"}
          register={register}
          registerName={"paymentPolicyId"}
          options={policies.data?.data.paymentPolicies ?? []}
          setValue={setValue}
          watch={watch}
        />
        <SelectInput
          label={"Returns Policy*"}
          register={register}
          registerName={"returnPolicyId"}
          options={policies.data?.data.returnPolicies ?? []}
          setValue={setValue}
          watch={watch}
        />

        <SelectInput
          label={"Location*"}
          register={register}
          registerName={"locationKey"}
          options={locations.data?.data.locations ?? []}
          setValue={setValue}
          watch={watch}
        />

        <NumberInput label="Width (cm)" registerName="width" register={register} step="1" />
        <NumberInput label="Height (cm)" registerName="height" register={register} step="1" />
        <NumberInput label="Length (cm)" registerName="length" register={register} step="1" />
        <NumberInput label="Weight (kg)" registerName="weight" register={register} step="1" />

        <ButtonInput label={"Save"} isSubmit={true} onClick={undefined} classes={"w-1/2"} />
      </div>
    </form>
  );
}