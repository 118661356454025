import { useEffect, useState } from "react";

import useEbayMessages from "../../data/useEbayMessages";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import EbayMessageTable from "../../components/ebayMessaging/EbayMessageTable";

export default function EbayMessages() {
  const { setInfo } = usePageNameContext();
  const [page, setPage] = useState(1);
  const [days, setDays] = useState(7);
  const { unread, allmsgs } = useEbayMessages({ page, days });
  const [unreadOnly, setUnreadOnly] = useState(false);

  useEffect(() => {
    setInfo({
      name: "View eBay Messages",
      desc: "View and manage messages to customers.",
    });
  }, []);

  var messages = unreadOnly ? unread : allmsgs;

  if (messages.isLoading)
    return (
      <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
        <LoadingWheel />
      </div>
    );

  return (
    <div className="mt-4">
      <div className="flex gap-x-6 items-center">
        <div>
          <label>Days to display </label>
          <select onChange={x => { setDays(parseInt(x.target.value)); messages.refetch(); }} value={days}>
            <option value={1}>1 Day</option>
            <option value={7}>1 Week</option>
            <option value={14}>2 Weeks</option>
            <option value={30}>1 Month</option>
            {/* <option value={60}>2 Months</option>
                    <option value={90}>3 Months</option>
                    <option value={190}>6 Months</option>
                    <option value={365}>1 Year</option> */}
          </select>
        </div>

        <div>
          <label>Unread Only </label>
          <input type="checkbox" checked={unreadOnly} onChange={x => setUnreadOnly(x.target.checked)} className="cursor-pointer" />
        </div>
      </div>


      <EbayMessageTable messages={messages.data?.data} />

      <div className="text-3xl mt-2 flex gap-x-2 items-center justify-center">
        <button onClick={() => { setPage(page - 1); messages.refetch(); }}>&lt;</button>
        <a className="text-xl">{page}</a>
        <button onClick={() => { setPage(page + 1); messages.refetch(); }}>&gt;</button>
      </div>
    </div>
  );
}