import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead, INewLead, ISingleLeadRespose } from "../models/Lead";
import { filter } from "lodash";

export default function useLeadsOnCollectionDay(day: string | undefined) {
  const { get, getFile, post, postGetFile, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const leadsOnCollectionDay = useQuery({
    queryKey: ["leads","collectonday",day],
    queryFn: () => get<IAllLeadsResponse>(`/lead/partialoncollectionday?collectionDay=${day}`),
    staleTime: 1000 * 60 * 1, // 1 minute
    // refetchInterval: 1000 * 60 * 1, // 1 minutes
    // refetchIntervalInBackground: true,
    enabled: day !== undefined && day !== null && day !== "",
  });

  return {
    leadsOnCollectionDay,
  };
}
