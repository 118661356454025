import date from "date-and-time";
import { useEffect, useState } from "react";
import useSingleLead from "../../data/useSingleLead";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ILead, LeadSource, LeadStatus } from "../../models/Lead";
import useNotification from "../notifications/useNotifications";
import Card from "../shared/Card/Card";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import getAddress from "../../utils/getAddress";
import { IVehicle } from "../../models/Vehicle";
import useVehicles from "../../data/useVehicles";
import classNames from "../../utils/classNames";
import { useQueryClient } from "@tanstack/react-query";
import useVehiclesMutations from "../../data/useVehiclesMutations";

export default function DriverLeadDetails({ lead }: { lead: ILead }) {
  const { update } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Lead Details");
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();
  const [collectConfirmOpen, setCollectConfirmOpen] = useState(false);

  const handleCollected = () => {
    const updatedVehicle: IVehicle = {
      ...lead.vehicle!,
      collectedOn: date.format(new Date(), "YYYY-MM-DD")
    };

    update.mutate(updatedVehicle, {
      onSuccess: () => {
        saveHandlers.onSuccess();

        setTimeout(() => {
          queryClient.invalidateQueries(["lead", lead.id]);
        }, 1000 * 2);
      },
      onError: (error) => {
        console.log(error);
        saveHandlers.onError();
      },
      onSettled: () => {
        setCollectConfirmOpen(false);
      }
    });
  }

  useEffect(() => {

  }, [lead])

  return (
    <>
      <ConfirmModal open={collectConfirmOpen} setOpen={setCollectConfirmOpen}
        title="Mark as collected?" confirmButtonText="Collect"
        message={`Are you sure you want to mark this vehicle as collected? \nMake sure that you are able to collect the vehicle, the customers payment details are correct and you have pictures of the vehicle before taking collection.`}
        isLoading={false}
        onConfirm={handleCollected}
      />

      <Card title="Lead Details" bodyClassName="p-4">

        <dl className="divide-y divide-gray-200">

          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Contact Name</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {lead.contact?.fullname}
            </dd>
          </div>

          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Contact Numbers</dt>
            <dd className="flex flex-col text-sm text-gray-900 col-span-2">
              <span>{lead.contact?.telephone1}</span>
              <span>{lead.contact?.telephone2}</span>
            </dd>
          </div>

          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Full Address</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {getAddress(lead.vehicle)}
            </dd>
          </div>
        </dl>

        <div className="mt-4">
          <div className="grid grid-cols-1 sm:grid-cols-3">
            <div className="flex items-center">
              <input checked={lead.vehicle?.isVan} disabled={true} type="checkbox" />
              <label className="ml-2">Van</label>
            </div>
            <div className="flex items-center">
              <input checked={lead.vehicle?.hasAlert} disabled={true} type="checkbox" />
              <label className="ml-2">Alert</label>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3">
            <div className="flex items-center">
              <input checked={lead.vehicle?.accessIssues} disabled={true} type="checkbox" />
              <label className="ml-2">Access issues</label>
            </div>
            <div className="flex items-center">
              <input checked={lead.vehicle?.vehicleComplete} disabled={true} type="checkbox" />
              <label className="ml-2">Vehicle complete</label>
            </div>
            <div className="flex items-center">
              <input checked={lead.vehicle?.freeOfWaste} disabled={true} type="checkbox" />
              <label className="ml-2">Vehicle free of waste</label>
            </div>
            <div className="flex items-center">
              <input checked={lead.vehicle?.vehicleStarting} disabled={true} type="checkbox" />
              <label className="ml-2">Vehicle starting</label>
            </div>
            <div className="flex items-center">
              <input checked={lead.vehicle?.vehiclePremium} disabled={true} type="checkbox" />
              <label className="ml-2">Premium vehicle</label>
            </div>
          </div>

        </div>

        <ButtonInput label="Mark as Collected" isSubmit={false} onClick={() => setCollectConfirmOpen(true)}
          classes={classNames(lead.status === LeadStatus.Collected ? "bg-green-600 hover:bg-green-700" : "", `mt-8`)}
        />

      </Card>
    </>
  );
}
