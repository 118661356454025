import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";

export default function useCategoryMutations() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const bulkUpdateDefaultCategories = useMutation(
    (body: any) => post(`/part/categories/defaults/bulk`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  return {
    bulkUpdateDefaultCategories
  };
}
