import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";

import { CameraIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import { ITruck } from "../../models/Truck";
import classNames from "../../utils/classNames";
import LoadingWheel from "../shared/LoadingWheel";
import { useAuth } from "../../contexts/AuthContext";
import { useUserLookup } from "../../hooks/useUserLookup";

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + "p-3 cursor-pointer"}
      {...rest}
    />
  );
}

//         <button
// onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
// style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
// className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
// >
// {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
// <ChevronRightIcon className="h-4 w-4" />
// </button>

const colHelper = createColumnHelper<ITruck>();

export default function TrucksTable({
  trucks,
  setTruck,
}: {
  trucks: ITruck[];
  setTruck: any;
}) {
  const { isLoading: usersLoading, findUser } = useUserLookup();
  const { loading } = useAuth();
  const [sorting, setSorting] = useState<SortingState>([]);

  const cols = [
    {
      id: "select",
      header: ({ table }: any) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <div
          className="flex justify-center items-center flex-1 p-4"
          onClick={(e) => e.stopPropagation()}
        >
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    colHelper.display({
      id: "image",
      cell: (info) => (
        <span className="flex justify-center relative">
          {info.row.original.vehicle?.vehicleImageUrl ? (
            <>
              <img
                className="w-32 h-20 object-contain"
                src={`${
                  info.row.original.vehicle != null
                    ? info.row.original.vehicle.vehicleImageUrl
                    : null
                }`}
              />
            </>
          ) : (
            <CameraIcon className="w-14 text-gray-600" />
          )}
        </span>
      ),
    }),
    colHelper.accessor("name", {
      header: "Name",
      sortingFn: "auto",
    }),
    colHelper.accessor("vehicle.vrm", {
      header: "VRM",
      enableSorting: false,
    }),
    colHelper.accessor("vehicle.combinedMake", {
      header: "Make",
    }),
    colHelper.accessor("vehicle.combinedModel", {
      header: "Model",
    }),
    colHelper.accessor("tripCount", {
      header: "Trips",
    }),
    colHelper.accessor("capacity", {
      header: "Capacity",
    }),
    colHelper.accessor("motDueDate", {
      header: "MOT Due",
      sortingFn: "auto",
      cell: (motDue) => (
        <span>
          {motDue.getValue() !== null
            ? date.format(new Date(motDue.getValue()!), "DD/MM/YYYY")
            : "Not Set"}
        </span>
      ),
    }),
    colHelper.accessor("serviceDueDate", {
      header: "Service Due",
      sortingFn: "auto",
      cell: (serviceDue) => (
        <span>
          {serviceDue.getValue() !== null
            ? date.format(new Date(serviceDue.getValue()!), "DD/MM/YYYY")
            : "Not Set"}
        </span>
      ),
    }),
    colHelper.display({
      header: "Default Driver",
      cell: (info) => {
        const user = findUser(info.row.original.driverId!);
        return (
          <>
            {user?.forename} {user?.surname}
          </>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: trucks,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (loading || usersLoading) {
    return (
      <div className="pt-[15vh]">
        <LoadingWheel />
      </div>
    );
  } else {
    return (
      <>
        <div className="mt-4 bg-white overflow-auto">
          <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="bg-gp-blue-50 text-gray-800"
                >
                  {headerGroup.headers.map((header, i) => (
                    <th
                      key={header.id}
                      scope="col"
                      className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " ▲",
                            desc: " ▼",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, i) => {
                let rowCells = row.getVisibleCells();
                return (
                  <Fragment key={i}>
                    <tr
                      key={row.id}
                      onClick={() => setTruck(row.original)}
                      className={classNames(
                        row.original.active ? "" : "bg-gray-200",
                        "hover:bg-gray-100 hover:cursor-pointer"
                      )}
                    >
                      {rowCells.map((cell) => (
                        <td key={cell.id} className="px-3 py-3 border text-sm">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && (
                      <></>
                      // <tr>
                      //   {/* 2nd row is a custom 1 cell row */}
                      //   <td colSpan={row.getVisibleCells().length}>
                      //     <LeadsTableSubComponent lead={row.original} />
                      //   </td>
                      // </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
