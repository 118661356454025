import { useEffect, useState } from "react";

import { RadioGroup } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faXmark } from "@fortawesome/free-solid-svg-icons";

import { EditAddress } from "./EditAddress";
import { IPart } from "../../../models/Part";
import { EditDimensions } from "./EditDimensions";
import useShipping from "../../../data/useShipping";
import LoadingWheel from "../../shared/LoadingWheel";
import usePartDefaultCategories from "../../../data/usePartDefaultCategories";
import {
  IShippingAddress,
  IShippingDimensions,
  IShippingLabelCreateRequest,
} from "../../../models/Shipping";
import classNames from "../../../utils/classNames";
import { FedexShipping } from "./FedexShipping";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import { ProoviaShipping } from "./ProoviaShipping";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import PinProtect, { PinProtectState } from "../../shared/PinProtect";

interface ManageShippingModalProps {
  part: IPart;
  setOpen: (open: boolean) => void;
}

export function ManageShippingModal({
  part,
  setOpen,
}: ManageShippingModalProps) {
  const { saveHandlers } = useDefaultCRUDHandlers("Shipping Label");
  const { defaultPartCategory } = usePartDefaultCategories({ part });
  const { shippingInfo, shippingLabel, cancelShipping } = useShipping(part);

  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [pinState, setPinState] = useState<PinProtectState>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const [address, setAddress] = useState<IShippingAddress>();
  const [addressLoading, setAddressLoading] = useState(true);
  const [isAddressEditing, setIsAddressEditing] = useState(false);

  const [dimensions, setDimensions] = useState<IShippingDimensions>();
  const [dimensionsLoading, setDimensionsLoading] = useState(true);
  const [isDimensionsEditing, setIsDimensionsEditing] = useState(false);

  const [shippingService, setShippingService] = useState<string>();

  const [prooviaItemId, setProoviaItemId] = useState<number>();
  const [prooviaCollection, setProoviaCollection] = useState<string>();
  const [prooviaDelivery, setProoviaDelivery] = useState<string>();

  const [shippingProvider, setShippingProvider] = useState(0);
  const shippingProviders = [
    { value: 0, label: "FedEx" },
    { value: 1, label: "Proovia" },
  ];

  const handleChangeProvider = (value: number) => {
    setShippingService(undefined);
    setShippingProvider(value);
  };

  const resolveAddress = () => {
    const contactAddress = shippingInfo.data?.data.contactAddress;

    return {
      addressLine1: part.addressLine1 ?? contactAddress?.addressLine1!,
      addressLine2: part.addressLine2 ?? contactAddress?.addressLine2!,
      city: part.city ?? contactAddress?.city!,
      county: part.county ?? contactAddress?.county!,
      postcode: part.postcode ?? contactAddress?.postalCode!,
      stateOrProvince: part.stateOrProvince ?? contactAddress?.stateOrProvince!,
      fullName: part.fullName ?? shippingInfo.data?.data.fullName!,
    };
  };

  const resolveDimensions = () => {
    const defaultDimensions = defaultPartCategory.data?.data;

    return {
      weight: part.weight ?? defaultDimensions?.weight!,
      height: part.height ?? defaultDimensions?.height!,
      length: part.length ?? defaultDimensions?.length!,
      width: part.width ?? defaultDimensions?.width!,
    };
  };

  const handleDeleteShipping = () => {
    setPinModalOpen(true);
    setPinState({
      onPinSuccess: () => {
        cancelShipping.mutate(null, {
          onSuccess: () => {
            saveHandlers.onSuccess();
            setOpen(false);
          },
          onError: (res: any) => {
            saveHandlers.onError();
          },
          onSettled: () => { },
        });
      },
      onPinFailed: () => { },
      pinLabel: "Delete Tracking Information",
    });
  };

  const handleGetShippingLabel = () => {

    //If there is a label, just print it
    if (!part.shippingLabelDocumentId) {
      console.log("No label to print");
      if (shippingService === undefined) {
        setError("Please select a shipping service");
        return;
      }

      if (!address?.addressLine1 || address?.addressLine1!.length < 4) {
        setError(
          "Please fill in the address line 1. Must be at least 4 characters."
        );
        return;
      }

      if (!address?.postcode || address.postcode!.length < 5) {
        setError(
          "Please fill in the address postcode. Must be at least 5 characters."
        );
        return;
      }

      if (
        !dimensions?.height ||
        dimensions.height === 0 ||
        !dimensions.length ||
        dimensions.length === 0 ||
        !dimensions.width ||
        dimensions.width === 0 ||
        !dimensions.weight ||
        dimensions.weight === 0
      ) {
        setError("Please fill in the parcel size and weight.");
        return;
      }
    }

    setLoading(true);
    setError(undefined);

    let shippingLabelCreateData: IShippingLabelCreateRequest = {
      ...address!,
      ...dimensions!,
      partDataId: part.id,
      service: shippingService!,
    };

    // is Proovia
    if (shippingProvider === 1) {
      shippingLabelCreateData = {
        ...shippingLabelCreateData,
        prooviaItemId: prooviaItemId,
        collectionDate: prooviaCollection,
        deliveryDate: prooviaDelivery,
      };
    }

    shippingLabel.mutate(shippingLabelCreateData, {
      onSuccess: (d: any) => {
        saveHandlers.onSuccess();
        d.blob().then((blob: Blob) => {
          const url = window.URL.createObjectURL(blob);
          var iframe = document?.getElementById("frame") as HTMLIFrameElement;
          iframe.src = url;
          iframe.onload = () => {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow?.print();
            }, 1);
          };
        });
      },
      onError: (d: any) => {
        saveHandlers.onError();
        d.text().then((x: string) => console.warn(x));
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!defaultPartCategory.isLoading && defaultPartCategory.data) {
      setDimensions(resolveDimensions());
      setDimensionsLoading(false);
    }

    if (!part.categoryId) {
      setDimensionsLoading(false);
    }
  }, [defaultPartCategory.isLoading, defaultPartCategory.data]);

  useEffect(() => {
    if (!shippingInfo.isLoading && shippingInfo.data) {
      setAddress(resolveAddress());
      setAddressLoading(false);
    }
  }, [shippingInfo.isLoading, shippingInfo.data]);

  return (
    <>
      <PinProtect
        open={pinModalOpen}
        setOpen={setPinModalOpen}
        pinState={pinState}
      />
      <div className="space-y-4">
        {loading ? (
          <LoadingWheel />
        ) : (
          <>
            <div className="flex items-center space-x-4">
              <label className="text-lg font-bold">Shipping Address</label>
              <FontAwesomeIcon
                className="rounded-full ml-1 cursor-pointer hover:ring-2 hover:ring-blue-500"
                icon={isAddressEditing ? faXmark : faPencil}
                onClick={() => setIsAddressEditing(!isAddressEditing)}
              />
            </div>
            <div>
              {addressLoading ? (
                <LoadingWheel />
              ) : isAddressEditing ? (
                <EditAddress
                  address={address!}
                  partId={part.id}
                  onEditSuccess={(result) => {
                    setAddress(result);
                    setIsAddressEditing(false);
                  }}
                />
              ) : (
                <>
                  <p>{address?.fullName}</p>
                  <p>{address?.addressLine1}</p>
                  <p>{address?.addressLine2}</p>
                  <p>{address?.city}</p>
                  <p>{address?.county}</p>
                  <p>{address?.stateOrProvince}</p>
                  <p>{address?.postcode}</p>
                </>
              )}
            </div>
            <div className="flex items-center space-x-4">
              <label className="text-lg font-bold">Dimensions & Weight</label>
              <FontAwesomeIcon
                className="rounded-full ml-1 cursor-pointer hover:ring-2 hover:ring-blue-500"
                icon={isDimensionsEditing ? faXmark : faPencil}
                onClick={() => setIsDimensionsEditing(!isDimensionsEditing)}
              />
            </div>
            <div>
              {dimensionsLoading ? (
                <LoadingWheel />
              ) : isDimensionsEditing ? (
                <EditDimensions
                  dimensions={dimensions!}
                  partId={part.id}
                  onEditSuccess={() => { }}
                />
              ) : (
                <div className="grid grid-cols-4 gap-2 text-center divide-x">
                  <p>
                    <span className="text-gray-400">Width:</span>{" "}
                    {dimensions?.width}
                    cm
                  </p>
                  <p>
                    <span className="text-gray-400">Height:</span>{" "}
                    {dimensions?.height}cm
                  </p>
                  <p>
                    <span className="text-gray-400">Length:</span>{" "}
                    {dimensions?.length}cm
                  </p>
                  <p>
                    <span className="text-gray-400">Weight:</span>{" "}
                    {dimensions?.weight}kg
                  </p>
                </div>
              )}
            </div>
            {!part.shippingLabelDocumentId && (
              <div className="flex items-center space-x-4">
                <label className="text-lg font-bold">Shipping Service</label>
              </div>
            )}
            {addressLoading && dimensionsLoading ? (
              <div>
                <LoadingWheel />
              </div>
            ) : (
              !part.shippingLabelDocumentId && (
                <div>
                  <div className="w-full">
                    <RadioGroup
                      value={shippingProvider}
                      onChange={handleChangeProvider}
                      className="my-4"
                    >
                      <RadioGroup.Label className="sr-only">
                        Choose which shipper to use
                      </RadioGroup.Label>
                      <div className="grid grid-cols-2 gap-3">
                        {shippingProviders.map((type) => (
                          <RadioGroup.Option
                            key={`${type.value}`}
                            value={type.value}
                            className={({ active, checked }) =>
                              classNames(
                                active
                                  ? "ring-2 ring-offset-2 ring-gp-blue-500"
                                  : "",
                                checked
                                  ? "bg-gp-blue-600 border-transparent text-white hover:bg-gp-blue-700"
                                  : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                "cursor-pointer focus:outline-none border rounded-md py-1.5 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                              )
                            }
                          >
                            <RadioGroup.Label as="span">
                              {type.label}
                            </RadioGroup.Label>
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                  {
                    {
                      0: (
                        <FedexShipping
                          address={address!}
                          dimensions={dimensions!}
                          part={part}
                          onServiceSelect={(svc) => setShippingService(svc)}
                          selectedService={shippingService}
                        />
                      ),
                      1: (
                        <ProoviaShipping
                          address={address!}
                          dimensions={dimensions!}
                          part={part}
                          onProoviaItemSelect={(item) => setProoviaItemId(item)}
                          onCollectionSlotSelect={(slot) =>
                            setProoviaCollection(slot)
                          }
                          onDeliverySlotSelect={(slot) =>
                            setProoviaDelivery(slot)
                          }
                          onServiceSelect={(svc) => setShippingService(svc)}
                          selectedService={shippingService}
                        />
                      ),
                    }[shippingProvider]
                  }
                </div>
              )
            )}
            {error && (
              <div className="col-span-4 bg-red-100 text-red-600 px-4 py-2 rounded">
                {error}
              </div>
            )}
            <div className="flex items-center justify-end space-x-2">
              <ButtonInput
                label="Cancel Shipping"
                disabled={part.shippingLabelDocumentId === null}
                onClick={handleDeleteShipping}
                classes=""
                isSubmit={false}
              />
              <ButtonInput
                label="Print Shipping Label"
                disabled={loading || (!shippingService && !part.shippingLabelDocumentId)}
                onClick={handleGetShippingLabel}
                classes=""
                isSubmit={false}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
