import { useState } from "react";

import { useForm } from "react-hook-form";

import useEbayAspects from "../../../data/useEbayAspects";
import usePartDefaultCategoryAssignees from "../../../data/usePartDefaultCategoryAssignees";
import { IPartCategory } from "../../../models/Part";
import { RadioGroupInput } from "../../shared/Inputs/RadioGroupInput";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";


const assigneeOptions = [
  { name: "None", value: 0 },
  { name: "Imager", value: 1 },
  { name: "Dismantler", value: 2 }
];

interface IFormValues {
  assignee: number;
}

interface IFormProps {
  register: any;
  getValues: any;
  setValue: any;
  watch: any;
}

interface IIndividualAspectProps extends IFormProps {
  aspect: any
}

interface IAspectDefaultsProps extends IFormProps {
  ebayCategoryId: string | undefined
}

const IndividualAspect = ({ aspect, register, getValues, setValue, watch }: IIndividualAspectProps) => {


  
  const handleSetValue = (registerName: string, value: any) => {
    // Have to add to assignee list object

    const assingnee = {
      [registerName]: value
    };

    console.log(assingnee);

    // add to object, overwrite if exists
    const assignees = getValues("assignees");

    console.log(assignees);

    if (assignees) {
      let found = false;
      const newAssignees = assignees.map((a: any) => {
        if (a.hasOwnProperty(registerName)) {
          found = true;
          return assingnee;
        }
        return a;
      });
    
      if (!found) {
        newAssignees.push(assingnee);
      }
    
      console.log(newAssignees);
    
      setValue("assignees", newAssignees);
    } else {
      setValue("assignees", [assingnee]);
    }

  };

  return (
    <div className="grid grid-cols-2 border-0 border-b pb-4">
      <div className="flex items-center">
        <label className="font-medium text-gray-500">{aspect.localizedAspectName}</label>
      </div>
      <div>
        <RadioGroupInput options={assigneeOptions} register={register} registerName={aspect.localizedAspectName} setValue={handleSetValue} watch={watch} />
      </div>
    </div>
  )
};


export default function BulkCategoryAspectsDefaults({ ebayCategoryId, register, getValues, setValue, watch }: IAspectDefaultsProps) {
  const { aspects: ebayAspects } = useEbayAspects(ebayCategoryId!);


  return (
    <div className="mt-6">
      <h1 className="text-lg mb-4">eBay Item Aspect Defaults</h1>
      <div className="border-t border-gray-200 pt-4 flex flex-col gap-4 sm:gap-y-6">

        {ebayAspects?.data?.map((aspect, i) => (
          <IndividualAspect key={i} aspect={aspect} register={register} getValues={getValues} setValue={setValue} watch={watch} />
        ))}
      </div>
    </div>
  );
};