import { useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPart, IPartResponse } from "../models/Part";
import { DefaultResponseWithData } from "../models/system";

export default function useVehicleCategoryParts(
  vehicleId: string,
  categoryId: string
) {
  const { get } = useApiHelper();
  const staleTime = 1000 * 60 * 10; //10 minutes

  const parts = useQuery({
    queryKey: ["parts", vehicleId, categoryId],
    queryFn: () =>
      get<DefaultResponseWithData<IPart[]>>(
        `/part/vehicle/${vehicleId}/category/${categoryId}`
      ).then((res) => res.data),
    staleTime: staleTime,
    refetchInterval: staleTime,
  });

  return {
    parts,
  };
}
