import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { UseMutationResult } from "@tanstack/react-query";

import Modal from "../../layouts/Modal";
import { TextInput } from "../shared/Inputs/TextInput";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { IScrapPrice } from "../../models/ScrapPrice";
import useScrapPrices from "../../data/useScrapPrices";
import { NumberInput } from "../shared/Inputs/NumberInput";

type FormValues = {
  name: string,
  postcode: string,
  pricePerTonne: number,
};

interface ScrapPricesModalProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  scrapPrice?: IScrapPrice;
}

export default function ScrapPricesModal({ open, setOpen, scrapPrice }: ScrapPricesModalProps) {
  const { deleteHandlers, saveHandlers } = useDefaultCRUDHandlers("Scrap Prices");
  const { update, create, remove } = useScrapPrices();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm<FormValues>();

  const onSubmit = (data: any) => {

    const updatedScrapPrice = {
      ...scrapPrice,
      ...data
    };

    if (scrapPrice !== undefined) {
      update.mutate(updatedScrapPrice, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    } else {
      create.mutate(updatedScrapPrice, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    }
  }

  const handleDelete = () => {
    remove.mutate(scrapPrice?.id!, {
      onSuccess: (data) => {
        deleteHandlers.onSuccess();
        setOpen(false);
        setDeleteOpen(false);
        reset({});
      },
      onError: deleteHandlers.onError
    });
  }

  useEffect(() => {

    if (scrapPrice !== undefined) {
      reset({
        name: scrapPrice.name,
        postcode: scrapPrice.postcode,
        pricePerTonne: scrapPrice.pricePerTonne,
      });
    } else {
      reset({})
    }
  }, [scrapPrice, open, reset]);

  return (
    <>
      <ConfirmModal open={deleteOpen} setOpen={setDeleteOpen}
        title={"Delete Scrap Price"} message={`Are you sure you want to delete this scrap price entry?\n This action cannot be undone.`}
        confirmButtonText={"Delete"} onConfirm={handleDelete} isLoading={false}
      />
      <Modal width="max-w-2xl" open={open} setOpen={setOpen} >
        <h1 className="text-xl">{`${scrapPrice ? "Edit" : "Add"} a Scrap Price`}</h1>
        <div className="mt-4">
          <form className="space-y-4 border-t border-gray-200" onSubmit={handleSubmit(onSubmit)} >
            <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-1">

              <TextInput label="Name" registerName="name" register={register} />

              <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                <TextInput label="Postcode" registerName="postcode" register={register} />
                <NumberInput label="Price per Tonne (£)" registerName="pricePerTonne" register={register} />
              </div>

              <ButtonInput label={`${scrapPrice ? "Save Changes" : "Create"}`} isSubmit={true} onClick={undefined} classes={undefined} />
              {scrapPrice !== undefined && (
                <ButtonInput label={"Delete"} isSubmit={false} onClick={() => (setDeleteOpen(true))} classes={"!bg-red-500 hover:!bg-red-600"} />
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
