import { ButtonInput } from "../shared/Inputs/ButtonInput";

import JobSheetTable from "../schedule/JobSheetTable";

export default function PrintJobSheet({ selectedDay, week, year }: { selectedDay: string | undefined, week: number, year: number }) {


  function printSection() {
    //Take the html content of the table and create a new document with it in the iframe
    //Add the content to the html, including specific styles for printing
    var content = document.getElementById("print-section");
    var iframe = document?.getElementById("frame") as HTMLIFrameElement;
    var pri = iframe.contentWindow;
    pri?.document.open();
    pri?.document.write(`
    <html>
      <head>
        <title>Print</title>
        <style>
        * {
          -webkit-print-color-adjust: exact !important;
          color-adjust: exact !important;
          print-color-adjust: exact !important;
        }
        table, th, td {
          padding: 5px;
          border: 1px solid black;
          border-collapse: collapse;
          font-family: 'Poppins', sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        .truck, th {
          background-color: #dbeafe;
        }
        .awaiting {
          // background-color: #FF0000;
        }
        .export {
          background-color: #ffff00;
        }
        .sales {
          background-color: #86dc3d;
        }
        .scrap {
          background-color: #90bbe8;
        }
        .cancelled {
          background-color: #e97451;
        }
        </style>
      </head>
      <body>
        ${content!.innerHTML}
      </body>
    </html>
  `);
    pri?.document.close();
    pri?.focus();
    pri?.print();
  }



  return (
    <>
      <ButtonInput label={"Print Job Sheet"} isSubmit={false} disabled={!selectedDay} onClick={() => printSection()} classes={!selectedDay && "bg-gray-400 hover:bg-gray-400"} />
      <div className="hidden" id="print-section">
        <JobSheetTable selectedDay={selectedDay} week={week} year={year} />
      </div>
      <iframe id="frame" className="h-0 w-0 absolute"></iframe>

    </>
  )
}