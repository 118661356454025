import date from "date-and-time";
import { Combobox } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { IContact } from "../../models/Contact";
import { ILead } from "../../models/Lead";
import classNames from "../../utils/classNames";
import useEmails from "../../data/useEmails";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

export default function SendEmail({ lead, setOpen }: { lead: ILead, setOpen: any }) {
  const { sendEmail } = useEmails();
  const { saveHandlers } = useDefaultCRUDHandlers("Email sent");
  const [selected, setSelected] = useState<any>({ id: undefined });
  const [email, setEmail] = useState<string>(lead.contact?.email!);
  const [content, setContent] = useState<string>("");

  const emailTemplates = [
    {
      id: 1,
      title: "Enquiry Received",
      content: `Hi ${lead.contact?.fullname},\n\nI hope you are well. I am contacting you regarding your recent enquiry. I am writing to inform you that we have received your request and it is currently being processed. We will be in touch with you shortly to let you know when we're ready to collect your vehicle.\n\nKind regards,\n\nBaxenden Car Breakers`,
    },
    {
      id: 2,
      title: "Vehicle Collection",
      content: `Hi ${lead.contact?.fullname.trim()}, \n\nThis email is confirming that you pickup date and time is ${date.format(new Date(lead.vehicle?.scheduledCollectionOn!), "HH:mm DD/MM/YYYY")}. \n\nKind regards,\n\nBaxenden Car Breakers`,
    }
  ]

  const handleSetSelected = (template: any) => {
    setSelected(template);
    setContent(template.content);
  }

  const handleSendEmail = () => {
    console.log("email", email);
    console.log("content", content);

    var emailToSend = {
      ContactId: lead.contact?.id,
      Recipient: email,
      Body: content,
      Name: "",
      Subject: selected.title
    }

    sendEmail.mutate(emailToSend, {
      onSuccess: () => {
        setOpen(false);
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      }
    })
  }

  return (
    <Combobox>
      {({ activeOption }) => (
        <>
          <Combobox.Options
            as="div"
            static
            className="flex divide-y divide-gray-100 flex-col sm:flex-row sm:divide-x h-full"
          >
            <div className="h-full min-w-0 h-80 flex-auto scroll-py-4 overflow-y-auto px-6 py-4">
              <div className="-mx-2 text-sm text-gray-700">
                {emailTemplates.map(
                  (template) => (
                    <Combobox.Option
                      as="div"
                      key={template.id}
                      value={template}
                      onClick={() => handleSetSelected(template)}
                      className={({ active }) =>
                        classNames(active || selected.id === template.id ? "bg-gray-100 text-gray-900" : "",
                          "flex cursor-pointer select-none items-center rounded-md p-2"
                        )
                      }
                    >
                      {({ active }) => (
                        <>
                          <span className="ml-3 flex-auto truncate">
                            {template.title}
                          </span>
                          {Boolean(active || selected.id === template.id) && (
                            <ChevronRightIcon
                              className="ml-3 h-5 w-5 flex-none text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  )
                )}
              </div>
            </div>

            {selected.id ? (
              <div className="h-full w-full flex-none grow flex-col divide-y divide-gray-100 overflow-y-auto flex sm:w-1/2">
                <div className="p-6">
                  <div className="mb-4">
                    <label>Email:</label>
                    <input value={email} onChange={((e) => setEmail(e.target.value))} type="text" className="text-sm w-full rounded-md border-gray-600" />
                  </div>

                  <div>
                    <label>Content:</label>
                  </div>
                  <textarea style={{ resize: "none" }} value={content} onChange={((e) => setContent(e.target.value))} rows={18} className="text-sm w-full rounded-md border-gray-600" />
                </div>


                <div className="flex flex-auto flex-col justify-between p-6">
                  <button
                    onClick={() => handleSendEmail()}
                    type="button"
                    className="w-full rounded-md border border-transparent bg-gp-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gp-blue-700 focus:outline-none focus:ring-2 focus:ring-gp-blue-500 focus:ring-offset-2"
                  >
                    Send
                  </button>
                </div>
              </div>
            ) : (
              <div className="h-80 w-full sm:h-96 sm:w-1/2"></div>
            )}
          </Combobox.Options>
        </>
      )}
    </Combobox>
  )
}