import { useMutation } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IDriverTrackingMapResponse } from "../models/DriverTracking";

export default function useDriverTrackingMap() {
  const { get } = useApiHelper();

  // const driverTrackingMap = useQuery({
  //   refetchInterval: 1000 * 10,
  //   queryKey: ["driverTrackingMap"],
  //   queryFn: () => get<IDriverTrackingMapResponse>(`/tracking/map?showIdle=true&fromDate=${fromDate}`),
  // });

  const initialData = useMutation((_: string) =>
    get<IDriverTrackingMapResponse>(`/tracking/map/latest?showIdle=true`)
  );

  const rangeData = useMutation(
    ({ fromDate, toDate }: { fromDate: string; toDate: string }) =>
      get<IDriverTrackingMapResponse>(
        `/tracking/map/range?showIdle=true&fromDate=${fromDate}&toDate=${toDate}`
      )
  );

  // const getDriverData = useMutation(
  //   () => get(`/driver/tracking`),
  //   {
  //     onSuccess: (data: any) => {
  //       queryClient.invalidateQueries(["drivers"]);
  //       return data;
  //     },
  //   }
  // );

  return {
    // driverTrackingMap,
    initialData,
    rangeData,
    // getDriverData
  };
}
