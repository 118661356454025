import { BaseEntity, DefaultResponseWithData } from "./system";

export interface IAllMessagesRepsonse extends DefaultResponseWithData<IMessage[]> {}
export interface IUnreadMessageResponse extends DefaultResponseWithData<IUnreadMessages[]> {}

export enum MessageStatus {
  Waiting,
  Accepted,
  Queued,
  Sending,
  Sent,
  Failed,
  Delivered,
  Undelivered,
  Received,
  Read,
}

export interface IUnreadMessages {
  leadId: string;
  unreadMessageCount: number;
}

export interface IMessage extends BaseEntity {
  status: MessageStatus;
  sid: string;
  from: string;
  recipient: string;
  body: string;
  userId: string;
  timestamp: string;
}
