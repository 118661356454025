import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAuthApi } from "../hooks/useAuthApi";

export default function useWilmaUsers() {
  const queryClient = useQueryClient();
  const { getUsers, updateUser, createUser, deleteUser } = useAuthApi();

  const wilmaUsers = useQuery({
    queryKey: ["wilmaUsers"],
    queryFn: () => getUsers(),
    staleTime: 1000 * 60 * 10,
  });

  const update = useMutation({
    mutationFn: updateUser,
    onSettled: () => queryClient.invalidateQueries(["wilmaUsers"]),
  });

  const create = useMutation({
    mutationFn: createUser,
    onSettled: () => queryClient.invalidateQueries(["wilmaUsers"]),
  });

  const remove = useMutation({
    mutationFn: deleteUser,
    onSettled: () => queryClient.invalidateQueries(["wilmaUsers"]),
  });

  return {
    wilmaUsers,
    update,
    create,
    remove,
  };
}
