import {
  CheckIcon,
  PencilIcon,
  TrashIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import formatDateTime from "../../utils/formatDateTime";
import { useDebounce } from "@uidotdev/usehooks";
import { ICalendarTrip } from "../../models/Trip";
import Avatar from "../user/Avatar";

interface TimeSlotSelectProps {
  trip?: ICalendarTrip;
  timeSlotChange: (value: DateTime | null, duration: number | null) => void;
  onDriverAvatarClick: () => void;
}

export default function TimeSlotSelect({
  trip,
  timeSlotChange: onChange,
  onDriverAvatarClick,
}: TimeSlotSelectProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [startTime, setStartTime] = useState<DateTime | null>(
    trip?.timeSlotStart
      ? DateTime.fromISO(trip.timeSlotStart).set({ minute: 0 })
      : DateTime.now().set({ minute: 0 })
  );
  const [duration, setDuration] = useState<number | null>(
    trip?.duration ? trip.duration : 2
  );

  const handleTimeSlotChange = () => {
    // setValue(value);
    onChange(startTime, duration);
    setIsEditing(false);
  };

  const handleRemoveTimeSlot = () => {
    onChange(null, null);
    setIsEditing(false);
  };

  if (!isEditing) {
    return (
      <div className="flex items-center justify-between text-xs text-gray-600">
        <div className="flex items-center">
          {trip?.timeSlotStart && (
            <div className="flex gap-x-1 items-center">
              <span>
                {formatDateTime(trip?.timeSlotStart, DateTime.TIME_24_SIMPLE)}
              </span>
              <span>-</span>
              <span>
                {formatDateTime(
                  DateTime.fromISO(trip?.timeSlotStart).plus({
                    hours: duration ?? 2,
                  }),
                  DateTime.TIME_24_SIMPLE
                )}
              </span>
            </div>
          )}

          {/* <span>{trip?.duration}</span> */}
          <ButtonInput
            isSubmit={false}
            onClick={() => setIsEditing(true)}
            classes={"!bg-white !border-0 !shadow-none !py-1 !ml-1"}
          >
            <PencilIcon className="text-gray-700 h-4 w-4" />
          </ButtonInput>
        </div>

        {trip?.driverId ? (
          <Avatar
            userId={trip.driverId}
            onClick={onDriverAvatarClick}
            textClasses="text-sm p-1 hover:cursor-pointer"
          />
        ) : (
          <UserCircleIcon
            onClick={onDriverAvatarClick}
            className="w-6 h-6 hover:cursor-pointer"
          />
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <p className="mb-1 text-xs">Timeslot / Duration (optional)</p>
      <div className="flex items-center gap-x-2">
        <input
          className="py-0 text-xs border-gray-400 h-6 "
          type="time"
          value={startTime?.toFormat("HH:mm")}
          step={900}
          onChange={(e) => setStartTime(DateTime.fromISO(e.target.value))}
        />
        <input
          className="py-0 text-xs border-gray-400 h-6 w-12"
          type="number"
          value={duration ?? 2}
          onChange={(e) => setDuration(parseInt(e.target.value))}
        />
        <ButtonInput
          isSubmit={false}
          onClick={handleTimeSlotChange}
          classes={"!bg-white !border-0 !shadow-none !px-2 !py-1"}
        >
          <CheckIcon className="text-green-700 h-4 w-4" />
        </ButtonInput>
        <ButtonInput
          isSubmit={false}
          onClick={handleRemoveTimeSlot}
          classes={"!bg-white !border-0 !shadow-none !px-2 !py-1"}
        >
          <TrashIcon className="text-red-700 h-4 w-4" />
        </ButtonInput>
      </div>
    </div>
  );
}
