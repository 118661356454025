import { useEffect, useState } from "react";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import Modal from "../../layouts/Modal";
import useTrips from "../../data/useTrips";
import { ITruck } from "../../models/Truck";
import LoadingWheel from "../shared/LoadingWheel";
import NotesOutput from "../shared/Notes/NotesOutput";
import { INote, ResourceType } from "../../models/Note";
import { useUserLookup } from "../../hooks/useUserLookup";
import { IWilmaUser } from "./../../models/auth/WilmaUser";
import { IUnavailableDate } from "../../models/UnavailableDate";

export interface TripManagerNotesProps {
  open: boolean;
  setOpen: any;
  truck: ITruck | undefined;
  date: string;
  unavailableDates: IUnavailableDate[];
  year: number;
  week: number;
}

export default function TripManagerNotes({
  open,
  setOpen,
  truck,
  date,
  unavailableDates,
  year,
  week,
}: TripManagerNotesProps) {
  const { isLoading: usersLoading, drivers } = useUserLookup();
  const { trips: calendarTrips } = useTrips(year, week);
  const [truckNotes, setTruckNotes] = useState<INote[]>([]);
  const [driverNotes, setDriverNotes] = useState<INote[]>([]);
  const [driver, setDriver] = useState<IWilmaUser>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      !usersLoading &&
      !calendarTrips.isLoading &&
      calendarTrips.data &&
      truck
    ) {
      const notes = calendarTrips.data.data.find(
        (day) => day.date === date
      )?.notes;

      if (notes) {
        setTruckNotes(
          notes!.filter(
            (note) =>
              note.resourceType === ResourceType.Truck &&
              note.resourceId === truck.id
          )
        );
        setDriverNotes(
          notes!.filter(
            (note) =>
              note.resourceType === ResourceType.Driver &&
              note.resourceId === truck.driverId
          )
        );
      }

      setDriver(drivers!.find((driver) => driver.id === truck?.driverId));

      setIsLoading(false);
    }
  }, [usersLoading, calendarTrips.isLoading, calendarTrips.data, truck]);

  return (
    <Modal open={open} width={"max-w-6xl"} setOpen={setOpen}>
      {isLoading ? (
        <LoadingWheel />
      ) : (
        <div className="grid grid-cols-2">
          {unavailableDates.length > 0 &&
            unavailableDates.map((unavailableDate, i) => (
              <div
                key={i}
                className="col-span-2 flex justify-center items-center mb-4 text-center bg-gray-100 p-2 border"
              >
                <div>
                  <h1 className=" font-semibold">
                    {ResourceType[unavailableDate.resourceType]} Unavailable
                    Today
                  </h1>
                  <p>{unavailableDate.message}</p>
                </div>
                <div className="pl-4">
                  <ExclamationTriangleIcon className="text-orange-500 w-10 h-10" />
                </div>
              </div>
            ))}
          <div className="flex flex-col px-4">
            <h1 className="text-center font-semibold">
              Truck Notes - {truck?.name}
            </h1>
            <hr />
            <NotesOutput
              notes={truckNotes}
              resourceType={ResourceType.Truck}
              resourceId={truck?.id!}
              date={date}
              refetchQuery={["trips", year, week]}
            />
          </div>
          <div className="flex flex-col px-4">
            <h1 className="text-center font-semibold">
              Driver Notes - {`${driver?.forename} ${driver?.surname}`}
            </h1>
            <hr />
            <NotesOutput
              notes={driverNotes}
              resourceType={ResourceType.Driver}
              resourceId={truck?.driverId!}
              date={date}
              refetchQuery={["trips", year, week]}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}
