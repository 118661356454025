import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllContactsResponse, IContact } from "../models/Contact";

export default function useContacts() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const contacts = useQuery({
    queryKey: ["contacts"],
    queryFn: () => get<IAllContactsResponse>("/contact"),
  });

  const update = useMutation(
    (contact: IContact) => put(`/contact/${contact.id}`, contact),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contacts"]);
      },
    }
  );

  return {
    contacts,
    update,
  };
}
