import { useEffect, useState } from "react";
import useLeadDocuments from "../../data/useLeadDocuments";
import { ILead } from "../../models/Lead";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { IDocument } from "../../models/Document";
import Modal from "../../layouts/Modal";
import LoadingWheel from "../shared/LoadingWheel";

interface ViewVideoButtonProps {
  lead: ILead;
}

export default function ViewVideoButton({ lead }: ViewVideoButtonProps) {
  const { leadDocuments, downloadDoc } = useLeadDocuments(lead.id);
  const [video, setVideo] = useState<IDocument | undefined>(undefined);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoOpen, setVideoOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!leadDocuments.isLoading) {
      setVideo(leadDocuments.data?.data?.filter((doc) => doc.contentType?.includes("video"))[0]);
      setIsLoading(false);
    }
  }, [leadDocuments.isLoading, leadDocuments.data])

  const handleVideoDownload = () => {
    if (!video) return;

    var ids = {
      leadId: lead.id,
      docId: video.id
    }

    downloadDoc.mutate(ids, {
      onSuccess: (file: any) => {
        const url = window.URL.createObjectURL(file);

        setVideoUrl(url);
        setVideoOpen(true);
      }
    })
  }

  const sendVideo = () => {
    //TODO: Send video to EWW
  }

  if (isLoading) {
    return (
      <div className="w-24">
        <LoadingWheel />
      </div>
    )
  }

  return (
    video ?
      <>
        <Modal open={videoOpen} setOpen={setVideoOpen} width="max-w-4xl">
          <div className="flex justify-center">
            <video width="1024" height="576" controls>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

          </div>
        </Modal>
        <div className="flex gap-x-4">
          <ButtonInput label={"View Video"} isSubmit={false} onClick={() => handleVideoDownload()} classes={"h-10"} />
          {/* <ButtonInput label={"Send Video"} isSubmit={false} onClick={() => sendVideo()} classes={"h-10"} /> */}
        </div>
      </>
      :
      <p>No Video</p>
  )
}