import { useState } from "react";

import { useForm } from "react-hook-form";

import PartAssemblySingleEditor from "../../components/partAssemblyEditor/PartAssemblySingleEditor";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import { TextInput } from "../../components/shared/Inputs/TextInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import usePartAssembly from "../../data/usePartAssembly";
import { IPartAssembly, IPartAssemblyOnly } from "../../models/Part";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useNotification from "../../components/notifications/useNotifications";

export default function PartAssemblyEditor() {
  const { assemblies, create } = usePartAssembly();
  const { saveHandlers } = useDefaultCRUDHandlers("Part Assembly");
  const { addNotification } = useNotification();
  const [selectedAssembly, setSelectedAssembly] = useState<IPartAssembly | undefined>(undefined);
  const { register, handleSubmit, reset } = useForm<IPartAssemblyOnly>();
  const [open, setOpen] = useState(false);

  const handleSelect = (assembly: IPartAssembly) => {
    setSelectedAssembly(assembly as IPartAssembly);
    setOpen(true);
  };

  const onSubmit = (data: IPartAssemblyOnly) => {
    if (!data.name) {
      //don't make anything
      addNotification({
        variant: "warning",
        primaryText: "Please enter a name for the assembly",
      });
      return;
    }

    create.mutate(data as IPartAssembly, saveHandlers);
    reset();
  };

  return (
    <>
      <PartAssemblySingleEditor assembly={selectedAssembly} open={open} setOpen={setOpen} />
      <div className="flex flex-col gap-y-4">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row flex-grow gap-x-4">
          <TextInput label={"Assembly Name"} register={register} registerName={"name"} />
          <ButtonInput isSubmit={true} label={"Create"} onClick={undefined} classes={"h-fit self-end"} />
        </form>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            {assemblies.isLoading ? <LoadingWheel /> : (
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
                  <thead className="bg-gp-blue-50 text-gray-800">
                    <tr>
                      <th scope="col" className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left">
                        Assembly Name
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {assemblies.data?.data?.map((assembly) => (
                      <tr key={assembly.id} onClick={() => handleSelect(assembly)} className={`hover:bg-gray-100 hover:cursor-pointer ${selectedAssembly?.id === assembly.id ? 'bg-green-100' : ''}`}>
                        <td className="px-3 py-3 border text-sm">
                          {assembly.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}