import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { IPart, IPartCategory, IPartImage, IPartImageRequirementGroup, IPartRequiredImage } from "../models/Part";
import useApiHelper from "../hooks/useApiHelper";

export default function usePartImageRequirement(categoryId?: string | undefined) {
  const { get, put, post, del, postDocs } = useApiHelper();
  const queryClient = useQueryClient();

  // const allCategories = useQuery<IPartCategory[]>({
  //   queryKey: ["categories"],
  //   queryFn: () => get<IPartCategory[]>("/partimagerequirement/categories").then((res: any) => res.data),
  // });

  const allGroups = useQuery<IPartImageRequirementGroup[]>({
    queryKey: ["partImageRequirement"],
    queryFn: () => get<IPartImageRequirementGroup[]>("/partimagerequirement").then((res: any) => res.data),
  });

  const imageRequirements = useQuery<IPartImageRequirementGroup>({
    queryKey: ["partImageRequirement", categoryId],
    queryFn: () => get<IPartImageRequirementGroup>(`/partimagerequirement/${categoryId}`).then((res: any) => res.data),
    enabled: categoryId != undefined
  });


  const createGroup = useMutation(
    (group: IPartImageRequirementGroup) => post(`/partimagerequirement/${categoryId}`, group),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partImageRequirement", categoryId]);
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const updateGroup = useMutation(
    (group: IPartImageRequirementGroup) => put(`/partimagerequirement/${group.id}`, group),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partImageRequirement", categoryId]);
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const deleteGroup = useMutation(
    (group: IPartImageRequirementGroup) => del(`/partimagerequirement/${group.id}`, {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partImageRequirement", categoryId]);
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const useGroup = useMutation(
    (group: IPartImageRequirementGroup) => post(`/partimagerequirement/${categoryId}/${group.id}`, {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partImageRequirement", categoryId]);
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const bulkUseGroup = useMutation(
    ({ categories, group }: { categories: IPartCategory[], group: IPartImageRequirementGroup }) => post(`/partimagerequirement/bulkuse/${group.id}`, [...categories.map(x => x.id)]),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partImageRequirement"]);
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const createImage = useMutation(
    ({ group, image, cback }: { group: IPartImageRequirementGroup, image: IPartRequiredImage, cback: (d: IPartRequiredImage) => void }) => post(`/partimagerequirement/${group.id}/newimage`, image),
    {
      onSuccess: (data: any, variables: { cback: (d: any) => void }) => {
        queryClient.invalidateQueries(["partImageRequirement", categoryId]);
        variables.cback(data.data);
      },
    }
  );

  const uploadImage = useMutation(
    ({ imgId, data }: { imgId: string, data: FormData }) => postDocs(`/partimagerequirement/${imgId}/upload`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partImageRequirement", categoryId]);
      },
    }
  );

  const updateImage = useMutation(
    ({ group, image, cback }: { group: IPartImageRequirementGroup, image: IPartRequiredImage, cback: (d: IPartRequiredImage) => void }) => post(`/partimagerequirement/${group.id}/editimage/${image.id}`, image),
    {
      onSuccess: (data: any, variables: { cback: (d: any) => void }) => {
        queryClient.invalidateQueries(["partImageRequirement", categoryId]);
        variables.cback(data.data);
      },
    }
  );

  const deleteImage = useMutation(
    ({ group, image }: { group: IPartImageRequirementGroup, image: IPartRequiredImage }) => del(`/partimagerequirement/${group.id}/deleteimage/${image.id}`, {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partImageRequirement", categoryId]);
      },
    }
  );

  return {
    // allCategories,
    allGroups,
    imageRequirements,
    updateGroup,
    deleteGroup,
    bulkUseGroup,
    useGroup,
    createImage,
    updateImage,
    uploadImage,
    createGroup,
    deleteImage
  };
}

