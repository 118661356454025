import { IContact } from "./Contact";
import { ILead } from "./Lead";
import { INote } from "./Note";
import { IPart, IPartCategory } from "./Part";
import { BaseEntity, DefaultResponseWithData } from "./system";

export interface IAllJobsResponse extends DefaultResponseWithData<IJob[]> { }
export interface ISingleJobResponse extends DefaultResponseWithData<IJob> { }
export interface IJobStatsResponse extends DefaultResponseWithData<JobStatsResponse> { }

export interface JobStatsResponse {
    totalJobs: number;
    duration: JobTotalAvg;
    postageCost: JobTotalAvg;
    disCost: JobTotalAvg;
    whCost: JobTotalAvg;
    totalCost: JobTotalAvg;
    numberOfParts: JobTotalAvg;
}

export interface JobTotalAvg {
    total: number;
    average: number;
}

export interface CreateJobRequest {
    type: JobType;
    title: string;
    description: string;
    duration: number;
    leadId: string | null;
    vehicleId: string | undefined;
    partCategories: string[];
    averagePrices: number[];
}

export interface IJob extends BaseEntity {
    type: JobType;
    title: string;
    description: string;
    duration: number;
    startDate: string | null;
    endDate: string | null;
    completedOn: string | null;
    completedBy: string | null;
    deleted: string | null;
    deletedBy: string | null;
    leadId: string | null;
    lead: ILead | null;
    mechanicId: string | null;
    mechanic: IContact | null;
    jobPartCategories: JobPartCategory[];
    notes: INote[];
}

export interface JobPartCategory extends BaseEntity {
    jobId: string | null;
    job: IJob | null;
    partCategoryId: string | null;
    partCategory: IPartCategory | null;
    partDataId: string | null;
    partData: IPart | null;
}

export enum JobType {
    Generic,
    Body,
    Mechanical
}