import { useEffect, useState } from "react";

import EbayUnlistedTable from "../../components/ebayListings/unlistedTable/EbayUnlistedTable";
import LoadingWheel from "../../components/shared/LoadingWheel";
import Tile from "../../components/shared/Tiles/Tile";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useCategories from "../../data/useCategories";
import usePartMutations from "../../data/usePartMutations";
import { ITopUnlistedCategories } from './../../models/Part';

export default function EbayUnlisted() {
  const { setInfo } = usePageNameContext();
  const { categories } = useCategories();
  const { topUnlisted } = usePartMutations();
  const [isLoading, setIsLoading] = useState(true);
  const [topCategories, setTopCategories] = useState<ITopUnlistedCategories[]>([]);

  const colours = ["green", "blue", "yellow", "pink", "orange", "green", "blue", "yellow", "pink", "orange"];

  useEffect(() => {
    setInfo({
      name: "eBay Unlisted Items",
      desc: "View and manage parts that aren't listed on eBay",
    });
  }, []);

  useEffect(() => { }, [categories.isLoading]);

  useEffect(() => {
    topUnlisted.mutate(undefined, {
      onSuccess: (data) => {
        setIsLoading(false);
        setTopCategories(data.data);
      }
    });
  }, []);

  if (categories.isLoading) return <LoadingWheel />;

  return (
    <div>

      <div className="flex flex-row flex-wrap mt-2 gap-4">
        {!isLoading && topCategories.length > 0 && (
          topCategories.map((category, i) => (
            <Tile key={i} label={category?.category?.name} color={colours[i]} value={category.count.toString()} />
          ))
        )}
      </div>

      <EbayUnlistedTable categories={categories.data?.data} />
    </div>
  )
}
