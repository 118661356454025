import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import date from "date-and-time";
import ordinal from "date-and-time/plugin/ordinal";
import { IDashboardStats } from "../../models/Dashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
};

date.plugin(ordinal);

const d = new Date();

const labels = Array(30)
  .fill("")
  .map((_, i) => new Date(new Date().setDate(new Date().getDate() - (i + 1))),)
  .reverse();

// const labels = Array(7)
// .fill("")
// .map((_, i) => {
//   return date.format(
//     new Date(new Date().setDate(new Date().getDate() - (i + 1))),
//     "DDD"
//   );
// })
// .reverse();


export default function ActivityGraph({ dashboardStats }) {
  const data = {
    labels: labels.map((day) => date.format(new Date(day), "ddd DD")),
    datasets: [
      {
        label: "Vehicles Collected",
        data: labels.map((day) => dashboardStats.vehiclesCollected[date.format(new Date(day), "DD/MM/YYYY")]),
        borderColor: "#2563eb",
        backgroundColor: "#2563eb",
      },
      {
        label: "Booked Today",
        data: labels.map((day) => dashboardStats.leadsBooked[date.format(new Date(day), "DD/MM/YYYY")]),
        borderColor: "#9333ea",
        backgroundColor: "#9333ea",
      },
      {
        label: "Parts Added with Images",
        data: labels.map((day) => dashboardStats.partsWithImages[date.format(new Date(day), "DD/MM/YYYY")]),
        borderColor: "#eb6325",
        backgroundColor: "#eb6325",
      },
      {
        label: "Parts Removed",
        data: dashboardStats.partsRemoved != null ? labels.map((day) => dashboardStats.partsRemoved[date.format(new Date(day), "DD/MM/YYYY")]) : [],
        borderColor: "#6F4E7C",
        backgroundColor: "#6F4E7C",
      },
      // {
      //   label: "Vehicles Completed",
      //   data: labels.map(() => Math.floor(Math.random() * 11)),
      //   borderColor: "#6F4E7C",
      //   backgroundColor: "#6F4E7C",
      // },
    ],
  };


  return <Line options={options} data={data} />;
}
