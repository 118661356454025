import { DocumentArrowUpIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import Compressor from 'compressorjs';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10485760;
const KILO_BYTES_PER_BYTE = 1024;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

export default function FileUpload({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  driver = false,
  ...otherProps
}) {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    return new Promise((resolve) => {
      const files = {};

      //Array of promises to resolve before returning
      const compressPromises = [];
      for (let file of newFiles) {
        if (file.size <= maxFileSizeInBytes) {
          compressPromises.push(
            new Promise((resolve) => {
              new Compressor(file, {
                quality: 0.7,
                success: (compressedFile) => {
                  //Change blob back to file
                  const newFile = new File([compressedFile], file.name, { type: file.type });

                  //Check if multiple files are allowed
                  if (!otherProps.multiple) {
                    resolve({ newFile });
                  } else {
                    files[file.name] = newFile;
                    resolve();
                  }
                },
              });
            })
          );
        }
      }

      Promise.all(compressPromises).then(() => {
        resolve({ ...files });
      });
    });
  };


  const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = async (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = await addNewFiles(newFiles);

      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <>
      <section className="flex flex-col items-center bg-white relative mt-8 mb-4 py-[35px] px-[20px] border border-gray-400 border-dotted">
        <label className="-top-7 text-lg font-semibold text-black left-0 absolute">
          {label}
          {otherProps.desc && <span className="text-gray-500 text-sm ml-2">- {otherProps.desc}</span>}
        </label>

        {!driver && (<p className="font-bold tracking-widest mt-0 text-center">Drag and drop your files anywhere or</p>)}
        <button
          type="button"
          className="mt-2 box-border appearance-none bg-transparent border border-[#3498db] text-xl cursor-pointer leading-none py-[1.1em] px-[2.8em] text-center uppercase font-bold rounded-[6px] text-[#3498db] relative overflow-hidden z-10 flex items-center w-[45%] justify-center hover:bg-[#3498db] hover:text-white"
          onClick={handleUploadBtnClick}
        >
          <DocumentArrowUpIcon className="w-[22px] h-[22px] mr-1 flex flex-col justify-center" />
          <span> Upload {otherProps.multiple ? "files" : "a file"}</span>
        </button>
        <input
          className="font-lg block w-full border-none normal-case absolute top-0 left-0 right-0 bottom-0 opacity-0 focus:outline-none"
          type="file"
          ref={fileInputField}
          title=""
          value=""
          onChange={handleNewFileUpload}
          {...otherProps}
        />
      </section>

      {/*Upload previews*/}
      <article className="mb-[35px]">
        {Object.keys(files).length > 0 && <span className="text-md">Upload Preview</span>}
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-6 mt-4 ">
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <div className="p-1 rounded-sm box-border mx-auto w-1/2 sm:w-full hover:opacity-60 h-full relative" key={fileName}>
                <div className="h-full flex items-center">
                  {isImageFile && (
                    <img
                      className="rounded-sm object-contain w-full h-full"
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <div className="opacity-0 flex flex-col justify-between z-20 absolute top-0 right-0 bottom-0 left-0 p-2 rounded-[6px] text-white font-bold bg-[rgba(0,0,0,0.75)] hover:opacity-100" isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside className="flex justify-between">
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <TrashIcon className="h-8 w-8 cursor-pointer hover:scale-125" onClick={() => removeFile(fileName)} />
                    </aside>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </article>
    </>
  );
};