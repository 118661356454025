import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import {ICategoryPartResponse, IPart} from "../models/Part";
import {IPromotion, IPromotionCollection} from "../models/Promotion";
import {DefaultResponseWithData, EbayResponseWrapper} from "../models/system";
import {ITruck} from "../models/Truck";

export default function useEbayPromotions() {
  const { get, put } = useApiHelper();
  const queryClient = useQueryClient();

  const promotions = useQuery({
    queryKey: ["promotions"],
    queryFn: () => get<EbayResponseWrapper<IPromotionCollection>>("/ebaysafe/promotions/searchfull"),
  });

  const applyPromotion = useMutation(
    ({part, promo}: {part: IPart | undefined, promo: IPromotion}) => put(`/ebaysafe/promotions/additem?promotionId=${promo.promotionId}&listingSKU=${part?.id}`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["promotions"]);
      },
    }
  );

  return {
    promotions,
    applyPromotion
  };
}
