import { useState } from "react";

import { useForm } from "react-hook-form";

import LoadingWheel from "../../shared/LoadingWheel";
import { TextInput } from "../../shared/Inputs/TextInput";
import {
  IShippingAddress,
  IShippingDimensions,
} from "./../../../models/Shipping";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import usePartMutations from "../../../data/usePartMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { NumberInput } from "../../shared/Inputs/NumberInput";

interface EditAddressProps {
  dimensions: IShippingDimensions;
  partId: string;
  onEditSuccess: (address: IShippingDimensions) => void;
}

export function EditDimensions({
  dimensions,
  partId,
  onEditSuccess,
}: EditAddressProps) {
  const { patchPart } = usePartMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Shipping");
  const { register, getValues, handleSubmit } = useForm<IShippingDimensions>({
    defaultValues: dimensions,
  });
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const updatePartDimensions = (data: IShippingDimensions) => {
    setError(undefined);

    if (!data.height || data.height == 0) {
      setError("Please fill in Height");
      return;
    }

    if (!data.length || data.length == 0) {
      setError("Please fill in Length");
      return;
    }

    if (!data.width || data.width == 0) {
      setError("Please fill in Width");
      return;
    }

    if (!data.weight || data.weight == 0) {
      setError("Please fill in Weight");
      return;
    }

    setSubmitting(true);

    patchPart.mutate(
      {
        ...data,
        id: partId,
      },
      {
        onSuccess: () => {
          saveHandlers.onSuccess();
          onEditSuccess(data);
        },
        onError: () => {
          saveHandlers.onError();
        },
        onSettled: () => {
          setSubmitting(false);
        },
      }
    );
  };

  return submitting ? (
    <div>
      <LoadingWheel />
    </div>
  ) : (
    <form
      className="grid grid-cols-4 gap-2"
      onSubmit={handleSubmit(updatePartDimensions)}
    >
      <NumberInput
        label={"Width (cm)"}
        register={register}
        registerName="width"
      />
      <NumberInput
        label={"Height (cm)"}
        register={register}
        registerName="height"
      />
      <NumberInput
        label={"Length (cm)"}
        register={register}
        registerName="length"
      />
      <NumberInput
        label={"Weight (kg)"}
        register={register}
        registerName="weight"
      />
      {error && (
        <div className="col-span-4 bg-red-100 text-red-600 px-4 py-2 rounded">
          {error}
        </div>
      )}
      <ButtonInput
        label="Save Dimensions"
        isSubmit={true}
        onClick={() => { }}
        classes={"mt-4"}
      />
    </form>
  );
}
