import { useEffect, useState } from "react";

import useLeadDocuments from "../../../data/useLeadDocuments";
import { DocumentType, IDocument } from "../../../models/Document";
import { ILead } from "../../../models/Lead";
import Card from "../../shared/Card/Card";
import ImageCarousel from "../../shared/Images/ImageCarousel";
import LoadingWheel from "../../shared/LoadingWheel";

export default function PartVehicleImages({ lead }: { lead: ILead }) {
  const { leadDocuments, downloadDoc } = useLeadDocuments(lead.id);

  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState<IDocument[]>([]);

  useEffect(() => {
    if (!leadDocuments.isLoading && leadDocuments.data) {
      let displayImages: IDocument[] = [];

      //Get vehicle and identity pictures
      displayImages.push(...leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["Vehicle"])! || []);
      displayImages.push(...leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["Identity"])! || []);

      //Put these 3 types at the front
      let front = displayImages.filter((doc: IDocument) => doc.fileName?.includes("front"))!;
      let odometer = displayImages.filter((doc: IDocument) => doc.fileName?.includes("odometer"))!;
      let engineVideo = displayImages.filter((doc: IDocument) => doc.fileName?.includes("engine-video"))!;

      let rest = displayImages.filter((doc: IDocument) =>
        !doc.fileName?.includes("front") &&
        !doc.fileName?.includes("odometer") &&
        !doc.fileName?.includes("engine-video")
      )!;

      let sortedImages = [...engineVideo, ...front, ...odometer, ...rest];

      setImages(sortedImages);
      setIsLoading(false);
    }

  }, [leadDocuments.data, leadDocuments.isLoading])

  if (isLoading) {
    return (
      <Card title="Lead Images" bodyClassName="p-4">
        <LoadingWheel />
      </Card>
    )
  }

  return (
    <>
      <Card title="Lead Images" bodyClassName="p-4">
        <ImageCarousel images={images} leadId={lead.id} />
      </Card>
    </>
  )

}