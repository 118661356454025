import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ICategoryPartResponse } from '../models/Part';
import { IEbayOffer } from "../models/EbayOffer";

export default function useBatchListings(batchId: string | undefined) {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes

  const parts = useQuery({
    queryKey: ["parts", "multiple"],
    queryFn: () => get<ICategoryPartResponse>(`/part/listings/batch/${batchId}`),
    staleTime: staleTime,
    enabled: !!batchId,
  });

  const createBatch = useMutation(
    (body: string) => post(`/part/listings/multiple/batch`, body),
    {
      onSuccess: (data, _, __) => {
        // queryClient.invalidateQueries(["parts", "multiple"]);
        return data;
      },
    }
  );

  const createOffer = useMutation(
    (partId: string) => post(`/part/offers/default/${partId}`, null),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["parts", "multiple"]);
      },
    }
  );

  const update = useMutation(
    (offer: IEbayOffer) => put(`/part/offers/${offer.id}`, offer),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["parts"]);
        queryClient.invalidateQueries(["pagedTable"]);
      },
    }
  );

  const bulkPublish = useMutation(
    (body: any) => post(`/part/offers/publish/bulk`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts"]);
      },
    }
  );

  return {
    parts,
    createBatch,
    createOffer,
    update,
    bulkPublish
  };
}
