import { useQueryClient } from "@tanstack/react-query";

import useVehiclesMutations from "../../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { IPart } from "../../../models/Part";
import { IVehicle } from "../../../models/Vehicle";

export default function UpdateColourCode({ part }: { part: IPart }) {
  const { update } = useVehiclesMutations();
  const queryClient = useQueryClient();
  const { saveHandlers } = useDefaultCRUDHandlers("Colour Code");

  const updateColourCode = (colourCode: string) => {
    const patchBody = {
      ...part.vehicle,
      vinvisColourCode: colourCode,
    };

    if (part.vehicle?.vinvisColourCode === colourCode) return;
    if (!colourCode) return;

    update.mutate(patchBody as IVehicle, {
      ...saveHandlers,
      onSuccess: () => {
        saveHandlers.onSuccess();
        queryClient.invalidateQueries(["parts", "multiple"]);
      }
    });
  };


  return (
    <div className="flex flex-col">
      <label className="text-gray-600">Colour Code:</label>
      <input
        key={part.id}
        defaultValue={part.vehicle?.vinvisColourCode ?? ""}
        className="w-1/2 border p-1"
        type="text"
        onBlur={(e) => updateColourCode(e.target.value)} />
    </div>
  )
}