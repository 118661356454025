import { IEbayOffer, OrderPaymentStatus, PaymentMethodType } from "../../models/EbayOffer";

export default function EbayPaymentMethod({ offers }: { offers: IEbayOffer[] }) {

  const offer = offers.find(x => x.paymentMethod !== null);

  if (!offer) {
    return <span>Not Found</span>;
  }

  return (
    <span>{PaymentMethodType[offer?.paymentMethod!]}</span>
  );
}