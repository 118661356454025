import date from "date-and-time";
import { useForm } from "react-hook-form";

import useEbayReturns from "../../data/useEbayReturns";
import Modal from "../../layouts/Modal";
import { IEbayReturn as IEbayReturnSummary, ISimpleRefundReturnRequest } from "../../models/EbayReturns";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { CheckboxInput } from "../shared/Inputs/CheckboxInput";
import ComboBoxInput from "../shared/Inputs/ComboBoxInput";
import { NumberInput } from "../shared/Inputs/NumberInput";
import { TextInput } from "../shared/Inputs/TextInput";
import LoadingWheel from "../shared/LoadingWheel";
import { IPartRefundSentRequest } from "../../models/Part";
import { useEffect, useState } from "react";

interface EbayReturnsModalProps {
  open: boolean;
  setOpen: any;
  ebayReturn: IEbayReturnSummary | undefined;
}

interface EbayReturnsModalForm extends ISimpleRefundReturnRequest {
  message: string;
  refundAmount: number;
}

export default function EbayReturnsModal({ open, setOpen, ebayReturn }: EbayReturnsModalProps) {
  const { returnFull, returnFiles, partsForOrder, decideRefund, markRefundAs, markRefundSent, sendMessage } = useEbayReturns(ebayReturn);
  const { register, handleSubmit, watch, setValue, reset } =
    useForm<EbayReturnsModalForm>({
    });

  useEffect(() => {
    reset({
      partialRefundAmount: 0,
      refundAmount: ebayReturn?.buyerTotalRefund.estimatedRefundAmount.value!,
      message: ""
    });
  }, [ebayReturn]);

  //https://json-xslt.codebase.ebay.com/Devzone/post-order/extra/pst-rdr_v2_rtrn-rtrnd_dcd__pst.Rqst.dcsn.html
  const decisionOptions = [
    { label: "Decline Return", value: "DECLINE" },
    { label: "Approve Return", value: "APPROVE" },
    { label: "Offer Partial Refund", value: "OFFER_PARTIAL_REFUND" },
    { label: "Offer Replacement", value: "OFFER_REPLACEMENT" },
    { label: "Offer Return", value: "OFFER_RETURN" },
  ];

  let part = partsForOrder?.data?.data.rows[0];

  const onDecide = (data: EbayReturnsModalForm) => {
    decideRefund.mutate(data, {
      onSuccess: () => {
        setOpen(false);
      }
    });
  }

  const onMessage = (data: EbayReturnsModalForm) => {
    sendMessage.mutate(data.message, {
      onSuccess: () => {
        setOpen(false);
      }
    });
  }

  const onMark = (as: string) => {
    markRefundAs.mutate(as, {
      onSuccess: () => {
        setOpen(false);
      }
    });
  }

  const onMarkSentSubmit = (data: EbayReturnsModalForm) => {
    markRefundSent.mutate({
      refundDetail:{
        itemizedRefundDetail:[
          {
            refundAmount:{
              currency: "GBP",
              value: data.refundAmount
            },
            refundFeeType: "PURCHASE_PRICE",
            restockingFeePercentage: "0"
          }
        ],
        totalAmount:{
          currency: "GBP",
          value: data.refundAmount
        }
      }
    } as IPartRefundSentRequest, {
      onSuccess: () => {
        setOpen(false);
      }
    });
  };


  const decodeHtml = (html: string) => {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  return <Modal open={open} setOpen={setOpen} width={"max-w-4xl"}>
    {
      partsForOrder?.isLoading ? <LoadingWheel />
        :
        <div>
          <div className="flex flex-row justify-between">
            <div className="w-[50%] flex flex-col gap-y-2 pr-[1%] font-semibold border-r border-slate">
              <p className="text-lg font-bold mb-2 justify-self-start">Ebay Returns Details</p>
              <p>Requested by: <span className="float-right ml-2 font-normal">{ebayReturn?.buyerLoginName}</span></p>
              <p>Opened at: <span className="float-right ml-2 font-normal">{date.format(new Date(ebayReturn?.creationInfo.creationDate.value!), "DD/MM/YYYY hh:mm")}</span></p>
              <p>Respond on or by: <span className="float-right ml-2 font-normal">{date.format(new Date(ebayReturn?.sellerResponseDue.respondByDate.value!), "DD/MM/YYYY")}</span></p>
              <p>Return type: <span className="float-right ml-2 font-normal">{ebayReturn?.currentType}</span></p>
              <p>Status: <span className="float-right ml-2 font-normal">{ebayReturn?.state}</span></p>
              <p>Reason: <span className="float-right ml-2 font-normal">{ebayReturn?.creationInfo.reasonType} - {ebayReturn?.creationInfo.reason}</span></p>
              <p>Justification: <span className="float-right ml-2 font-normal">{decodeHtml(ebayReturn?.creationInfo.comments.content ?? "")}</span></p>
            </div>
            <div className="text-right max-w-[50%] pl-[1%] flex flex-col gap-y-2">
              <p className="text-lg font-bold mb-2">Part Details</p>
              {
                part == undefined ? <p>No part found for this order</p> :
                  <>
                    <img src={part.images[0].url} className="object-contain max-w-[100%]" />
                    <p><span className="float-left font-semibold">Title: </span> {part.offers[0].title}</p>
                    <p><span className="float-left font-semibold">Purchased On: </span> {date.format(new Date(part.soldDate!), "DD/MM/YYYY hh:mm")}</p>
                    <p><span className="float-left font-semibold">Price: </span> £{part.price}</p>
                  </>
              }
            </div>
          </div>
          <div className="mt-2 pt-2 border-t border-slate">
            <p className="text-lg font-bold">Dispute Images</p>
            {
              returnFiles?.isLoading ? <LoadingWheel /> :
                <div className="overflow-x-scroll">
                  <div className="flex flex-row gap-x-1">
                    {
                      returnFiles?.data?.data.response.files.map((file, index) => {
                        return <div className="min-w-fit">
                          <img key={index} src={`data:image/jpeg;base64,${file.fileData}`} className="h-[20rem] border border-black" />
                          <p className="text-center">{file.submitter} @ {date.format(new Date(file.creationDate.value), "DD/MM/YYYY hh:mm")}</p>
                        </div>
                      })
                    }
                  </div>
                </div>
            }
          </div>
          <div className="mt-2 pt-2 border-t border-slate">
            <p className="text-lg font-bold">Dispute Messages</p>
            {
              returnFull?.isLoading ? <LoadingWheel /> : <>
                <div className="overflow-y-scroll max-h-[20rem]">
                  {
                    returnFull.data?.data.response ? returnFull?.data?.data.response.detail.responseHistory.filter(message => message.notes != null).map((message, index) => {
                      return <div key={index} className="flex flex-col gap-y-2">
                        <p className="font-semibold">{message.author} @ {date.format(new Date(message.creationDate.value), "DD/MM/YYYY hh:mm")}</p>
                        <p>{decodeHtml(message.notes ?? "")}</p>
                      </div> 
                    }):null
                  }
                </div>
                <form className="flex flex-col justify-between" onSubmit={handleSubmit(onMessage)}>
                  <TextInput label={"Message"} register={register} registerName="message" richText={true} />
                  <ButtonInput label={"Send Message"} isSubmit={true} onClick={undefined} classes={undefined} />
                </form>
              </>
            }

          </div>
          <div className="mt-2 pt-2 border-t border-slate">
            {
              ebayReturn?.state.includes("REQUESTED") || ebayReturn?.state.includes("REJECTED") ?
                <form className="flex flex-col justify-between" onSubmit={handleSubmit(onDecide)}>
                  <p className="text-lg font-bold">Return Decision</p>
                  <div>
                    <CheckboxInput label={"Partial Refund"} register={register} registerName="usePartialRefund" />
                    {
                      watch("usePartialRefund") &&
                      <NumberInput label={"Partial Refund Amount (£)"} register={register} registerName="partialRefundAmount" />
                    }
                    <CheckboxInput label={"Customer Keeps Original Item"} register={register} registerName="keepOriginalItem" />
                    <ComboBoxInput label={"Decision"} register={register} setValue={setValue} searchField={"label"} valueField={"value"} registerName="decision" options={decisionOptions} initialValue={decisionOptions[0].label} />
                    <TextInput label={"Message"} register={register} registerName="message" richText={true} />
                  </div>
                  <ButtonInput label={"Submit Decision"} isSubmit={true} onClick={undefined} classes={undefined} />
                </form>
                :
                <div>
                  {
                    //https://developer.ebay.com/Devzone/post-order/types/ReturnStatusEnum.html
                    ebayReturn?.state == "ITEM_READY_TO_SHIP" &&
                    <div>
                      <p className="text-lg font-semibold">Return Approved</p>
                      <p>Return has been approved and is awaiting for the customer to ship the item.</p>
                      <ButtonInput label={"Mark As Shipped"} isSubmit={false} onClick={() => onMark("shipped")} classes={undefined} />
                    </div>
                  }
                  {
                    ebayReturn?.state == "ITEM_SHIPPED" &&
                    <div>
                      <p className="text-lg font-semibold">Return Shipped</p>
                      <p>Return has been shipped and is awaiting to be received.</p>
                      <ButtonInput label={"Mark As Received"} isSubmit={false} onClick={() => onMark("received")} classes={undefined} />
                    </div>
                  }
                  {
                    ebayReturn?.state == "ITEM_DELIVERED" &&
                    <div>
                      <form onSubmit={()=>handleSubmit(onMarkSentSubmit)} className="flex flex-col gap-y-2">
                        <p className="text-lg font-bold">Mark Refund As Sent</p>
                        <p>The item has been delivered, mark the refund as sent to the customer.</p>
                        <p>This only marks it as sent, no payment will be created by the system.</p>
                        <NumberInput label={"Total Refund Issued (£)"} register={register} registerName="refundAmount" />
                        <ButtonInput label={"Mark Refund As Sent"} isSubmit={true} onClick={undefined} classes={undefined} />
                      </form>
                    </div>
                  }
                  {
                    ebayReturn?.state == "CLOSED" &&
                    <div>
                      <p className="text-lg font-semibold">Return Completed</p>
                      <p>Return has been completed.</p>
                    </div>
                  }
                </div>
            }
          </div>
        </div>
    }

  </Modal>
}