import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPackingBoxResponse, IPackingBoxSize } from "../models/PackingBoxSize";

export default function usePackingBoxSizing(categoryId?: string | undefined) {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; // 1 minutes

  const packingBoxes = useQuery({
    queryKey: ["packingBoxes"],
    queryFn: () => get<IPackingBoxResponse>("/PackingBoxSizing"),
    staleTime: staleTime,
  });

  const create = useMutation(
    (boxSize: IPackingBoxSize) => post("/PackingBoxSizing", boxSize),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["packingBoxes"]);
      },
    }
  );

  const bestFit = useMutation(
    (boxSize: IPackingBoxSize) => post("/PackingBoxSizing/best", boxSize),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["packingBoxes"]);
      },
    }
  );

  const update = useMutation(
    (boxSize: IPackingBoxSize) => put(`/PackingBoxSizing/${boxSize.id}`, boxSize),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["packingBoxes"]);
      },
    }
  );

  const remove = useMutation(
    (boxSizeId: string) => del(`/PackingBoxSizing/${boxSizeId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["packingBoxes"]);
      },
    }
  );

  return {
    packingBoxes,
    bestFit,
    create,
    update,
    remove
  };
}