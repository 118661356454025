import { useEffect, useState } from "react";

import useWarehouseLocations from "../../data/useWarehouseLocations";
import { IWarehouseLocation } from "../../models/Location";
import formatDateTime from "../../utils/formatDateTime";

export default function WarehouseLocationFromId({ id, lastStockChecked }: { id: string | null, lastStockChecked?: string | null}) {
  const { warehouseLocations } = useWarehouseLocations();
  const [location, setLocation] = useState<IWarehouseLocation | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!warehouseLocations.isLoading && warehouseLocations.data) {
      const location = warehouseLocations.data.data.find((location) => location.id === id);
      setLocation(location);
      setLoading(false);
    }
  }, [warehouseLocations.data, warehouseLocations.isLoading, id]);

  if (loading) {
    return <span>Loading...</span>;
  }

  if (!location) {
    return (
    <div>
      <span>Not Found</span>
      {lastStockChecked && <p className="text-xs text-gray-700">Last Stock Checked: {formatDateTime(lastStockChecked)}</p>}
    </div>
    );
  };

  return (
    <div>
      <span>{location.displayName}</span>
      {lastStockChecked && <p className="text-xs text-gray-700">Last Stock Checked: {formatDateTime(lastStockChecked)}</p>}
    </div>
    );
}