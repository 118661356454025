import { DateTime } from "luxon";

//date is an ISO string - can be straight from the API
//format is a luxon DateTime format - https://github.com/moment/luxon/blob/master/docs/formatting.md#presets
//e.g DateTime.DATE_SHORT = 10/14/1983
//e.g DateTime.DATETIME_SHORT = 10/14/1983 10:30 AM


// We need to always use this function to display the dates so the timezone is handled correctly
export default function formatDateTime(date, format, nullValue = "") {
  if (!date) return nullValue;
  return DateTime.fromISO(date, { zone: 'utc' }) //Parse date as UTC
    .setZone("Europe/London") //Convert to local timezone
    .setLocale('en-gb') //Set locale to UK
    .toLocaleString(format) //Format date
}