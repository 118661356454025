import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ICategoryPartResponse } from '../models/Part';
import { IEbayOffer } from "../models/EbayOffer";

export default function useBatchListingsMutations() {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes

  const createBatch = useMutation(
    (body: any) => post(`/part/listings/multiple/batch`, body),
    {
      onSuccess: (data, _, __) => {
        // queryClient.invalidateQueries(["parts", "multiple"]);
        return data;
      },
    }
  );

  return {
    createBatch
  };
}
