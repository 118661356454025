import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

import LoadingWheel from "../../shared/LoadingWheel";
import {
  IShippingAddress,
  IShippingDimensions,
  IShippingQuote,
} from "../../../models/Shipping";
import { IPart } from "../../../models/Part";
import useShipping from "../../../data/useShipping";
import { useEffect, useState } from "react";
import { ShippingQuoteTable } from "./ShippingQuoteTable";

interface FedexShippingProps {
  address: IShippingAddress;
  dimensions: IShippingDimensions;
  part: IPart;
  onServiceSelect: (service: string) => void;
  selectedService?: string;
}

export function FedexShipping({
  address,
  dimensions,
  part,
  onServiceSelect,
  selectedService,
}: FedexShippingProps) {
  const { quotes } = useShipping(part);

  const [loading, setLoading] = useState(true);
  const [shippingQuotes, setShippingQuotes] = useState<IShippingQuote[]>();
  const [error, setError] = useState<string>();

  const getQuotes = () => {
    quotes.mutate(
      {
        ...address,
        ...dimensions,
        partDataId: part.id,
      },
      {
        onSuccess: (res) => {
          console.log(res);
          setShippingQuotes(res.data);
        },
        onSettled: () => {
          setLoading(false);
        },
      }
    );
  };

  useEffect(() => {
    if (
      !address ||
      !address.addressLine1 ||
      address.addressLine1 === "" ||
      !address.postcode ||
      address.postcode === "" ||
      !dimensions ||
      !dimensions.height ||
      dimensions.height === 0 ||
      !dimensions.length ||
      dimensions.length === 0 ||
      !dimensions.width ||
      dimensions.width === 0 ||
      !dimensions.weight ||
      dimensions.weight === 0
    ) {
      setError("Please ensure Shipping address & Dimensions are filled in");
    } else {
      getQuotes();
    }
  }, [address, dimensions]);

  return (
    <div className="flex flex-col gap-y-4">
      {error ? (
        <div className="col-span-4 bg-red-100 text-red-600 px-4 py-2 rounded">
          {error}
        </div>
      ) : loading ? (
        <LoadingWheel />
      ) : (
        <ShippingQuoteTable
          shippingQuotes={shippingQuotes!}
          onServiceSelect={onServiceSelect}
          selectedService={selectedService}
        />
      )}
    </div>
  );
}
