import { useEffect, useState } from "react";

import { DateTime } from "luxon";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import AwaitingInventoryTable from "../../components/awaitingInventory/AwaitingInventoryTable";
import CustomDatePicker from "../../components/awaitingInventory/CustomDatePicker";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useLeads from "../../data/useLeads";
import { ILead, LeadStatus } from "../../models/Lead";
import { PartsStatusDropdowns, PartsStatusValues } from "../../models/Vehicle";

type DateRangeType = {
  startDate: string | null;
  endDate: string | null;
};

export default function AwaitingInventory() {
  const { setInfo } = usePageNameContext();
  const { leads: allLeads } = useLeads();
  const [leads, setLeads] = useState<ILead[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentFilter, setCurrentFilter] = useState("Awaiting Schedule");
  const [hideNotCollected, setHideNotCollected] = useState(false);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [dates, setDates] = useState<DateRangeType>({
    startDate: null,
    endDate: null
  });

  const handleDatesChange = (newDates: any) => {
    // console.log("newValue:", newDates);
    setDates(newDates);
  }

  const filters = [
    ...Object.values(PartsStatusDropdowns).filter((ct) => isNaN(Number(ct))),
    "All"
  ];

  useEffect(() => {
    setInfo({
      name: "Vehicles Awaiting Inventory",
      desc: "View and manage parts, engine export prices and status' here",
    });
  }, []);

  useEffect(() => {
    if (!allLeads.isLoading) {

      //Auto hide not collected after 30 days
      let filter = allLeads.data?.data.leads.filter((lead) => {
        const leadDate = DateTime.fromISO(lead.created).toISODate();
        const daysAgo = DateTime.now().minus({ days: 30 }).toISODate();

        if (lead.vehicle?.scheduledCollectionOn === null && leadDate! >= daysAgo!) {
          return false;
        } else {
          return true;
        }
      });

      if (dates?.startDate !== null && dates?.endDate !== null) {
        filter = filter?.filter((lead) => {
          let leadDate = DateTime.fromISO(lead.vehicle?.scheduledCollectionOn!).toISODate();
          if (leadDate && leadDate >= dates.startDate! && leadDate <= dates.endDate!) {
            return true;
          } else {
            return false;
          }
        });
      }

      filter = filter?.filter((lead) =>
        lead.vehicle?.combinedMake?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.vehicle?.combinedModel?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.vehicle?.vrm?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.vehicle?.engineModelCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.vehicle?.enginesWorldwideEngineOffer?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (currentFilter !== "All") {
        filter = filter?.filter((lead) => PartsStatusValues[lead.vehicle?.engineExportStatus!] === currentFilter);
      }

      if (hideNotCollected) {
        filter = filter?.filter((lead) => lead.vehicle?.collectedOn !== null);
      }

      if (hideCompleted) {
        filter = filter?.filter((lead) => lead.status !== LeadStatus.Completed);
      }

      setLeads(filter);
      setIsLoading(false);
    }
  }, [allLeads.isLoading, allLeads.data, searchTerm, currentFilter, hideNotCollected, hideCompleted, dates]);

  return allLeads.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <div className="mt-4 flex justify-between">
        <div className="flex gap-x-5">
          <select aria-label="Contact type filter"
            onChange={(e) => setCurrentFilter(e.target.value)}
            className="sm:text-sm">
            {filters.map((filter) => (
              <option
                className="sm:text-sm"
                key={filter}>
                {String(filter)}
              </option>
            ))}
          </select>


          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 sm:text-sm"
              placeholder="Search any vehicle details"
            />
          </div>

          <div>
            <CustomDatePicker dates={dates} handleDatesChange={handleDatesChange} />
          </div>

        </div>
        <div className="flex items-center gap-x-4">
          <div className="flex items-center">
            <input id="hideNotCollected" onChange={(e) => setHideNotCollected(e.target.checked)} value="hideNotCollected" checked={hideNotCollected} type="checkbox" className="mr-2 h-5 w-5" />
            <label htmlFor="hideNotCollected">Hide Not Collected</label>
          </div>
          <div className="flex items-center">
            <input id="hideCompleted" onChange={(e) => setHideCompleted(e.target.checked)} value="hideCompleted" checked={hideCompleted} type="checkbox" className="mr-2 h-5 w-5" />
            <label htmlFor="hideCompleted">Hide Completed</label>
          </div>
        </div>
      </div>
      <AwaitingInventoryTable leads={leads} />
    </>
  );
}
