import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ILocation, IWarehouseLocation, IWarehouseLocationsResponse } from "../models/Location";

export default function useWarehouseLocations() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const warehouseLocations = useQuery({
    queryKey: ["warehouseLocations"],
    queryFn: () => get<IWarehouseLocationsResponse>("/warehouse/locations"),
    refetchInterval: 1000 * 60, // 60 seconds
  });

  const create = useMutation(
    (location: IWarehouseLocation) => post("/warehouse/locations", location),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["warehouseLocations"]);
      },
    }
  );

  const update = useMutation(
    (location: IWarehouseLocation) => put(`/warehouse/locations/${location.id}`, location),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["warehouseLocations"]);
      },
    }
  );

  const remove = useMutation(
    (locationId: string) => del(`/warehouse/locations/${locationId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["warehouseLocations"]);
      },
    }
  );

  return {
    warehouseLocations,
    create,
    update,
    remove,
  };
}
