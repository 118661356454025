import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead } from "../models/Lead";
import { IUnreadMessageResponse, IUnreadMessages } from "../models/Message";
import { ICustomShippingRequest, IShippingCreateRequest, IShippingInfo, IShippingInfoResponse, IShippingQuote, IShippingQuotes, IShippingQuotesRequest } from "../models/Shipping";
import { IPart } from "../models/Part";
import { kStringMaxLength } from "buffer";
import { DefaultResponse } from "../models/system";

export default function useShippingBulk() {
  const { get, post, postRaw, del } = useApiHelper();
  const queryClient = useQueryClient();
  const bulkShipping = useMutation(
    (partIds : string[]) => post(`/shipping/bulkship`, partIds),
    {
      onSuccess: () => {
        document.location.reload();
      }
    }
  );

  return {
    bulkShipping
  };
}
