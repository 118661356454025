import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartResponse } from "../models/Part";

export default function useVehicleLookupParts(vrm: string) {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60 * 10; //10 minutes

  const parts = useQuery({
    queryKey: ["parts", vrm],
    queryFn: () => get<IPartResponse>(`/part/vehicle?vrm=${vrm}`),
    staleTime: staleTime,
  });

  return {
    parts
  };
}
