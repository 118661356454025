export default function sortOffers(a: any, b: any): number {
  // Step 1: Prioritize offers with a publishedOn date
  if (a.publishedOn !== null && b.publishedOn === null) {
    return -1; // a comes first because it's published
  }
  if (a.publishedOn === null && b.publishedOn !== null) {
    return 1; // b comes first because it's published
  }

  // Step 2: If both have publishedOn, or both are null, sort by listingStarted
  if (a.publishedOn !== null && b.publishedOn !== null) {
    if (a.listingStarted !== null && b.listingStarted === null) {
      return -1; // a comes first because it has listingStarted
    }
    if (a.listingStarted === null && b.listingStarted !== null) {
      return 1; // b comes first because it has listingStarted
    }

    // Step 3: If both have listingStarted or both are null, check for paymentMethod and paymentStatus
    const aHasPaymentDetails = a.paymentMethod !== null && a.paymentStatus !== null;
    const bHasPaymentDetails = b.paymentMethod !== null && b.paymentStatus !== null;

    if (aHasPaymentDetails && !bHasPaymentDetails) {
      return -1; // a comes first because it has both paymentMethod and paymentStatus
    }
    if (!aHasPaymentDetails && bHasPaymentDetails) {
      return 1; // b comes first because it has both paymentMethod and paymentStatus
    }

    // Step 4: If both have payment details or neither does, sort by publishedOn date
    return Date.parse(a.publishedOn) - Date.parse(b.publishedOn);
  }

  // Step 5: If neither have a publishedOn, fallback to listingStarted comparison
  if (a.listingStarted !== null && b.listingStarted === null) {
    return -1; // a comes first because it has listingStarted
  }
  if (a.listingStarted === null && b.listingStarted !== null) {
    return 1; // b comes first because it has listingStarted
  }

  // If both publishedOn and listingStarted are null, or they are equal, consider them equal
  return 0;
};
