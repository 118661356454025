import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useSingleLead from "../../data/useSingleLead";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../models/Lead";
import Card from "../shared/Card/Card";
import { TextInput } from "../shared/Inputs/TextInput";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import useSingleSetting from "../../data/useSingleSetting";

type FormValues = {
  price: number;
  commission: number;
  accountName: string;
  paymentReference: string;
  accountNumber: string;
  sortCode: string;
  chequeName: string;
};

export default function DriverPaymentDetails({ lead }: { lead: ILead }) {
  const { singleSetting } = useSingleSetting("PaymentRefPrefix");
  const { update } = useSingleLead(lead.id);
  const { saveHandlers } = useDefaultCRUDHandlers("Payment Details");
  const { register, handleSubmit, reset, control, setValue } =
    useForm<FormValues>({
      defaultValues: {
        price: lead.price,
        commission: lead.commission,
        accountName: lead.accountName!,
        paymentReference: lead.paymentReference === "" || lead.paymentReference === null ? "" : lead.paymentReference!,
        accountNumber: lead.accountNumber!,
        sortCode: lead.sortCode!,
        chequeName: lead.chequeName!,
      },
    });

  const onSubmit = (data: any) => {
    const updatedLead = {
      ...lead,
      ...data,
      price: data.price && parseFloat(data.price),
      commission: data.commission && parseFloat(data.commission),
    };

    update.mutate(updatedLead, saveHandlers);

    reset({
      price: data.price,
      commission: data.commission,
      accountName: data.accountName!,
      paymentReference: data.paymentReference!,
      accountNumber: data.accountNumber!,
      sortCode: data.sortCode!,
      chequeName: data.chequeName!,
    });
  }

  useEffect(() => {

  }, [lead, lead.paymentStatus])

  useEffect(() => {
    if(singleSetting.isLoading) return;
    if(lead.paymentReference !== "" && lead.paymentReference !== null) return;
    if(singleSetting.data == null || singleSetting.data == undefined) return;
    setValue("paymentReference",
      singleSetting.data?.data.value + lead.vehicle?.vrm
    );
  }, [singleSetting.isLoading]);

  return (
    <>
      <Card bodyClassName="p-4">
        <h2 className="flex justify-between items-center text-lg font-medium pb-5 pt-2 leading-6 text-gray-900">
          <span>
            Payment Details
          </span>
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 border-t border-gray-200">

          <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-2">

            <TextInput label="Current Price (£)" registerName="price" disabled={true} register={register} />
            <TextInput label="Commission (£)" registerName="commission" disabled={true} register={register} />
            <TextInput label="Payment Reference" registerName="paymentReference" disabled={true} register={register} />
            <TextInput label="Account Name" registerName="accountName" disabled={false} register={register} />
            <TextInput label="Account Number" registerName="accountNumber" disabled={false} register={register} />
            <TextInput label="Sort Code" registerName="sortCode" disabled={false} register={register} />

          </div>
          <ButtonInput label="Update" isSubmit={true} onClick={undefined} classes={undefined} />
        </form>
      </Card>
    </>
  );
}
