import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllContactsResponse, IContact } from "../models/Contact";
import { CreateJobRequest, IAllJobsResponse, IJob, IJobStatsResponse } from "../models/Job";
// import { ISetting, ISettingsResponse } from "../models/Setting";

export default function useJobs() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const jobs = useQuery({
    queryKey: ["jobs"],
    queryFn: () => get<IAllJobsResponse>("/job"),
  });

  const stats = useQuery({
    queryKey: ["jobs","stats"],
    queryFn: () => get<IJobStatsResponse>("/job/stats"),
  });

  const create = useMutation(
    (job: CreateJobRequest) => post(`/job`, job),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["jobs"]);
      },
    }
  );

  const update = useMutation(
    (job: IJob) => put(`/job/${job.id}`, job),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["jobs"]);
      },
    }
  );

  const remove = useMutation(
    (id: string) => del(`/job/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["jobs"]);
      },
    }
  );

  return {
    jobs,
    stats,
    create,
    update,
    remove
  };
}
