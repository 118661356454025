import { useEffect } from "react";

import { usePageNameContext } from "../../contexts/PageTitleContext";
import EbayListingsTable from "../../components/ebayListings/EbayListingsTable";



export default function EbayListings() {
  const { setInfo } = usePageNameContext();

  useEffect(() => {
    setInfo({
      name: "eBay Listings",
      desc: "View and manage parts that are listed on eBay",
    });
  }, []);

  return <>
      <EbayListingsTable/>
    </>
}
