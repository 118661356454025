import { useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IEbayMessageResponse } from "../models/EbayMessage";

interface EbayMessagesProps {
  page: number;
  days: number;
}

export default function useEbayMessages({ page, days }: EbayMessagesProps) {
  const { get } = useApiHelper();

  const unread = useQuery({
    queryKey: ["ebayunreadmessages", page, days],
    queryFn: () => get<IEbayMessageResponse>(`/ebaymessaging/unread/${page}?daysToSearch=${days}`),
  });

  const allmsgs = useQuery({
    queryKey: ["ebayallmessages", page, days],
    queryFn: () => get<IEbayMessageResponse>(`/ebaymessaging/messages/${page}?daysToSearch=${days}`),
  });

  // const applyPromotion = useMutation(
  //   ({part, promo}: {part: IPart | undefined, promo: IPromotion}) => put(`/ebaysafe/promotions/additem?promotionId=${promo.promotionId}&listingSKU=${part?.id}`, null),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["promotions"]);
  //     },
  //   }
  // );

  return {
    unread,
    allmsgs
  };
}
