import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllMessagesRepsonse } from "../models/Message";

export default function useLeadMessages(contactId: String) {
  const { get, post } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 10;

  const leadMessages = useQuery({
    queryKey: ["leadCommunications", contactId],
    queryFn: () => get<IAllMessagesRepsonse>(`/message/${contactId}`),
    staleTime: staleTime,
    refetchInterval: staleTime,
  });

  const sendMessage = useMutation(((message: any) => post("/message", message)), {
    onMutate: () => {
      queryClient.invalidateQueries(["leadCommunications", contactId]);
    },
  });

  const markAsRead = useMutation(((messages: any) => post("/message/read", messages)), {
    onMutate: () => {
      queryClient.invalidateQueries(["leadCommunications", contactId]);
      queryClient.invalidateQueries(["leads"]);
    },
  });

  return {
    leadMessages,
    sendMessage,
    markAsRead
  };
}
