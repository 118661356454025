import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead, INewLead, ISingleLeadRespose } from "../models/Lead";
import { filter } from "lodash";

export interface ILeadFilters{
  query: string;
  start?: string | null;
  end?: string | null;
}

export default function useLeadsForVehiclesAwaitingArrival(filters: ILeadFilters) {
  const { get, getFile, post, postGetFile, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const leadsForVehiclesAwaitingArrival = useQuery({
    queryKey: ["leads","filtered",filters.query, filters.start, filters.end],
    queryFn: () => get<IAllLeadsResponse>(`/lead/partialforawaitingarrival?query=${filters.query}&start=${filters.start??""}&end=${filters.end??""}`),
    // staleTime: 1000 * 60 * 1, // 1 minute
    // refetchInterval: 1000 * 60 * 1, // 1 minutes
    // refetchIntervalInBackground: true,
  });

  return {
    leadsForVehiclesAwaitingArrival,
  };
}
