import { useEffect, useRef, useState } from "react";
import L, { LatLngExpression, marker } from "leaflet";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
  LayerGroup,
} from "react-leaflet";

import { DriverWithTracking } from "../../models/DriverTracking";
import getLatestPoint from "./../../utils/getLatestPoint";
import { set } from "react-hook-form";

export default function TrackingMap({
  driverTracking,
}: {
  driverTracking: DriverWithTracking[];
}) {
  const [icon, setIcon] = useState<any>(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const [polylines, setPolylines] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const markerRef = useRef(L.layerGroup());

  const myCustomColour = "#224b96";

  // const markerHtmlStyles = `
  //     background-color: ${myCustomColour};
  //     width: 2.5rem;
  //     height: 2.5rem;
  //     display: block;
  //     left: -1rem;
  //     top: -1rem;
  //     position: relative;
  //     border-radius: 3rem 3rem 0;
  //     transform: rotate(45deg);
  //     border: 1px solid #FFFFFF;`;

  const getCustomIcon = (colour: string) => {
    return L.divIcon({
      className: "my-custom-pin",
      iconSize: [32, 32],
      // iconAnchor: [18, 36],
      // labelAnchor: [-6, 0],
      popupAnchor: [0, -10],
      html: `<span class="driver-tracker" style="background-color: ${colour};"/>`, //style="${markerHtmlStyles} />`
    });
  };

  useEffect(() => {
    const markers = driverTracking
      .filter((x) => x.show)
      .map((driver) => {
        const recentPoint = getLatestPoint(driver);
        return (
          recentPoint &&
          recentPoint?.latitude! &&
          recentPoint?.longitude! && (
            <Marker
              key={recentPoint.latitude + recentPoint.longitude}
              position={[recentPoint?.latitude!, recentPoint?.longitude!]}
              icon={getCustomIcon(driver.colour)}
            >
              <Popup className="w-[12rem]">
                <div>
                  {driver.driver.forename} {driver.driver.surname}
                </div>
              </Popup>
            </Marker>
          )
        );
      });

    setMarkers(markers);

    setPolylines(
      driverTracking
        .filter((x) => x.show)
        .map((driver, i) => {
          var points = driver.tracking
            .sort(
              (a, b) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
            )
            .map((point) => [
              point.latitude,
              point.longitude,
            ]) as LatLngExpression[];
          return (
            <Polyline
              key={i}
              pathOptions={{ color: driver.colour }}
              positions={points}
            />
          );
        })
    );

    setIcon(getCustomIcon(myCustomColour));
    setIsLoading(false);
  }, [driverTracking]);

  return (
    <div className="h-[65vh] z-10 w-full">
      {!isLoading && (
        <MapContainer
          className="h-full z-10"
          center={[51.049758, -2.407408]}
          zoom={8}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LayerGroup ref={markerRef}>{markers}</LayerGroup>

          <LayerGroup>{polylines}</LayerGroup>
        </MapContainer>
      )}
    </div>
  );
}
