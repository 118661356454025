import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { CameraIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";

import { EbayCategoryDropdown } from "../../components/ebayListings/EbayCategoryDropdown";
import { EbayListingFormValues } from "../../components/ebayListings/EditListing";
import EbayTableAspects from "../../components/ebayListings/unlistedTable/EbayTableAspects";
import UnlistedNpmUpdate from "../../components/ebayListings/unlistedTable/UnlistedMpnUpdate";
import useNotification from "../../components/notifications/useNotifications";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import { CheckboxInput } from "../../components/shared/Inputs/CheckboxInput";
import { DateTimeInput } from "../../components/shared/Inputs/DateTimeInput";
import { NumberInput } from "../../components/shared/Inputs/NumberInput";
import SelectInput from "../../components/shared/Inputs/SelectInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useBatchListings from "../../data/useBatchListings";
import useEbay from "../../data/useEbay";
import useOffers from "../../data/useOffers";
import usePartAspectsMutations from "../../data/usePartAspectsMutations";
import usePartDefaultCategories from "../../data/usePartDefaultCategories";
import usePartDefaultCategoriesMutations from "../../data/usePartDefaultCategoriesMutations";
import usePartMutations from "../../data/usePartMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { usePartDescriptionGenerator } from "../../hooks/usePartDescriptionGenerator";
import Modal from "../../layouts/Modal";
import { FormatType, IEbayOffer } from "../../models/EbayOffer";
import { IPart } from "../../models/Part";
import checkEbayDisabled from "../../utils/checkEbayDisabled";
import openInNewTab from "../../utils/openInNewTab";
import { IEbayAllPolicies } from './../../models/EbayOffer';
import { DefaultResponseWithData } from './../../models/system';
import PriceFormat from './../../utils/priceFormat';
import UpdateColourCode from "../../components/ebayListings/bulkList/UpdateColourCode";
import sortOffers from "../../utils/sortOffers";


const PartRow = ({ part, policies }: { part: IPart, policies: IEbayAllPolicies | undefined }) => {
  const { defaultPartCategory, useDefaultPartCategory } = usePartDefaultCategories({ part: part });
  const { createOffer } = usePartMutations();
  const { update } = useOffers();
  const { saveHandlers } = useDefaultCRUDHandlers("Part");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleSubmit, register, getValues, setValue, watch, reset } = useForm<EbayListingFormValues>({
    defaultValues: {
      ...part.offers[0] as EbayListingFormValues,
    }
  });

  const handleCreateOffer = () => {
    createOffer.mutate(part.id, saveHandlers);
  };


  const handleApplyDefaults = () => {
    setIsLoading(true);

    useDefaultPartCategory.mutate(undefined, {
      ...saveHandlers,
      onSettled: () => setIsLoading(false)
    });
  };

  const onSubmit = (data: any) => {
    // console.log("row");

    const updatedOffer = {
      ...part.offers[0] as EbayListingFormValues,
      ...data,
    };

    update.mutate(updatedOffer, {
      ...saveHandlers,
      onSuccess: () => {
        saveHandlers.onSuccess();
        setIsEditing(false);
      }
    });
  };

  let offer = part.offers[0] as IEbayOffer;


  useEffect(() => {
    reset(part.offers[0] as EbayListingFormValues);

    if (offer?.format === FormatType.Auction && !offer?.auctionStartPrice) {
      setIsEditing(true);
    } else if (offer?.format === FormatType.FixedPrice && !offer?.buyItNowPrice) {
      setIsEditing(true);
    }
  }, [part])

  if (isLoading) {
    return <LoadingWheel />;
  }

  const enableBestOfferValue = watch("enableBestOffer");

  let result = checkEbayDisabled({ part, offer, ignoreListingTime: true });

  return (
    <form className="flex gap-x-2 w-full" onSubmit={handleSubmit(onSubmit)} >
      <span className="flex justify-center relative" >
        {part.images?.length > 0 && part.images.some((image) => image.isThumbnail === true) ? (
          <img
            className="w-32 h-20 object-contain"
            src={`${part.images.find((image) => image.isThumbnail === true)?.url != null ? part.images.find((image) => image.isThumbnail === true)?.url : null}`}
          />
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
      </span>

      <div key={part.id} className="pt-2 grid grid-cols-5 w-full">
        {/* Col 1 */}
        <div className="col-span-2 space-y-2">
          <div className="flex gap-x-2">{part.title ? <p className="font-semibold">{part.title}</p> : <p className="text-red-500">No title</p>}
            {!part.offers[0]?.publishedOn && !part.soldDate && (
              <ButtonInput isSubmit={false} onClick={() => setIsEditing(!isEditing)} classes={"!bg-gray-100 !shadow-none !p-0 hover:!bg-gray-300"} >
                <PencilIcon className="h-4 w-4 text-gray-700" />
              </ButtonInput>
            )}
          </div>
          <div className="[&>div>span]:text-gray-600">
            <div><span>Reg:</span> {part.vehicle?.vrm}</div>
            <div><span>Make/Model:</span> {part.vehicle?.combinedMake} {part.vehicle?.combinedModel}</div>
            <div><span>VIN:</span> {part.vehicle?.combinedVIN}</div>
            <div><span>Tag Number:</span> {part.tagNumber}</div>
            {isEditing ? (
              <>
                <UnlistedNpmUpdate part={part} onTable={false} />
                <UpdateColourCode part={part} />
              </>
            ) : (
              <>
                <div><span>MPN:</span> {part.partNumber}</div>
                <div><span>Colour Code:</span> {part.vehicle?.vinvisColourCode}</div>
              </>
            )}

          </div>

          {result.isDisabled && <p className="text-red-500">Error: {result.error}</p>}
          {!result.isDisabled && !part.offers[0]?.publishedOn && !part.soldDate && <p className="text-green-500">Ready to list</p>}

          {isEditing && (
            <div className="flex items-start flex-col">
              <ButtonInput
                label={"Apply Defaults"}
                isSubmit={false}
                onClick={() => handleApplyDefaults()}
                classes={""}
                disabled={!defaultPartCategory.data?.data}
              />
              {
                !defaultPartCategory.data?.data &&
                <span className="text-gray-600 text-sm">No defaults found</span>
              }
            </div>
          )}
        </div>

        {/* Col 2 */}

        {/* No offer */}
        {part.offers.length === 0 ? (
          <div className="col-span-2 flex items-center">
            <ButtonInput label="Create Initial Offer" isSubmit={false} onClick={() => handleCreateOffer()} classes={undefined} />
          </div>
        ) : (
          // Offer exists
          <div className="flex flex-col col-span-2">
            {offer?.categoryId ? (
              <EbayTableAspects part={part} button={isEditing} />
            ) : (
              <>
                <p className="text-red-500">Please select an eBay category</p>
                {isEditing && (
                  <EbayCategoryDropdown
                    categoryId={offer?.categoryId}
                    register={register}
                    registerName={"categoryId"}
                    setValue={setValue}
                    watch={watch}
                  />
                )}
              </>
            )}

            {isEditing ? (
              offer?.format === FormatType.Auction ? (
                <div>
                  <div className="flex grow gap-x-4 items-start">
                    <NumberInput
                      label={"Starting Price (£)"}
                      register={register}
                      registerName={"auctionStartPrice"}
                      placeholder={part.suggestedPrice && part.suggestedPrice !== 0 ? `Suggested: ${PriceFormat(part.suggestedPrice)}` : ``}
                    />
                    <div className="mt-6">
                      <span className="text-sm text-blue-600 rounded-full bg-blue-200 p-1 px-2">Suggested: {part.suggestedPrice && part.suggestedPrice !== 0 ? PriceFormat(part.suggestedPrice) : "N/A"}</span>
                    </div>
                  </div>
                  <NumberInput
                    label={"Reserve Price (£)"}
                    register={register}
                    registerName={"auctionReservePrice"}
                  />
                </div>
              ) : (
                <div>

                  <div className="flex grow gap-x-4 items-start">
                    <NumberInput
                      label={`Buy It Now Price (£)`}
                      register={register}
                      registerName={"buyItNowPrice"}
                      placeholder={part.suggestedPrice && part.suggestedPrice !== 0 ? `Suggested: ${PriceFormat(part.suggestedPrice)}` : ``}
                    />
                    <div className="flex grow flex-col">
                      <CheckboxInput label={"Best offer?"} register={register} registerName={"enableBestOffer"} />
                      <div className="mt-3">
                        <span className="text-sm text-blue-600 rounded-full bg-blue-200 p-1 px-2">Suggested: {part.suggestedPrice && part.suggestedPrice !== 0 ? PriceFormat(part.suggestedPrice) : "N/A"}</span>
                      </div>
                    </div>
                  </div>


                  {enableBestOfferValue && (
                    <NumberInput
                      label={"Best Offer Auto Accept Price (£)"}
                      register={register}
                      registerName={"autoAcceptPrice"}
                    />
                  )}
                </div>
              )
            ) : (
              offer?.format === FormatType.Auction ? (
                <div>
                  <p>Price: {PriceFormat(offer?.auctionStartPrice)} <span className="text-sm text-blue-600 rounded-full bg-blue-200 p-2">Suggested: {part.suggestedPrice && part.suggestedPrice !== 0 ? PriceFormat(part.suggestedPrice) : "N/A"}</span></p>
                  <p>Reserve: {PriceFormat(offer?.auctionReservePrice)}</p>
                </div>
              ) : (
                <div>
                  <p>Price: {PriceFormat(offer?.buyItNowPrice)} <span className="text-sm text-blue-600 rounded-full bg-blue-200 p-1 px-2">Suggested: {part.suggestedPrice && part.suggestedPrice !== 0 ? PriceFormat(part.suggestedPrice) : "N/A"}</span></p>
                  {enableBestOfferValue && <p>Auto Accept Price: {PriceFormat(offer?.autoAcceptPrice)}</p>}
                </div>
              )
            )}

            {isEditing ? (
              <div>
                <SelectInput
                  label={"Shipping Policy"}
                  register={register}
                  registerName={"fulfillmentPolicyId"}
                  options={policies?.fulfilmentPolicies}
                  setValue={setValue}
                  watch={watch}
                />
              </div>
            ) : (
              <div>
                <p>Shipping Policy: {policies?.fulfilmentPolicies.find((policy) => policy.id === offer?.fulfillmentPolicyId)?.name ?? "None Set"}</p>
              </div>
            )}

          </div>
        )}


        {/* Col 3 */}
        <div className="flex items-center justify-end gap-x-2">
          <ButtonInput label="View Listing" isSubmit={false} onClick={() => openInNewTab(`/ebay-listings/${part.id}`)} classes={undefined} />
          {isEditing && <ButtonInput label="Save Part" isSubmit={true} onClick={() => { }} classes={undefined} />}
        </div>

      </div>
    </form>
  )
}

export default function BulkList() {
  const { setInfo } = usePageNameContext();
  const { batchId } = useParams();
  const { parts: multipleParts, createOffer, update, bulkPublish } = useBatchListings(batchId);
  const { createCustomAspects } = usePartAspectsMutations();
  const { autoReorderImages } = usePartMutations();
  const { useDefaultPartCategory, useBulkDefaultPartCategory } = usePartDefaultCategoriesMutations();
  const { policies } = useEbay();
  // const { addDefaultCustomAspects } = useDefaultCustomAspects({ part });
  const { saveHandlers } = useDefaultCRUDHandlers("Part");
  const { saveHandlers: defaultsSaveHandlers } = useDefaultCRUDHandlers("Defaults Applied");
  const { addNotification } = useNotification();
  const queryClient = useQueryClient();
  const generateDescription = usePartDescriptionGenerator();
  const { handleSubmit, register, getValues, setValue, watch } = useForm({
    defaultValues: {
      listingStarted: DateTime.now().plus({ minutes: 30 }).toFormat("yyyy-MM-dd'T'HH:mm"),
    }
  });
  const [parts, setParts] = useState<IPart[] | undefined>(undefined);
  const [publishedParts, setPublishedParts] = useState<IPart[] | undefined>(undefined);
  const [soldParts, setSoldParts] = useState<IPart[] | undefined>(undefined);
  const [readyOfferIds, setReadyOfferIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [readyCount, setReadyCount] = useState<number>(0);
  const [results, setResults] = useState<DefaultResponseWithData<IEbayOffer>[] | undefined>(undefined);
  const [resultsOpen, setResultsOpen] = useState<boolean>(false);

  const handleBulkList = () => {
    setIsLoading(true);

    const body = {
      listingStarted: getValues("listingStarted"),
      offerIds: readyOfferIds?.map((id) => id)
    };
    // console.log(readyOfferIds)
    console.log(body);

    bulkPublish.mutate(body, {
      onSuccess: (data) => {
        console.log(data);
        setResults(data as DefaultResponseWithData<IEbayOffer>[]);
        setResultsOpen(true);
      },
      onError: (data) => {
        console.log("error");
        addNotification({
          primaryText: "Bulk List Failed",
          variant: "error",
        });
      },
      onSettled: () => {
        setIsLoading(false);
      }
    });

  };

  const applyDefaultsToAll = () => {
    setIsLoading(true);

    let partIds = parts?.map((part) => part.id);

    const body = {
      partIds: partIds
    };

    useBulkDefaultPartCategory.mutate(body as any, {
      ...defaultsSaveHandlers,
      onSettled: () => setIsLoading(false)
    });

  };

  const handleAutoReorderImages = () => {
    const ids = parts?.map((part) => part.id);

    autoReorderImages.mutate(ids, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        queryClient.invalidateQueries(["parts", "multiple"]);
      },
      onError: () => {
        saveHandlers.onError();
      }
    });

  }

  useEffect(() => {
    setInfo({ name: "Bulk List", desc: "Check the status and list multiple parts at once" });
  }, [])

  useEffect(() => {
    handleSetParts();
  }, [batchId, multipleParts.isLoading, multipleParts.data, policies.data, policies.isLoading])

  const handleSetParts = () => {

    if (!multipleParts.isLoading && multipleParts.data && policies.data && !policies.isLoading) {
      let partsData = multipleParts.data.data
        .filter((part) => part.images && part.images?.length > 0) //Don't want to do anything with parts without images
        .sort((a, b) => (a.vehicle?.vrm || '').localeCompare(b.vehicle?.vrm || ''));
      let currReadyCount = 0;
      let currReadyPartIds: string[] = [];

      // console.log(partsData)
      if (multipleParts.data?.data?.length === 0) {
        //If no data then don't break everything
        setIsLoading(false);
        return;
      }

      let invalidate = false;

      //check each part for errors
      //Flag for refetching the parts data if any of the parts needed to create an offer
      partsData.forEach((part: IPart) => {

        //check for an offer - if none create one with default values
        if (part.offers === undefined || part.offers?.length === 0) {
          createOffer.mutate(part.id, saveHandlers);
          invalidate = true;
        }

        //check if has an ebay category Id - if not then display error and ebay category selector for that part

        //run generate asepcts on part
        if (part.aspects === undefined || part.aspects?.length <= 5) {
          createCustomAspects.mutate(part.id);
          invalidate = true;
        }

        //sort offers by published date
        part.offers.sort(sortOffers);

        //then error check the part
        let offer = part.offers[0] as IEbayOffer;

        console.log(offer);

        if (offer && offer.description === null) {
          offer.description = generateDescription(part);
          update.mutate(offer);
          invalidate = true;
        };

        let result = checkEbayDisabled({ part, offer, ignoreListingTime: true });

        if (!result.isDisabled && offer?.publishedOn === null) {
          currReadyCount++;
          currReadyPartIds.push(offer.id);
        }
      });

      if (invalidate) {
        queryClient.invalidateQueries(["parts", "multiple"]); //Do it once, rather than 3 times per part
        return;
      }

      let parts = partsData.filter((part: IPart) => !part.offers[0]?.publishedOn && !part.soldDate);
      let published = partsData.filter((part: IPart) => part.offers[0]?.publishedOn && !part.soldDate);
      let sold = partsData.filter((part: IPart) => part.soldDate);

      setReadyCount(currReadyCount);
      setParts(parts);
      setPublishedParts(published);
      setSoldParts(sold);
      setReadyOfferIds(currReadyPartIds);
      setIsLoading(false);
    };

  };



  if (isLoading) {
    return <LoadingWheel />
  }

  if (!parts) {
    return <div>Something went wrong!</div>
  }

  return (
    <>
      <Modal open={resultsOpen} setOpen={setResultsOpen} width="max-w-6xl" >
        <div className="divide-y">
          <div>
            <h1 className="text-lg font-semibold">Bulk List Results</h1>
            <p>Check the results of each offer being published.</p>
            <p>{results?.filter((res) => res.success).length} Successful, {results?.filter((res) => !res.success).length} Failed</p>
          </div>

          {results?.map((result, i) => (
            <div key={i} className="">
              <p className="font-bold">Status: {result.success ? "Succeeded!" : "Failed"}</p>
              <p>Message: {result.message}</p>
            </div>
          ))}
        </div>
      </Modal>
      <div className="mt-6 space-y-4">
        <div className="flex justify-between">
          <div>
            <h1 className="text-lg font-semibold">Bulk List {parts.length} parts</h1>
            <p>Check the status of the parts below and list them on eBay.</p>
          </div>

          <div className="flex items-start gap-x-4">
            <ButtonInput label={"Apply Defaults to All Parts"} isSubmit={false} onClick={() => applyDefaultsToAll()} classes={undefined} />
            <ButtonInput label={"Auto Reorder Pictures for All Parts"} isSubmit={false} onClick={() => handleAutoReorderImages()} classes={undefined} />
          </div>

        </div>

        <form>
          <p>Select a listing date for all parts:</p>
          <DateTimeInput label={"Listing Date"} register={register} registerName={"listingStarted"} />
        </form>

        <hr />

        <div className="flex items-center gap-x-4">
          <ButtonInput label={`List ${readyCount} Parts`} isSubmit={false} onClick={() => handleBulkList()} classes={undefined} />
          <p className="text-lg">{readyCount} / {parts.length} Parts Ready</p>
        </div>

        <hr />

        <div className="flex flex-col divide-y gap-y-2">
          {parts.map((part, i) => (
            <PartRow part={part} key={part.id} policies={policies.data?.data} />
          ))}
        </div>

        <hr />

        <div className="flex items-center gap-x-4 pb-8">
          <ButtonInput label={`List ${readyCount} Parts`} isSubmit={false} onClick={() => handleBulkList()} classes={undefined} />
          <p className="text-lg">{readyCount} / {parts.length} Parts Ready</p>
        </div>

        {publishedParts && publishedParts.length > 0 && (
          <>
            <hr />

            <h1 className="text-lg font-semibold">Published Parts</h1>
            <div className="flex flex-col divide-y gap-y-2">
              {publishedParts?.map((part, i) => (
                <PartRow part={part} key={part.id} policies={policies.data?.data} />
              ))}
            </div>
          </>
        )}

        {soldParts && soldParts.length > 0 && (
          <>
            <hr />

            <h1 className="text-lg font-semibold">Sold Parts</h1>
            <div className="flex flex-col divide-y gap-y-2">
              {soldParts?.map((part, i) => (
                <PartRow part={part} key={part.id} policies={policies.data?.data} />
              ))}
            </div>
          </>
        )}

      </div>
    </>
  )
}