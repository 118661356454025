import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import Modal from "../../layouts/Modal";
import { ContactType } from "../../models/Contact";
import useWilmaUsers from "../../data/useWilmaUsers";
import { TextInput } from "../shared/Inputs/TextInput";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { IWilmaUser } from "./../../models/auth/WilmaUser";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { useAuth } from "../../contexts/AuthContext";

type FormValues = {
  forename: string;
  surname: string;
  email: string;
  username: string;
};

interface StaffModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  staff: IWilmaUser | undefined;
}

const claimsToType = (user: IWilmaUser) => {
  if (user.userClaims[0].value === "true") {
    return ContactType.Admin;
  } else if (user.userClaims[1].value === "true") {
    return ContactType.Driver;
  } else if (user.userClaims[2].value === "true") {
    return ContactType.Staff;
  } else if (user.userClaims[3].value === "true") {
    return ContactType.Mechanic;
  }
};

const typeToClaims = (type: ContactType) => {
  const template = {
    isAdmin: false,
    isDriver: false,
    isStaff: false,
    isMechanic: false,
  };

  switch (type) {
    case ContactType.Admin:
      return { ...template, isAdmin: true };
    case ContactType.Driver:
      return { ...template, isDriver: true };
    case ContactType.Staff:
      return { ...template, isStaff: true };
    case ContactType.Mechanic:
      return { ...template, isMechanic: true };
  }
};

export default function StaffModal({ open, setOpen, staff }: StaffModalProps) {
  const { customerId } = useAuth();
  const { saveHandlers } = useDefaultCRUDHandlers("Staff");
  const { update, create, remove } = useWilmaUsers();
  const [contactType, setContactType] = useState<ContactType>(
    ContactType.Staff
  );
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm<FormValues>();

  const contactTypes: Record<number, string> = {
    4: "Admin",
    2: "Staff",
    3: "Driver",
    5: "Mechanic",
  };

  const onSubmit = (data: any) => {
    const claimsObj: Record<string, boolean> = typeToClaims(contactType)!;

    const claims = Object.keys(claimsObj).reduce<Record<string, string>>(
      (acc, key) => {
        acc[key] = claimsObj[key] ? "true" : "false";
        return acc;
      },
      {}
    );

    const updatedStaff: IWilmaUser = {
      ...staff,
      ...data,
      claims: claims,
      customerId,
    };

    if (staff !== undefined) {
      update.mutate(updatedStaff, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError,
      });
    } else {
      create.mutate(updatedStaff, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError,
      });
    }
  };

  const handleDelete = () => {
    remove.mutate(staff?.id!, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setOpen(false);
        setDeleteOpen(false);
        reset({});
      },
      onError: saveHandlers.onError,
    });
  };

  useEffect(() => {
    if (staff !== undefined) {
      setContactType(claimsToType(staff)!);
      reset({
        forename: staff.forename,
        surname: staff.surname,
        email: staff.email,
        username: staff.username,
      });
    } else {
      reset({
        forename: "",
        surname: "",
        email: "",
        username: "",
      });
    }
  }, [staff, open, reset]);

  return (
    <>
      <ConfirmModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title={"Delete staff"}
        message={`Are you sure you want to delete this staff member?\n This action cannot be undone.`}
        confirmButtonText={"Delete"}
        onConfirm={handleDelete}
        isLoading={false}
      />
      <Modal width="max-w-2xl" open={open} setOpen={setOpen}>
        <h1 className="text-xl">{`${staff ? "Edit" : "Add"} Staff`}</h1>
        <div className="mt-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 border-t border-gray-200"
          >
            <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-2">
              <TextInput
                label="First Name"
                registerName="forename"
                register={register}
              />
              <TextInput
                label="Last Name"
                registerName="surname"
                register={register}
              />

              <TextInput
                label="Username"
                registerName="username"
                register={register}
              />

              <TextInput
                label="Email"
                disabled={staff !== undefined}
                registerName="email"
                register={register}
              />

              <div className="sm:col-span-1">
                <label className="text-sm font-medium text-gray-500">
                  Role
                </label>
                <select
                  aria-label="Contact type filter"
                  value={contactType}
                  onChange={(e) => setContactType(parseInt(e.target.value))}
                  className="w-full mt-1 text-sm text-gray-900 h-[40px]"
                >
                  {Object.keys(contactTypes).map((key, i) => (
                    <option className="sm:text-sm" key={i} value={key}>
                      {contactTypes[Number(key)]}
                    </option>
                  ))}
                </select>
              </div>

              <TextInput
                label="Password"
                disabled={staff !== undefined}
                registerName="password"
                register={register}
              />
              <ButtonInput
                label={"Save"}
                isSubmit={true}
                onClick={undefined}
                classes={undefined}
              />
              {staff !== undefined && (
                <ButtonInput
                  label={"Delete"}
                  isSubmit={false}
                  onClick={() => setDeleteOpen(true)}
                  classes={"!bg-red-500 hover:!bg-red-600"}
                />
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
