import { useEffect } from "react";

import StockTable from "../../components/stock/StockTable";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useParts from "../../data/useParts";
import LoadingWheel from "../../components/shared/LoadingWheel";

const Tile = ({ label, color, value, children }: { label: string, color: string, value?: string, children?: any }) => (
  <div className={`border border-slate-500 px-2 py-1 shadow-md rounded bg-${color}-100 grow pt-2 pb-4 max-w-fit`}>
    <p className={`mx-2 text-center text-${color}-600 font-semibold mb-2`}>{label}</p>
    <div className="flex flex-col justify-center">
      {
        value ? <p className={`font-bold text-center my-2 text-2xl text-${color}-600 mx-2`}>{value}</p> : null
      }
      {
        children ? <div className="flex flex-row justify-center">{children}</div> : null
      }
    </div>
  </div>
);

export default function Stock() {
  const { stockStats } = useParts();
  const { setInfo } = usePageNameContext();

  useEffect(() => {
    setInfo({
      name: "Stock",
      desc: "View and manage all stock that hasn't yet been sold",
    });
  }, []);

  return (
    <>
      {stockStats.isLoading ? <LoadingWheel /> : (
        <div className="flex flex-row flex-wrap mt-2 gap-4">
          <Tile label="Parts In Stock" color="green" value={stockStats.data?.data.inStock?.toString()} />
          <Tile label="Parts To Dissmantle" color="green" value={stockStats.data?.data.toBeDissasembled?.toString()} />
          <Tile label="Avg Part Price" color="blue" value={stockStats.data?.data.averagePartPrice ? "£" + stockStats.data?.data.averagePartPrice?.toFixed(2).toString() : "N/A"} />
          <Tile label="Avg Part Year" color="blue" value={stockStats.data?.data.averagePartYear?.toString()} />
          <Tile label="Avg Stock Age" color="blue" value={stockStats.data?.data.averageStockAge ? stockStats.data?.data.averageStockAge?.toFixed(0).toString() + " Days" : "N/A"} />
          <Tile label="Avg Days To Sell" color="blue" value={stockStats.data?.data.averageDaysToSell ? stockStats.data?.data.averageDaysToSell?.toFixed(0).toString() + " Days" : "N/A"} />

          <Tile label="Parts Conversion Rate" color="pink" value={stockStats.data?.data.partsConversionRate?.toString()} />
          <Tile label="Parts Without Images" color="pink" value={stockStats.data?.data.partsWithoutImages?.toString()} />
          <Tile label="Parts Without MPN" color="pink" value={stockStats.data?.data.partsWithoutMPN?.toString()} />

          <Tile label="Parts With QC Issue" color="orange" value={stockStats.data?.data.qcIssues?.toString()} />
        </div>
      )}

      <StockTable />
    </>
  );
}
