import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead } from "../models/Lead";
import { IUnreadMessageResponse, IUnreadMessages } from "../models/Message";
import { INewNote, INote } from "../models/Note";

export default function useNotes() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 10;

  // const unreadMessages = useQuery({
  //   queryKey: ["unreadMessages"],
  //   queryFn: () => get<IUnreadMessageResponse>(`/message/unread`),
  //   staleTime: staleTime,
  //   refetchInterval: staleTime,
  // });

  const create = useMutation(
    (note : INewNote) => post(`/note`, note),
    {
      onSuccess: () => {
        
      },
    }
  );

  const remove = useMutation(
    (id: string) => del(`/note/${id}`),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries("notes");
      },
    }
  );

  return {
    create,
    remove,
  };
}
