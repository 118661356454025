import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { UseMutationResult } from "@tanstack/react-query";

import usePartImageRequirement from "../../data/usePartImageRequirement";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { IPartCategory, PartPosition, PartSide } from "../../models/Part";
import PartCategoryRequiredImagesEditor from "../../pages/PartCategoryRequiredImages/PartCategoryRequiredImagesEditor";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { NumberInput } from "../shared/Inputs/NumberInput";
import { TextInput } from "../shared/Inputs/TextInput";


type CategoryFormValues = {
  name: string,
  partOnly: boolean,
  mechOnly: boolean,
  postage: number | null | undefined,
  whCost: number | null | undefined,
  whTime: number | null | undefined,
  disCost: number | null | undefined,
  disTime: number | null | undefined,
  pinnacleName: string,
  frontierName: string,
  ucid: string,
  skuPrefix: string,
  side: number,
  position: number,
  assemblyNames: string,
  requireSecuirtySeal: boolean,
};

interface CategoryDetailsProps {
  category: IPartCategory | undefined,
  update: UseMutationResult<IPartCategory, unknown, IPartCategory, unknown>,
  create: UseMutationResult<IPartCategory, unknown, IPartCategory, unknown>,
  open?: boolean,
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>,
};


export default function CategoryDetails({ category, update, create, open, setOpen }: CategoryDetailsProps) {
  const { register, handleSubmit, reset, watch } = useForm<CategoryFormValues>();
  const { imageRequirements } = usePartImageRequirement(category?.id);
  const { saveHandlers } = useDefaultCRUDHandlers("Category");
  const [reqImageEditOpen, setReqImageEditOpen] = useState(false);

  const partSides = [...Object.values(PartSide).filter((ct) => isNaN(Number(ct)))];
  const partPositions = [...Object.values(PartPosition).filter((ct) => isNaN(Number(ct)))];


  const onSubmit = (data: any) => {

    const updatedCategory = {
      ...category,
      ...data,
      side: parseInt(data.side),
      position: parseInt(data.position)
    };

    if (category !== undefined) {
      update.mutate(updatedCategory, saveHandlers);
    } else {
      create.mutate(updatedCategory, saveHandlers);
      reset({});
    }

    setOpen && setOpen(false);
  };

  useEffect(() => {
    if (category !== undefined) {
      reset({
        name: category.name,
        partOnly: category.partOnly,
        mechOnly: category.mechOnly,
        postage: category.postage,
        whCost: category.whCost,
        whTime: category.whTime,
        disCost: category.disCost,
        disTime: category.disTime,
        pinnacleName: category.pinnacleName,
        frontierName: category.frontierName,
        ucid: category.ucid,
        skuPrefix: category.skuPrefix,
        side: category.side,
        position: category.position,
        assemblyNames: category.partAssemblyPartCategories?.map(x => x.partAssembly?.name).join(", "),
        requireSecuirtySeal: imageRequirements?.data?.partRequiredImages.some(x => x.type == 8)
      });
    } else {
      reset({})
    }
  }, [category, open, reset, imageRequirements?.data]);


  return (
    <>
      <PartCategoryRequiredImagesEditor category={category} open={reqImageEditOpen} setOpen={setReqImageEditOpen} jumpToSecuritySeal={true} />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 border-t border-gray-200">
        <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-2">

          <TextInput label="Name" registerName="name" register={register} />
          <NumberInput label="Postage (£)" registerName="postage" register={register} />

          <NumberInput label="Warehouse Time" registerName="whTime" step="1" register={register} />
          <NumberInput label="Warehouse Cost  (£)" registerName="whCost" register={register} />
          <NumberInput label="Disassembly Time" registerName="disTime" step="1" register={register} />
          <NumberInput label="Disassembly Cost (£)" registerName="disCost" register={register} />

          <div className="sm:col-span-1">
            <label className="text-sm font-medium text-gray-500">Side</label>
            <select {...register("side")} className="w-full mt-1 text-sm text-gray-900">
              {partSides.map((ct, i) => (
                <option key={ct} value={i}>
                  {ct}
                </option>
              ))}
            </select>
          </div>

          <div className="sm:col-span-1">
            <label className="text-sm font-medium text-gray-500">Position</label>
            <select {...register("position")} className="w-full mt-1 text-sm text-gray-900">
              {partPositions.map((ct, i) => (
                <option key={ct} value={i}>
                  {ct}
                </option>
              ))}
            </select>
          </div>

          <TextInput label="Pinnacle Name" registerName="pinnacleName" register={register} />
          <TextInput label="Frontier Name" registerName="frontierName" register={register} />
          <TextInput label="SKU Prefix" registerName="skuPrefix" register={register} />
          <TextInput label="Assemblies" registerName="assemblyNames" register={register} disabled={true} />
          <TextInput label="UCID" registerName="ucid" register={register} />

          <div> {/* spacer */} </div>

          <div className="flex flex-wrap items-center justify-between">
            <div>
              <input
                id="bodyPart"
                type="checkbox"
                {...register("partOnly")}
              />
              <label htmlFor="bodyPart" className="ml-3 text-sm font-medium text-gray-700">Body Part?</label>
            </div>
            <div>
              <input
                id="mechPart"
                type="checkbox"
                {...register("mechOnly")}
              />
              <label htmlFor="mechPart" className="ml-3 text-sm font-medium text-gray-700">Mechanical Part?</label>
            </div>
            <div>
              <input
                id="requireSecuirtySeal"
                type="checkbox"
                {...register("requireSecuirtySeal")}
              />
              <label htmlFor="requireSecuirtySeal" className="ml-3 text-sm font-medium text-gray-700">Require Security Seal?</label>
            </div>
          </div>

          <div>
            {/* spacer */}
          </div>

          <div className="flex flex-col gap-y-4">

            {
              watch("requireSecuirtySeal") && !imageRequirements?.data?.partRequiredImages.some(x => x.type == 8) ?
                <div className="">
                  <p className="text-red-500 text-md">To properly enable security seal requirement, please create a "Security Seal" image requirement.</p><br />
                  <ButtonInput label="Edit Required Images" isSubmit={false} onClick={() => setReqImageEditOpen(true)} classes={"w-1/2"} />
                </div> : null
            }

            {
              !watch("requireSecuirtySeal") && imageRequirements?.data?.partRequiredImages.some(x => x.type == 8) ?
                <div className="">
                  <p className="text-red-500 text-md">Security seal requirement still enabled as "Secuirty Seal" image requirement exists. Delete it disable.</p><br />
                  <ButtonInput label="Edit Required Images" isSubmit={false} onClick={() => setReqImageEditOpen(true)} classes={"w-1/2"} />
                </div> : null
            }

            <div className="">
              <ButtonInput label={"Save"} isSubmit={true} onClick={undefined} classes={"w-1/2"}
                disabled={(watch("requireSecuirtySeal") && !imageRequirements?.data?.partRequiredImages.some(x => x.type == 8)) || !watch("requireSecuirtySeal") && imageRequirements?.data?.partRequiredImages.some(x => x.type == 8)} />
            </div>

          </div>

        </div>
      </form>
    </>
  )
}