import date from 'date-and-time';
import Papa from 'papaparse';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import useLeads from '../../../../data/useLeads';


const JSONToCSVExporter = ({ displayColumnNames, columnNames, jsonData }) => {
  const { exportLeads } = useLeads();
  const [error, setError] = useState("");

  const exportToCSV = (bypass = false) => {
    //Filter out exported leads and not found vehicles
    let filteredData = jsonData.filter(lead => lead.exportedOn === null);
    filteredData = filteredData.filter(lead => lead.vehicle.found !== false);

    // console.log("filtered", filteredData)

    if (filteredData.length === 0 && bypass === false) {
      setError("No leads to export");
      return;
    }

    // console.log(Object.byString(jsonData[0], "vehicle.bore") );
    // console.log(Object.byString(jsonData[0], "Location Locality") );

    let rows;
    if (bypass === false) {
      filteredData = filteredData.filter(lead => lead.vehicle.found !== false);
      rows = filteredData.map(row => columnNames.map(columnName => Object.byString(row, columnName)));
    } else {
      const found = jsonData.filter(lead => lead.vehicle.found !== false);
      rows = found.map(row => columnNames.map(columnName => Object.byString(row, columnName)));
    }

    let csvContent = "";
    console.log(rows)

    //Format the various columns
    rows.forEach((row, i) => {
      row[6] = date.format(new Date(row[6]), "DD/MM/YYYY");
      row[10] = row[10] ? date.format(new Date(row[10]), "HH:mm DD/MM/YYYY") : "";
      row[21] = date.format(new Date(row[21]), "DD/MM/YYYY");
    });

    console.log(rows)

    csvContent = Papa.unparse({
      fields: displayColumnNames,
      data: rows
    })

    // Add the first two rows to match the CSV format
    var formatting = "Operator:,All Sites," + ",".repeat(displayColumnNames.length - 1 - 2) + "\r\n" + ",".repeat(displayColumnNames.length - 1) + "\r\n";
    csvContent = formatting + csvContent;

    let csvData = encodeURIComponent(csvContent);
    csvData = "data:text/csv;charset=utf-8," + csvData;

    const link = document.createElement("a");
    link.setAttribute("href", csvData);
    link.setAttribute("download", "export.csv");
    document.body.appendChild(link);

    // console.log(rows)
    link.click();

    //Send up IDs of leads we're exporting for the first time
    if (!bypass) {
      const leadIds = filteredData.map(lead => lead.id);

      const body = { leads: leadIds };
      exportLeads.mutate(body, {
        onSuccess: () => {
          console.log("Success");
        },
        onError: () => {
          console.log("Error");
        }
      })
    }

  }

  //Gets nested object properties by string
  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-8 py-4 text-xl font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
        onClick={() => exportToCSV(false)}>
        <DocumentArrowDownIcon
          className="-ml-2 mr-5 h-8 w-8"
          aria-hidden="true"
        />
        Download CSV
      </button>
      {error &&
        <>
          <p className="mt-5 text-lg text-red-500">{error}</p>
          <button
            type="button"
            className="mt-1 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-4 py-2 text-md leading-4 text-white shadow-sm hover:bg-gp-blue-700"
            onClick={() => exportToCSV(true)}>
            Download All
          </button>
        </>
      }
    </div>
  );
};

export default JSONToCSVExporter;
