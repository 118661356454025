import { HTMLProps, SetStateAction, useEffect, useRef, useState } from "react";

import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { createColumnHelper, Row, RowModel } from "@tanstack/react-table";

import Modal from "../../../layouts/Modal";
import { FormatType, IEbayOffer } from "../../../models/EbayOffer";
import { PageMode } from "../../../models/PagedTable";
import { IPart, PartStatus } from "../../../models/Part";
import { ListingStatus } from "../../../pages/EbayListings/ViewListing";
import openInNewTab from "../../../utils/openInNewTab";
import PartStatusLabel from "../../ebayOrders/PartStatusLabel";
import ImageCarousel from "../../shared/Images/ImageCarousel";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import PagedTable from "../../shared/Tables/PagedTable";
import { IPartCategory } from '../../../models/Part';
import PriceFormat from '../../../utils/priceFormat';
import EbayListingTitle from "../EbayListingTitle";
import EditListingImages from "../EditListingImages";
import usePartMutations from "../../../data/usePartMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { EbayCategoryDropdown } from "../EbayCategoryDropdown";
import { EbayTableCategoryDropdown } from "./EbayTableCategoryDropdown";
import EbayTableAspects from "./EbayTableAspects";
import checkEbayDisabled from "../../../utils/checkEbayDisabled";
import { EbayListingFormValues } from "../EditListing";
import useNotification from "../../notifications/useNotifications";
import WarehouseLocationFromId from "../../warehouseLocations/WarehouseLocationFromId";
import UnlistedOfferPrice from "./UnlistedOfferPrice";
import UnlistedNpmUpdate from "./UnlistedMpnUpdate";
import TableListingError from "../TableListingError";
import useBatchListingsMutations from "../../../data/useBatchListingsMutations";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer'}
      {...rest}
    />
  )
}

export default function EbayUnlistedTable({ categories }: { categories: IPartCategory[] | undefined }) {
  const { patchPart, autoReorderImages } = usePartMutations();
  const { createBatch } = useBatchListingsMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Part")
  const { addNotification } = useNotification();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(50);

  const colHelper = createColumnHelper<IPart>();

  const cols = [
    {
      id: 'select',
      header: ({ table }: any) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <div className="flex justify-center items-center flex-1 p-4" onClick={(e) => e.stopPropagation()}>
          <IndeterminateCheckbox
            {...{
              id: row.original.id,
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          <button
            onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
            style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
            className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
          >
            {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
            <ChevronRightIcon className="h-4 w-4" />
          </button>
        </div>
      ),
    },
    colHelper.display({
      id: "images",
      cell: (info) => (
        <span className="flex justify-center relative" >
          {info.row.original.images?.length > 0 && info.row.original.images.some((image) => image.isThumbnail === true) ? (
            <img
              className="w-32 h-20 object-contain"
              src={`${info.row.original.images.find((image) => image.isThumbnail === true)?.url != null ? info.row.original.images.find((image) => image.isThumbnail === true)?.url : null}`}
            />
          ) : (
            <CameraIcon className="w-14 text-gray-600" />
          )}
        </span>
      ),
    }),
    colHelper.accessor("categoryId", {
      header: "Category",
      sortingFn: "auto",
      cell: (info) => <span>{info.getValue() ? categories?.find((cat) => cat.id === info.getValue())?.name : "N/A"}</span>,
    }),
    colHelper.accessor("tagNumber", {
      header: "Tag No.",
      sortingFn: "auto",
    }),
    colHelper.accessor("vehicle.vrm", {
      header: "VRM",
      id: "vrm",
      sortingFn: "auto",
      cell: (info) => <div className="flex flex-col">
        <span>{info.row.original.vehicle?.vrm ?? ""}</span>
        {/* <span className="text-xs">({info.row.original.vehicle?.combinedVIN ?? ""})</span> */}
        <span>{info.row.original.vehicle?.combinedMake ?? ""}</span>
        <span>{info.row.original.vehicle?.combinedModel ?? ""}</span>
      </div>,
    }),
    colHelper.accessor("offers", {
      id: "offer price",
      header: "Price",
      sortingFn: (rowA: any, rowB: any, columnId: any) => {
        //Sort by value of both
        return (parseFloat(rowA.original.offers[0]?.auctionStartPrice ?? 0)) + (parseFloat(rowA.original.offers[0]?.buyItNowPrice ?? 0)) -
          (parseFloat(rowB.original.offers[0]?.auctionStartPrice ?? 0)) - (parseFloat(rowB.original.offers[0]?.buyItNowPrice ?? 0));
      },
      cell: (info) => <UnlistedOfferPrice part={info.row.original} />
    }),
    colHelper.accessor("offers", {
      id: "error state",
      header: "Error",
      sortingFn: "auto",
      cell: (info) => <TableListingError part={info.row.original} />
    }),
    // colHelper.accessor("offers", {
    //   id: "best offer enabled",
    //   header: "Best Offer?",
    //   sortingFn: "auto",
    //   cell: (info) => <span className="flex justify-center text-xl">
    //     {
    //       info.getValue()?.at(0)?.format === FormatType.Auction ?
    //         "✘" :
    //         (info.getValue()?.at(0)?.enableBestOffer ? "✔" : "✘")
    //     }
    //   </span>,
    // }),
    colHelper.accessor("passedQcOn", {
      header: "QC",
      cell: (info) => <span className="flex justify-center text-xl">{info.getValue() ? "✔" : "✘"}</span>,
    }),
    colHelper.display({
      id: "mpn image",
      header: "MPN",
      cell: (info) => <UnlistedNpmUpdate part={info.row.original} />,
    }),
    colHelper.accessor("warehouseLocationId", {
      header: "Warehouse",
      sortingFn: (rowA: any, rowB: any, columnId: any) => {
        const dateA = rowA.original.lastStockChecked;
        const dateB = rowB.original.lastStockChecked;

        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return 1;
        if (dateB === null) return -1;

        return new Date(dateA).getTime() - new Date(dateB).getTime();
      },
      cell: (info) => <WarehouseLocationFromId id={info.getValue()} lastStockChecked={info.row.original.lastStockChecked} />,
    }),
    colHelper.accessor("grade", {
      header: "Grade",
      cell: (info) => <span className="flex justify-center">{info.getValue() ?? "N/A"}</span>,
    }),
    colHelper.accessor("vehicle", {
      id: "mileage",
      header: "Mileage",
      cell: (info) => <span className="flex justify-center">{info.getValue()?.odometerInMiles?.toLocaleString() ?? "N/A"}</span>,
    }),
    colHelper.accessor("offers", {
      id: "offer status",
      header: "Offer Status",
      enableSorting: false,
      cell: (info) => <ListingStatus offer={info.getValue()?.at(0)} part={info.row.original} />,
    }),
    colHelper.accessor("partStatus", {
      header: "Part Status",
      enableSorting: false,
      cell: (info) => <PartStatusLabel part={info.row.original} />,
    }),
  ];



  const bulkList = (rows: RowModel<IPart>) => {
    const selected = rows.rows.filter((row) => row.getIsSelected());

    if (selected.length === 0) {
      addNotification({
        primaryText: "No parts selected",
        variant: "warn"
      });
      return;
    }

    const ids = selected.map((row) => row.original.id);

    //Create body for batch
    const body = {
      partIds: ids,
    };

    createBatch.mutate(body, {
      onSuccess: (data) => {
        console.log(data);
        openInNewTab(`ebay-listings/bulk-list/${data.data}`);
      },
      onError: () => {
        saveHandlers.onError();
      }
    });

  };

  const handleAutoReorderImages = (rows: RowModel<IPart>) => {
    const selected = rows.rows.filter((row) => row.getIsSelected());

    if (selected.length === 0) {
      addNotification({
        primaryText: "No parts selected",
        variant: "warn"
      });
      return;
    }

    const ids = selected.map((row) => row.original.id);

    autoReorderImages.mutate(ids, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        queryClient.refetchQueries(["pagedTable", PageMode.Unlisted]);
      },
      onError: () => {
        saveHandlers.onError();
      }
    });

  }

  const handleSendToQc = (part: IPart) => {
    patchPart.mutate({ id: part.id, partStatus: PartStatus.QC }, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        queryClient.invalidateQueries(["pagedTable", PageMode.Unlisted]);
      },
      onError: () => {
        saveHandlers.onError();
      }
    });
  };

  const expandedRow = (part: IPart) => {

    if (part.images && part.images.length > 0) {
      part.images.sort((a, b) => {
        if (a.order === null) {
          return 1;
        }

        if (b.order === null) {
          return -1;
        }

        return a.order - b.order;
      });
    }

    let offer: IEbayOffer = {
      ...part.offers[0] as IEbayOffer,
    };

    let result = checkEbayDisabled({ part, offer, ignoreListingTime: true });
    // console.log(result);

    return (
      <>
        <Modal open={open} width={"max-w-7xl"} setOpen={setOpen}>
          <EditListingImages part={part} />
        </Modal>
        <div className="grid grid-cols-1 gap-4 p-2">
          <div>
            <EbayListingTitle title={part.offers[0]?.title} table register={undefined} setValue={undefined} watch={undefined} part={part} getValues={undefined} />
          </div>

          <div>
            <EbayTableCategoryDropdown part={part} categoryId={part.offers[0]?.categoryId} />
          </div>

          {/* {part.offers[0]?.categoryId && (
            <EbayTableAspects part={part} />
          )} */}

          {result.error && <p className="text-red-500">{result.error}</p>}

          <div className="">
            <h1 className="font-semibold">eBay Images:</h1>
            {part.images.filter((img) => img.useOnEbay).length === 0 && <p>No eBay images selected</p>}
            <ImageCarousel images={part.images.filter((img) => img.useOnEbay)} part size="w-40 h-40" />
          </div>

          <div className="space-x-4">
            <ButtonInput label="Sent To QC" isSubmit={false} onClick={() => handleSendToQc(part)} classes={""} />
            <ButtonInput label="Photo Manager" isSubmit={false} onClick={() => setOpen(true)} classes={""} />
          </div>
        </div>
      </>
    )
  };

  return (
    <>
      <PagedTable
        url="/part/listings"
        pageMode={PageMode.UnlistedHideMissingDeleted}
        columns={cols}
        queryLabel="Search part details"
        // customUrlQuery={`pageSize=${pageSize}`}
        customUrlQuery="pageSize=100"
        onRowClick={(x: IPart) => openInNewTab(`ebay-listings/${x.id}`)}
        actionButtons={[
          { label: "Bulk List", onClick: bulkList },
          { label: "Auto Reorder Images", onClick: handleAutoReorderImages },
        ]}
        expandedRow={expandedRow}
        showTotal={true}
        modeToggles={[
          { label: "Show Missing/Deleted", pageMode: PageMode.Unlisted },
          { label: "Dismantled", pageMode: PageMode.UnlistedDismantled },
        ]}
      />
      {/* <div className="flex gap-x-2 items-center mt-4">
        <label>Page Size:</label>
        <input className="w-20" type="number" value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))} />
      </div> */}
    </>
  );
}
