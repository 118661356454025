import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import useWarehouseLocations from "../../data/useWarehouseLocations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { IWarehouseLocation } from "../../models/Location";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { NumberInput } from "../shared/Inputs/NumberInput";
import { TextInput } from "../shared/Inputs/TextInput";
import { useLabelGenerator } from "../../hooks/useLabelGenerator";

type FormValues = {
  displayName: string;
};

interface LocationsModalProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  location?: IWarehouseLocation;
}

export default function WarehouseLocationsModal({ open, setOpen, location }: LocationsModalProps) {
  const { saveHandlers } = useDefaultCRUDHandlers("Warehouse Location");
  const { deleteHandlers } = useDefaultCRUDHandlers("Warehouse Location");
  const { generateWarehouseLabel } = useLabelGenerator();
  const { update, create, remove } = useWarehouseLocations();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm<FormValues>();

  const onSubmit = (data: any) => {

    const updatedLocation = {
      ...location,
      ...data
    };

    if (location !== undefined) {
      update.mutate(updatedLocation, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    } else {
      create.mutate(updatedLocation, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    }
  }

  const handleDelete = () => {
    remove.mutate(location?.id!, {
      onSuccess: (data) => {
        deleteHandlers.onSuccess();
        setOpen(false);
        setDeleteOpen(false);
        reset({});
      },
      onError: deleteHandlers.onError
    });
  };

  const handlePrint = async () => {
    const html = await generateWarehouseLabel(location!);

    var iframe = document?.getElementById("frame") as HTMLIFrameElement;
    var pri = iframe.contentWindow;
    pri?.document.open();
    pri?.document.write(html);
    pri?.document.close();
    pri?.focus();

    //QR code doesn't show up without a timeout
    setTimeout(async () => {
      pri?.print();
    }, 1);
  };

  useEffect(() => {

    if (location !== undefined) {
      reset({
        displayName: location.displayName
      });
    } else {
      reset({})
    }
  }, [location, open, reset]);

  return (
    <>
      <ConfirmModal open={deleteOpen} setOpen={setDeleteOpen}
        title={"Delete Location"} message={`Are you sure you want to delete this location?\n This action cannot be undone.`}
        confirmButtonText={"Delete"} onConfirm={handleDelete} isLoading={false}
      />
      <Modal width="max-w-2xl" open={open} setOpen={setOpen} >
        <div className="flex justify-between">
          <h1 className="text-xl">{`${location ? "Edit" : "Add"} a Location`}</h1>
          {location &&
            <ButtonInput label={"Print Label"} isSubmit={false} onClick={() => handlePrint()} classes={undefined} />
          }
        </div>
        <div className="mt-4">
          <form className="space-y-4 border-t border-gray-200" onSubmit={handleSubmit(onSubmit)} >
            <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-1">

              <TextInput label="Display Name" registerName="displayName" register={register} />

              <ButtonInput label={`${location ? "Save Changes" : "Create"}`} isSubmit={true} onClick={undefined} classes={undefined} />
              {location !== undefined && (
                <ButtonInput label={"Delete"} isSubmit={false} onClick={() => (setDeleteOpen(true))} classes={"!bg-red-500 hover:!bg-red-600"} />
              )}
            </div>
          </form>
        </div>
      </Modal>

      <iframe id="frame" className="h-0 w-0 absolute"></iframe>
    </>
  );
}
