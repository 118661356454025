import { useEffect, useState } from "react";
import useEbayCategories from "../../data/useEbayCategories";
import LoadingWheel from "../shared/LoadingWheel";
import ComboBoxInput from "../shared/Inputs/ComboBoxInput";

interface EbayCategoryDropdownProps {
  categoryId: string | null | undefined;
  register: any;
  registerName: string;
  setValue: any;
  watch: any;
}

export function EbayCategoryDropdown({ categoryId, register, registerName, setValue, watch }: EbayCategoryDropdownProps) {
  const { categories } = useEbayCategories();
  const [loading, setLoading] = useState(true);

  // console.log(categoryId);

  useEffect(() => {
    if (!categories.isLoading && categories.data) {
      if (!watch(registerName)) {
        setValue(registerName, categories.data!.categories[0].id);
      }

      setLoading(false);
    }
  }, [categories.data, categories.isLoading])

  useEffect(() => {
    if (categories.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [categories.isLoading, categories.data]);

  const handleCategoryChange = (registerName: string, option: any) => {
    if (option != null && option.id != null)
      setValue(registerName, option.id);
  };

  if (loading || categories.isLoading || !categories.data) {
    return (
      <div className="flex items-center space-x-2">
        Getting categories from eBay...
        <span className="h-5">
          <LoadingWheel />
        </span>
      </div>
    );
  } else {

    return (
      <ComboBoxInput
        label="eBay Category*"
        options={categories.data!.categories}
        searchField={"name"}
        register={register}
        setValue={handleCategoryChange}
        registerName={registerName}
        initialValue={categoryId as string | undefined}
      />
    );
  }
}
