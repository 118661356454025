import { useState } from "react";

import { faCar, faRoad, faStar, faTrashCan, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DriverGreenEngineIcon from "../../../assets/svgs/driver-green-engine.svg";
import DriverRedEngineIcon from "../../../assets/svgs/driver-red-engine.svg";
import GreenEngineIcon from "../../../assets/svgs/green-engine.svg";
import RedEngineIcon from "../../../assets/svgs/red-engine.svg";
import { IVehicle } from "../../../models/Vehicle";
import classNames from './../../../utils/classNames';

export default function VehicleIcons({ vehicle, hover = false }: { vehicle: IVehicle, hover?: boolean }) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const getIconColour = (value: boolean) => {
    return value ? "text-green-600" : "text-red-600";
  }

  return (
    <div>
      <div
        className={classNames(hover ? "cursor-help" : "", "relative")}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <div className="text-xl space-x-1">
          {vehicle?.vehiclePremium && <FontAwesomeIcon icon={faStar} className="text-yellow-600" />}
          {vehicle?.isVan && <FontAwesomeIcon icon={faTruck} />}
        </div>
        <div className="flex text-xl gap-x-1">
          <img src={vehicle.vehicleStarting ? GreenEngineIcon : RedEngineIcon} className="h-6 w-6" />
          <img src={vehicle.vehicleStartingDriver ? DriverGreenEngineIcon : DriverRedEngineIcon} className="h-6 w-6" />
        </div>
        <div className="flex text-xl gap-x-1">
          <FontAwesomeIcon icon={faRoad} className={getIconColour(!vehicle.accessIssues)} />
          <FontAwesomeIcon icon={faCar} className={getIconColour(vehicle.vehicleComplete)} />
          <FontAwesomeIcon icon={faTrashCan} className={getIconColour(vehicle.freeOfWaste)} />
        </div>
        {hover && isHovering && (
          <div className={`p-1 absolute z-30 w-[25rem] bg-white border rounded-md`}>
            <p><FontAwesomeIcon icon={faStar} className="text-yellow-600" /> - Premium vehicle</p>
            <p><FontAwesomeIcon icon={faTruck} /> - Van</p>
            <p>Green for good option - Starts, No access Issues, Is Complete, Is Free of Waste</p>
            <p className="flex"><img src={GreenEngineIcon} className="h-6 w-6" /> - Does vehicle start?</p>
            <p className="flex"><img src={DriverGreenEngineIcon} className="h-6 w-6" /> - Driver reported does vehicle start?</p>
            <p><FontAwesomeIcon icon={faRoad} className={"text-green-600"} /> - Access Issues?</p>
            <p><FontAwesomeIcon icon={faCar} className={"text-green-600"} /> - Vehicle Complete?</p>
            <p><FontAwesomeIcon icon={faTrashCan} className={"text-green-600"} /> - Free Of Waste?</p>
          </div>
        )}
      </div>
    </div>
  )
}