import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";

import { IAllVehicleResponse, IVehicle } from "../models/Vehicle";

export default function useVehicles() {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();

  const vehicles = useQuery({
    queryKey: ["vechicles"],
    queryFn: () => get<IAllVehicleResponse>('/vehicle'),
  });

  return {
    vehicles,
  };
}
