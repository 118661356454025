import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartLookupResponse } from "../models/PartLookup";

export default function usePartsLookup(leadId: string, requestId: string | null | undefined) {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60 * 10; //10 minutes

  const lookup = useQuery({
    queryKey: ["partslookup"],
    queryFn: () => get<IPartLookupResponse>(`/partlookup/${leadId}`),
    staleTime: staleTime,
    enabled: !!requestId,
  });

  const lookupMutation = useMutation(
    () => get<IPartLookupResponse>(`/partlookup/${leadId}`),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["partslookup"], data);
      },
    }
  );

  return {
    lookup,
    lookupMutation
  };
}
