import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";

import LoadingWheel from "../../components/shared/LoadingWheel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import StaffTable from "../../components/staff/StaffTable";
import StaffModal from "../../components/staff/StaffModel";
import { useUserLookup } from "../../hooks/useUserLookup";
import { IWilmaUser } from "./../../models/auth/WilmaUser";

export default function Staff() {
  const { setInfo } = usePageNameContext();
  const { isLoading: userLoading, users: allStaff } = useUserLookup();
  const [staff, setStaff] = useState<IWilmaUser[] | undefined>(undefined);
  const [editStaff, setEditStaff] = useState<IWilmaUser | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Staff",
      desc: "View and manage your staff here",
    });
  }, []);

  useEffect(() => {
    if (!userLoading && allStaff) {
      const st = searchTerm.toLowerCase();

      var filter = allStaff.filter(
        (staff) =>
          staff.forename.toLowerCase().includes(st) ||
          staff.surname.toLowerCase().includes(st) ||
          staff.email.toLowerCase().includes(st)
      );

      setStaff(filter);
      setIsLoading(false);
    }
  }, [userLoading, searchTerm, allStaff]);

  const handleSetStaff = (staff: IWilmaUser) => {
    setEditStaff(staff);
    setCreateOpen(true);
  };

  const handleAddStaff = () => {
    setEditStaff(undefined);
    setCreateOpen(true);
  };

  return isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <StaffModal open={createOpen} setOpen={setCreateOpen} staff={editStaff} />
      <div className="mt-4 flex justify-between">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 sm:text-sm"
            placeholder="Name or Email"
          />
        </div>
        {/* <div className="flex items-center mr-8">
          <input id="hideFailed" onChange={(e) => setShowInactive(e.target.checked)} value="showInactive" checked={showInactive} type="checkbox" className="mr-2 h-5 w-5" />
          <label htmlFor="hideFailed">Show Inactive</label>
        </div> */}
        <button
          className=" bg-gp-blue-600 hover:bg-gp-blue-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={handleAddStaff}
        >
          Add Staff
        </button>
      </div>
      <StaffTable staff={staff!} setStaff={handleSetStaff} />
    </>
  );
}
