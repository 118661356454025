import { Fragment, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
  SortingState,
} from "@tanstack/react-table";

import { ISetting } from "../../models/Setting";
import AdminSettingsValue from "./AdminSettingsValue";

const colHelper = createColumnHelper<ISetting>();

export default function AdminSettingsTable({
  settings,
}: {
  settings: ISetting[];
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const cols = [
    colHelper.accessor("key", {
      header: "Name",
      size: 10,
      maxSize: 10,
      sortingFn: "auto",
    }),
    colHelper.accessor("value", {
      header: "Value",
      size: 40,
      maxSize: 40,
      cell: (info) => <AdminSettingsValue setting={info.row.original} />,
    }),
  ];

  const table = useReactTable({
    data: settings,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div className="mt-4 bg-white overflow-auto">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-gp-blue-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                    // style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " ▲",
                          desc: " ▼",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    // className="hover:bg-gray-100 hover:cursor-pointer"
                  >
                    {rowCells.map((cell) => (
                      <td
                        key={cell.id}
                        className="px-3 py-3 border text-sm"
                        // style={{ width: cell.column.getSize() }}
                        width={`${cell.column.getSize()}%`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <></>
                    // <tr>
                    //   {/* 2nd row is a custom 1 cell row */}
                    //   <td colSpan={row.getVisibleCells().length}>
                    //     <LeadsTableSubComponent lead={row.original} />
                    //   </td>
                    // </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
