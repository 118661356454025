import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { useForm } from "react-hook-form";

import useEbay from "../../data/useEbay";
import useOffers from "../../data/useOffers";
import usePartAspectsMutations from "../../data/usePartAspectsMutations";
import usePartDefaultCategories from "../../data/usePartDefaultCategories";
import useSinglePart from "../../data/useSinglePart";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { usePartDescriptionGenerator } from "../../hooks/usePartDescriptionGenerator";
import { FormatType, IEbayOffer, ListingDuration } from "../../models/EbayOffer";
import { IPart } from "../../models/Part";
import checkEbayDisabled, { ebayChecks } from "../../utils/checkEbayDisabled";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import CustomJoditEditor from "../shared/Inputs/CustomJoditEditor";
import { DateTimeInput } from "../shared/Inputs/DateTimeInput";
import { NumberInput } from "../shared/Inputs/NumberInput";
import SelectInput from "../shared/Inputs/SelectInput";
import { TextInput } from "../shared/Inputs/TextInput";
import LoadingWheel from "../shared/LoadingWheel";
import { EbayCategoryDropdown } from "./EbayCategoryDropdown";
import { EbayConditionDropdown } from "./EbayConditionDropdown";
import EbayListingTitle from "./EbayListingTitle";
import { EbayStoreCategoryDropdown } from "./EbayStoreCategoryDropdown";
import EditListingSpecifics from "./EditListingSpecifics";

export type EbayListingFormValues = {
  title: string;
  description: string;
  format: FormatType;
  duration: ListingDuration;
  listingStarted: string | null;
  auctionStartPrice: number | null;
  auctionReservePrice: number | null;
  buyItNowPrice: number | null;
  enableBestOffer: boolean | null;
  autoAcceptPrice: number | null;
  locationKey: any;
  fulfillmentPolicyId: any;
  paymentPolicyId: any;
  returnPolicyId: any;
  categoryId: any;
  condition: any;
  conditionDescription: string;
};

const formatOptions = [
  { id: FormatType.Auction, name: "Auction" },
  { id: FormatType.FixedPrice, name: "Buy It Now" },
];

const durationOptions = [
  // { id: ListingDuration.GTC, name: "Until Cancelled" }, //auto selected on 
  { id: ListingDuration.Days1, name: "1 Day" },
  { id: ListingDuration.Days3, name: "3 Days" },
  { id: ListingDuration.Days5, name: "5 Days" },
  { id: ListingDuration.Days7, name: "7 Days" },
  { id: ListingDuration.Days10, name: "10 Days" },
  { id: ListingDuration.Days21, name: "21 Days" },
  { id: ListingDuration.Days30, name: "30 Days" },
];

const defaultValues = {
  fulfillmentPolicyId: "125494812024",
  paymentPolicyId: "121933662024",
  returnPolicyId: "204693254024",
};

export default function EditListing({ part }: { part: IPart }) {
  const { defaultPartCategory, useDefaultPartCategory } = usePartDefaultCategories({ part: part });
  const { createCustomAspects } = usePartAspectsMutations();
  const { create, update } = useOffers();
  const { policies, locations } = useEbay();
  const { withdraw, createOffer } = useSinglePart(part.id);
  const { saveHandlers } = useDefaultCRUDHandlers("Offer");
  const [isLoading, setIsLoading] = useState(true);
  const [withdrawIsLoading, setWithdrawIsLoading] = useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [applyDefaultsOpen, setApplyDefaultsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [isHovering, setIsHovering] = useState(false);
  const generateDescription = usePartDescriptionGenerator();

  const defaultVals = {
    title: `${part.vehicle?.dvlaYearOfManufacture} ${part.vehicle?.combinedMake} ${part.vehicle?.combinedModel} ${part.title}`,
    format: FormatType.FixedPrice,
    duration: ListingDuration.GTC,
    listingStarted: DateTime.now().plus({ minutes: 30 }).toFormat("yyyy-MM-dd'T'HH:mm"),
    auctionStartPrice: 0,
    auctionReservePrice: 0,
    buyItNowPrice: 0,
    enableBestOffer: false,
    autoAcceptPrice: 0,
    fulfillmentPolicyId: "62833190024", //Default to collection only
    paymentPolicyId: "121933662024", //Default to Managed Payments
    returnPolicyId: "204693254024", //Default to 30 day returns
    categoryId: undefined, //SET TO UNDEFINED AS GRABS A VALUE IN THE CUSTOM SELECT
    storeCategoryId: undefined, //SET TO UNDEFINED AS GRABS A VALUE IN THE CUSTOM SELECT
    locationKey: "gpmetals", //Default to GP
    condition: 3000,
    conditionDescription: "Used - "
  };

  const { register, getValues, setValue, watch, handleSubmit, reset, formState, control } =
    useForm<EbayListingFormValues>({
      defaultValues: defaultVals
    });

  const onSubmit = (data: any, nextPage: boolean) => {
    const partOffer = {
      ...part.offers[0],
      ...data,
      partDataId: part.id,
      quantity: 1,
      description: description,
      // condition: parseInt(data.condition),
    };

    if (!partOffer.id) {
      create.mutate(partOffer, {
        ...saveHandlers,
        onSuccess: () => {
          if (nextPage) setPage(1);
          saveHandlers.onSuccess();
        },
      });
    } else {
      update.mutate(partOffer, {
        ...saveHandlers,
        onSuccess: () => {
          if (nextPage) setPage(1);
          saveHandlers.onSuccess();
        },
      });
    }
  };

  const handleSetFormat = (_: string, value: any) => {
    setValue(
      "duration",
      value === FormatType.FixedPrice
        ? ListingDuration.GTC
        : ListingDuration.Days30
    );
    setValue("format", value);
  };

  const handleIsDisabled = () => {
    const offerData: EbayListingFormValues = getValues();

    const offer = offerData as IEbayOffer;

    let result: ebayChecks = checkEbayDisabled({ part, offer });

    setError(result.error);
    // setIsDisabled(result.isDisabled);
    setIsDisabled(false);
  };

  const handleWithdraw = () => {
    if (!part.offers[0].publishedOn)
      return;

    withdraw.mutate(part.offers[0].id, {
      onSuccess: () => {
        setWithdrawOpen(false);
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      }
    });
  };

  const handleApplyDefaults = () => {
    setIsLoading(true);

    useDefaultPartCategory.mutate(undefined, {
      onSuccess: () => {
        setApplyDefaultsOpen(false);
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      },
      onSettled: () => {
        setIsLoading(false);
      }

    });
  };

  useEffect(() => {
    if (!policies.isLoading && policies.data && !locations.isLoading && locations.data && !defaultPartCategory.isLoading) {

      if (isLoading) {
        //Add default custom aspects
        createCustomAspects.mutate(part.id);
      }

      if (defaultPartCategory.data && defaultPartCategory.data?.data && part.offers.length === 0) {

        // console.log("New Offer is Being Created!");
        createOffer.mutate(part.id); // API call

      } else if (part.offers?.length > 0) {
        // console.log(part.offers[0])

        //Map default values onto offer where offer value is null
        let offer = Object.fromEntries(
          Object.entries(part.offers[0]).map(([key, value]) => [
            key,
            value !== null ? value : Object.entries(defaultVals).filter(x => x[0] == key)[0]?.[1] ?? null,
          ])
        );

        reset(offer);
        //Set description
        setDescription(part.offers[0].description ?? generateDescription(part));

        setIsLoading(false);

      } else {
        //Generate description
        setDescription(generateDescription(part));
        setIsLoading(false);
      }

      // setIsLoading(false);
      handleIsDisabled();
    }
  }, [part, policies.data, policies.isLoading, locations.data, locations.isLoading, defaultPartCategory.isLoading]);


  //Check if form is valid
  useEffect(() => {
    handleIsDisabled();
    // console.log(getValues())
  }, [formState]);

  //Scroll to top on page load - this is mostly for when you go back from the second page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bestOfferValue = watch("enableBestOffer");

  if (isLoading) {
    return (
      <div className="mt-[20vh]">
        <LoadingWheel />
      </div>
    );
  }

  return (
    <>
      <ConfirmModal open={withdrawOpen} setOpen={setWithdrawOpen} isLoading={withdrawIsLoading}
        title={"Withdraw Listing?"}
        message={`Are you sure you want to withdraw this listing? \nThis will remove it from eBay and cannot be undone.`}
        confirmButtonText={"Withdraw Listing"}
        onConfirm={() => handleWithdraw()}
      />
      <ConfirmModal open={applyDefaultsOpen} setOpen={setApplyDefaultsOpen} isLoading={false}
        title={"Apply Defaults to Listing?"}
        message={`Are you sure you want to apply defaults to this listing? \nThis will change all the current information displayed.`}
        confirmButtonText={"Apply Defaults"}
        onConfirm={() => handleApplyDefaults()}
      />
      <div className="space-y-4">

        {/* Move from original form to specifics */}
        {page === 0 ? (
          <form onSubmit={handleSubmit(() => onSubmit(getValues(), false))} >
            <div className="grid grid-cols-1 pb-20 sm:grid-cols-2 gap-4">

              <EbayListingTitle part={part} register={register} getValues={getValues} watch={watch} setValue={setValue} />

              <div className="sm:col-span-2">
                <CustomJoditEditor
                  content={description}
                  setContent={setDescription}
                  placeholder="Create your listing description..."
                  height={300}
                />
              </div>

              <SelectInput
                label={"Listing Format"}
                register={register}
                registerName={"format"}
                options={formatOptions}
                setValue={handleSetFormat}
                watch={watch}
              />

              {getValues("format") === FormatType.Auction ? (
                <SelectInput
                  label={"Duration"}
                  register={register}
                  registerName={"duration"}
                  options={durationOptions}
                  setValue={setValue}
                  watch={watch}
                />
              ) : (
                <div className="sm:col-span-1 h-16">{/* Spacer */}</div>
              )}

              <DateTimeInput
                label={"Listing Start"}
                register={register}
                registerName={"listingStarted"}
                min={DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm")}
              />

              <EbayCategoryDropdown
                categoryId={part.offers.length > 0 ? part.offers[0]?.categoryId : defaultPartCategory.data?.data?.ebayCategoryId}
                register={register}
                registerName={"categoryId"}
                setValue={setValue}
                watch={watch}
              />

              <EbayStoreCategoryDropdown
                categoryId={part.offers.length > 0 ? part.offers[0]?.storeCategoryId : defaultPartCategory.data?.data?.ebayStoreCategoryId}
                register={register}
                registerName={"storeCategoryId"}
                setValue={setValue}
                watch={watch}
              />

              <div>
                {/* spacer */}
              </div>

              {getValues("format") === FormatType.Auction ? (
                <>
                  <NumberInput
                    label={"Starting Price (£)"}
                    register={register}
                    registerName={"auctionStartPrice"}
                  />
                  <NumberInput
                    label={"Reserve Price (£)"}
                    register={register}
                    registerName={"auctionReservePrice"}
                  />
                </>
              ) : (
                <>
                  <NumberInput
                    label={"Buy It Now Price (£)"}
                    register={register}
                    registerName={"buyItNowPrice"}
                  />
                  {bestOfferValue ? (
                    <NumberInput
                      label={"Best Offer Auto Accept Price (£)"}
                      register={register}
                      registerName={"autoAcceptPrice"}
                    />
                  ) : (
                    <div className="sm:col-span-1 h-16">{/* Spacer */}</div>
                  )}
                </>
              )}

              {getValues("format") === FormatType.FixedPrice && (
                <>
                  <div className="sm:col-span-1 mt-4">
                    <input
                      id="enableBestOffer"
                      {...register("enableBestOffer")}
                      type="checkbox"
                      className="mr-2 h-5 w-5"
                      disabled={part.soldDate !== null}
                    />
                    <label htmlFor="enableBestOffer">Allow Best Offer?</label>
                  </div>

                  <div className="sm:col-span-1 h-16">{/* Spacer */}</div>
                </>
              )}

              {/* Policies */}
              <SelectInput
                label={"Shipping Policy*"}
                register={register}
                registerName={"fulfillmentPolicyId"}
                options={policies.data?.data.fulfilmentPolicies}
                setValue={setValue}
                watch={watch}
              />
              <SelectInput
                label={"Payment Policy*"}
                register={register}
                registerName={"paymentPolicyId"}
                options={policies.data?.data.paymentPolicies}
                setValue={setValue}
                watch={watch}
              />
              <SelectInput
                label={"Returns Policy*"}
                register={register}
                registerName={"returnPolicyId"}
                options={policies.data?.data.returnPolicies}
                setValue={setValue}
                watch={watch}
              />

              {/* Locations */}
              <SelectInput
                label={"Location of Part*"}
                register={register}
                registerName={"locationKey"}
                options={locations.data?.data.locations}
                setValue={setValue}
                watch={watch}
              />

              {/* Condition */}
              {getValues("categoryId") ? (
                <EbayConditionDropdown
                  categoryId={getValues("categoryId")}
                  register={register}
                  registerName={"condition"}
                  setValue={setValue}
                  watch={watch}
                />
              ) : (
                <div className="flex flex-col">
                  <label className="mb-2 text-sm font-medium text-gray-700">
                    Condition*
                  </label>
                  <select className="text-sm bg-gray-400" value={1} disabled >
                    <option value={1} disabled>Pick a Category</option>
                  </select>
                </div>

              )}

              {
                watch("condition") < 2000 ? null :
                  <TextInput
                    register={register}
                    registerName={"conditionDescription"}
                    label={"Condtion Description*"}
                    richText
                    rows={1}
                  />
              }

              <div className="sm:col-span-2 mt-4 flex justify-between">
                <div className="flex items-start gap-x-4">
                  {/* Withdraw button shows if listing has been published and not sold */}
                  {part.offers.length > 0 && part.offers[0].publishedOn !== null && !part.soldDate && (
                    <ButtonInput
                      label={"Withdraw Listing"}
                      isSubmit={false}
                      onClick={() => setWithdrawOpen(true)}
                      classes={"!bg-red-500 hover:!bg-red-600"}
                    />
                  )}
                  {part.offers.length > 0 && !part.soldDate && (
                    <div className="flex flex-col">
                      <ButtonInput
                        label={"Apply Defaults"}
                        isSubmit={false}
                        onClick={() => setApplyDefaultsOpen(true)}
                        classes={""}
                        disabled={!defaultPartCategory.data?.data}
                      />
                      {
                        !defaultPartCategory.data?.data &&
                        <span className="text-gray-600 text-sm">No defaults found</span>
                      }
                    </div>
                  )}
                </div>

                <div className="flex items-start gap-4">
                  <ButtonInput
                    label={"Save"}
                    isSubmit={true}
                    onClick={undefined}
                    classes={undefined}
                    disabled={part.soldDate !== null}
                  />
                  <div
                    onMouseOver={() => setIsHovering(true)}
                    onMouseOut={() => setIsHovering(false)}
                    className="relative"
                  >
                    {isHovering && error && (
                      <div className="absolute bottom-10 w-[250px] bg-white border-2 border-gray-200">
                        <p className=" text-red-700 p-2">
                          {error}
                        </p>
                      </div>
                    )}
                    <ButtonInput
                      label={"Save & Next"}
                      isSubmit={false}
                      onClick={() => onSubmit(getValues(), true)}
                      classes={"!bg-white !border-1 !border-gray-400 !text-gray-600 hover:!bg-gray-200"}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>

        ) : (
          <EditListingSpecifics part={part} setPage={setPage} />
        )}

      </div>
    </>
  );
}
