import { Fragment, useEffect, useState } from "react";
import useAutoDap from "../../data/useAutoDap";
import { AutoDapCategory } from "../../models/Part";
import { IAutoDap } from "../../models/AutoDap";
import { TextInput } from "../shared/Inputs/TextInput";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useNotification from "../notifications/useNotifications";
import Card from "../shared/Card/Card";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import PriceFormat from "../../utils/priceFormat";


interface FormValues {
  vin: string;
}

export default function AutoDapSearch() {
  // const {saveHandlers} = useDefaultCRUDHandlers("Job");
  const { register, handleSubmit } = useForm<FormValues>();
  const { addNotification } = useNotification();
  const { autoDapLookup } = useAutoDap();
  const [autoDapData, setAutoDapData] = useState<IAutoDap | undefined>();
  const [autoDapFiltered, setAutoDapFiltered] = useState<IAutoDap | undefined>();
  const [dapCategory, setDapCategory] = useState<AutoDapCategory>(AutoDapCategory["All"]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const categories = [
    ...Object.values(AutoDapCategory).filter((ct) => isNaN(Number(ct)))
  ];

  const handlePartsLookup = (data: FormValues) => {

    const queries = {
      category: Number.isInteger(dapCategory) ? dapCategory : AutoDapCategory[dapCategory],
      vin: data.vin
    }

    autoDapLookup.mutate(queries, {
      onSuccess: (data) => {
        console.log(data);
        addNotification({
          variant: "success",
          primaryText: "Lookup successful"
        })
        setAutoDapData(data.data);
      },
      onError: (err: any) => {
        addNotification({
          variant: "error",
          primaryText: err.message
        })
      }
    })

  }

  useEffect(() => {

    if (autoDapData) {

      //Filter out the parts that don't match the search term
      const filtered = autoDapData.category.map((category) => {
        return {
          ...category,
          assembly: category.assembly.map((assembly) => {
            return {
              ...assembly,
              parts: assembly.parts.filter((part) =>
                part.partname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                part.oenumber.toLowerCase().replace("-", "").replace(" ", "").includes(searchTerm.toLowerCase().replace("-", "").replace(" ", ""))
              )
            }
          })
        }
      })

      // console.log(filtered)
      setAutoDapFiltered({ ...autoDapData, category: filtered })
    }
  }, [autoDapData, searchTerm])

  return (
    <div className="w-full p-4">
      <h1 className="text-lg font-semibold">AutoDapSearch</h1>
      <form onSubmit={handleSubmit(handlePartsLookup)} className="flex items-center gap-x-4">
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-500">
            Choose a category
          </label>
          <select aria-label="Part categories"
            onChange={(e) => setDapCategory(e.target.value as unknown as AutoDapCategory)}
            // value={currentFilter}
            className="sm:text-sm">
            {categories.map((filter) => (
              <option
                value={filter}
                className="sm:text-sm"
                key={filter}>
                {String(filter)}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-2">
          <label className="text-sm font-medium text-gray-500">
            Vin
          </label>
          <input type="text" {...register("vin")} className={"w-full mt-1 text-sm text-gray-900"} />
        </div>

        <ButtonInput label={"Lookup"} isSubmit={true} onClick={() => { }} classes={"mt-5"} />
      </form>

      <div>
        {autoDapData && (
          <div className="mt-4">

            <div className=" mb-4 relative flex items-stretch flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 sm:text-sm"
                placeholder="Part Name or Number"
              />
            </div>

            {autoDapFiltered?.category.map((category, i) => (
              <Fragment key={i}>
                <div>
                  <h1 className="text-2xl font-medium underline">{category.categoryname}</h1>
                </div>
                {category.assembly.map((assembly, j) => (
                  <Fragment key={j}>
                    <div className="space-y-2">
                      {assembly.parts.length > 0 && (
                        <h1 className="text-lg font-medium">{assembly.assemblyname}</h1>
                      )}

                      {assembly.parts.map((part, k) => (
                        <div key={k} className="">
                          <div className="flex gap-x-2">
                            <p>{part.oenumber}</p> -
                            <p>{part.partname}</p> -
                            <p>{part.fitment ? `${part.fitment} -` : ""}</p>
                            <p>{PriceFormat(part.price)}</p>
                          </div>
                          <div className="text-sm">
                            {part.parttype ? `Type: ${part.parttype}` : ""}
                            {part.partinfo ? ` - Info: ${part.partinfo}` : ""}
                          </div>
                        </div>
                      ))}

                      {/* <img src={assembly.image} alt={assembly.assemblyname} useMap={`#${j}`} />

                      <map name={j.toString()}>
                        {assembly.parts.map((part, k) => (
                          part.hotspot && part.hotspot[0] && part.hotspot[0].hotspotx && part.hotspot[0].hotspoty && (
                            <area
                              shape="rect"
                              coords={`${part.hotspot[0].hotspotx[0]}, ${part.hotspot[0].hotspoty[0]}, ${part.hotspot[0].hotspotx[1]}, ${part.hotspot[0].hotspotx[1]}`}
                              alt={part.partname}
                              className="hover:cursor-pointer"
                              onClick={() => console.log(part)}
                            />
                          )
                        ))}
                      </map> */}

                    </div>
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </div>
        )}
      </div>


    </div>
  )
}