import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { XMarkIcon } from "@heroicons/react/24/outline";

import LeadContactDetails from "../../components/contacts/LeadContactDetails";
import LeadPaymentDetails from "./LeadInformation/LeadPaymentDetails";
import LeadStatusCard from "./LeadInformation/LeadStatusCard";
import LoadingWheel from "../../components/shared/LoadingWheel";
import VehicleDetails from "../../components/vehicles/VehicleDetails";
import VehicleLocation from "../../components/vehicles/VehicleLocation";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useSingleLead from "../../data/useSingleLead";
import { ILead } from "../../models/Lead";
import getAddress from "../../utils/getAddress";
import DriverNotes from "./LeadInformation/DriverNotes";
import OfficeNotes from "./LeadInformation/OfficeNotes";
import VehicleNotes from "./LeadInformation/VehicleNotes";
import LeadIdentityImages from "./LeadInformation/LeadIdentityImages";
import EnginesWorldwideDetails from "../vehicles/EnginesWorldwideDetails";


export default function LeadModal({ leadId, setOpen }: { leadId: string, setOpen: (open: boolean) => void }) {
  const navigate = useNavigate();
  const { setInfo } = usePageNameContext();
  const { lead: singleLead, remove } = useSingleLead(leadId);
  const [lead, setLead] = useState<ILead | undefined>();
  const [loading, setLoading] = useState(true);
  const [leadName, setLeadName] = useState<string | undefined>("");

  useEffect(() => {
    if (!singleLead.isLoading) {

      if (singleLead.data?.data) {
        console.log(singleLead.data?.data);
        setLead(singleLead.data?.data);
        setLeadName(singleLead.data?.data?.contact?.fullname)
        setLoading(false);
      } else {
        //TODO: Probably add some sort of error notif
        navigate(-1);
      }
    }
  }, [
    leadId,
    singleLead.isLoading,
    singleLead.data?.data,
    navigate,
  ]);

  return loading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <div className="flex flex-col md:grid md:grid-cols-3 gap-6">
        <div className="col-span-3">
          <XMarkIcon onClick={() => setOpen(false)} className="w-8 h-8 cursor-pointer float-right" />
        </div>
        {/* Left hand thin column */}
        <div className="col-span-1 gap-6 flex flex-col">
          <div className="col-span-1 bg-white rounded-lg shadow">
            <iframe
              className="min-h-[50vh]"
              title="Google map"
              style={{ border: 0, borderRadius: "0.5rem" }}
              loading="lazy"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAJWEQovZtn6KkghJ5z6maiL0G6KHjwbS4&q=${getAddress(lead?.vehicle)}&zoom=9`}
              width="100%"
              height="100%"
            ></iframe>
          </div>

          <VehicleDetails vehicle={lead?.vehicle!} />
          {lead?.vehicle?.enginesWorldwide.map((offer) => <EnginesWorldwideDetails offer={offer} />)}
        </div>

        {/* Right hand wide column */}
        <div className="col-span-2 gap-6 flex flex-col">
          <LeadContactDetails lead={lead!} />
          <VehicleLocation vehicle={lead?.vehicle!} />
          <LeadStatusCard leadId={lead?.id!} />
          <LeadIdentityImages lead={lead!} />
          <LeadPaymentDetails lead={lead!} />
        </div>
        <OfficeNotes lead={lead!} />
        <DriverNotes lead={lead!} />
        <VehicleNotes lead={lead!} />

      </div>
    </>
  );
}
