import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ICategoryPartResponse, IPart, IPartCollectionResponse, IPartRefundSentRequest } from "../models/Part";
import { IPagedData } from "../components/shared/Tables/PagedTable";
import { EbayResponseWrapper } from "../models/system";
import { IEbayReturn, IEbayReturnsCollection, ISimpleRefundReturnRequest } from "../models/EbayReturns";
import { IEbayReturnFilesResponse, IFullEbayReturnResponse } from "../models/EbayReturnsFull";

export default function useEbayReturns(ebayReturn: IEbayReturn | undefined) {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const returnFull = useQuery({
    queryKey: ["ebayReturns", ebayReturn?.returnId],
    queryFn: () => get<IFullEbayReturnResponse>(`/ebaysafe/returns/${ebayReturn?.returnId}`),
    enabled: ebayReturn !== undefined,
  });

  const returnFiles = useQuery({
    queryKey: ["ebayReturns", ebayReturn?.returnId, "files"],
    queryFn: () => get<IEbayReturnFilesResponse>(`/ebaysafe/returns/${ebayReturn?.returnId}/files`),
    enabled: ebayReturn !== undefined,
    onSuccess: (data) => {
      console.log(data);
    }
  });

  const partsForOrder = useQuery({
    queryKey: ["ebayOrders", ebayReturn?.orderId],
    queryFn: () => get<IPartCollectionResponse>(`/part/listings/byorder/${ebayReturn?.orderId}`),
    enabled: ebayReturn !== undefined,
  });

  const decideRefund = useMutation({
    mutationFn: (refund: ISimpleRefundReturnRequest) => post<any>(`/ebaysafe/returns/decide/${ebayReturn?.orderId}`, refund),
    onSuccess: () => queryClient.invalidateQueries(["ebayOrders", ebayReturn?.orderId]),
  });

  const markRefundAs = useMutation({
    mutationFn: (as: string) => post<any>(`/ebaysafe/returns/markas/${ebayReturn?.orderId}?markAS=${as}`, {}),
    onSuccess: () => queryClient.invalidateQueries(["ebayOrders", ebayReturn?.orderId]),
  });

  const markRefundSent = useMutation({
    mutationFn: (req: IPartRefundSentRequest) => post<any>(`/ebaysafe/returns/refundsent/${ebayReturn?.orderId}`, req),
    onSuccess: () => queryClient.invalidateQueries(["ebayOrders", ebayReturn?.orderId]),
  });

  const sendMessage = useMutation({
    mutationFn: (message: string) => post<any>(`/ebaysafe/returns/message/${ebayReturn?.orderId}`, { message: message }),
    onSuccess: () => queryClient.invalidateQueries(["ebayOrders", ebayReturn?.orderId]),
  });

  return {
    returnFull, returnFiles, partsForOrder, decideRefund, markRefundAs, markRefundSent, sendMessage
  };
}
