import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import { CameraIcon } from "@heroicons/react/24/outline";
import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { IPart, PartPosition, PartSide } from "../../models/Part";
import classNames from "../../utils/classNames";
import Pagination from "../shared/Tables/Pagination";
import MovePartsModal from "./MovePartsModal";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer'}
      {...rest}
    />
  )
}

const colHelper = createColumnHelper<IPart>();

const cols = [
  {
    id: 'select',
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div className="flex justify-center items-center flex-1 p-4" onClick={(e) => e.stopPropagation()}>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  colHelper.display({
    id: "image",
    cell: (info) => (
      <span className="flex justify-center relative" >
        {info.row.original.cdnThumbnail ? (
          <>
            <img
              className="w-32 h-20 object-contain"
              src={`${info.row.original.cdnThumbnail != null ? info.row.original.cdnThumbnail : null}`}
            />
          </>
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
      </span>
    ),
  }),
  colHelper.accessor("titleSanitised", {
    header: "Title",
    sortingFn: "auto",
  }),
  colHelper.accessor("side", {
    header: "Side",
    cell: (info) => <span>{PartSide[info.getValue()]}</span>,
  }),
  colHelper.accessor("position", {
    header: "Position",
    cell: (info) => <span>{PartPosition[info.getValue()]}</span>,
  }),
  colHelper.accessor("price", {
    header: "Price",
    cell: (info) => <span>£{info.getValue()}</span>,
  }),
  colHelper.accessor("margin", {
    header: "Margin",
    cell: (info) => <span>£{info.getValue()}</span>,
  }),
  colHelper.accessor("partNumber", {
    header: "Part Number",
  }),
];


export default function PartsEditorTable({ parts, setPart }: { parts: IPart[], setPart: any }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [movePartsOpen, setMovePartsOpen] = useState(false);

  useEffect(() => {
    table.setPageSize(50);
  }, []);

  const table = useReactTable({
    data: parts,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <MovePartsModal open={movePartsOpen} setOpen={setMovePartsOpen} parts={table.getSelectedRowModel().flatRows.map((row: any) => row.original)} />
      <div className="flex justify-between">
        <button
          className={classNames(table.getSelectedRowModel().flatRows.length === 0 ? "bg-gray-200" : "bg-gp-blue-600 hover:bg-gp-blue-700", "mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm")}
          type="button"
          disabled={table.getSelectedRowModel().flatRows.length === 0}
          onClick={() => setMovePartsOpen(true)}
        >
          Move Parts
        </button>
      </div>
      <div className="mt-4 bg-white overflow-auto">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-gp-blue-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ▲',
                            desc: ' ▼',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>

                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    onClick={() => setPart(row.original)}
                    className={"hover:bg-gray-100 hover:cursor-pointer"}
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-3 py-3 border text-sm">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <></>
                    // <tr>
                    //   {/* 2nd row is a custom 1 cell row */}
                    //   <td colSpan={row.getVisibleCells().length}>
                    //     <LeadsTableSubComponent lead={row.original} />
                    //   </td>
                    // </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
        <Pagination table={table} />
      </div>
    </>
  );
}
