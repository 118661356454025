import { Combobox, Transition } from "@headlessui/react";
import Modal from "../../../layouts/Modal";
import useCategories from "../../../data/useCategories";
import { Fragment, useEffect, useState } from "react";
import LoadingWheel from "../../shared/LoadingWheel";
import classNames from "../../../utils/classNames";
import { IPartCategory } from "../../../models/Part";

interface PartCategoryComboProps {
  addManualOpen: boolean;
  setAddManualOpen: any;
  handleAddCategory: any;
}

export default function PartCategoryCombo({ addManualOpen, setAddManualOpen, handleAddCategory }: PartCategoryComboProps) {
  const { categories: allCategories } = useCategories();
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [categories, setCategories] = useState<any>(allCategories!);

  useEffect(() => {
    if (!allCategories.isLoading) {
      setCategories(allCategories.data?.data);
    }

    if (!allCategories.isLoading && searchTerm.length > 0) {
      setCategories(
        allCategories.data?.data.filter((category) =>
          category.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [allCategories.isLoading, allCategories.data, searchTerm])

  if (allCategories.isLoading) {
    return <LoadingWheel />;
  }

  return (
    <Modal open={addManualOpen} width={"max-w-6xl"} setOpen={setAddManualOpen}>
      <div className="">
        <Combobox
          onChange={(category: IPartCategory) => handleAddCategory(category)}
        >
          {({ open }) => (
            <>
              <Combobox.Label className="block text-sm font-medium text-gray-700">
                Part Categories
              </Combobox.Label>
              <div className="relative mt-1">
                <Combobox.Input
                  className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-gp-blue-500 focus:outline-none focus:ring-1 focus:ring-gp-blue-500 sm:text-sm"
                  onChange={(e) =>
                    setSearchTerm(e.target.value)
                  }
                  displayValue={searchTerm}
                  placeholder="Start typing to search for a category"
                />
                <Transition
                  show={open}
                  appear={true}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Combobox.Options
                    static
                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  >
                    {categories.length! > 0 && searchTerm.length > 0 ? (
                      categories.map((category: IPartCategory, i: number) => (
                        <Combobox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-gp-blue-600"
                                : "text-gray-900",
                              "cursor-default select-none relative py-2 pl-8 pr-4"
                            )
                          }
                          key={i}
                          value={category}
                        >
                          <span className="block truncate">
                            {category.name}
                          </span>
                        </Combobox.Option>
                      ))
                    ) : (
                      <Combobox.Option
                        disabled
                        className="cursor-default select-none relative py-2 text-center text-gray-500"
                        value={null}
                      >
                        No categories match the search terms
                      </Combobox.Option>
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </>
          )}
        </Combobox>
      </div>
    </Modal>
  )
}