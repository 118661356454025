import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ITopUnlistedCategoriesResponse } from "../models/Part";

export default function usePartMutations() {
  const { get, post, put, patch, postDocs } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes

  const markDamaged = useMutation(
    (partData: any) => patch(`/part/${partData.id}`, partData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["job"]);
        queryClient.invalidateQueries(["parts"]);
      },
    }
  );

  const patchPart = useMutation(
    (partData: any) => patch(`/part/${partData.id}`, partData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["job"]);
        queryClient.invalidateQueries(["parts"]);
        queryClient.invalidateQueries(["pagedTable"]);
      },
    }
  );

  const autoReorderImages = useMutation(
    (ids: any) => post(`/part/images/autoOrder`, ids),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["job"]);
        queryClient.invalidateQueries(["parts"]);
        queryClient.invalidateQueries(["pagedTable"]);
      },
    }
  );

  const topUnlisted = useMutation(
    () => get<ITopUnlistedCategoriesResponse>(`/part/stats/top/unlisted`),
    {
      onSuccess: (data, _, __) => {
        return data;
      },
    }
  );

  const createOffer = useMutation(
    (partId: string) => post(`/part/offers/default/${partId}`, null),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["parts", partId]);
        queryClient.invalidateQueries(["parts", "multiple"]);
      },
    }
  );


  return {
    markDamaged,
    patchPart,
    autoReorderImages,
    topUnlisted,
    createOffer
  };
}
