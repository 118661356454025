import { useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IEbayCategoriesResponse, IEbayStoreCategoryResponse } from "./../models/EbayOffer";

export default function useEbayCategories() {
  const { get } = useApiHelper();

  // const categories = useQuery({
  //   queryKey: ["ebayCategories", title],
  //   queryFn: () =>
  //     get<IEbayCategoriesResponse>(
  //       `/ebay/categories?${title ? `query=${title}` : ""}`
  //     ).then((res) => res.data),
  // });

  const categories = useQuery({
    queryKey: ["ebayCategories"],
    queryFn: () =>
      get<IEbayCategoriesResponse>(
        `/ebay/vehiclecategories`
      ).then((res) => res.data),
    staleTime: 1000 * 60 * 5, //5 mins
  }
  );

  const storeCategories = useQuery({
    queryKey: ["ebayStoreCategories"],
    queryFn: () =>
      get<IEbayStoreCategoryResponse>(
        `/ebay/store/categories`
      ).then((res) => res.data),
    staleTime: 1000 * 60 * 5, //5 mins
  }
  );

  return {
    categories,
    storeCategories
  };
}
