import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartAspectsResponse } from "../models/EbayOffer";

export default function usePartAspects(partId: string) {
  const { get, post, put, patch, del, postDocs } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes

  const partAspects = useQuery({
    queryKey: ["partAspects", partId],
    queryFn: () => get<IPartAspectsResponse>(`/part/${partId}/aspects`),
    staleTime: staleTime,
    refetchOnWindowFocus: false,
    refetchInterval: staleTime,
  });

  const createUpdate = useMutation(
    (body: any) => post(`/part/aspects`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partAspects", partId]);
      },
    }
  );

  const remove = useMutation(
    (aspectId: string) => del(`/part/aspects/${aspectId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partAspects", partId]);
      },
    }
  );

  return {
    partAspects,
    createUpdate,
    remove
  };
}
