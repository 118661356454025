import useLeadDocuments from "../../data/useLeadDocuments";

export default function CarCollectedImage({ leadId }: { leadId: string }) {
  const { leadDocuments } = useLeadDocuments(leadId);

  if (leadDocuments.isLoading || !leadDocuments.data) return <></>;

  const frontImage = leadDocuments.data.data.find(img => img.fileName?.includes("front"));

  console.log(frontImage);
  return (
    <span className="flex justify-center relative" >
      {frontImage ? (
        <>
          <img
            className="w-32 h-20 object-contain"
            src={`data:${frontImage.contentType};base64, ${frontImage.thumbnail}` || ""}
          />
        </>
      ) : (
        "N/A"
      )}
    </span>
  )
}