import { useQueryClient } from "@tanstack/react-query";

import useLocations from "../../data/useLocations";
import useVehiclesMutations from "../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { IVehicle } from "../../models/Vehicle";

export default function VehicleLocationDropdown({ vehicle, classes = "" }: { vehicle: IVehicle, classes?: string }) {
  const queryClient = useQueryClient();
  const { locations } = useLocations();
  const { update } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Vehicle Location");

  const handleLocationUpdate = (locationId: string) => {

    const updatedVehicle: IVehicle = {
      ...vehicle,
      locationId: locationId ? locationId : null,
    };

    update.mutate(updatedVehicle, {
      onSuccess: () => {
        queryClient.refetchQueries(["leads"]);
        saveHandlers.onSuccess();
      },
      onError: (error) => {
        saveHandlers.onError();
      }
    });
  }

  if (locations.isLoading) {
    return <></>
  }

  return (
    <div className="w-full h-full flex items-center" onClick={(e) => e.stopPropagation()}>
      <select value={vehicle.locationId ? vehicle.locationId : ""} className={`${classes} border p-1`} onChange={(e) => handleLocationUpdate(e.target.value)}>
        <option value="">No Location</option>
        {locations.data?.data && locations.data?.data.map((location) => (
          <option key={location.id} value={location.id}>
            {location.name}
          </option>
        ))}
      </select>
    </div>
  )
}
