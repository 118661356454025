import { useState } from "react";

import { useForm } from "react-hook-form";

import useEbayAspects from "../../data/useEbayAspects";
import usePartDefaultCategoryAssignees from "../../data/usePartDefaultCategoryAssignees";
import { IPartCategory } from "../../models/Part";
import { RadioGroupInput } from "../shared/Inputs/RadioGroupInput";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";


const assigneeOptions = [
  { name: "None", value: 0 },
  { name: "Imager", value: 1 },
  { name: "Dismantler", value: 2 }
];

interface IFormValues {
  assignee: number;
}

const IndividualAspect = ({ aspect, category }: { aspect: any, category: IPartCategory | undefined }) => {
  const { addAssignee, removeAssignee } = usePartDefaultCategoryAssignees();
  const { saveHandlers } = useDefaultCRUDHandlers("Default Assignee");
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm<IFormValues>({
    defaultValues: {
      assignee: category?.partCategoryDefaults[0]?.aspectAssignees?.find((a) => a.aspectName === aspect.localizedAspectName)?.assignee || 0
    }
  });

  const handleSetValue = (registerName: string, value: any) => {

    //Do an update mutation here
    //create or remove the default assignee for this aspect
    const body = {
      defaultCategoryId: category?.partCategoryDefaults[0]?.id,
      aspectName: aspect.localizedAspectName,
      assignee: value,
      imageUrl: null,
      isImageRequired: false
    };

    addAssignee.mutate(body, {
      onSuccess: () => {
        setValue("assignee", value);
        saveHandlers.onSuccess();
      },
      onError: () => {
        saveHandlers.onError();
      }
    });


    // setValue("assignee", value);
  };

  return (
    <div className="grid grid-cols-2 border-0 border-b pb-4">
      <div className="flex items-center">
        <label className="font-medium text-gray-500">{aspect.localizedAspectName}</label>
      </div>
      <div>
        <RadioGroupInput options={assigneeOptions} register={register} registerName={"assignee"} setValue={handleSetValue} watch={watch} />
      </div>
    </div>
  )
};


export default function CategoryAspectsDefaults({ category }: { category: IPartCategory | undefined }) {
  const [ebayCategoryId, setEbayCategoryId] = useState<string | undefined>(category?.partCategoryDefaults[0]?.ebayCategoryId!);
  const { aspects: ebayAspects } = useEbayAspects(ebayCategoryId!);


  return (
    <div className="mt-6">
      <h1 className="text-lg mb-4">eBay Item Aspect Defaults</h1>
      <div className="border-t border-gray-200 pt-4 flex flex-col gap-4 sm:gap-y-6">

        {ebayAspects?.data?.map((aspect, i) => (
          <IndividualAspect key={i} aspect={aspect} category={category} />
        ))}
      </div>
    </div>
  );
};