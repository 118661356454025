import { useQueryClient } from "@tanstack/react-query";

import usePartMutations from "../../../data/usePartMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { IPart, IPartImageType } from "../../../models/Part";
import { CameraIcon } from "@heroicons/react/24/outline";
import classNames from "../../../utils/classNames";


export default function UnlistedNpmUpdate({ part, onTable = true }: { part: IPart, onTable?: boolean }) {
  const queryClient = useQueryClient();
  const { patchPart } = usePartMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Part Number");

  const handleMpnUpdate = (mpn: string) => {
    const patchBody = {
      id: part.id,
      partNumber: mpn,
    };

    if (part.partNumber === mpn) return;
    if (!mpn) return;

    patchPart.mutate(patchBody, saveHandlers);
  }

  return (
    <div className="w-full h-full flex flex-col" onClick={(e) => e.stopPropagation()}>
      <div className={classNames(onTable ? "items-center" : "", "flex flex-col justify-center relative")}>
        <span>
          {part.images?.length > 0 && part.images.some((image) => image.imageType === IPartImageType.MPN) ? (
            <img
              className="w-28 h-20 object-contain"
              src={`${part.images.find((image) =>
                image.imageType === IPartImageType.MPN)?.url != null ?
                part.images.find((image) => image.imageType === IPartImageType.MPN)?.url :
                null}`}
            />
          ) : (
            <CameraIcon className="w-10 text-gray-600" />
          )}
        </span>
        {!part.partNumber && <p className="text-xs text-gray-500">No MPN</p>}
        <div className="flex ">
          <input
            key={part.id}
            defaultValue={part.partNumber ?? ""}
            className={classNames(onTable ? "w-full text-xs h-6" : "w-1/2", "border p-1 ")}
            type="text"
            onBlur={(e) => handleMpnUpdate(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}