import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import PartsEditorTable from "../../components/partsEditor/PartsEditorTable";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import PopOutBox from "../../components/shared/PopOutBox";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useCategories from "../../data/useCategories";
import useCategoryParts from "../../data/useCategoryParts";
import { IPart, IPartCategory } from "../../models/Part";

export default function PartsEditor() {
  const { setInfo } = usePageNameContext();
  const { categoryId } = useParams();
  const { categories: allCategories } = useCategories();
  const { categoryParts } = useCategoryParts(categoryId!);
  const [parts, setParts] = useState<IPart[] | undefined>(undefined);
  const [part, setPart] = useState<IPart | undefined>(undefined);
  const [category, setCategory] = useState<IPartCategory | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [includes, setIncludes] = useState("");
  const [excludes, setExcludes] = useState("");
  const [regex, setRegex] = useState("");
  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Parts Editor",
      desc: "View and edit your parts here",
    });
  }, []);

  useEffect(() => {
    if (!allCategories.isLoading && allCategories.data && !categoryParts.isLoading && categoryParts.data) {

      setCategory(allCategories.data?.data.find((category) =>
        category.id === categoryId
      ));

      if (includes.length > 0 || excludes.length > 0) {
        let includesFormatted = includes.replace(/\s+/g, '').split(",").join("|");
        let excludesFormatted = excludes.replace(/\s+/g, '').split(",").join("|");

        let newRegex = "";

        if (excludes.length > 0 && includes.length > 0) {
          newRegex = `(?<!(${excludesFormatted}).*)(?:${includesFormatted})(?!.*(${excludesFormatted}))`;
        } else if (excludes.length > 0 && includes.length === 0) {
          newRegex = `^(?!.*(${excludesFormatted})).*$`
        } else {
          newRegex = `(?:${includesFormatted})`;
        }

        setRegex(newRegex);

        let regexExp = new RegExp(newRegex, "i");

        let filter = categoryParts.data?.data.filter((part) =>
          part.titleSanitised?.match(regexExp)
        )

        setParts(filter);
      } else {
        setRegex("");
        setParts(categoryParts.data?.data);
      }

      setIsLoading(false);
    }
  }, [allCategories.isLoading, allCategories.data, categoryParts.isLoading, categoryParts.data, includes, excludes, categoryId]);


  const handleSetPart = (part: IPart) => {
    setPart(part);
    setCreateOpen(true);
  };

  return allCategories.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <div className="mt-4">
        <h1 className="text-lg">Category: {category?.name}</h1>
      </div>
      <div className="mt-4 flex justify-between">
        <div className="flex gap-x-2">
          <input
            type="text"
            onChange={(e) => setIncludes(e.target.value)}
            className="sm:text-sm"
            placeholder="Include"
          />
          <input
            type="text"
            onChange={(e) => setExcludes(e.target.value)}
            className="sm:text-sm"
            placeholder="Exclude"
          />

          <input
            type="text"
            value={regex}
            className="sm:text-sm text-gray-500"
            placeholder="Regex"
            disabled
          />
          <ButtonInput label={"Copy"} isSubmit={false} classes="" onClick={() => navigator.clipboard.writeText(regex)} />
          <PopOutBox classes="w-96">
            <p>Comma separate your include and exclude words to generate regex and see the results in real time.</p>
            <p>E.g: Includes - petrol, pump, compressor</p>
            <p>Excludes - diesel, door, mirror</p>
          </PopOutBox>
        </div>
      </div>
      <PartsEditorTable parts={parts!} setPart={handleSetPart} />
    </>
  );
}
