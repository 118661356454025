import { useEffect, useMemo, useRef, useState } from "react";

import { DateTime } from "luxon";

import FullCalendar from "@fullcalendar/react";
import adaptivePlugin from "@fullcalendar/adaptive";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, {
  Draggable,
  EventReceiveArg,
} from "@fullcalendar/interaction";

import Modal from "../../../../layouts/Modal";
import { ButtonInput } from "../../../shared/Inputs/ButtonInput";
import useLeads from "../../../../data/useLeads";
import { IVehicle } from "../../../../models/Vehicle";
import useVehiclesMutations from "../../../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../../../hooks/useDefaultCRUDHandlers";
import LoadingWheel from "../../../shared/LoadingWheel";
import { DatesSetArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import useLeadsWithFilters from "../../../../data/useLeadsWithFilters";

function DropOffCalendar() {
  const calendarRef = useRef(null);
  const { saveHandlers } = useDefaultCRUDHandlers("Lead");
  const { update: updateLead } = useLeads();
  const { leadsWithFilters } = useLeadsWithFilters({
    query: "",
    pageFilter: 4,
  });
  const { update: updateVehicle } = useVehiclesMutations();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const scheduled = useMemo(
    () =>
      leadsWithFilters.data?.data?.leads.filter(
        (l) => l.vehicle?.scheduledCollectionOn !== null
      ),
    [leadsWithFilters.data]
  );

  const unscheduled = useMemo(
    () =>
      leadsWithFilters.data?.data?.leads.filter(
        (l) => l.vehicle?.scheduledCollectionOn === null
      ),
    [leadsWithFilters.data]
  );

  const handleEventChange = (e: EventReceiveArg) => {
    const event = e.event;
    let eventEnd = event.end;
    let eventStart = event.start;

    const lead = leadsWithFilters.data?.data.leads.find(
      (l) => l.id === event.id
    );

    if (!lead) {
      //TODO: Notif here to say failed
      return;
    }

    setIsSubmitting(true);

    if (!eventEnd) {
      let end = DateTime.fromJSDate(eventStart!);
      end = end.plus({ hours: 1 });
      eventEnd = end.toJSDate();
    }

    const vehicle: IVehicle = {
      ...lead?.vehicle!,
      scheduledCollectionOn: eventStart?.toISOString()!,
    };

    var updatedLead = {
      ...lead,
      vehicle: vehicle,
    };

    updateVehicle.mutate(vehicle, {
      onSuccess: () => {
        updateLead.mutate(updatedLead, {
          ...saveHandlers,
          onSettled: () => setIsSubmitting(false),
        });
      },
      onError: () => {
        setIsSubmitting(false);
        saveHandlers.onError();
      },
    });
  };

  useEffect(() => {
    var containerEl = document.getElementById("job-events");
    if (containerEl) {
      new Draggable(containerEl!, {
        itemSelector: ".fc-event",
        eventData: (eventEl) => {
          return {
            title: eventEl.innerText,
            id: eventEl.id,
          };
        },
      });
    }
  }, [leadsWithFilters.isLoading]);

  if (leadsWithFilters.isLoading) {
    return <LoadingWheel />;
  } else {
    return (
      <>
        <div
          id="job-events"
          className="mb-6 grid lg:grid-cols-5 grid-cols-3 gap-2"
        >
          {isSubmitting && (
            <div className="absolute inset-0 bg-white bg-opacity-50 flex justify-center items-center z-10">
              <LoadingWheel />
            </div>
          )}
          {unscheduled!.map((lead) => (
            <div
              className="fc-event col-span-1 text-sm flex flex-row items-center space-x-2 bg-red-200 p-1 rounded-md cursor-pointer"
              title={lead.vehicle?.vrm}
              id={lead.id}
              key={lead.id}
            >
              <span>
                {lead.vehicle?.hasAlert && "⚠️"}
                {lead.vehicle?.isVan && "🚚"}
              </span>
              <span>
                {lead.vehicle?.vrm
                  ? `${lead.vehicle.vrm}, ${lead.vehicle.postcode ?? ""} `
                  : "No VRM"}
              </span>
            </div>
          ))}
        </div>
        <FullCalendar
          ref={calendarRef}
          allDaySlot={false}
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          viewClassNames={"timeline"}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            adaptivePlugin,
          ]}
          initialView="timeGridWeek"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "",
          }}
          titleFormat={{
            month: "long",
            year: "numeric",
            day: "numeric",
            weekday: "long",
          }}
          editable={true}
          defaultTimedEventDuration="01:00:00"
          eventDurationEditable={true}
          eventStartEditable={true}
          nowIndicator={true}
          contentHeight="auto"
          slotMinTime="06:00:00"
          slotMaxTime="20:00:00"
          events={scheduled?.map((t) => ({
            id: t.id,
            title: t.vehicle?.vrm,
            start: t.vehicle?.scheduledCollectionOn!,
          }))}
          eventReceive={handleEventChange}
        />
      </>
    );
  }
}

interface BookDropOffModalProps {}

export function BookDropOffModal({}: BookDropOffModalProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <ButtonInput
        label="Book Drop Off"
        isSubmit={false}
        onClick={(e: React.MouseEvent) => handleOpen(e)}
        classes=""
      />
      <Modal width="max-w-7xl" open={open} setOpen={setOpen}>
        <div className="mb-6 flex justify-between">
          <h1 className="text-xl">Book Drop Off</h1>
        </div>
        <DropOffCalendar />
      </Modal>
    </>
  );
}
