import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead } from "../models/Lead";
import { IUnreadMessageResponse, IUnreadMessages } from "../models/Message";

export default function useMessages() {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 10;

  const unreadMessages = useQuery({
    queryKey: ["unreadMessages"],
    queryFn: () => get<IUnreadMessageResponse>(`/message/unread`),
    staleTime: staleTime,
    refetchInterval: staleTime,
  });

  const sendText = useMutation(
    (text : any) => post(`/message`, text),
    {
      onSuccess: () => {
      },
    }
  );

  return {
    unreadMessages,
    sendText
  };
}
