import classNames from "../../../utils/classNames"

export const DateTimeInput = ({ label, register, registerName, disabled = false, min = "" }) => {

  return (
    <div className="sm:col-span-1">
      <label className="text-sm font-medium text-gray-500">
        {label}
      </label>
      <input
        disabled={disabled} {...register(registerName)} type="datetime-local" min={min}
        className={classNames(disabled ? "bg-gray-300" : "", "w-full mt-1 text-sm text-gray-900 border-1 border-gray-400")}
      />
    </div>
  )
}