import { useState } from "react";
import useVehiclesMutations from "../../data/useVehiclesMutations";
import { IVehicle } from "../../models/Vehicle";
import useNotification from "../notifications/useNotifications";
import { TextInput } from "../shared/Inputs/TextInput";
import { useForm } from "react-hook-form";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import LoadingWheel from "../shared/LoadingWheel";
import useVehicleLookupParts from "../../data/useVehicleLookupParts";
import { IPart } from "../../models/Part";
import openInNewTab from "../../utils/openInNewTab";

function FindMatchingParts({ vrm }: { vrm: string }) {
  const { parts } = useVehicleLookupParts(vrm);
  const [showParts, setShowParts] = useState(false);

  if (!showParts) {
    return (
      <ButtonInput label="Show In Stock Matching Parts" isSubmit={false} onClick={() => setShowParts(true)} classes={undefined} />
    );
  }

  if (parts.isLoading) {
    return (
      <LoadingWheel />
    );
  }

  if (parts.data?.data.body.length === 0 && parts.data?.data.mech.length === 0) {
    return (
      <div>
        No in stock parts found
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-lg font-semibold">Matching Stock</h2>
        <table className="min-w-full">
          <thead className="text-left">
            <tr>
              <th>Thumbnail</th>
              <th>Part</th>
              <th>Tag Number</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {
              parts.data?.data.body.map((part, i) => (
                <tr key={part.id} className="cursor-pointer hover:bg-gray-100 border-b" onClick={() => openInNewTab(`/stock/${part.id}`)}>
                  <td className="py-2">{part.images.length > 0 ? <img src={part.images[0].url} className="max-w-[5rem]"/> : <p>N/A</p>}</td>
                  <td>{part.title}</td>
                  <td>{part.tagNumber}</td>
                  <td>Body</td>
                </tr>
              ))
            }
            {
              parts.data?.data.mech.map((part, i) => (
                <tr key={part.id} className="cursor-pointer hover:bg-gray-100 border-b" onClick={() => openInNewTab(`/stock/${part.id}`)}>
                  <td className="py-2">{part.images.length > 0 ? <img src={part.images[0].url} className="max-w-[5rem]"/> : <p>N/A</p>}</td>
                  <td>{part.title}</td>
                  <td>{part.tagNumber}</td>
                  <td>Mechanical</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {parts.data?.data.body !== null && parts.data?.data.body.length! > 0 && (
          <div>
            <h3 className="font-semibold">Body Parts</h3>
            <MatchingParts parts={parts.data?.data.body!} />
          </div>
        )}
        {parts.data?.data.mech !== null && parts.data?.data.mech.length! > 0 && (
          <div>
            <h3 className="font-semibold">Mechanical Parts</h3>
            <MatchingParts parts={parts.data?.data.mech!} />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default function VehicleSearch() {
  const { vrmLookup } = useVehiclesMutations();
  const { addNotification } = useNotification();
  const { register, handleSubmit } = useForm<any>();
  const [vehicle, setVehicle] = useState<IVehicle | undefined>(undefined);
  const [vrm, setVrm] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const onSubmit = (data: any) => {
    setIsLoading(true);

    vrmLookup.mutate(data.vrm, {
      onSuccess: (data: any) => {
        console.log(data.data)
        addNotification({
          variant: "success",
          primaryText: "Found vehicle",
        });
        setVehicle(data.data);
      },
      onError: (error) => {
        console.error(error);
        addNotification({
          variant: "error",
          primaryText: "Error looking up VRM",
        });
      },
      onSettled: () => {
        setIsLoading(false);
      }
    });
  }

  return (
    <div className="px-4">
      <h1 className="mb-2 text-lg font-semibold">Vehicle Lookup</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-2 mb-4 grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-4 sm:grid-cols-2">
        <TextInput label="VRM" register={register} registerName="vrm" />
        <div className="flex items-center mt-7">
          <ButtonInput label="Find Vehicle" isSubmit={true} classes="w-40 h-10" onClick={() => { }} />
        </div>
      </form>

      {(isLoading && !vehicle) ? (
        <LoadingWheel />
      ) : (
        vehicle && (
          <>
            <div className="pb-6">
              <dl className="grid grid-cols-2 lg:grid-cols-4 divide-gray-200">
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">Make/Model</dt>
                  <dd className="text-sm text-gray-900 col-span-2">
                    {vehicle.combinedMake} {vehicle.combinedModel}
                  </dd>
                </div>
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">VRM</dt>
                  <dd className="flex text-sm text-gray-900 col-span-2">
                    <div>{vehicle.vrm}</div>
                  </dd>
                </div>
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">Year</dt>
                  <dd className="text-sm text-gray-900 col-span-2">
                    {vehicle.dvlaYearOfManufacture}
                  </dd>
                </div>
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">Engine Size</dt>
                  <dd className="text-sm text-gray-900 col-span-2">
                    {vehicle.combinedEngineCapacity}cc
                  </dd>
                </div>
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">Fuel Type</dt>
                  <dd className="text-sm text-gray-900 col-span-2">
                    {vehicle.combinedFuelType}
                  </dd>
                </div>
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">Transmission</dt>
                  <dd className="text-sm text-gray-900 col-span-2">
                    {vehicle.combinedForwardGears} Speed {vehicle.combinedTransmission}
                  </dd>
                </div>
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">Engine Code</dt>
                  <dd className="text-sm text-gray-900 col-span-2">
                    {vehicle.engineModelCode}
                  </dd>
                </div>
                <div className="py-2 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-500">Chassis No</dt>
                  <dd className="text-sm text-gray-900 col-span-2">
                    {vehicle.combinedVIN}
                  </dd>
                </div>
              </dl>
            </div>

            <FindMatchingParts vrm={vehicle.vrm} />
          </>
        )
      )}

    </div>
  )
}