import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";

import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { IPart } from "../../models/Part";
import classNames from "../../utils/classNames";
import openInNewTab from "../../utils/openInNewTab";
import Pagination from "../shared/Tables/Pagination";
import { FormatType } from "../../models/EbayOffer";
import { ListingStatus } from "../../pages/EbayListings/ViewListing";
import formatDateTime from "../../utils/formatDateTime";
import { DateTime } from "luxon";
import PagedTable from "../shared/Tables/PagedTable";
import { PageMode } from "../../models/PagedTable";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer'}
      {...rest}
    />
  )
}

const colHelper = createColumnHelper<IPart>();

const cols = [
  {
    id: 'select',
    // header: ({ table }: any) => (
    //   <IndeterminateCheckbox
    //     {...{
    //       checked: table.getIsAllRowsSelected(),
    //       indeterminate: table.getIsSomeRowsSelected(),
    //       onChange: table.getToggleAllRowsSelectedHandler(),
    //     }}
    //   />
    // ),
    cell: ({ row }: any) => (
      <div className="flex justify-center items-center flex-1 p-4" onClick={(e) => e.stopPropagation()}>
        {/* <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        /> */}
        <button
          onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
          style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
          className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
        >
          {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    ),
  },
  colHelper.display({
    id: "images",
    cell: (info) => (
      <span className="flex justify-center relative" >
        {info.row.original.images?.length > 0 && info.row.original.images.some((image) => image.isThumbnail === true) ? (
          <img
            className="w-32 h-20 object-contain"
            src={`${info.row.original.images.find((image) => image.isThumbnail === true)?.url != null ? info.row.original.images.find((image) => image.isThumbnail === true)?.url : null}`}
          />
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
      </span>
    ),
  }),
  colHelper.accessor("title", {
    header: "Category",
    sortingFn: "auto",
    // cell: (info) => <span>{info.getValue() !== null ? date.format(new Date(info.getValue()!), "DD/MM/YYYY") : "Not Set"}</span>,
  }),
  colHelper.accessor("tagNumber", {
    header: "Tag No.",
    sortingFn: "auto",
  }),
  colHelper.accessor("offers", {
    id: "offer title",
    header: "Offer Title",
    sortingFn: "auto",
    cell: (info) => <span>{info.getValue()?.at(0)?.title}</span>,
  }),
  colHelper.accessor("offers", {
    id: "offer type",
    header: "Offer Type",
    sortingFn: "auto",
    cell: (info) => <span>{FormatType[info.getValue()?.at(0)?.format!]}</span>,
  }),
  colHelper.accessor("offers", {
    id: "offer price",
    header: "Offer Price",
    sortingFn: "auto",
    cell: (info) => <span>
      {
        info.getValue()?.at(0)?.format === FormatType.Auction ?
          info.getValue()?.at(0)?.auctionStartPrice :
          info.getValue()?.at(0)?.buyItNowPrice
      }
    </span>,
  }),
  colHelper.accessor("offers", {
    id: "listed date",
    header: "Listed Date",
    sortingFn: "auto",
    cell: (info) => <span>
      {
        info.getValue()?.at(0)?.listingStarted ?
          formatDateTime(info.getValue()?.at(0)?.listingStarted!, DateTime.DATE_SHORT) :
          "Not Listed"
      }
    </span>,
  }),
  colHelper.accessor("offers", {
    id: "# of offers",
    header: "# Offers",
    enableSorting: false,
    cell: (info) => <span>{info.row.original.offers.length}</span>,
  }),
  colHelper.accessor("offers", {
    id: "offer status",
    header: "Status",
    enableSorting: false,
    cell: (info) => <ListingStatus offer={info.getValue()?.at(0)} part={info.row.original} />,
  }),
  // colHelper.accessor("vehicle.combinedMake", {
  //   header: "Make & Model",
  //   enableSorting: false,
  //   cell: (info) => <div>
  //     <p>{info.row.original.vehicle?.combinedMake}</p>
  //     <p>{info.row.original.vehicle?.combinedModel}</p>
  //   </div>,
  // }),
  // colHelper.accessor("contact.fullname", {
  //   header: "Name & Number",
  //   sortingFn: "auto",
  //   cell: (info) => <div>
  //     <p>{info.getValue()}</p>
  //     <p>{info.row.original.contact?.telephone1}</p>
  //   </div>,
  // }),
  // colHelper.accessor("vehicle.collectedBy", {
  //   header: "Collected By",
  //   enableSorting: false,
  //   cell: (info) => <ContactFromId contactId={info.getValue()!} />,
  // }),
  // colHelper.accessor("vehicle.vehicleStarting", {
  //   header: "Vehicle",
  //   enableSorting: false,
  //   cell: (info) => <div className="w-24"><VehicleIcons vehicle={info.row.original.vehicle!} /></div>,
  // }),
  // colHelper.accessor("vehicle.codConfirmedOn", {
  //   header: "COD Confirmed",
  //   enableSorting: false,
  //   cell: (info) => <span className="flex justify-center text-xl">{info.getValue() ? "✔" : "✘"}</span>,
  // }),
  // colHelper.accessor("paymentStatus", {
  //   header: "Payment Status",
  //   enableSorting: false,
  //   cell: (info) => <span className="flex items-center text-center">{PaymentBubble(info.getValue()!)}</span>,
  // }),
  // colHelper.accessor("status", {
  //   header: "Lead Status",
  //   enableSorting: false,
  //   cell: (info) => <span>{LeadStatus[info.getValue()]}</span>,
  // }),
  // colHelper.accessor("vehicle.yardStatus", {
  //   header: "Status",
  //   enableSorting: false,
  //   cell: (info) => <div id={info.row.original.id}>
  //     <p>Parts Status</p>
  //     <PartsManagerStatusDropdown vehicle={info.row.original.vehicle!} classes="h-8 w-40 text-sm" />
  //     <p className="mt-2">Yard Status</p>
  //     <YardStatusDropdown vehicle={info.row.original.vehicle!} classes="h-8 w-40 text-sm" />
  //   </div>
  // }),
];


export default function EbayListingsTable() {

  return (
    <>
      <PagedTable
        url="/part/listings"
        pageMode={PageMode.Listed}
        columns={cols}
        queryLabel="Search part details"
        onRowClick={(x: IPart) => openInNewTab(`ebay-listings/${x.id}`)}
        showTotal={true}
      />
    </>
  );
}
