import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import useWarehouseLocations from "../../data/useWarehouseLocations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { TextInput } from "../shared/Inputs/TextInput";
import { IPackingBoxSize } from "../../models/PackingBoxSize";
import usePackingBoxSizing from "../../data/usePackingBoxSizing";

// type FormValues = {
//   name: string;
//   description: string;
//   length: number;
//   width: number;
//   height: number;
//   volume: number;
// };

interface BoxSizesModalProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  boxSize?: IPackingBoxSize;
}

export default function BoxSizesModal({ open, setOpen, boxSize }: BoxSizesModalProps) {
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Box Size");
  const { update, create, remove } = usePackingBoxSizing();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm<IPackingBoxSize>();

  const onSubmit = (data: any) => {
    const updatedBox = {
      ...boxSize,
      ...data
    };

    if (boxSize !== undefined) {
      update.mutate(updatedBox, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    } else {
      create.mutate(updatedBox, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    }
  };

  const handleDelete = () => {
    remove.mutate(boxSize?.id!, {
      onSuccess: (data) => {
        deleteHandlers.onSuccess();
        setOpen(false);
        setDeleteOpen(false);
        reset({});
      },
      onError: deleteHandlers.onError
    });
  };

  useEffect(() => {
    if (boxSize !== undefined) {
      reset({ ...boxSize });
    } else {
      reset({})
    }
  }, [boxSize, open, reset]);

  return (
    <>
      <ConfirmModal open={deleteOpen} setOpen={setDeleteOpen}
        title={"Delete Box"} message={`Are you sure you want to delete this box?\n This action cannot be undone.`}
        confirmButtonText={"Delete"} onConfirm={handleDelete} isLoading={false}
      />
      <Modal width="max-w-2xl" open={open} setOpen={setOpen} >
        <div className="flex justify-between">
          <h1 className="text-xl">{`${boxSize ? "Edit" : "Add"} a Box Size`}</h1>
        </div>
        <div className="mt-4">
          <form className="space-y-4 border-t border-gray-200" onSubmit={handleSubmit(onSubmit)} >
            <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-1">

              <TextInput label="Box Name" registerName="name" register={register} />
              <TextInput richText label="Box Description" registerName="description" register={register} />

              <div className="flex gap-x-2 items-center">
                <TextInput label="Width" registerName="width" register={register} type="number" />
                <TextInput label="Height" registerName="height" register={register} type="number" />
                <TextInput label="Length" registerName="length" register={register} type="number" />
              </div>

              <ButtonInput label={`${boxSize ? "Save Changes" : "Create"}`} isSubmit={true} onClick={undefined} classes={undefined} />
              {boxSize !== undefined && (
                <ButtonInput label={"Delete"} isSubmit={false} onClick={() => (setDeleteOpen(true))} classes={"!bg-red-500 hover:!bg-red-600"} />
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
