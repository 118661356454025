import { useState } from "react";

import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

export default function PopOutBox({ classes, children, icon = <QuestionMarkCircleIcon className="h-5 w-5 text-gray-600" /> }: { classes: string, children: React.ReactNode, icon?: React.ReactNode }) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div
      className="relative cursor-help"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {icon}
      {isHovering && (
        <div className={`p-1 absolute z-30 bg-white border rounded-md ${classes}`}>
          {children}
        </div>
      )}
    </div>
  )
}