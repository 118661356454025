import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead, INewLead, ISingleLeadRespose } from "../models/Lead";

export interface ILeadFilters{
  query: string;
  status?: number;
  website?: number;
  pageFilter?: number;
}

export default function useLeadsWithFilters(filters: ILeadFilters) {
  const { get, getFile, post, postGetFile, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const leadsWithFilters = useQuery({
    queryKey: ["leads","filtered",filters.query, filters.status, filters.website, filters.pageFilter],
    queryFn: () => get<IAllLeadsResponse>(`/lead/partialwithfilters?query=${filters.query}&leadstatus=${filters.status??0}&websitefilter=${filters.website??0}&pagefilter=${filters.pageFilter ?? 0}`),
    // staleTime: 1000 * 60 * 1, // 1 minute
    // refetchInterval: 1000 * 60 * 1, // 1 minutes
    // refetchIntervalInBackground: true,
  });

  return {
    leadsWithFilters,
  };
}
