import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartCancelRequest, IPartImagePatchRequest, IPartQcRequest, ISingleCategoryPartResponse } from "../models/Part";

export default function useSinglePart(partId: string | undefined) {
  const { get, post, put, patch, postDocs, del } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes

  const singlePart = useQuery({
    queryKey: ["parts", partId],
    queryFn: () => get<ISingleCategoryPartResponse>(`/part/${partId}/offers`),
    staleTime: staleTime,
    refetchOnWindowFocus: false,
    // refetchInterval: staleTime, //No refetch interval to stop the editor from refreshing
  });

  const patchPart = useMutation(
    (req: IPartQcRequest) => patch(`/part/${req.partId}`, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", partId]);
      },
    }
  );

  const publish = useMutation(
    (offerId: string) => post(`/part/offers/${offerId}/publish`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", partId]);
      },
    }
  );

  const createOffer = useMutation(
    (partId: string) => post(`/part/offers/default/${partId}`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", partId]);
        queryClient.invalidateQueries(["parts", "multiple"]);
      },
    }
  );

  const withdraw = useMutation(
    (offerId: string) => post(`/part/offers/${offerId}/withdraw`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", partId]);
      },
    }
  );

  const cancel = useMutation(
    (req: IPartCancelRequest) => post(`/part/cancelorder`, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", partId]);
      },
    }
  );

  const uploadPicture = useMutation(
    (data: FormData) => postDocs(`/part/${partId}/upload`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", partId]);
      },
    }
  );

  const removeImage = useMutation(
    (imageId: string) => del(`/part/images/${imageId}`,),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", partId]);
      },
    }
  );

  const removePictureBg = useMutation(
    (imageId: string | undefined) => get(`/part/images/${imageId}/removebg`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", partId]);
      },
    }
  );

  const patchPicture = useMutation(
    (data: IPartImagePatchRequest[]) => patch(`/part/images/bulk`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts", partId]);
      },
    }
  );

  return {
    patchPart,
    singlePart,
    publish,
    createOffer,
    cancel,
    withdraw,
    uploadPicture,
    removeImage,
    patchPicture,
    removePictureBg
  };
}
