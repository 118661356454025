import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartAspectsResponse } from "../models/EbayOffer";

export default function usePartAspectsMutations() {
  const { get, post, put, patch, del, postDocs } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes

  const createUpdate = useMutation(
    (body: any) => post(`/part/aspects`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partAspects"]);
      },
    }
  );

  const createCustomAspects = useMutation(
    (partDataId: any) => post(`/part/${partDataId}/aspects/defaults`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partAspects"]);
        queryClient.invalidateQueries(["pagedTable"]);
        // queryClient.invalidateQueries(["parts", "multiple"]);
      },
    }
  );

  const remove = useMutation(
    (aspectId: string) => del(`/part/aspects/${aspectId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partAspects"]);
      },
    }
  );



  return {
    createUpdate,
    createCustomAspects,
    remove,
  };
}
