import { BaseEntity } from "./system";

export enum ResourceType {
  System,
  Staff,
  Driver,
  Trip,
  Truck,
  Job,
  Mechanic,
  Part
}

export interface INote extends BaseEntity {
  resourceType: ResourceType;
  resourceId: string;
  date: string;
  message: string;
  createdBy: string;
}


export interface INewNote {
  resourceType: ResourceType;
  resourceId: string;
  date: string;
  message: string;
}