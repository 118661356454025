import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "../../layouts/Modal";
import LoadingWheel from "../shared/LoadingWheel";
import { useUserLookup } from "../../hooks/useUserLookup";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import useTrips from "../../data/useTrips";
import { ICalendarTrip } from "./../../models/Trip";
import useEbay from "../../data/useEbay";

interface AssignDriverModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  year: number;
  week: number;
  trip?: ICalendarTrip;
}

export function AssignDriverModal({
  open,
  setOpen,
  year,
  week,
  trip,
}: AssignDriverModalProps) {
  const { isLoading: usersLoading, drivers } = useUserLookup();
  const { updateDriver, removeDriver } = useTrips(year, week);
  const [selectedDriver, setSelectedDriver] = useState<string>(
    trip?.driverId ? trip.driverId : "0"
  );

  useEffect(() => {
    if (trip) {
      setSelectedDriver(trip.driverId ? trip.driverId : "0");
    }
  }, [trip]);

  const handleAssignDriver = () => {
    updateDriver.mutate(
      { tripId: trip?.id, driverId: selectedDriver },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleUnassignDriver = () => {
    removeDriver.mutate(
      { tripId: trip?.id },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDriver("0");
  };

  return (
    <Modal width="max-w-xl" open={open} setOpen={setOpen}>
      <h1 className="text-xl mb-6">Assign A Driver</h1>
      {usersLoading ? (
        <div className="flex items-center justify-center py-10">
          <LoadingWheel />
        </div>
      ) : (
        <div>
          <select
            value={selectedDriver}
            onChange={(e) => setSelectedDriver(e.currentTarget.value)}
            aria-label="Driver"
            className="w-full mt-1 text-sm text-gray-900 h-[40px]"
          >
            <option className="sm:text-sm" value="0" selected disabled>
              Select driver...
            </option>
            {drivers?.map((driver) => (
              <option className="sm:text-sm" key={driver.id} value={driver.id}>
                {driver.forename} {driver.surname}
              </option>
            ))}
          </select>
          <div className="mt-4 grid grid-cols-2 gap-x-4">
            <ButtonInput
              disabled={selectedDriver === "0"}
              isSubmit={false}
              label="Assign"
              onClick={handleAssignDriver}
              classes="w-full"
            />
            <ButtonInput
              disabled={!trip?.driverId}
              isSubmit={false}
              label="Unassign"
              onClick={handleUnassignDriver}
              classes={`w-full ${!trip?.driverId ? "" : "!bg-red-600"}`}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}
