import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ICategoryResponse, IPartCategory } from "../models/Part";
import { IEbayEngineData, IEbayEngineDataResponse } from "../models/Vehicle";

export default function useEbayEngineData() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const ebayEngineData = useMutation(
    (data: any) => get(`/vehicle/ebayEngineData/${data.make}/${data.engineCode}`),
    {
      onSuccess: (data: any, variables: any, context: any) => {
        return data;
      },
    }
  );


  return {
    ebayEngineData
  };
}
