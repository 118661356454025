import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse, ILead, INewLead, ISingleLeadRespose } from "../models/Lead";

export default function useLeads(ignoreQuery = false) {
  const { get, getFile, post, postGetFile, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  if (!ignoreQuery) {
    // console.log("ignoreQuery", ignoreQuery);
  }

  const leads = useQuery({
    queryKey: ["leads"],
    queryFn: () => get<IAllLeadsResponse>("/lead/partial"),
    staleTime: 1000 * 60 * 1, // 1 minute
    refetchInterval: 1000 * 60 * 1, // 1 minutes
    refetchIntervalInBackground: true,
    enabled: !ignoreQuery,
  });

  // const singleLead = useMutation(
  //   (leadId: string) => get(`/lead/${leadId}`),
  //   {
  //     onSuccess: (lead: ISingleLeadRespose) => {
  //       return lead;
  //     },
  //   }
  // );


  const distances = useMutation(
    (leadId: string) => get(`/lead/${leadId}/distance`),
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries(["leads"]);
        return data;
      },
    }
  );

  const downloadDoc = useMutation(
    (ids: any) => getFile(`/lead/${ids.leadId}/documents/${ids.docId}`),
    {
      onSuccess: (data: any) => {
        return data;
      },
    }
  );

  const getCollectionNote = useMutation(
    (leadId: string) => getFile(`/lead/${leadId}/documents/note`),
    {
      onSuccess: (data: any) => {
        return data;
      },
    }
  );

  const getAllCollectionNotes = useMutation(
    (body: any) => postGetFile(`/Utilities/notes`, body),
    {
      onSuccess: (data: any) => {
        return data;
      },
    }
  );

  const create = useMutation(
    (body: any) => post(`/lead?lookupLocation=true`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["leads"]);
      },
    }
  );

  const update = useMutation(
    (lead: ILead) => put(`/lead/${lead.id}`, lead),
    {
      onMutate: async (lead: ILead) => {
        await queryClient.cancelQueries({ queryKey: ["leads"] });
        const previousLead = queryClient.getQueryData(["leads", lead.id]);
        queryClient.setQueryData(["leads", lead.id], lead);
        return { previousLead, lead };
      },
      onError: (err, lead, context) => {
        queryClient.setQueryData(
          ["leads", context?.lead.id],
          context?.previousLead
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(["leads"]);
        queryClient.invalidateQueries(["raisedPayments"]);
        queryClient.invalidateQueries(["unscheduledLeads"]);
        queryClient.invalidateQueries(["scheduledLeads"]);
      },
    }
  );

  const undropLead = useMutation(
    (leadId: string) => put(`/trip/${leadId}/undrop`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["leads"]);
        queryClient.invalidateQueries(["raisedPayments"]);
        queryClient.invalidateQueries(["unscheduledLeads"]);
        queryClient.invalidateQueries(["scheduledLeads"]);
      },
    }
  );

  const bulkUpdate = useMutation(
    (body: any) => post(`/lead/bulk`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["leads"]);
      },
    }
  );

  const bulkComplete = useMutation(
    (body: any) => post(`/lead/bulk/complete`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["leads"]);
      },
    }
  );

  const remove = useMutation(
    (leadId: string) => del(`/lead/${leadId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["leads"]);
      },
    }
  );

  const exportLeads = useMutation((leadIds) => post("/lead/exported", leadIds), {
    onSuccess: () => {
      queryClient.invalidateQueries(["leads"]);
    }
  });


  const importLeads = useMutation((leads) => post("/lead/import", leads), {
    onSuccess: () => {
      queryClient.invalidateQueries(["leads"]);
    }
  });

  return {
    leads,
    // singleLead,
    distances,
    downloadDoc,
    getCollectionNote,
    getAllCollectionNotes,
    create,
    update,
    undropLead,
    bulkUpdate,
    bulkComplete,
    remove,
    exportLeads,
    importLeads
  };
}
