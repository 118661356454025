import { useQueryClient } from "@tanstack/react-query";
import { IPart } from "../../../models/Part";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { FormatType } from "../../../models/EbayOffer";
import useOffers from "../../../data/useOffers";


export default function UnlistedOfferPrice({ part }: { part: IPart }) {
  const queryClient = useQueryClient();
  const { update } = useOffers();
  const { saveHandlers } = useDefaultCRUDHandlers("Part Price");

  const handlePriceUpdate = (price: string) => {
    const doublePrice = parseFloat(price);

    if (isNaN(doublePrice)) return;

    // console.log("Price updated", doublePrice);

    const updatedOffer = {
      ...part.offers[0],
    };

    if (updatedOffer.format === FormatType.Auction) {
      updatedOffer.auctionStartPrice = doublePrice;
    } else {
      updatedOffer.buyItNowPrice = doublePrice;
    }

    update.mutate(updatedOffer, saveHandlers);
  }



  if (!part.offers || part.offers.length === 0) {
    return (
      <div className="flex flex-col items-center">
        <p>No Offers</p>
        <p>on Part</p>
      </div>
    )
  };

  const startingPrice = part.offers[0]?.format === FormatType.Auction ?
    part.offers[0]?.auctionStartPrice :
    part.offers[0]?.buyItNowPrice;

  return (
    <div className="w-full h-full flex flex-col items-center" onClick={(e) => e.stopPropagation()}>
      <p className="text-xs text-gray-500">{part.offers[0]?.format === FormatType.Auction ? "Auction" : "Buy It Now"}</p>
      <div className="flex items-center">
        £ <input
          key={part.id}
          defaultValue={startingPrice!}
          step={1}
          className="h-8 w-20 border p-1 text-sm" type="number"
          onBlur={(e) => handlePriceUpdate(e.target.value)}
        />
      </div>
    </div>
  )
}


//Old display
// <span>
//   {
//     info.getValue()?.at(0)?.format === FormatType.Auction ?
//       PriceFormat(info.getValue()?.at(0)?.auctionStartPrice) :
//       PriceFormat(info.getValue()?.at(0)?.buyItNowPrice)
//   }
// </span>,