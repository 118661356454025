import { BaseEntity, DefaultResponseWithData } from "./system";

export interface IDocumentResponse extends DefaultResponseWithData<IDocument[]> { }

export enum DocumentType {
  Vehicle,
  Identity,
  Misc,
  COD,
  CollectionNote,
  Other,
  Yard
}

export interface IDocument extends BaseEntity {
  fileName: string | null;
  length: number;
  contents: string;
  contentType: string | null;
  userId: string | null;
  timestamp: string | null;
  thumbnail: string | null;
  type: DocumentType;
}