import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import { IVehicle } from "../../models/Vehicle";
import LeadParts from "../../components/leads/LeadParts/LeadParts";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { useForm } from "react-hook-form";
import { TextInput } from "../../components/shared/Inputs/TextInput";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import useVehiclesMutations from "../../data/useVehiclesMutations";
import useNotification from "../../components/notifications/useNotifications";

export default function PartsVehicleLookup() {
  const { setInfo } = usePageNameContext();
  const { addNotification } = useNotification();
  const { register, getValues} = useForm<any>();
  const { vrmLookup } = useVehiclesMutations();
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState<IVehicle | null>(null);


  const handleVrmLookup = () => {
    setIsLoading(true);
    vrmLookup.mutate(getValues("vrm"), {
      onSuccess: (data: any) => {
        console.log(data.data)
        // addNotification({
        //   variant: "success",
        //   primaryText: "Found vehicle",
        // });
        setVehicle(data.data);
      },
      onError: (error) => {
        console.error(error);
        addNotification({
          variant: "error",
          primaryText: "Error looking up VRM",
        });
      },
      onSettled: () => {
        setIsLoading(false);
      }
    });
  }

  useEffect(() => {
    setInfo({
      name: "Parts Vehicle Lookup",
      desc: "Lookup details and part information for a vehicle",
    });
  }, []);

  if (isLoading) {
    return <LoadingWheel />;
  }

  return (
    <div className="mt-4">
      <div className="mt-4 grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-4 sm:grid-cols-2">
        <TextInput label="VRM" register={register} registerName="vrm" />
        <div className="flex items-center mt-7">
          <ButtonInput label="Find Vehicle" isSubmit={false} classes="w-40 h-10" onClick={() => handleVrmLookup()} />
        </div>
      </div>

      {vehicle &&
        <LeadParts lead={null} vehicle={vehicle!} />
      }
    </div>
  )
}