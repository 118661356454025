import { useState } from "react";
import date from "date-and-time";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { Dialog } from "@headlessui/react";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

import useLeads from "../../../../data/useLeads";
import JSONToCSVExporter from "./JSONToCSVExporter.js";

const displayColumnNames = 
["CollectID", "CCID", "Atf Reference Number", "Status", "Locked", "Source", "Enquiry Date", "Customer_Accepted", "Earliest Contact", "Latest Contact", "Earliest Collect", "Latest Collect", "ATF_Date_Accept", "Date_Confirmed", "Date_Accepted", "VRM", "Make", "Model", "Category", "VIN", "Fuel_Type", "Year_First_Registered", "Mileage", "Remaining MOT", "Service History", "Bodywork", "Interior", "Mechanical", "Overall Condition", "Reserve", "VAT_Applicable", "VehicleID", "CTBID", "Location Title", "Location First Name", "Location Last Name", "Location Reference", "Location Organisation", "Location Building", "Location Street", "Location Locality", "Location Town", "Location County", "Location Postcode", "Location Telephone (main)", "Location Telephone (alternative)", "Location Email", "Customer Title", "Customer First Name", "Customer Last Name", "Customer Organisation", "Customer Building", "Customer Street", "Customer Locality", "Customer Town", "Customer County", "Customer Postcode", "Customer Telephone (main)", "Customer Telephone (alternative)", "Customer Email", "Customer Group", "Central Payment", "Hide Price", "Advert Reference", "Other Advert Reference", "Starts and Drives", "Area", "Service", "Method", "Operator", "Sales Price", "Referral Fee", "Payment Type", "Account Name", "Account Number", "Sort Code", "Payment Reference", "Card Number", "Payee DOB", "Cheque Name", "Cheque Number", "Premium Price Used", "Scheme", "Vehicle Grade"];
const columnNames = 
["CollectId", "ccid", "Atf Reference Number", "Status", "Locked", "source", "created", "Customer_Accepted", "Earliest Contact", "Latest Contact", "vehicle.collectedOn", "Latest Collect", "ATF_Date_Accept", "Date_Confirmed", "Date_Accepted", "vehicle.vrm", "vehicle.combinedMake", "vehicle.combinedModel", "vehicle.vehicleCategoryDescription", "vehicle.combinedVIN", "vehicle.fuelType", "vehicle.dateFirstRegistered", "Mileage", "Remaining MOT", "Service History", "Bodywork", "Interior", "Mechanical", "Overall Condition", "Reserve", "VAT_Applicable", "vehicle.id", "CTBID", "Location Title", "vehicle.fullname", "Location Last Name", "Location Reference", "vehicle.organisation", "vehicle.line1", "vehicle.line2", "vehicle.locality", "vehicle.town", "vehicle.county", "vehicle.postcode", "vehicle.telephone1", "vehicle.telephone2", "vehicle.email", "Customer Title", "contact.fullname", "Customer Last Name", "contact.organisation", "contact.line1", "contact.line2", "Customer Locality", "contact.town", "contact.county", "contact.postcode", "contact.telephone1", "contact.telephone2", "contact.email", "Customer Group", "Central Payment", "Hide Price", "Advert Reference", "Other Advert Reference", "Starts and Drives", "Area", "Service", "Method", "Operator", "price", "commission", "paymentType", "accountName", "accountNumber", "sortCode", "paymentReference", "cardNumber", "payeeDOB", "chequeName", "chequeNumber", "premiumPriceUsed", "scheme", "vehicleGrade"];

export interface ExportContactsProps {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onExport: React.MouseEventHandler<HTMLButtonElement>;
}

export default function ExportLeads({ onCancel, onExport, }: ExportContactsProps) {
  const { leads } = useLeads();
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [exportType, setExportType] = useState(0);
  const exportTypes = [
    { value: 0, label: "Automatic" },
    { value: 1, label: "Manual" },
  ];

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gp-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <DocumentArrowDownIcon
            className="h-6 w-6 text-gp-blue-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Export Leads to CSV
          </Dialog.Title>
        </div>
      </div>
      <div>
        {/* <RadioGroup
          value={exportType}
          onChange={setExportType}
          className="my-4"
        >
          <RadioGroup.Label className="sr-only">
            Choose an export type
          </RadioGroup.Label>
          <div className="grid grid-cols-2 gap-3">
            {exportTypes.map((type) => (
              <RadioGroup.Option
                key={`${type.value}`}
                value={type.value}
                className={({ active, checked }) =>
                  classNames(
                    active ? "ring-2 ring-offset-2 ring-gp-blue-500" : "",
                    checked
                      ? "bg-gp-blue-600 border-transparent text-white hover:bg-gp-blue-700"
                      : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                    "cursor-pointer focus:outline-none border rounded-md py-1.5 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                  )
                }
              >
                <RadioGroup.Label as="span">{type.label}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>

          test 
        </RadioGroup> */}
        <div>
          {exportType === 0 ? (
            <div className="my-8">
              In automatic mode the export will contain all the leads that haven't been exported yet.
              <div className="my-6">
                Last export: {leads.data?.data.lastExported !== null ? (date.format(new Date(leads.data?.data.lastExported!), "DD/MM/YY HH:mm")) : "Never"}
              </div>
            </div>
          ) : (
            <div className="my-8">
              In manual mode you can choose a date range to export
              <div className="my-6">
                <Datepicker
                  primaryColor="blue"
                  value={value}
                  onChange={(val) => setValue(val)}
                />
              </div>
            </div>
          )}
          <div className="mt-10 text-center">
            <JSONToCSVExporter displayColumnNames={displayColumnNames} columnNames={columnNames} jsonData={leads.data?.data.leads!} />
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        {/* <button
          type="button"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-gp-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gp-blue-700 focus:outline-none focus:ring-2 focus:ring-gp-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onExport}
        >
          Deactivate
        </button> */}
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gp-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
