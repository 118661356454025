import { SetStateAction, useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import BoxSizeTable from "../../components/boxSizeEditor/BoxSizeTable";
import usePackingBoxSizing from './../../data/usePackingBoxSizing';
import LoadingWheel from "../../components/shared/LoadingWheel";
import { IPackingBoxSize } from "../../models/PackingBoxSize";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import BoxSizesModal from "../../components/boxSizeEditor/BoxSizesModal";

export default function BoxSizeEditor() {
  const { setInfo } = usePageNameContext();
  const { packingBoxes } = usePackingBoxSizing();
  const [selectedBox, setSelectedBox] = useState<IPackingBoxSize | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const handleAddNewBoxSize = () => {
    setSelectedBox(undefined);
    setOpen(true);
  };

  const handleSetBoxSize = (boxSize: IPackingBoxSize) => { 
    setSelectedBox(boxSize);
    setOpen(true);
  };

  useEffect(() => {
    setInfo({
      name: "Box Size Editor",
      desc: "View and edit your boxes here",
    });
  }, [])

  useEffect(() => {
    if (!packingBoxes.isLoading && packingBoxes.data) {
      // Filtering code goes here
      // console.log(packingBoxes.data)
    }
  }, [packingBoxes.isLoading, packingBoxes.data])

  if (packingBoxes.isLoading) {
    return (
      <LoadingWheel />
    )
  }

  return (
    <>
      <BoxSizesModal open={open} setOpen={setOpen} boxSize={selectedBox} />
      <div>
        <ButtonInput isSubmit={false} onClick={() => handleAddNewBoxSize()} classes={undefined}>
          <div className="flex items-center">
            <PlusSmallIcon className="h-5 w-5 mr-2" />
            Add New Box
          </div>
        </ButtonInput>
      </div>

      <BoxSizeTable boxSizes={packingBoxes.data?.data!} setSelectedBox={handleSetBoxSize} />
    </>
  )
}