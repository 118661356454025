import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartResponse } from "../models/Part";

export default function useVehicleParts(vehicleId: string) {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60 * 10; //10 minutes

  const parts = useQuery({
    queryKey: ["parts", vehicleId],
    queryFn: () => get<IPartResponse>(`/part/vehicle/${vehicleId}`),
    staleTime: staleTime,
    refetchInterval: staleTime,
  });

  return {
    parts
  };
}
