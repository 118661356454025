import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPart, IPartCategoryDefault, IPartCategoryDefaultResponse } from "../models/Part";

export default function usePartDefaultCategoriesMutations() {
  const { get, post, put, patch, postDocs } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes


  const useDefaultPartCategory = useMutation(
    (partId) => patch(`/part/${partId}/defaultcategory`, {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts"]);
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const useBulkDefaultPartCategory = useMutation(
    (body) => post(`/part/defaultcategory/bulk`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts"]);
        queryClient.invalidateQueries(["parts", "multiple"]);
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );


  return {
    useDefaultPartCategory,
    useBulkDefaultPartCategory
  };
}
