import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartCollectionResponse, IStockStatsResponse } from "../models/Part";

export default function useParts(readyForEbay: boolean = false) {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes

  // const parts = useQuery({
  //   queryKey: ["parts"],
  //   queryFn: () => get<IPartCollectionResponse>(`/part/listings/1?readyForEbay=${readyForEbay}`),
  // });

  const stockStats = useQuery({
    queryKey: ["parts","stats"],
    queryFn: () => get<IStockStatsResponse>(`/part/stockstats`),
  });

  const partPage = useMutation(
    ({page, query} : {page: number, query: string}) => get<IPartCollectionResponse>(`/part/listings/${page}?query=${query}&readyForEbay=${readyForEbay}`),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["parts"], data);
      },
    }
  );

  return {
    stockStats,
    // parts,
    partPage
  };
}
