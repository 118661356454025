import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { TextInput } from "../shared/Inputs/TextInput";
import { ISetting } from "../../models/Setting";
import { TextInputHidden } from "../shared/Inputs/TextInputHidden";
import { useForm } from "react-hook-form";
import useSettings from "../../data/useSettings";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

type FormValues = {
  value: string;
};

export default function AdminSettingsValue({ setting }: { setting: ISetting }) {
  const { update } = useSettings();
  const { saveHandlers } = useDefaultCRUDHandlers("Admin Settings");
  const { register, handleSubmit } =
    useForm<FormValues>({
      defaultValues: {
        value: setting.value,
      },
    });


  const onSubmit = (data: FormValues) => {
    console.log(setting);
    const updatedSetting = {
      ...setting,
      value: data.value,
    };

    update.mutate(updatedSetting, saveHandlers);
  }

  return (
    <form className="flex justify-between" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-8/12 md:w-1/2">
        <TextInputHidden label={null} register={register} registerName="value" />
      </div>
      <ButtonInput label={"Update"} isSubmit={true} onClick={undefined} classes={undefined} />
    </form>
  )

}