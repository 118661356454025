import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { TrashIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";

import useEbayAspects from "../../data/useEbayAspects";
import usePartAspects from "../../data/usePartAspects";
import usePartAspectsMutations from "../../data/usePartAspectsMutations";
import useSinglePart from "../../data/useSinglePart";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { IAspect, IPartAspect } from "../../models/EbayOffer";
import { IPart } from "../../models/Part";
import toTitleCase from "../../utils/toTitleCase";
import useNotification from "../notifications/useNotifications";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import ComboBoxInput from "../shared/Inputs/ComboBoxInput";
import ComboBoxMultipleInput from "../shared/Inputs/ComboBoxMultipleInput";
import { TextInput } from "../shared/Inputs/TextInput";
import LoadingWheel from "../shared/LoadingWheel";

// Also known as Item Aspects (eBay website says "Item Specifics" and the eBay API calls them "Aspects")
export default function EditListingSpecifics({ part, setPage }: { part: IPart, setPage: any }) {
  const { publish } = useSinglePart(part.id!);
  const { partAspects, createUpdate, remove } = usePartAspects(part.id!);
  const { aspects: ebayAspects } = useEbayAspects(part.offers[0]?.categoryId!);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Aspects");
  const queryClient = useQueryClient();
  const { register, handleSubmit, getValues, setValue } = useForm({});
  const { addNotification } = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [customAspects, setCustomAspects] = useState<IPartAspect[]>([]);
  const [usedAspects, setUsedAspects] = useState<number | undefined>(undefined);
  const [refresh, setRefresh] = useState<boolean>(true);


  const publishListing = () => {
    // console.log("Publish listing");

    //Don't publish if listing date is in the past
    publish.mutate(part.offers[0].id!, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: `Publish Successsful`,
        });
      },
      onError: (error) => {
        addNotification({
          variant: "error",
          primaryText: `Publish Failed - ${error}`,
        });
      },
      onSettled: () => {
        setIsPublish(false);
      }
    });
  };

  const onSubmit = (data: any, publish: boolean): any => {
    // console.log("Submit:", publish);
    setIsPublish(publish);

    let currentAspects: any = {};

    //Create object of aspects with data
    Object.keys(data).forEach((key, i) => {
      if (data[key] === "" || key === "newAspectName" || key === "newAspectValue") {
        return;
      }
      currentAspects[key] = data[key]?.localizedValue ?? data[key]; //If it's a combo box, get the localized value
    });

    //Pull out required aspects
    const requiredAspects = ebayAspects.data?.filter(aspect => aspect.aspectConstraint.aspectRequired);
    let missingRequiredAspects: string[] = [];

    //Check that all required aspects have a value in body
    requiredAspects?.forEach(aspect => {
      if (!currentAspects[aspect.localizedAspectName]) {
        missingRequiredAspects.push(aspect.localizedAspectName);
      }
    });

    if (missingRequiredAspects.length > 0) {
      alert(`Please fill in the following required aspects: ${missingRequiredAspects.join(", ")}`);
      setIsPublish(false);
      return;
    }

    //Check for create or update
    let updateAspects: any = [];
    let createAspects: any = [];

    Object.keys(currentAspects).forEach((key: any) => {
      //Check if aspect is in partAspects
      if (partAspects.data?.data.find((partAspect: any) => partAspect.name === key)) {
        //Update
        let aspect = partAspects.data?.data.find((partAspect: any) => partAspect.name === key);
        updateAspects.push({
          id: aspect?.id,
          name: key,
          value: currentAspects[key],
          isCustom: aspect?.isCustom
        });
      } else {
        //Create
        createAspects.push({
          name: key,
          value: currentAspects[key]
        });
      }
    });

    // console.log("Create:", createAspects);
    // console.log("Update:", updateAspects);

    const body = {
      partDataId: part.id,
      aspects: [...createAspects, ...updateAspects]
    };

    createUpdate.mutate(body, {
      onSuccess: () => {
        if (publish) {
          publishListing();
        } else {
          saveHandlers.onSuccess();
        }
      },
      onError: (error) => {
        saveHandlers.onError();
        setIsPublish(false);
      }

    });
  };

  const addCustomAspect = (name: string, value: string) => {
    let createAspects: any = [];

    console.log("Custom Aspect:", name, value);

    if (!name) return;

    createAspects.push({
      name: name,
      value: value,
      isCustom: true
    });

    const body = {
      partDataId: part.id,
      aspects: [...createAspects]
    };

    createUpdate.mutate(body, {
      onSuccess: () => {
        setValue("newAspectName", "");
        setValue("newAspectValue", "");
        setValue(name, value);
        saveHandlers.onSuccess();
      },
      onError: (error) => {
        saveHandlers.onError();
      }

    });
  };



  const deleteCustomAspect = (aspect: IPartAspect) => {

    remove.mutate(aspect.id, {
      ...deleteHandlers,
      onSuccess: () => {
        //Else it gets stored in form data and created as not custom on save
        setValue(aspect.name, "");
        deleteHandlers.onSuccess();
      },
    });
  };

  const getAspectInitialValue = (aspect: IAspect) => {
    let existingValue = partAspects.data?.data.find((partAspect: IPartAspect) => partAspect.name === aspect.localizedAspectName)?.value;

    if (existingValue != null) return existingValue;

    let lookFor = "";
    // console.log("Aspect:", aspect.localizedAspectName, part.vehicle?.combinedMake, part.titleSanitised)
    // Pre fill known values
    switch (aspect.localizedAspectName) {
      case "Brand":
        lookFor = part.vehicle?.combinedMake ?? "";
        break;
      case "Manufacturer Part Number":
        return part.partNumber ?? "";
      case "Placement on Vehicle":
        lookFor = part.titleSanitised ?? "";
        break;
      case "Item Height":
        return `${part.height}cm` ?? "";
      case "Item Length":
        return `${part.length}cm` ?? "";
      case "Item Width":
        return `${part.width}cm` ?? "";
      case "Item Weight":
        return `${part.weight}kg` ?? "";
    }

    //Look for the value in the aspect values
    if (lookFor !== "") {
      let idx = aspect.aspectValues?.findIndex((value: any, i: number) => value.localizedValue.toLowerCase() === lookFor.toLowerCase());

      if (idx === -1)
        idx = aspect.aspectValues?.findIndex((value: any, i: number) => value.localizedValue.toLowerCase().includes(lookFor.toLowerCase()));

      const val = aspect.aspectValues[idx];

      if (val == null)
        return "";

      return val.localizedValue;
    }
  }

  useEffect(() => {
    if (!ebayAspects.isLoading && ebayAspects.data && !partAspects.isLoading && partAspects.data) {
      if (isLoading) {

        //Set initial values
        partAspects.data?.data.forEach((partAspect: IPartAspect) => {
          setValue(partAspect.name, partAspect.value);
        });
      }

      let customAspects = partAspects.data?.data.filter((aspect: IPartAspect) => aspect.isCustom === true) ?? [];

      //Imported aspects are not custom but don't match the ebay Aspects
      let importedAspects = partAspects.data?.data.filter(
        (aspect: IPartAspect) => aspect.isCustom === false &&
          !ebayAspects.data?.find((ebayAspect: IAspect) => ebayAspect.localizedAspectName === aspect.name)
      ) ?? [];

      setCustomAspects([...customAspects, ...importedAspects]);

      // console.log(getValues())
      let currentValues = getValues();
      let usedAspectCount = 0;
      Object.keys(currentValues).forEach((key, i) => {
        if (currentValues[key] === "" || key === "newAspectName" || key === "newAspectValue") {
          return;
        }
        usedAspectCount++;
      });

      setUsedAspects(usedAspectCount);
      setIsLoading(false);
    }
  }, [ebayAspects.isLoading, ebayAspects.data, partAspects.isLoading, partAspects.data, refresh]);


  useEffect(() => {
    //Scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const sortByRequired = (a: IAspect, b: IAspect) => {
    if (a.aspectConstraint.aspectRequired === b.aspectConstraint.aspectRequired) {
      return 0;
    }

    if (a.aspectConstraint.aspectRequired) {
      return -1;
    }

    return 1;
  }

  if (isLoading || isPublish) {
    return <LoadingWheel />;
  }

  return (
    <div>
      <h1 className="text-lg font-semibold">Category Specifics</h1>
      <h2 className="text-sm">Maximum number of Item Aspects eBay allows: 45 {usedAspects !== undefined ? ` (${usedAspects}/45)` : "(0/45)"}</h2>

      <form onSubmit={handleSubmit(() => onSubmit(getValues(), false))} onChange={() => setRefresh(!refresh)} className="mt-4 space-y-4">

        <div className="flex flex-col gap-4">
          {customAspects.length > 0 && <h2 className="text-lg font-semibold -mb-2">Custom Aspects</h2>}
          {customAspects.map((aspect: IPartAspect) => {
            return (
              <div key={aspect.id} className="flex items-center gap-x-2">
                <div key={aspect.id} className="w-full">
                  <TextInput
                    key={aspect.id}
                    label={toTitleCase(aspect.name)}
                    register={register}
                    registerName={aspect.name}
                    maxLength={50}
                    customClasses="w-full"
                  />
                </div>
                <TrashIcon className="h-6 w-6 mt-6 cursor-pointer hover:text-red-600" onClick={() => deleteCustomAspect(aspect)} />
              </div>
            )
          })}
        </div>

        <div className="flex flex-col gap-4">
          <h2 className="text-lg font-semibold -mb-2">eBay Aspects</h2>
          {ebayAspects.data?.sort((a: IAspect, b: IAspect) => sortByRequired(a, b)).map((aspect: IAspect) => {
            if (!aspect.aspectValues || aspect.aspectValues.length === 0) {
              return (
                <TextInput
                  label={toTitleCase(`${aspect.localizedAspectName} ${aspect.aspectConstraint.aspectRequired ? "(Required)" : `(${aspect.aspectConstraint.aspectUsage})`} `)}
                  register={register}
                  registerName={aspect.localizedAspectName}
                  maxLength={50}
                  initialValue={getAspectInitialValue(aspect)}
                />
              )
            }

            if (aspect.aspectConstraint.itemToAspectCardinality === "MULTI") {
              return (
                <ComboBoxMultipleInput
                  label={toTitleCase(`${aspect.localizedAspectName} ${aspect.aspectConstraint.aspectRequired ? "(Required - Multi Select)" : `(${aspect.aspectConstraint.aspectUsage} - Multi Select)`} `)}
                  options={aspect.aspectValues}
                  searchField={"localizedValue"}
                  register={register}
                  setValue={setValue}
                  registerName={aspect.localizedAspectName}
                  initialValue={getAspectInitialValue(aspect)}
                />
              )
            } else {
              return (
                <ComboBoxInput
                  label={toTitleCase(`${aspect.localizedAspectName} ${aspect.aspectConstraint.aspectRequired ? "(Required)" : `(${aspect.aspectConstraint.aspectUsage})`} `)}
                  options={aspect.aspectValues}
                  searchField={"localizedValue"}
                  register={register}
                  setValue={setValue}
                  registerName={aspect.localizedAspectName}
                  initialValue={getAspectInitialValue(aspect)}
                />
              )
            }

            // return (
            //   <ComboBoxInput
            //     label={toTitleCase(`${aspect.localizedAspectName} ${aspect.aspectConstraint.aspectRequired ? "(Required)" : `(${aspect.aspectConstraint.aspectUsage})`} `)}
            //     options={aspect.aspectValues}
            //     searchField={"localizedValue"}
            //     register={register}
            //     setValue={setValue}
            //     registerName={aspect.localizedAspectName}
            //     initialValue={getAspectInitialValue(aspect)}
            //   />
            // )

          })}
        </div>

        <div className="flex justify-end gap-4 mt-8">
          <ButtonInput
            label={"Back"}
            isSubmit={false}
            onClick={() => setPage((p: number) => p - 1)}
            classes={"!bg-white !border-1 !border-gray-400 !text-gray-600 hover:!bg-gray-200"}
          />

          <ButtonInput
            label={"Save"}
            isSubmit={true}
            onClick={undefined}
            classes={undefined}
          />

          <ButtonInput
            label={"Publish"}
            isSubmit={false}
            onClick={() => onSubmit(getValues(), true)}
            classes={undefined}
            disabled={part.offers[0].publishedOn !== null}
          />
        </div>

      </form>

      <div className="flex flex-col gap-4 mt-6">
        <hr />
        <div className="" onKeyDown={(e: any) => { if (e.key === "Enter") { e.preventDefault(); } }}>
          <h2>Add Custom Aspect:</h2>
          <TextInput
            label={`Name: (Max length: 40)`}
            maxLength={40}
            register={register}
            registerName={"newAspectName"}
          />

          <TextInput
            label={`Value (Max length: 50)`}
            maxLength={50}
            register={register}
            registerName={"newAspectValue"}
          />
        </div>

        <div>
          <ButtonInput
            label={"Add Custom Aspect"}
            isSubmit={false}
            onClick={() => addCustomAspect(getValues().newAspectName, getValues().newAspectValue)}
            classes={undefined}
          />
        </div>

      </div>
    </div>
  )
}