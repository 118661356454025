import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContactDetails from "../../components/contacts/ContactDetails";

import LoadingWheel from "../../components/shared/LoadingWheel";
import VehicleDetails from "../../components/vehicles/VehicleDetails";
import VehicleStatus from "../../components/vehicles/VehicleStatus";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useContacts from "../../data/useContacts";
import useLeads from "../../data/useLeads";

import useVehicles from "../../data/useVehicles";
import { IContact } from "../../models/Contact";
import { ILead } from "../../models/Lead";
import { IVehicle } from "../../models/Vehicle";

export default function Vehicle() {
  const navigate = useNavigate();
  const { setInfo } = usePageNameContext();
  const { vehicleId } = useParams();
  const { vehicles } = useVehicles();
  const { leads: allLeads } = useLeads();
  const [vehicle, setVehicle] = useState<IVehicle | undefined>(undefined);
  const [leads, setLeads] = useState<ILead[] | undefined>(undefined);
  const [contact, setContact] = useState<IContact | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setInfo({
      name: `${vehicleId}`,
      desc: `View and manage ${vehicleId}`,
    });
  }, [setInfo, vehicleId]);

  useEffect(() => {
    if (!vehicles.isLoading && !allLeads.isLoading) {
      const foundVehicle = vehicles.data?.data.find(
        (v) => v.vrmCurr === vehicleId
      );

      if (foundVehicle) {
        setVehicle(foundVehicle);

        const foundLeads = allLeads.data?.data.leads.filter(
          (l) => l.vehicle?.vrm === foundVehicle.vrm
        );

        if (foundLeads && foundLeads?.length > 0) {
          setLeads(foundLeads);
          setContact(foundLeads[0].contact ?? undefined);
          setLoading(false);
        }
      } else {
        //TODO: Probably add some sort of error notif
        navigate(-1);
      }
    }
  }, [
    vehicleId,
    vehicles.isLoading,
    vehicles.data?.data,
    allLeads.isLoading,
    allLeads.data?.data,
    navigate,
  ]);

  return loading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <div className="mt-8 grid grid-cols-3 gap-6">
      <div className="flex items-center bg-white rounded-lg shadow">
        <img
          className="w-full"
          src={`${vehicle?.vehicleImageUrl}`}
        />
      </div>
      <div className="grid col-span-2 grid-cols-2 gap-6">
        {/* <VehicleStatus vehicle={vehicle!} /> */}
      </div>
      <VehicleDetails vehicle={vehicle!} />
      <div className="grid col-span-2 grid-cols-1 gap-6">
        <ContactDetails contact={contact!} />
      </div>
    </div>
  );
}
