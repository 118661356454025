import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllContactsResponse, IContact } from "../models/Contact";
import { ISetting, ISettingsResponse } from "../models/Setting";

export default function useSettings() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const settings = useQuery({
    queryKey: ["settings"],
    queryFn: () => get<ISettingsResponse>("/setting"),
  });

  const update = useMutation(
    (setting: ISetting) => put(`/setting/${setting.id}`, setting),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["settings"]);
      },
    }
  );

  return {
    settings,
    update,
  };
}
