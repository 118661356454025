import { useEffect, useState } from "react";
import useDefaultCRUDHandlers from "../../../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../../../models/Lead";
import useMessages from "../../../../data/useMessages";
import classNames from "../../../../utils/classNames";

export default function LeadUnreadMessage({ lead }: { lead: ILead }) {
  // const { update } = useSingleLead(lead.id);
  const { unreadMessages } = useMessages();
  const { saveHandlers } = useDefaultCRUDHandlers("Leads");
  const [currentMessageCount, setCurrentMessageCount] = useState<number>();

  useEffect(() => {
    if (!unreadMessages.isLoading && unreadMessages.data) {
      setCurrentMessageCount(unreadMessages.data.data.find(m => m.leadId === lead.id)?.unreadMessageCount);
    }
  }, [unreadMessages.isLoading, unreadMessages.data, lead])

  return (
    <div className="flex justify-center items-center">
      {currentMessageCount && (
        <span
          className={classNames(currentMessageCount > 0 ?
            "font-semibold text-lg bg-blue-100 text-blue-600 rounded-full" : "",
            "flex items-center justify-center text-center w-10 h-10")}>
          {currentMessageCount}
        </span>
      )}
    </div>
  )
}
