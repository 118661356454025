import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IEbayLocationsResponse, IEbayPoliciesResponse } from "../models/EbayOffer";

export default function useEbay() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const policies = useQuery({
    queryKey: ["ebayPolicies"],
    queryFn: () => get<IEbayPoliciesResponse>("/ebay/policies"),
  });

  const locations = useQuery({
    queryKey: ["ebayLocations"],
    queryFn: () => get<IEbayLocationsResponse>("/ebay/locations"),
  });

  return {
    policies,
    locations,
  };
}
