import { useState } from "react";

import { DateTime } from "luxon";
import { useForm } from "react-hook-form";

import { IPart } from "../../../models/Part";
import useShipping from "../../../data/useShipping";
import LoadingWheel from "../../shared/LoadingWheel";
import SelectInput from "../../shared/Inputs/SelectInput";
import { ShippingQuoteTable } from "./ShippingQuoteTable";
import {
  IProoviaSlot,
  IShippingAddress,
  IShippingDimensions,
  IShippingQuote,
} from "../../../models/Shipping";

interface ProoviaShippingProps {
  part: IPart;
  address: IShippingAddress;
  dimensions: IShippingDimensions;
  onProoviaItemSelect: (item: number) => void;
  onCollectionSlotSelect: (date: string) => void;
  onDeliverySlotSelect: (date: string) => void;
  onServiceSelect: (service: string) => void;
  selectedService?: string;
}

export function ProoviaShipping({
  part,
  address,
  dimensions,
  onProoviaItemSelect,
  onCollectionSlotSelect,
  onDeliverySlotSelect,
  onServiceSelect,
  selectedService,
}: ProoviaShippingProps) {
  const { register, watch, setValue, getValues } = useForm({
    defaultValues: { prooviaItemId: -1, collectionSlot: -1, deliverySlot: -1 },
  });
  const { prooviaItems, quotes, prooviaCollectionDates, prooviaDeliveryDates } =
    useShipping(part);

  const [quotesLoading, setQuotesLoading] = useState(false);
  const [shippingQuotes, setShippingQuotes] = useState<IShippingQuote[]>();

  const [showCollectionSlots, setShowCollectionSlots] = useState(false);
  const [collectionSlotsLoading, setCollectionSlotsLoading] = useState(false);
  const [collectionSlots, setCollectionSlots] = useState<IProoviaSlot[]>();
  const [selectedCollectionSlot, setSelectedCollectionSlot] = useState();

  const [showDeliverySlots, setShowDeliverySlots] = useState(false);
  const [deliverySlotsLoading, setDeliverySlotsLoading] = useState(false);
  const [deliverySlots, setDeliverySlots] = useState<IProoviaSlot[]>();
  const [selectedDeliverySlot, setSelectedDeliverySlot] = useState();

  const [error, setError] = useState<string>();

  const getQuotes = () => {
    setQuotesLoading(true);
    quotes.mutate(
      {
        ...address,
        ...dimensions,
        partDataId: part.id,
        prooviaItemId: getValues("prooviaItemId"),
      },
      {
        onSuccess: (res) => {
          setShippingQuotes(res.data);
        },
        onSettled: () => {
          setQuotesLoading(false);
        },
      }
    );
  };

  const handleTypeChange = (_: any, val: any) => {
    if (val === -1) {
      return;
    }

    setValue("prooviaItemId", val);
    onProoviaItemSelect(val);
    getQuotes();
  };

  const getCollectionSlots = () => {
    setCollectionSlotsLoading(true);
    prooviaCollectionDates.mutate(
      {
        from: "DT2 9PW",
        to: address.postcode.replace(/^(.*)(\d)/, "$1 $2"),
      },
      {
        onSuccess: (res) => {
          setCollectionSlots(res.data);
        },
        onSettled: () => {
          setCollectionSlotsLoading(false);
        },
      }
    );
  };

  const handleServiceSelect = (service: string) => {
    getCollectionSlots();
    onServiceSelect(service);
    setShowCollectionSlots(true);
  };

  const getDeliverySlots = () => {
    setDeliverySlotsLoading(true);
    prooviaDeliveryDates.mutate(
      {
        from: "DT2 9PW",
        to: address.postcode.replace(/^(.*)(\d)/, "$1 $2"),
        collectionDate: String(getValues("collectionSlot")),
      },
      {
        onSuccess: (res) => {
          setDeliverySlots(res.data);
        },
        onSettled: () => {
          setDeliverySlotsLoading(false);
        },
      }
    );
  };

  const handleCollectionSlotChange = (_: any, val: any) => {
    if (val === -1) {
      return;
    }

    setValue("collectionSlot", val);
    onCollectionSlotSelect(val);
    setSelectedCollectionSlot(val);
    getDeliverySlots();
    setShowDeliverySlots(true);
  };

  const handleDeliverySlotChange = (_: any, val: any) => {
    if (val === -1) {
      return;
    }

    setValue("deliverySlot", val);
    onDeliverySlotSelect(val);
  };

  return (
    <div>
      {prooviaItems.isLoading ? (
        <LoadingWheel />
      ) : (
        <div className="space-y-4">
          <SelectInput
            label="Item Type"
            register={register}
            registerName="prooviaItemId"
            setValue={handleTypeChange}
            options={[
              { id: -1, name: "Choose..." },
              ...prooviaItems.data!.data,
            ]}
            watch={watch}
          />
          {watch("prooviaItemId") !== -1 &&
            (quotesLoading ? (
              <LoadingWheel />
            ) : (
              <div>
                <ShippingQuoteTable
                  shippingQuotes={shippingQuotes!}
                  onServiceSelect={handleServiceSelect}
                  selectedService={selectedService}
                />
              </div>
            ))}
          {showCollectionSlots ? (
            collectionSlotsLoading ? (
              <LoadingWheel />
            ) : (
              <SelectInput
                label="Collection Date"
                register={register}
                registerName="collectionSlot"
                setValue={handleCollectionSlotChange}
                options={[
                  { id: -1, name: "Choose..." },
                  ...collectionSlots!.map((d) => ({
                    id: d.date,
                    name: DateTime.fromISO(d.date).toFormat("dd/MM/yyyy"),
                  })),
                ]}
                watch={watch}
              />
            )
          ) : (
            <div />
          )}
          {showDeliverySlots ? (
            deliverySlotsLoading ? (
              <LoadingWheel />
            ) : (
              <SelectInput
                label="Delivery Date"
                register={register}
                registerName="deliverySlot"
                setValue={handleDeliverySlotChange}
                options={[
                  { id: -1, name: "Choose..." },
                  ...deliverySlots!.map((d) => ({
                    id: d.date,
                    name: DateTime.fromISO(d.date).toFormat("dd/MM/yyyy"),
                  })),
                ]}
                watch={watch}
              />
            )
          ) : (
            <div />
          )}
        </div>
      )}
    </div>
  );
}
