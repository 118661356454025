import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IUnavailableDate, IUnavailableDatesResponse } from "../models/UnavailableDate";

export default function useUnavailiableDates(resourseId?: string | undefined) {
  const { get, post, del } = useApiHelper();
  const queryClient = useQueryClient();

  const unavailableDates = useQuery({
    queryKey: ["unavailableDates", "resource", resourseId],
    queryFn: () => get<IUnavailableDatesResponse>(`/unavailabledate/resource/${resourseId}`),
  });

  const createUnavailableDate = useMutation(
    (unavailableDate: IUnavailableDate) => post(`/unavailabledate`, unavailableDate),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["unavailableDates", "resource", resourseId]);
      },
    }
  );

  const delUnavailableDate = useMutation(
    (unavailableDate: IUnavailableDate) => del(`/unavailabledate/${unavailableDate.id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["unavailableDates", "resource", resourseId]);
      },
    }
  );

  return {
    unavailableDates,
    createUnavailableDate,
    delUnavailableDate,
  };
}

