import { SetStateAction, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Category, IPartImage } from "../../../models/Part";
import { CameraIcon } from "@heroicons/react/24/outline";
import classNames from "../../../utils/classNames";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import PriceFormat from "../../../utils/priceFormat";
import GPLogo from "../../../assets/logo_black_cropped.png";
import Modal from "../../../layouts/Modal";
import PartImageCarousel from "./PartImageCarousel";
import ImageCarousel from "../../shared/Images/ImageCarousel";

export default function PartCategory({ category, handleCategorySelect, selected, isMech }: { category: Category, handleCategorySelect: any, selected: Category[], isMech: boolean }) {
  const [partsOpen, setPartsOpen] = useState<boolean>(false);
  const [partPicturesOpen, setPartPicturesOpen] = useState<boolean>(false);
  const [partPictures, setPartPictures] = useState<IPartImage[]>([]);


  const innerColCss = "flex justify-evenly items-center h-full text-center w-full [&>div]:flex [&>div]:flex-col [&>div]:flex-auto [&>div]:px-4 [&>div]:items-center [&>div]:justify-center [&>div]:border-l-2 [&>div]:border-gray-200"

  return (
    <>
      <Modal open={partPicturesOpen} setOpen={setPartPicturesOpen} width={"max-w-7xl"} >
        <ImageCarousel images={partPictures} part />
      </Modal>
      <div className="w-full" key={category.category.id}>
        <div className="p-2 mb-2 h-24 w-full flex gap-x-4 items-center justify-between">
          <div className="p-4 h-full w-48 flex items-center justify-center text-center bg-gp-blue-500 text-white shadow rounded-lg">
            <h1 className="text-base">{category.category.name}</h1>
          </div>
          <div
            className="flex justify-evenly items-center h-full text-center w-full bg-white shadow rounded-lg 
          [&>div]:flex [&>div]:flex-col [&>div]:flex-auto [&>div]:px-4 [&>div]:items-center [&>div]:justify-center [&>div]:border-l-2 [&>div]:border-gray-200"
          >
            <div className="!border-l-0">
              <span className="text-sm text-gray-500">Qty Sold</span>
              <h1 className="text-lg">{category.parts.length}</h1>
            </div>
            <div className="">
              <span className="text-sm text-gray-500">Average Sold Price</span>
              <h1 className="text-lg">{(PriceFormat(category.category.averagePrice))}</h1>
            </div>
            <div className="">
              <span className="text-sm text-gray-500">Average Margin</span>
              <h1 className="text-lg">{PriceFormat(category.category.averageMargin)}</h1>
            </div>
            <div className="">
              <span className="text-sm text-gray-500">Warehouse Time</span>
              <h1 className="text-lg">{category.category.whTime}</h1>
            </div>
            <div className="">
              <span className="text-sm text-gray-500">Dismantling Time</span>
              <h1 className="text-lg">{category.category.disTime}</h1>
            </div>
            <div className="">
              <span className="text-sm text-gray-500">Postage</span>
              <h1 className="text-lg">{PriceFormat(category.category.postage)}</h1>
            </div>
            <div className="">
              <input type="checkbox" checked={selected.some((c) => c.category.name === category.category.name)} onChange={(e) => handleCategorySelect(category, e.target.checked, isMech)} className="w-6 h-6 my-3" />
            </div>
          </div>
          <div>
            <ButtonInput label={">"} isSubmit={false}
              onClick={() => setPartsOpen(!partsOpen)}
              classes={classNames(partsOpen ? "!rotate-90" : "", "!rounded-full transition-all duration-300 ")}
            />
          </div>
        </div>

        <div className={classNames(partsOpen ? "max-h-[40rem]" : "max-h-0", "transition-all transform flex flex-col gap-y-4 overflow-auto duration-500")}>
          {/* Sorts parts to show GP parts first */}
          {category.parts.sort((a, b) => Number(b.isInternal) - Number(a.isInternal)).map(part => {

            //Find image that isThumbnail is true
            const imageUrl = part.images.find((image) => image.isThumbnail === true)?.url;
            return (
              <div key={part.id} className="flex justify-between">
                <div className="w-56 p-1">
                  {/* For spacing */}
                </div>

                <div className="w-full flex bg-white shadow rounded-lg ml-1" key={part.id}>
                  <span
                    onClick={() => { setPartPictures(part.images); setPartPicturesOpen(true); }}
                    className="z-20 flex justify-center w-[12%] rounded-l-lg relative cursor-pointer bg-gray-400"
                  >
                    {imageUrl ? (
                      <img
                        className="w-full h-full object-contain rounded-l-lg"
                        src={imageUrl}
                      />
                    ) : (
                      <CameraIcon className="w-14 text-gray-600" />
                    )}
                  </span>

                  <div className="p-2 flex flex-col w-full">
                    <div className="flex justify-between relative">
                      <h1 className="ml-4 mb-1 font-semibold">{part.titleSanitised}</h1>
                      {part.isInternal && (
                        <img src={GPLogo} className="w-10 h-10 " />
                      )}
                    </div>

                    <div
                      className={innerColCss}
                    >
                      <div className="!border-l-0">
                        <span className="text-sm text-gray-500">Price</span>
                        <h1 className="text-lg">{PriceFormat(part.price)}</h1>

                        <span className="text-sm mt-2 text-gray-500">Make</span>
                        <h1>{part.vehicle?.combinedMake}</h1>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-500">Margin</span>
                        <h1 className="text-lg">{PriceFormat(part.margin)}</h1>

                        <span className="text-sm mt-2 text-gray-500">Model</span>
                        <h1>{part.vehicle?.combinedModel}</h1>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-500">Part Number</span>
                        <h1 className="text-lg">{part.partNumber ?? "N/A"}</h1>

                        <span className="text-sm mt-2 text-gray-500">Reg</span>
                        <h1>{part.vehicle?.vrm}</h1>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-500">Sold</span>
                        <h1 className="text-lg">{DateTime.fromISO(part.created).setLocale('en-gb').toLocaleString(DateTime.DATE_SHORT)}</h1>

                        <span className="text-sm mt-2 text-gray-500">Engine Code</span>
                        <h1>{part.vehicle?.engineModelCode}</h1>
                      </div>
                    </div>

                    {/* Extra row */}
                    {/* <div
                    className={innerColCss}
                  >
                    <div className="!border-l-0">

                    </div>
                    <div className="">

                    </div>
                    <div className="">

                    </div>
                    <div className="">

                    </div>
                  </div> */}

                  </div>

                </div>

                <div className="w-16 p-5">
                  {/* For spacing */}
                </div>

              </div>
            )
          })}
        </div>

      </div >
    </>
  )
}