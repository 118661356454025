import { useEffect, useState } from "react";
import useVehicleCategoryParts from "../../data/useVehicleCategoryParts";
import PriceFormat from "../../utils/priceFormat";
import Card from "../shared/Card/Card";
import LoadingWheel from "../shared/LoadingWheel";
import {
  CameraIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import classNames from "../../utils/classNames";
import { IPart } from "../../models/Part";
import { DateTime } from "luxon";

function PartInfoRow({
  label,
  value,
}: {
  label: string;
  value?: string | number | null;
}) {
  return (
    <li className="py-1 grid grid-cols-4 gap-x-2">
      <div className="ml-2 text-sm text-gray-500">{label}</div>
      <div className="col-span-3 text-sm">{value}</div>
    </li>
  );
}

function PartRow({ part }: { part: IPart }) {
  const rows = [
    {
      label: "Price",
      value: `£${part.price}`,
    },
    {
      label: "Margin",
      value: `£${part.margin}`,
    },
    {
      label: "Make",
      value: part.vehicle?.combinedMake,
    },
    {
      label: "Model",
      value: part.vehicle?.combinedModel,
    },
    {
      label: "Part #",
      value: part.partNumber,
    },
    {
      label: "Reg",
      value: `${part.vehicle?.vrm}`,
    },
    {
      label: "Sold",
      value: DateTime.fromISO(part.created)
        .setLocale("en-gb")
        .toLocaleString(DateTime.DATE_SHORT),
    },
    {
      label: "Engine",
      value: part.vehicle?.engineModelCode,
    },
  ];

  return (
    <div key={part.id}>
      <div className="p-2">
        <h1 className="font-semibold">{part.titleSanitised}</h1>
      </div>
      <div>
        <ul className="grid grid-cols-1 2xl:grid-cols-2 divide-y">
          {rows.map((row) => (
            <PartInfoRow key={row.label} {...row} />
          ))}
        </ul>
      </div>
    </div>
  );
}

interface VehicleCategoryPartsProps {
  vehicleId: string;
  categoryId: string;
}

export function VehicleCategoryParts({
  vehicleId,
  categoryId,
}: VehicleCategoryPartsProps) {
  const { parts } = useVehicleCategoryParts(vehicleId, categoryId);
  const [loading, setLoading] = useState(true);
  const [avgSold, setAvgSold] = useState<number | undefined>();
  const [avgMargin, setAvgMargin] = useState<number | undefined>();
  const [expanded, setExpanded] = useState(false);

  const processParts = () => {
    const numParts = parts.data?.length;

    const totalSold = parts.data?.reduce(
      (acc, cv) => (cv.price ? acc + cv.price : acc + 0),
      0
    );
    const totalMargin = parts.data?.reduce(
      (acc, cv) => (cv.margin ? acc + cv.margin : acc + 0),
      0
    );

    setAvgSold(totalSold! / numParts!);
    setAvgMargin(totalMargin! / numParts!);
    setLoading(false);
  };

  useEffect(() => {
    if (!parts.isLoading && parts.data) {
      processParts();
    }
  }, [parts.isLoading, parts.data]);

  return (
    <Card title="Category Information">
      {loading ? (
        <LoadingWheel />
      ) : (
        <>
          <div
            className="flex justify-evenly items-center h-full text-center w-full bg-white shadow
          [&>div]:flex [&>div]:flex-col [&>div]:flex-auto [&>div]:px-4 [&>div]:items-center [&>div]:justify-center [&>div]:border-l-2 [&>div]:border-gray-200"
          >
            <div className="p-4 !border-l-0">
              <span className="text-sm text-gray-500">Qty Sold</span>
              <h1 className="text-lg">{parts.data!.length}</h1>
            </div>
            <div className="">
              <span className="text-sm text-gray-500">Average Sold Price</span>
              <h1 className="text-lg">{PriceFormat(avgSold)}</h1>
            </div>
            <div className="">
              <span className="text-sm text-gray-500">Average Margin</span>
              <h1 className="text-lg">{PriceFormat(avgMargin)}</h1>
            </div>
          </div>
          <div
            className={classNames(
              expanded ? "max-h-[40rem]" : "max-h-0",
              "transition-all transform flex flex-col border-b divide-y overflow-auto duration-500"
            )}
          >
            {parts
              .data!.sort((a, b) => Number(b.isInternal) - Number(a.isInternal))
              .map((part) => (
                <PartRow key={part.id} part={part} />
              ))}
          </div>
          <div
            onClick={() => setExpanded(!expanded)}
            className="py-3 flex flex-row space-x-1 items-center justify-center hover:cursor-pointer"
          >
            <span className="text-sm font-medium text-gray-500">
              {expanded ? "Hide" : "Show"} Sold Parts
            </span>
            {expanded ? (
              <ChevronUpIcon className="w-6 h-6 text-gray-500" />
            ) : (
              <ChevronDownIcon className="w-6 h-6 text-gray-500" />
            )}
          </div>
        </>
      )}
    </Card>
  );
}
