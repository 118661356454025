import { useState } from "react";
import useEbayMessagesListing from "../../../data/useEbayMessagesListings";
import { IEbayMessage } from "../../../models/EbayMessage";
import { IPart } from "../../../models/Part";
import Card from "../Card/Card";
import LoadingWheel from "../LoadingWheel";
import EbayMessageChain from "../../ebayMessaging/EbayMessageChain";
import formatDateTime from "../../../utils/formatDateTime";
import { DateTime } from "luxon";

export default function EbayPartMessages({ part }: { part: IPart }) {
  //part.id="266554555829";
  const { messages } = useEbayMessagesListing({ part: part });
  const [chainOpen, setChainOpen] = useState(false);
  const [chosenMessage, setChosenMessage] = useState<IEbayMessage | undefined>();

  return (
    <Card title="Ebay Messages" bodyClassName="px-6 py-5">
      {
        messages.isLoading && part.ebayItemNumber ?
          <LoadingWheel /> :
          <>
            <EbayMessageChain open={chainOpen} setOpen={setChainOpen} originMessage={chosenMessage} />
            <div className="flex-col">
              {
                messages.data?.data.length ?
                  messages.data?.data.map((message, i) => <div key={i} className="mb-2 hover:bg-slate-200 hover:underline cursor-pointer" onClick={() => { setChosenMessage(message); setChainOpen(true); }}>
                    <span className="underline"><span className="font-bold">{message.sendingUserID}</span> {formatDateTime(message.receiveDate, DateTime.DATETIME_SHORT)}</span>
                    <p className="">{message.body}</p>
                  </div>)
                  :
                  part.ebayItemNumber ? <div><p>No Messages</p></div> : <div><p>Unable to retrieve messages</p></div>
              }
            </div>
          </>
      }
    </Card>
  );
};