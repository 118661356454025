import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ICategoryResponse, IPartCategory } from "../models/Part";

export default function useCategories() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const categories = useQuery({
    queryKey: ["categories"],
    queryFn: () => get<ICategoryResponse>("/part/categories"),
  });

  const create = useMutation(
    (category: IPartCategory) => post(`/part/categories`, category),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const update = useMutation(
    (category: IPartCategory) => put(`/part/categories/${category.id}`, category),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const bulkUpdatePartCategories = useMutation(
    (body: any) => post(`/part/categories/bulk`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["categories"]);
        queryClient.invalidateQueries(["categoryParts"]);
      },
    }
  );

  return {
    categories,
    create,
    update,
    bulkUpdatePartCategories
  };
}
