export default function getAddress(obj) {
  let address = "";

    if (obj.line1) {
      address += `${obj.line1}, `;
    }

    if (obj.line2) {
      address += `${obj.line2}, `;
    }

    if (obj.line3) {
      address += `${obj.line2}, `;
    }

    if (obj.line4) {
      address += `${obj.line2}, `;
    }

    if (obj.locality) {
      address += `${obj.locality}, `;
    }

    if (obj.town) {
      address += `${obj.town}, `;
    }

    if (obj.county) {
      address += `${obj.county}, `;
    }

    if (obj.postcode) {
      address += `${obj.postcode}`;
    }
    
    return address;
}