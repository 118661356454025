import { Link } from "react-router-dom";
import { HomeIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

function Breadcrumb({ label, to, current }) {
  return (
    <li className="flex">
      <div className="flex items-center">
        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-500" />
        <Link
          to={to}
          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
          aria-current={current ? "page" : undefined}
        >
          <span dangerouslySetInnerHTML={{__html: label}}></span>
        </Link>
      </div>
    </li>
  );
}

export default function Breadcrumbs({ pages }) {
  return (
    <nav
      className="mt-4 bg-white border-b border-gray-200 rounded-lg shadow flex"
      aria-label="Breadcrumb"
    >
      <ol
        role="list"
        className="max-w-screen-xl w-full p-3 flex space-x-4 sm:px-6 lg:px-6"
      >
        <li className="flex">
          <div className="flex items-start">
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page, i) => (
          <Breadcrumb key={`${page.label}-${i}`} {...page} />
        ))}
      </ol>
    </nav>
  );
}
