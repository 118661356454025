import { useEffect, useState } from "react";
import useUtilities from "../../../data/useUtilities";
import Modal from "../../../layouts/Modal";
import getAddress from "../../../utils/getAddress";
import useNotification from "../../notifications/useNotifications";



export default function GetAddresses({ postcode, setAddress, open, setOpen }) {
  const [addresses, setAddresses] = useState([]);
  const { getAddresses } = useUtilities();
  const { addNotification } = useNotification();

  useEffect(() => {

    if (postcode) {
      getAddresses.mutate(postcode, {
        onSuccess: (data) => {
          console.log(data);
          if (data.data.length !== 0) {
            //Alphanumeric sort the addresses
            setAddresses(data.data.sort((a, b) => {
              //If there isn't a line 1 put it at the bottom
              return a.line1 ? a.line1.localeCompare(b.line1, 'en', { numeric: true }) : +1;
            }));
            setOpen(true);
          } else {
            addNotification({
              variant: "error",
              primaryText: "No addresses found",
            })
          }
        },
        onError: (error) => {
          console.error("error", error);
          addNotification({
            variant: "error",
            primaryText: "Could not get addresses",
          })
        },
      });
    }

  }, [postcode]);

  return (
    <Modal open={open} setOpen={setOpen} width="max-w-4xl">
      <div className="flex flex-col max-h-[80vh] overflow-auto w-full">
        {/* Display all addresses recieved from API */}
        {
          addresses.length > 0 && (
            addresses.map((address, i) => (
              <div
                key={i}
                className="flex flex-col w-full p-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  setAddress(address);
                  setOpen(false);
                }}
              >
                {getAddress(address)}
              </div>
            )))}
      </div>
    </Modal>
  );
}
