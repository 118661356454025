export async function handleResponse<T>(response: Response): Promise<T> {
  try {
    // Check if the response is successful
    if (response.ok) {
      // If the response contains JSON data
      if (
        response.headers.has("content-type") &&
        response.headers.get("content-type")?.includes("application/json")
      ) {
        return response.json();
      } else {
        // If the response is not JSON, return the raw response
        return response as T;
      }
    } else {
      // Handle HTTP error responses
      let errorMessage = `HTTP Error: ${response.status}`;

      if (
        (response.status === 400 || response.status === 401) &&
        response.headers.has("content-type") &&
        response.headers.get("content-type")?.includes("application/json")
      ) {
        errorMessage = await response.json();
      }

      // Throw an error with detailed information
      throw {
        message: errorMessage,
        response: response,
      };
    }
  } catch (error: any) {
    // Handle Axios error object with full details
    if (error.response) {
      // Server responded with a status code outside the 2xx range
      throw error; // Return the full Axios error object
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Axios error request:", error.request);
      throw error; // Return the full Axios error object
    } else {
      // Something happened in setting up the request that triggered an error
      console.error("Error message:", error.message);
      throw error; // Return the full Axios error object
    }
  }
}
