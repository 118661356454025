import { IContact } from "./Contact";
import { IJob } from "./Job";
import { IVehicle } from "./Vehicle";
import { BaseEntity, DefaultResponseWithData } from "./system";

export interface ILeadResponse {
  lastExported: string | null;
  lastImported: string | null;
  leads: ILead[];
}

export interface INewLead {
  CCID: string | null;
  website: LeadSource | null;
  source: string | null;
  price: number | null;
  commission: number | null;
  vehicleGrade: string | null;
  vehicle: {
    vrm: string | null;
    make: string | null;
    model: string | null;
    postcode: string | null;
    line1: string | null;
    line2: string | null;
    line3: string | null;
    line4: string | null;
    locality: string | null;
    town: string | null;
    county: string | null;
    isDropOff: boolean;
  };
  contact: {
    fullname: string | null;
    email: string | null;
    telephone1: string | null;
    telephone2: string | null;
  };
}

export interface ISingleLeadRespose extends DefaultResponseWithData<ILead> {}

export interface IAllLeadsResponse
  extends DefaultResponseWithData<ILeadResponse> {}

export interface IPaymentsLeadsResponse
  extends DefaultResponseWithData<ILead[]> {}

export interface IUnscheduledLeadsResponse
  extends DefaultResponseWithData<ILead[]> {}

export enum LeadStatus {
  "Awaiting Contact",
  "Scheduled",
  "Cancelled",
  "Reschedule",
  "Collected",
  "Awaiting Payment",
  "Awaiting COD",
  "Customer to Call Back",
  "Completed",
  "All",
}

export enum LeadSource {
  GPM,
  SCC,
  CTB,
  RMC,
  Motorwise,
}

export enum PaymentStatus {
  "Not Started",
  "Raised",
  "Sent",
  "Failed",
  "Approved",
}

export enum LeadQualified {
  "No Reply",
  "Qualified",
  "Stop",
  "No Match",
}

export interface ILead extends BaseEntity {
  tripId: string | null;
  truckId: string | null;
  contactId: string | null;
  driverId: string | null;
  driver: IContact | null;
  ccid: string | null;
  contact: IContact | null;
  officeNotes: string | null;
  driverNotes: string | null;
  vehicleId: string | null;
  vehicle: IVehicle | null;
  status: LeadStatus;
  website: LeadSource;
  source: string | null;
  price: number;
  applyVAT: boolean;
  commission: number;
  notes: string;
  completedOn: string;
  qualified: LeadQualified;
  exportedOn: string | null;
  partsLookupRequestId: string | null;
  paymentStatus: PaymentStatus;
  paymentMessage: string | null;
  paymentRaisedBy: string | null;
  paymentApprovedBy: string | null;
  paymentConfirmedOn: string;
  paymentType: string | null;
  accountName: string | null;
  accountNumber: string | null;
  sortCode: string | null;
  paymentReference: string | null;
  cardNumber: string | null;
  payeeDOB: string | null;
  chequeName: string | null;
  chequeNumber: string | null;
  premiumPriceUsed: string | null;
  scheme: string | null;
  vehicleGrade: string | null;
  callCount: number;
  callCountUpdatedOn: string | null;
  callCountUpdatedBy: string | null;
  documentsRequested: string | null;
  order: number;
  jobs: IJob[] | null;
  catalyticAveragePrice: number | null;
  correctedVrm: string | null;
}
