import { useQuery } from "@tanstack/react-query";

import { IPart } from "../models/Part";
import useApiHelper from "../hooks/useApiHelper";
import { IEbayMessageResponse } from "../models/EbayMessage";

export default function useEbayMessagesListing({ part }: { part: IPart }) {
  const { get } = useApiHelper();
  const staleTime = 1000 * 60 * 10; // 10 minutes

  const messages = useQuery({
    queryKey: ["ebaylistingmessages", part.id],
    queryFn: () => get<IEbayMessageResponse>(`/ebaymessaging/itemmessages/${part.id}`),
    staleTime: staleTime,
  });

  // const applyPromotion = useMutation(
  //   ({part, promo}: {part: IPart | undefined, promo: IPromotion}) => put(`/ebaysafe/promotions/additem?promotionId=${promo.promotionId}&listingSKU=${part?.id}`, null),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["promotions"]);
  //     },
  //   }
  // );

  return {
    messages
  };
}
