import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ITableDisplayColumnResponse, TableType } from "../models/TableDisplayColumn"

export default function useTableDisplayColumns(tableType: TableType) {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const displayColumns = useQuery({
    queryKey: ["tableType", tableType],
    queryFn: () => get<ITableDisplayColumnResponse>(`/TableDisplay/${tableType}`),
  });

  return {
    displayColumns
  };
}
