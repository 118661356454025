import { useState } from "react";
import classNames from "../../utils/classNames";
import DashboardTile from "./DashboardTile";


interface DashboardTileMultipleProps {
  values: string[];
  labels: string[];
  subLabels: string[];
  colour?: string;
};

export default function DashboardTileMultiple({ values, labels, subLabels, colour = "slate" }: DashboardTileMultipleProps) {
  const [tileStage, setTileStage] = useState(0);

  const circleCss = "rounded-full h-2 w-2 bg-gray-600";

  const getOpacity = (card: number) => {
    if (tileStage === card) {
      return "opacity-100";
    } else {
      return "opacity-60";
    }
  };

  const handleTileStage = () => {

    if (tileStage === values.length - 1) {
      setTileStage(0);
    } else {
      setTileStage(tileStage + 1);
    }
  };

  return (
    <DashboardTile>
      <div
        className={`h-full rounded bg-${colour}-100 text-${colour}-600 p-2 px-4 prose-base hover:bg-${colour}-200 select-none cursor-pointer`}
        onClick={() => handleTileStage()}
      >
        <div className="flex justify-between">
          <h1 className={`text-${colour}-600 font-semibold`}>
            {values[tileStage]}
          </h1>
          <div className="flex gap-x-1">
            {values.map((_, i) => (
              <div
                key={i}
                className={classNames(getOpacity(i), circleCss)}
              ></div>
            ))}
          </div>
        </div>
        <p className="!m-0">{labels[tileStage]}</p>
        <p className="!m-0 text-sm">{subLabels[tileStage]}</p>
      </div>
    </DashboardTile>
  )
}