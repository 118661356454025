import { IEnginesWorldwide } from "./EnginesWorldwide";
import { ILocation } from "./Location";
import { IPart } from "./Part";
import { BaseEntity, DefaultResponseWithData } from "./system";

export interface IAllVehicleResponse
  extends DefaultResponseWithData<IVehicle[]> {}
export interface ISingleVehicleResponse
  extends DefaultResponseWithData<IVehicle> {}

export interface IEbayEngineDataResponse
  extends DefaultResponseWithData<IEbayEngineData> {}

export enum ScheduledCollectionSlot {
  "All Day",
  "AM",
  "PM",
}

// export enum PartsStatus {
//   "Awaiting Dismantling",
//   "Export - Engine & Frontend",
//   "Parts No Engine",
//   "Scrap",
//   "Cancelled",
//   "Parts Export",
//   "Salvage",
//   "Export - Engine",
//   "Export - Frontend",
//   "Parts Only",
// }

export enum PartsStatusValues {
  "Awaiting Schedule" = 0,
  "Export - Engine + Frontend" = 1,
  "Parts - No Engine" = 2,
  "Scrap" = 3,
  "Cancelled" = 4,
  "Parts + Export" = 5,
  "Salvage" = 6,
  "Export - Engine" = 7,
  "Export - Frontend" = 8,
  "Parts - Engine + Gearbox" = 9,
}

export enum PartsStatusDropdowns {
  "Awaiting Schedule",
  "Parts - Engine + Gearbox",
  "Parts - No Engine",
  "Parts + Export",
  "Export - Engine",
  "Export - Frontend",
  "Export - Engine + Frontend",
  "Salvage",
  "Scrap",
  "Cancelled",
}

export enum YardStatus {
  "None",
  "Issues",
  "Awaiting Parts",
  "Awaiting Depo",
  "Awaiting Export",
  "Crush",
  "Awaiting Garage Assistance",
  "Awaiting Salvage",
  "Cancelled",
}

export interface IEbayEngineData extends BaseEntity {
  make: string;
  engineCode: string;
  url: string;
  averagePrice: number;
  lowPriceRange: number;
  highPriceRange: number;
  totalSold: number;
  totalSales: number;
  demand: number;
}

export interface IVehicle extends BaseEntity {
  latitude: number | null | undefined;
  longitude: number | null | undefined;
  contactId: string | null;
  vrm: string;
  found: boolean;
  notes: string | null;
  line1: string | null;
  line2: string | null;
  line3: string | null;
  line4: string | null;
  locality: string | null;
  town: string | null;
  county: string | null;
  postcode: string | null;

  accessIssues: boolean;
  vehicleComplete: boolean;
  freeOfWaste: boolean;
  vehicleStarting: boolean;
  vehicleStartingDriver: boolean;
  vehiclePremium: boolean;
  isVan: boolean;
  hasAlert: boolean;

  locationId: string | null;
  yardLocation: ILocation | null;

  enginesWorldwide: IEnginesWorldwide[];

  codRequired: boolean;
  codCertificateNumber: string | null;
  codCertificateDocument: string | null;

  collectionNoteDocument: string | null;

  allocatedToStock: boolean;
  scheduledCollectionOn: string | null;
  scheduledCollectionSlot: ScheduledCollectionSlot;
  collectionStart: string | null;
  collectionEnd: string | null;
  collectedOn: string | null;
  collectedBy: string | null;
  bookedCollectionOn: string | null;
  bookedCollectionBy: string | null;
  paymentConfirmedOn: string;
  codConfirmedOn: string | null;
  completedOn: string;
  enginesWorldwideEngineOffer: string | null;
  enginesWorldwideVfeOffer: string | null;
  ebayEnginePrice: number | null | undefined;
  engineExportStatus: PartsStatusValues;
  yardStatus: YardStatus;

  parts: IPart[];

  odometerInMiles: number | null;
  valuationTradePoor: number | null;
  visibilityDate: string | null;
  terminateDate: string | null;
  motExpiryDate: string | null;

  exportedOn: string | null;
  bodyStyle: string;
  cherishedTransferDate: string | null;
  cherishedTransferMarker: number | null;
  colourCurrent: string;
  colourOriginal: string;
  colourLast: string;
  colourLastDateOfChange: string | null;
  colourPrevCount: number | null;
  cO2: number | null;
  dateFirstRegistered: string | null;
  dateOfFirstRegistrationUK: string | null;
  dvlaYearOfManufacture: number | null;
  dateOfPreviousKeeperAcquisition: string | null;
  dateOfPreviousKeeperDisposal: string | null;
  dvlaMake: string;
  dvlaModel: string;
  dvlaVehicleDescription: string;
  dvlaWheelPlanDesc: string;
  engineNumber: string;
  engineCapacity: number | null;
  exportDate: string | null;
  exportMarker: number | null;
  fuelDescription: string;
  fuelType: string;
  grossWeightKG: number | null;
  importDate: string | null;
  importMarker: boolean;
  importMarkerFromNorthernIreland: boolean;
  issueDateOfLatestV5: string | null;
  numberOfPreviousKeepers: number | null;
  scrappingDate: string | null;
  scrappingMarker: number | null;
  scrappedRemovedMarker: boolean;
  scrapStatus: string;
  startDateOfCurrentKeeper: string | null;
  usedBeforeFirstRegistrationMarker: boolean;
  vinOriginalDVLA: string;
  vrmCurr: string;
  vrmNorthernIreland: string;
  wheelPlan: string;
  accelerationTo100KPHSecs: number | null;
  arrangementOfCylinders: string;
  aspiration: string;
  bodyStyleDescription: string;
  bore: number | null;
  busSeatingCapacity: number | null;
  coGtoKm: number | null;
  combinedEngineCapacity: number | null;
  combinedForwardGears: number | null;
  combinedFuelType: string;
  combinedMake: string;
  combinedModel: string;
  combinedTransmission: string;
  combinedVIN: string;
  combinedVINLast8: string;
  countryOfOrigin: string;
  delivery: string;
  driveAxle: string;
  driveType: string;
  dvlaForwardGears: number | null;
  dvlaTransmission: string;
  engineLocation: string;
  engineManufacturer: string;
  engineModelCode: string;
  euroStatus: string;
  exportFromGBDate: string | null;
  exportFromNIDate: string | null;
  fuelConsumptionCombinedLtr100km: number | null;
  fuelConsumptionExtraUrbanLtr100km: number | null;
  fuelConsumptionUrbanColdLtr100km: number | null;
  fuelConsumptionCombinedMPG: number | null;
  fuelConsumptionExtraUrbanMPG: number | null;
  fuelConsumptionUrbanColdMPG: number | null;
  forwardGears: number | null;
  hc: number | null;
  heightMM: number | null;
  introductionDateToUK: string | null;
  kerbWeightMin: number | null;
  kerbWeightMax: number | null;
  lengthMM: number | null;
  lrHandDrive: string;
  loadspaceLength: number | null;
  manufacturerBuildDate: string | null;
  marketSegment: string;
  marqueDescription: string;
  massInService: number | null;
  maximumNettPower: number | null;
  maximumPowerAtRPM: number | null;
  maximumPowerBHP: number | null;
  maximumPowerInKW: number | null;
  maximumSpeedKPH: number | null;
  maximumSpeedMPH: number | null;
  maximumTechnicallyPermissableMass: number | null;
  maximumTorqueAtRPM: number | null;
  maximumTorqueLbFt: number | null;
  maximumTorqueNM: number | null;
  modelRangeDescription: string;
  modelSeries: string;
  modelVariantDescription: string;
  modelYear: number | null;
  nomCC: string;
  nox: number | null;
  numberOfCylinders: number | null;
  numberOfDoors: number | null;
  numberOfValvesPerCylinder: number | null;
  originalSalesType: string;
  particulatesGtoKm: number | null;
  payloadForVans: number | null;
  powerWeightRatioMotorcyclesOnly: number | null;
  seatingCapacityInclDriver: number | null;
  seats: number | null;
  soundLevelEngineSpeed: number | null;
  soundLevelDriveBy: number | null;
  soundLevelStationary: number | null;
  cwEngineCapacity: number | null;
  stroke: number | null;
  techPermMaxTowableMassOfTrailerBraked: number | null;
  techPermMaxTowableMassOfTrailerUnbraked: number | null;
  transmission: string;
  unladenWeightForVans: number | null;
  valveGear: string;
  vehicleCategoryDescription: string;
  vinVerified: string;
  wheelbaseForVans: string;
  widthMM: number | null;
  ncapAdult: number | null;
  ncapChild: number | null;
  ncapPedestrian: number | null;
  ncapSafety: number | null;
  ncapOverall: number | null;
  vehicleImageUrl: string;
  kType: number | null;
  nType: number | null;
  catalyticAveragePrice: number | null;
  catalyticResults: CatalyticResult[] | null;

  vinvisColourCode: string;
  vinvisTransmissionCode: string;
  isDropOff: boolean;
  correctedVrm: string | null;
  originalVrm: string | null;
}

export interface CatalyticResult {
  engineSizeMatch: boolean;
  serialNumber: string;
  horsepowerMatch: boolean;
  varientMatch: boolean;
  fuelTypeMatch: boolean;
  sid: number;
  value: number;
  id: string;
  created: string;
  updated: any;
}
