import { useForm } from "react-hook-form";

import { UseMutationResult } from "@tanstack/react-query";

import useMappings from "../../data/useMappings";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { IPartCategory, IPartMapping } from "../../models/Part";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { NumberInput } from "../shared/Inputs/NumberInput";
import { TextInput } from "../shared/Inputs/TextInput";
import CategoryDetails from "./CategoryDetails";
import { CategoryListingDefaults } from "./CategoryListingDefaults";
import CategoryAspectsDefaults from "./CategoryAspectsDefaults";
import { useEffect } from "react";


type MappingFormValues = {
  regex: string,
  priority: number
};

interface CategoryModalProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  category: IPartCategory | undefined,
  update: UseMutationResult<IPartCategory, unknown, IPartCategory, unknown>,
  create: UseMutationResult<IPartCategory, unknown, IPartCategory, unknown>
};

function MappingRow({ mapping, categoryId, setOpen }: { mapping: IPartMapping | null, categoryId: string, setOpen: any }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Part Mapping");
  const { create, update } = useMappings();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm<MappingFormValues>({
    defaultValues: {
      regex: mapping?.regex,
      priority: mapping?.priority,
    }
  });

  const onSubmit = (data: MappingFormValues) => {
    console.log(data);

    const updatedMapping = {
      partCategoryId: categoryId,
      ...mapping!,
      ...data,
    };

    if (mapping) {
      update.mutate(updatedMapping, saveHandlers);
    } else {
      create.mutate(updatedMapping, saveHandlers);
      reset({});
      setOpen(false);
    }

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-7 gap-x-6">
        <div className="col-span-4">
          <TextInput label="Regex" registerName="regex" register={register} />
        </div>
        <div className="col-span-2">
          <NumberInput label="Priority" registerName="priority" step="1" register={register} />
        </div>
        <ButtonInput label={mapping ? "Save" : "Create"} isSubmit={true} onClick={undefined} classes={"mt-7"} />
      </div>
    </form>
  )
};


export default function CategoryModal({ open, setOpen, category, update, create }: CategoryModalProps) {

  useEffect(() => {

  }, [category, open])

  return (
    <>
      <Modal width="max-w-7xl" open={open} setOpen={setOpen} >
        <div className="flex justify-between items-center">
          <h1 className="text-xl">{`${category ? "Edit" : "Add"} a Category`}</h1>
          {category && <ButtonInput label="View Parts" isSubmit={false} onClick={() => window.open(`/parts-editor/${category?.id}`, "_blank", "noreferrer")} classes={undefined} />}
        </div>
        <div className="mt-4">
          <CategoryDetails category={category} update={update} create={create} open={open} setOpen={setOpen} />
          <br />
          {category &&
            <CategoryListingDefaults category={category} />
          }

          {category?.partCategoryDefaults[0]?.ebayCategoryId && (
            <CategoryAspectsDefaults category={category} />
          )}

          {category &&
            <div className="w-full mt-4 space-y-4">
              <h1 className="text-lg">Part Mappings <span className="text-sm"> - Smallest number is highest priority</span></h1>
              <hr />
              {category?.partMappings.map((mapping) => (
                <MappingRow key={mapping.id} mapping={mapping} categoryId={category.id} setOpen={setOpen} />
              ))}
              <MappingRow mapping={null} categoryId={category.id} setOpen={setOpen} />
            </div>
          }
        </div>
      </Modal>
    </>
  );
};
