import { useEffect, useRef, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";

import LoadingWheel from "../../components/shared/LoadingWheel";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { IWarehouseLocation } from "../../models/Location";
import useWarehouseLocations from "../../data/useWarehouseLocations";
import WarehouseLocationsTable from "../../components/warehouseLocations/WarehouseLocationsTable";
import WarehouseLocationsModal from "../../components/warehouseLocations/WarehouseLocationsModal";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";

export default function WarehouseLocations() {
  const { setInfo } = usePageNameContext();
  const { warehouseLocations: allLocations, create, update } = useWarehouseLocations();
  const [locations, setLocations] = useState<IWarehouseLocation[] | undefined>(undefined);
  const [location, setLocation] = useState<IWarehouseLocation | undefined>(undefined);
  const [editLocation, setEditLocation] = useState<IWarehouseLocation | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [createOpen, setCreateOpen] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Warehouse Locations",
      desc: "View and manage warehouse shelving locations",
    });
  }, []);

  useEffect(() => {
    if (!allLocations.isLoading && allLocations.data) {

      // Filtering code goes here

      setLocations(allLocations.data?.data);
      setIsLoading(false);
    }
  }, [allLocations.isLoading, allLocations.data, searchTerm]);

  const handleSetLocation = (location: IWarehouseLocation) => {
    setEditLocation(location);
    setCreateOpen(true);
  };

  const handleAddLocation = () => {
    setEditLocation(undefined);
    setCreateOpen(true);
  }

  return allLocations.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <WarehouseLocationsModal open={createOpen} setOpen={setCreateOpen} location={editLocation} />
      <div className="mt-4 flex justify-between">

        {/* Button for adding a new location */}
        <ButtonInput isSubmit={false} onClick={() => handleAddLocation()} classes={undefined} >
          <div className="flex items-center">
            <PlusSmallIcon className="h-5 w-5 mr-2" />
            Add New Location
          </div>
        </ButtonInput>

      </div>

      {/* Table of warehouse locations */}
      <WarehouseLocationsTable locations={locations!} setLocation={handleSetLocation} />
      {/* <LocationsTable locations={locations!} setLocation={handleSetLocation} /> */}
    </>
  );
}
