import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { faCar, faRoad, faStar, faTrashCan, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EngineIcon from "../../assets/svgs/engine.svg";
import useUtilities from "../../data/useUtilities";
import useVehiclesMutations from "../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { ContactType } from "../../models/Contact";
import { IVehicle } from "../../models/Vehicle";
import getAddress from "../../utils/getAddress";
import useNotification from "../notifications/useNotifications";
import Card from "../shared/Card/Card";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { TextInput } from "../shared/Inputs/TextInput";

type FormValues = {
  fullname: string;
  contactType: ContactType;
  telephone1: string;
  telephone2: string;
  email: string;
  line1: string | null;
  line2: string | null;
  line3: string | null;
  line4: string | null;
  locality: string | null;
  town: string | null;
  county: string | null;
  postcode: string | null;
  accessIssues: boolean;
  vehicleComplete: boolean;
  freeOfWaste: boolean;
  vehicleStarting: boolean;
  vehiclePremium: boolean;
  isVan: boolean;
  hasAlert: boolean;
};

export default function VehicleLocation({ vehicle }: { vehicle: IVehicle }) {
  const { update } = useVehiclesMutations();
  const { getAddresses } = useUtilities();
  const { saveHandlers } = useDefaultCRUDHandlers("Vehicle location details");
  const { addNotification } = useNotification();
  const [addresses, setAddresses] = useState<any[]>([]);
  const [addressesOpen, setAddressesOpen] = useState(false);
  const { register, getValues, setValue, watch, handleSubmit, reset, control } =
    useForm<FormValues>({
      defaultValues: {
        // fullname: vehicle.fullname.trim() ?? "",
        // contactType: vehicle.contactType,
        // telephone1: vehicle.telephone1,
        // telephone2: vehicle.telephone2,
        // email: vehicle.email,
        line1: vehicle.line1 ? vehicle.line1.trim() : "",
        line2: vehicle.line2,
        line3: vehicle.line3,
        line4: vehicle.line4,
        locality: vehicle.locality,
        town: vehicle.town,
        county: vehicle.county,
        postcode: vehicle.postcode,
        accessIssues: vehicle.accessIssues,
        vehicleComplete: vehicle.vehicleComplete,
        freeOfWaste: vehicle.freeOfWaste,
        vehicleStarting: vehicle.vehicleStarting,
        vehiclePremium: vehicle.vehiclePremium,
        isVan: vehicle.isVan,
        hasAlert: vehicle.hasAlert,
      },
    });

  const onSubmit = (data: any) => {
    const updatedVehicle = {
      ...vehicle,
      ...data,
    };

    // console.log(data);
    update.mutate(updatedVehicle, saveHandlers);

    reset({
      fullname: data.fullname,
      telephone1: data.telephone1,
      telephone2: data.additionalTelephone,
      email: data.email,
      line1: data.line1,
      line2: data.line2,
      line3: data.line3,
      line4: data.line4,
      town: data.town,
      county: data.county,
      postcode: data.postcode,
      accessIssues: data.accessIssues,
      vehicleComplete: data.vehicleComplete,
      freeOfWaste: data.freeOfWaste,
      vehicleStarting: data.vehicleStarting,
      vehiclePremium: data.vehiclePremium,
      isVan: data.isVan,
      hasAlert: data.hasAlert,
    });
  }

  const handlePostcode = () => {
    const postcode = getValues("postcode");

    getAddresses.mutate(postcode!, {
      onSuccess: (data: any) => {
        console.log(data);
        if (data.data.length !== 0) {
          //Alphanumeric sort the addresses
          setAddresses(data.data.sort((a: any, b: any) => {
            //If there isn't a line 1 put it at the bottom
            return a.line1 ? a.line1.localeCompare(b.line1, 'en', { numeric: true }) : +1;
          }));
          setAddressesOpen(true);
        } else {
          addNotification({
            variant: "error",
            primaryText: "No addresses found",
          })
        }
      },
      onError: (error) => {
        console.error("error", error);
        addNotification({
          variant: "error",
          primaryText: "Could not get addresses",
        })
      },
    });
  }

  useEffect(() => {

  }, [vehicle, addresses])

  return (
    <>
      <Modal open={addressesOpen} setOpen={setAddressesOpen} width="max-w-4xl">
        <div className="flex flex-col max-h-[80vh] overflow-auto w-full">
          {/* Display all addresses recieved from API */}
          {
            addresses.length > 0 && (
              addresses.map((address: any) => (
                <div
                  key={address.id}
                  className="flex flex-col w-full p-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setValue("line1", address.line1);
                    setValue("line2", address.line2);
                    setValue("line3", address.line3);
                    setValue("line4", address.line4);
                    setValue("locality", address.locality);
                    setValue("town", address.town);
                    setValue("county", address.county);
                    setValue("postcode", address.postcode);
                    setAddressesOpen(false);
                  }}
                >
                  {getAddress(address)}
                </div>
              )))}
        </div>
      </Modal>
      <Card title="Vehicle Pickup Details" bodyClassName="p-4">

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">

          <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
            <TextInput label="Postcode" register={register} registerName="postcode" />
            <ButtonInput classes="mt-7 w-1/2" label="Get Addresses" isSubmit={false} onClick={() => handlePostcode()} />
            <TextInput label="Address Line 1" register={register} registerName="line1" />
            <TextInput label="Address Line 2" register={register} registerName="line2" />
            <TextInput label="Address Line 3" register={register} registerName="line3" />
            <TextInput label="Address Line 4" register={register} registerName="line4" />
            <TextInput label="Locality" register={register} registerName="locality" />
            <TextInput label="Town" register={register} registerName="town" />
            <TextInput label="County" register={register} registerName="county" />
          </div>

          <div className="gap-x-4 gap-y-4 sm:gap-y-8">
            <div>
              <dt className="text-sm font-medium text-gray-500">Full Address</dt>
              <dd className="mt-1 text-sm text-gray-900">{getAddress(vehicle)}</dd>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-1 sm:grid-cols-3">
              <div className="flex items-center gap-x-2">
                <input {...register("isVan")} type="checkbox" />
                <FontAwesomeIcon icon={faTruck} />
                <label>Van</label>
              </div>
              <div className="flex items-center gap-x-2">
                <input {...register("hasAlert")} type="checkbox" />
                <label>Alert</label>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3">
              <div className="flex items-center gap-x-2">
                <input {...register("accessIssues")} type="checkbox" />
                <FontAwesomeIcon icon={faRoad} />
                <label>Access issues</label>
              </div>
              <div className="flex items-center gap-x-2">
                <input {...register("vehicleComplete")} type="checkbox" />
                <FontAwesomeIcon icon={faCar} />
                <label>Vehicle complete</label>
              </div>
              <div className="flex items-center gap-x-2">
                <input {...register("freeOfWaste")} type="checkbox" />
                <FontAwesomeIcon icon={faTrashCan} />
                <label>Vehicle free of waste</label>
              </div>
              <div className="flex items-center gap-x-2">
                <input {...register("vehicleStarting")} type="checkbox" />
                <img src={EngineIcon} className="h-5 w-5" />
                <label>Vehicle starting</label>
              </div>
              <div className="flex items-center gap-x-2">
                <input {...register("vehiclePremium")} type="checkbox" />
                <FontAwesomeIcon icon={faStar} className="text-yellow-600" />
                <label>Premium vehicle</label>
              </div>
            </div>

          </div>

          <ButtonInput classes={null} label="Update" isSubmit={true} onClick={null} />
        </form>

      </Card>
    </>
  );
}
