import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllScrapPricesResponse, IScrapPrice } from "../models/ScrapPrice";

export default function useScrapPrices() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const scrapPrices = useQuery({
    queryKey: ["scrapPrices"],
    queryFn: () => get<IAllScrapPricesResponse>("/scrapprice"),
    refetchInterval: 1000 * 10,
  });

  const create = useMutation(
    (scrapPrice: IScrapPrice) => post("/scrapprice", scrapPrice),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["scrapPrices"]);
      },
    }
  );

  const update = useMutation(
    (scrapPrice: IScrapPrice) => put(`/scrapprice/${scrapPrice.id}`, scrapPrice),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["scrapPrices"]);
      },
    }
  );

  const remove = useMutation(
    (scrapPriceId: string) => del(`/scrapprice/${scrapPriceId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["scrapPrices"]);
      },
    }
  );

  return {
    scrapPrices,
    create,
    update,
    remove,
  };
}
