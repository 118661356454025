import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useApiHelper from "../hooks/useApiHelper";
import { IPartAssemblyResponse, IPartAssembly } from "../models/Part";

export default function usePartAssembly(categoryId?: string | undefined) {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const assemblies = useQuery({
    queryKey: ["assemblies"],
    queryFn: () => get<IPartAssemblyResponse>("/PartAssembly"),
  });

  const assembliesByCategory = useQuery({
    queryKey: ["assemblies", categoryId],
    queryFn: () => get<IPartAssemblyResponse>(`/PartAssembly/by-category/${categoryId}`),
    enabled: !!categoryId,
  });

  const create = useMutation(
    (assembly: IPartAssembly) => post("/PartAssembly", assembly),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assemblies"]);
      },
    }
  );

  const update = useMutation(
    (assembly: IPartAssembly) => put(`/PartAssembly/${assembly.id}`, assembly),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assemblies"]);
      },
    }
  );

  const remove = useMutation(
    (id: string) => del(`/PartAssembly/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assemblies"]);
      },
    }
  );

  const toggleCategory = useMutation(
    (data: { assemblyId: string; categoryId: string }) =>
      post(
        `/PartAssembly/${data.assemblyId}/togglecategory/${data.categoryId}`,
        {}
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assemblies"]);
      },
    }
  );

  return {
    assemblies,
    assembliesByCategory,
    create,
    update,
    remove,
    toggleCategory
  };
}