import { useEffect, useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";

import { ILead, LeadStatus } from "../../models/Lead";
import VehiclesCollectedTable from "../../components/vehiclesCollected/VehiclesCollectedTable";
import useLeadsWithFilters from "../../data/useLeadsWithFilters";
import { useDebounce } from "@uidotdev/usehooks";

export default function VehiclesCollected() {
  const { setInfo } = usePageNameContext();
  const [leads, setLeads] = useState<ILead[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [hideAwaitingCod, setHideAwaitingCod] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const refreshTriggers = useDebounce([searchTerm], 500);

  const { leadsWithFilters } = useLeadsWithFilters({ query: refreshTriggers[0] as string, pageFilter: 3 });

  useEffect(() => {
    setInfo({
      name: "Vehicles Collected",
      desc: "View and manage your collected and not yet completed leads here",
    });
  }, []);

  useEffect(() => {
    if (!leadsWithFilters.isLoading) {

      let filter = leadsWithFilters.data?.data.leads;

      if (hideAwaitingCod) {
        filter = filter?.filter((lead) =>
          lead.status !== LeadStatus["Awaiting COD"]
        );
      }

      setLeads(filter);
      setIsLoading(false);
    }
  }, [leadsWithFilters.isLoading, leadsWithFilters.data, hideAwaitingCod, refreshTriggers]);

  return (
    <>
      <div className="mt-2 flex justify-between">
        <div className="flex gap-x-5">

          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 sm:text-sm"
              placeholder="Name, VRM or Postcode"
            />
          </div>

          <div className="flex items-center">
            <input id="hideAwaitingCod" onChange={(e) => setHideAwaitingCod(e.target.checked)} value="hideAwaitingCod" checked={hideAwaitingCod} type="checkbox" className="mr-2 h-5 w-5" />
            <label htmlFor="hideAwaitingCod">Hide Awaiting COD</label>
          </div>

        </div>
        <div className="flex items-center">

        </div>
      </div>
      {
        leadsWithFilters.isLoading || isLoading ? (
          <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
            <LoadingWheel />
          </div>
        ) : (<VehiclesCollectedTable data={leads!} />)
      }
    </>
  );
}
