import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ICategoryResponse, IPartCategory, ISingleCategoryResponse } from "../models/Part";

export default function useCategorySingle(categoryId: string | undefined) {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const category = useQuery({
    queryKey: ["categories", "single", categoryId],
    queryFn: () => get<ISingleCategoryResponse>(`/part/categories/${categoryId}/single`),
    enabled: !!categoryId,
  });

  const create = useMutation(
    (category: IPartCategory) => post(`/part/categories`, category),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const update = useMutation(
    (category: IPartCategory) => put(`/part/categories/${category.id}`, category),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["categories"]);
      },
    }
  );

  const bulkUpdatePartCategories = useMutation(
    (body: any) => post(`/part/categories/bulk`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["categories"]);
        queryClient.invalidateQueries(["categoryParts"]);
      },
    }
  );

  return {
    category,
    create,
    update,
    bulkUpdatePartCategories
  };
}
