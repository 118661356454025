import {
  PhotoIcon,
  PlusIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { Buffer } from "buffer";

import { IDocument } from "./../../models/Document";
import Modal from "../../layouts/Modal";
import { useState } from "react";

interface VehicleImageThumbProps {
  image: IDocument;
  index: number;
  onClick: (file: File) => void;
}

export function VehicleImageThumb({ image, index, onClick }: VehicleImageThumbProps) {
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleAddClick = () => {
    var binaryString = Buffer.from(image.contents, "base64");
    var blob = new Blob([binaryString], { type: image.contentType! });
    var file = new File([blob], image.fileName!);
    onClick(file);
  };

  const imgThumb = image.thumbnail ? (
    <img
      src={`data:image/png;base64, ${image.thumbnail!} `}
      alt={image.fileName!}
      className="rounded-sm object-contain w-full h-full"
    />
  ) : image.contentType?.includes("video") ? (
    <VideoCameraIcon className="w-1/2 rounded-sm object-contain" />
  ) : (
    <PhotoIcon className="w-1/2 rounded-sm object-contain" />
  );

  return (
    <>
      <Modal open={previewOpen} width={"max-w-7xl"} setOpen={setPreviewOpen} >
        <div className="w-full h-full flex justify-center items-center">
          <img src={`data:image/png;base64, ${image.contents!}`} alt="Preview" className="max-w-full max-h-full" />
        </div>
      </Modal>

      <div
        key={`vehicle-image-${index}`}
        className="text-center relative w-36 h-36 snap-start"
      >
        <div onClick={() => setPreviewOpen(true)} className="cursor-pointer relative w-36 h-36 flex justify-center z-0">
          {imgThumb}
        </div>
        <div
          onClick={handleAddClick}
          className="absolute cursor-pointer translate-x-1/2 top-0 right-0 text-center p-1 bg-gp-blue-600 text-white rounded-full h-8 w-8"
        >
          <PlusIcon className="w-6 h-6" />
        </div>
      </div>
    </>
  );
}
