import { useQueryClient } from "@tanstack/react-query";

import useVehiclesMutations from "../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { PartsStatusValues, IVehicle, PartsStatusDropdowns } from "../../models/Vehicle";

export default function AwaitingInventoryStatusDropdown({ vehicle, classes = "" }: { vehicle: IVehicle, classes?: string }) {
  const queryClient = useQueryClient();
  const { update } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Parts Status");

  const partsStatus = [
    ...Object.values(PartsStatusDropdowns).filter((ct) => isNaN(Number(ct)))
  ];

  const handleStatusUpdate = (status: string) => {

    const updatedVehicle: IVehicle = {
      ...vehicle,
      //@ts-ignore
      engineExportStatus: PartsStatusValues[status],
    };

    // console.log("Vehicle:", vehicle.engineExportStatus)
    // console.log("updatedVehicle export status:", updatedVehicle.engineExportStatus)

    update.mutate(updatedVehicle, {
      onSuccess: () => {
        queryClient.refetchQueries(["leads"]);
        saveHandlers.onSuccess();
      },
      onError: (error) => {
        saveHandlers.onError();
      }
    });
  }

  return (
    <div className="w-full h-full flex items-center" onClick={(e) => e.stopPropagation()}>

      <select aria-label="Lead status filter"
        onChange={(e) => handleStatusUpdate(e.target.value)}
        value={PartsStatusValues[vehicle.engineExportStatus]}
        className={`${classes} border p-1`}>
        {partsStatus.map((status) => (
          <option
            className="sm:text-sm"
            key={status}>
            {String(status)}
          </option>
        ))}
      </select>
      {/* <select value={vehicle.engineExportStatus} className={`${classes} border p-1`} onChange={(e) => handleStatusUpdate(e.target.value)}>
        {Object.values(PartsStatusValues).filter((ct) => isNaN(Number(ct))).map((status, i) => (
          <option key={status} value={i}>
            {status}
          </option>
        ))}
      </select> */}
    </div>
  )
}
