import { useEffect, useState } from 'react';
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';

import { ILead } from "../../models/Lead";
import { ScheduledCollectionSlot } from "../../models/Vehicle";
import useSingleSetting from "../../data/useSingleSetting";


export default function CalendarDayMap({ leads, selectedLead }: { leads: ILead[], selectedLead: ILead | undefined }) {
  const [icon, setIcon] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { singleSetting: longSetting } = useSingleSetting("MapCentre:Long");
  const { singleSetting: latSetting } = useSingleSetting("MapCentre:Lat");

  const [mapCentreLong, setMapCentreLong] = useState<number>(51.049758);
  const [mapCentreLat, setMapCentreLat] = useState<number>(-2.407408);
  const [coordinatesLoading, setCoordinatesLoading] = useState(true);
  // const myCustomColour = '#138200';

  //   const markerHtmlStyles = `
  //     background-color: ${myCustomColour};
  //     width: 2.5rem;
  //     height: 2.5rem;
  //     display: block;
  //     left: -1rem;
  //     top: -1rem;
  //     position: relative;
  //     border-radius: 3rem 3rem 0;
  //     transform: rotate(45deg);
  //     border: 1px solid #FFFFFF;`;

  const customIcon = L.divIcon({
    className: "my-custom-pin",
    iconSize: [32, 32],
    iconAnchor: [18, 36],
    popupAnchor: [0, -36],
    html: `<span class="custom-pin"/>` //style="${markerHtmlStyles}"
  });

  useEffect(() => {
    setIcon(customIcon);
    setIsLoading(false);
  }, [leads])

  useEffect(() => {
    if (longSetting.isLoading || latSetting.isLoading) return;
    if (longSetting.data == null || longSetting.data == undefined) return;
    if (latSetting.data == null || latSetting.data == undefined) return;

    setMapCentreLong(+longSetting.data?.data.value);
    setMapCentreLat(+latSetting.data?.data.value);

    setCoordinatesLoading(false);

  }, [longSetting.isLoading, latSetting.isLoading])

  return (
    <div className="my-6 h-[45vh] z-10 w-full">
      {(!isLoading && !coordinatesLoading) &&
        <MapContainer className="h-full z-10" center={[mapCentreLong, mapCentreLat]} zoom={8} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {
            leads.map((lead, index) => (
              lead.vehicle?.latitude! && lead.vehicle?.longitude! && (
                <Marker key={index} position={[lead.vehicle?.latitude!, lead.vehicle?.longitude!]}>
                  <Popup className="w-[20rem]">
                    <div>
                      {lead.vehicle?.vrm ? `${lead.vehicle.vrm}, ${lead.vehicle?.postcode ?? ""} - ${ScheduledCollectionSlot[lead.vehicle.scheduledCollectionSlot]} ` : "No VRM"}
                    </div>
                  </Popup>
                </Marker>
              )
            ))
          }
          {
            selectedLead?.vehicle?.latitude! && selectedLead.vehicle?.longitude! && (
              <Marker key={selectedLead.id} position={[selectedLead.vehicle?.latitude!, selectedLead.vehicle?.longitude!]} icon={icon}>
                <Popup className="w-[20rem]">
                  <div>
                    {selectedLead.vehicle?.vrm ? `${selectedLead.vehicle.vrm}, ${selectedLead.vehicle?.postcode ?? ""} - ${ScheduledCollectionSlot[selectedLead.vehicle.scheduledCollectionSlot]} ` : "No VRM"}
                  </div>
                </Popup>
              </Marker>
            )
          }
          {/* <Marker position={[51.505, -0.09]}>
          <Popup className="w-[20rem]">
            
          </Popup>
        </Marker> */}
        </MapContainer>
      }
    </div>
  );
}
