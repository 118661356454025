import { Fragment, useEffect, useState } from "react";

import useTrips from "../../data/useTrips";
import useTrucks from "../../data/useTrucks";
import classNames from "../../utils/classNames";
import { ICalendarTrip } from "../../models/Trip";
import { ILead, LeadStatus } from "../../models/Lead";
import { PartsStatusValues } from "../../models/Vehicle";
import useLeadsOnCollectionDay from "../../data/useLeadsOnCollectionDays";

export default function JobSheetTable({
  selectedDay,
  week,
  year,
}: {
  selectedDay: string | undefined;
  week: number;
  year: number;
}) {
  const { leadsOnCollectionDay } = useLeadsOnCollectionDay(selectedDay);
  const { trucks } = useTrucks();
  const { trips } = useTrips(year, week);
  const [todaysLeads, setTodaysLeads] = useState<ILead[]>();
  const [todaysTrips, setTodaysTrips] = useState<ICalendarTrip[]>();

  useEffect(() => {
    if (
      selectedDay &&
      !leadsOnCollectionDay.isLoading &&
      leadsOnCollectionDay.data &&
      !trips.isLoading &&
      trips.data &&
      !trucks.isLoading &&
      trucks.data 
    ) {
      setTodaysLeads(
        leadsOnCollectionDay.data?.data.leads!.filter(
          (lead) => lead.status !== LeadStatus.Cancelled
        )
      );

      setTodaysTrips(
        trips.data.data.find((trip) => trip.date === selectedDay)?.trips
      );
    }
  }, [
    leadsOnCollectionDay.isLoading,
    leadsOnCollectionDay.data,
    trips.isLoading,
    trips.data,
    trucks.isLoading,
    trucks.data,
    selectedDay,
  ]);

  const handleBackground = (status: PartsStatusValues) => {
    switch (status) {
      case PartsStatusValues["Awaiting Schedule"]:
        return "awaiting";
      case PartsStatusValues["Export - Engine + Frontend"]:
      case PartsStatusValues["Export - Engine"]:
      case PartsStatusValues["Export - Frontend"]:
        return "export";
      case PartsStatusValues["Parts - No Engine"]:
        return "sales";
      case PartsStatusValues["Parts + Export"]:
      case PartsStatusValues["Parts - Engine + Gearbox"]:
        return "sales";
      case PartsStatusValues["Scrap"]:
        return "scrap";
      case PartsStatusValues["Salvage"]:
        return "salvage";
      case PartsStatusValues["Cancelled"]:
        return "cancelled";
      default:
        return "";
    }
  };

  return (
    <table className="print-table">
      <thead>
        <tr>
          <th>Reg</th>
          <th>Make/Model</th>
          <th>Location</th>
          <th>Postcode</th>
          <th>Driver Notes</th>
          <th>Vehicle Notes</th>
          <th>Engine Code</th>
          <th>Parts/Scrap</th>
        </tr>
      </thead>
      <tbody>
        {trucks.data?.data.map((truck, i) => (
          <Fragment key={i}>
            <tr key={truck.id}>
              <td
                colSpan={8}
                className="truck"
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {truck.name}
              </td>
            </tr>
            {todaysTrips?.map((trip) => {
              if (trip.truckId !== truck.id) {
                return null;
              } else {
                const leads = todaysLeads?.filter((lead) =>
                  trip.leads.find((leadId) => leadId === lead.id)
                );
                return leads
                  ?.sort((a: ILead, b: ILead) => (a.order > b.order ? 1 : -1))
                  .map((lead) => {
                    return (
                      <tr
                        key={lead.id}
                        className={classNames(
                          handleBackground(lead.vehicle?.engineExportStatus!),
                          ""
                        )}
                        style={{ fontSize: "14px" }}
                      >
                        <td>{lead.vehicle?.vrm}</td>
                        <td style={{ fontSize: "12px" }}>
                          {lead.vehicle?.combinedMake}{" "}
                          {lead.vehicle?.combinedModel}
                        </td>
                        <td style={{ fontSize: "12px" }}>
                          {lead.vehicle?.town}
                        </td>
                        <td>{lead.vehicle?.postcode}</td>
                        <td style={{ whiteSpace: "pre-line" }}>
                          {lead.driverNotes}
                        </td>
                        <td style={{ whiteSpace: "pre-line" }}>
                          {lead.vehicle?.notes}
                        </td>
                        <td style={{ fontSize: "12px" }}>
                          {lead.vehicle?.engineModelCode}
                        </td>
                        <td style={{ fontSize: "12px" }}>
                          {PartsStatusValues[
                            lead.vehicle?.engineExportStatus!
                          ].toString()}
                        </td>
                      </tr>
                    );
                  });
              }
            })}
            <tr style={{ height: "20px" }}>
              <td colSpan={8}> </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
}
