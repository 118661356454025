import { useQuery } from "@tanstack/react-query";

import getStub from "../utils/getStub";
import contactsStub from "../stubs/contacts.json";

import useApiHelper from "../hooks/useApiHelper";
import { IAllContactsResponse } from "../models/Contact";

export default function useUser() {
  const { get } = useApiHelper();

  const user = useQuery({
    queryKey: ["user"],
    queryFn: () => get("/user"),
  });

  return {
    user,
  };
}
