import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import {ICategoryPartResponse, IPart} from "../models/Part";
import {IPromotion, IPromotionCollection} from "../models/Promotion";
import {DefaultResponseWithData, EbayResponseWrapper} from "../models/system";
import {ITruck} from "../models/Truck";

export default function useEbayPromotion(promotionId: string) {
  const { get } = useApiHelper();
  const queryClient = useQueryClient();

  const promotion = useQuery({
    queryKey: ["promotion", promotionId],
    queryFn: () => get<EbayResponseWrapper<IPromotion>>(`/ebaysafe/promotions/${promotionId}`),
    enabled: promotionId != null && promotionId.length>0
  });

  return {
    promotion
  };
}
