import { useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse } from "../models/Lead";

export default function useLeadsFromIds(ids: string[]) {
  const { get, getFile, post, postGetFile, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const body: any = {
    leadIds: ids
  }

  const leadsFromIds = useQuery({
    queryKey: ["leads", "filtered", "ids", ...ids],
    queryFn: () => post<IAllLeadsResponse>(`/lead/partialfromids`, body),
    staleTime: 1000 * 60 * 1, // 1 minute
    // refetchInterval: 1000 * 60 * 1, // 1 minutes
    // refetchIntervalInBackground: true,
    // enabled: ids.length > 0,
  });

  return {
    leadsFromIds,
  };
}
