import { useQueryClient } from "@tanstack/react-query";
import { ILead } from "../../../models/Lead";
import { Category } from "../../../models/Part";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import useJobs from "../../../data/useJobs";
import { useEffect, useState } from "react";
import { CreateJobRequest, JobType } from "../../../models/Job";
import Card from "../../shared/Card/Card";
import PriceFormat from "../../../utils/priceFormat";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";



const PartDetails = ({ category, isMech, removePartFromJob }: { category: Category, isMech: boolean, removePartFromJob: any }) => {

  return (
    <div className="flex flex-col mt-4 gap-y-1">
      <div className="flex flex-row justify-between gap-x-2 items-center">
        <h1 className="text-lg">{category.category.name}</h1>
        <div className="grow border-b-2 border-dashed mx-2"></div>
        <h1>{category.category.disTime! + category.category.whTime!} minutes</h1>
        <ButtonInput label={"X"} isSubmit={false} onClick={() => removePartFromJob(category, isMech)} classes={"!py-0 !px-1 text-xs bg-red-500 hover:bg-red-600"} />
      </div>
    </div>
  )
}


export default function SelectedCategoryDetails({ mech, body, setMech, setBody, lead }: { mech: Category[], body: Category[], setMech: any, setBody: any, lead: ILead }) {
  const queryClient = useQueryClient();
  const { saveHandlers } = useDefaultCRUDHandlers("Job");
  const { create } = useJobs();
  const [totalMechTime, setTotalMechTime] = useState<number>(0);
  const [totalBodyTime, setTotalBodyTime] = useState<number>(0);
  const [totalMechMargin, setTotalMechMargin] = useState<number>(0);
  const [totalBodyMargin, setTotalBodyMargin] = useState<number>(0);

  useEffect(() => {
    // console.log(mech, body)
    if (mech.length > 0) {
      //add up warehouse and disassembly time for each category
      setTotalMechTime(mech.map(category => category.category.whTime!).reduce((a, b) => a! + b!)! + mech.map(category => category.category.disTime!).reduce((a, b) => a! + b!)!);
      //get average margin for each category (add all the part margins up / length of parts array) and sum them for total
      setTotalMechMargin(mech.map(category => category.category.averageMargin).reduce((a, b) => a! + b!)!);
    }


    //same same but different
    if (body.length > 0) {
      setTotalBodyTime(body.map(category => category.category.whTime!).reduce((a, b) => a! + b!)! + body.map(category => category.category.disTime!).reduce((a, b) => a! + b!)!);
      setTotalBodyMargin(body.map(category => category.category.averageMargin).reduce((a, b) => a! + b!)!);
    }
  }, [mech, body])

  const createJob = (isMech: boolean) => {

    let type = isMech ? JobType.Mechanical : JobType.Body;
    let duration = isMech ? totalMechTime : totalBodyTime;
    let categoryNames = isMech ? mech.map(cat => cat.category.name) : body.map(cat => cat.category.name);
    let categoryIds = isMech ? mech.map(cat => cat.category.id) : body.map(cat => cat.category.id);

    let averagePrices = isMech ? mech.map(cat => cat.category.averagePrice) : body.map(cat => cat.category.averagePrice);

    console.log(averagePrices);

    let newJob: CreateJobRequest = {
      type: type,
      title: `${lead.vehicle?.combinedMake} ${lead.vehicle?.combinedModel} ${lead.vehicle?.vrm} - ${JobType[type]}`,
      description: categoryNames.join(", "),
      duration: duration,
      leadId: lead.id,
      vehicleId: lead.vehicle?.id,
      partCategories: categoryIds,
      averagePrices: averagePrices
    };

    create.mutate(newJob, {
      onSuccess: () => {
        queryClient.invalidateQueries(["lead", lead.id]);
        //empty the selected parts arrays
        if (isMech) setMech([]);
        else setBody([]);

        saveHandlers.onSuccess();
      },
      onError: saveHandlers.onError
    });
  };

  const removePartFromJob = (category: Category, isMech: boolean) => {
    if (isMech) {
      setMech(mech.filter(cat => cat.category.id !== category.category.id));
    } else {
      setBody(body.filter(cat => cat.category.id !== category.category.id));
    }
  };

  return (
    <Card title="Selected Parts" bodyClassName="p-4">
      {mech.length > 0 && (
        <>
          <h1 className="text-lg font-semibold">Mechanical</h1>
          <hr className="pb-2" />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16">
            {mech.map(category => (
              <PartDetails category={category} isMech removePartFromJob={removePartFromJob} />
            ))}
          </div>
          <div className="flex gap-x-8">
            <div>
              <h1 className="mt-2 text-lg">Total time:</h1>
              <p className="font-semibold">
                {totalMechTime} minutes / {(totalMechTime / 60).toFixed(2)} hours
              </p>
            </div>
            <div>
              <h1 className="mt-2 text-lg">Estimated Margin:</h1>
              <p className="font-semibold">
                {PriceFormat(totalMechMargin)}
              </p>
            </div>
            <div className="flex flex-col-reverse">
              <ButtonInput label={"Create Job"} isSubmit={false} onClick={() => createJob(true)} classes={""} />
            </div>
          </div>
        </>
      )}

      {body.length > 0 && (
        <>
          <h1 className="text-lg font-semibold mt-6">Body</h1>
          <hr />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16">
            {body.map(category => (
              <PartDetails category={category} isMech={false} removePartFromJob={removePartFromJob} />
            ))}
          </div>
          <div className="flex gap-x-8">
            <div>
              <h1 className="mt-2 text-lg">Estimated time:</h1>
              <p className="font-semibold">
                {totalBodyTime} minutes / {(totalBodyTime / 60)} hours
              </p>
            </div>
            <div>
              <h1 className="mt-2 text-lg">Estimated Margin:</h1>
              <p className="font-semibold">
                {PriceFormat(totalBodyMargin)}
              </p>
            </div>
            <div className="flex flex-col-reverse">
              <ButtonInput label={"Create Job"} isSubmit={false} onClick={() => createJob(false)} classes={""} />
            </div>
          </div>
        </>
      )}

      {/* Change displayed variables */}
      {/* Show total at bottom for each type */}
    </Card>
  )
};