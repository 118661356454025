import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { IPart } from "../../models/Part";
import { ListingStatus } from "../../pages/EbayListings/ViewListing";
import classNames from "../../utils/classNames";
import openInNewTab from "../../utils/openInNewTab";
import OrderStatus from "../ebayOrders/OrderStatus";
import Pagination from "../shared/Tables/Pagination";
import WarehouseLocationFromId from "../warehouseLocations/WarehouseLocationFromId";
import PagedTable from "../shared/Tables/PagedTable";
import PartStatusDropdown from "./PartStatusDropdown";
import { PageMode } from "../../models/PagedTable";
import formatDateTime from './../../utils/formatDateTime';

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer'}
      {...rest}
    />
  )
}

const colHelper = createColumnHelper<IPart>();

const cols = [
  {
    id: 'select',
    // header: ({ table }: any) => (
    //   <IndeterminateCheckbox
    //     {...{
    //       checked: table.getIsAllRowsSelected(),
    //       indeterminate: table.getIsSomeRowsSelected(),
    //       onChange: table.getToggleAllRowsSelectedHandler(),
    //     }}
    //   />
    // ),
    cell: ({ row }: any) => (
      <div className="flex justify-center items-center flex-1 p-4" onClick={(e) => e.stopPropagation()}>
        {/* <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        /> */}
        <button
          onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
          style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
          className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
        >
          {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    ),
  },
  colHelper.display({
    id: "images",
    cell: (info) => (
      <span className="flex justify-center relative" >
        {info.row.original.images?.length > 0 && info.row.original.images.some((image) => image.isThumbnail === true) ? (
          <img
            className="w-32 h-20 object-contain"
            src={`${info.row.original.images.find((image) => image.isThumbnail === true)?.url != null ? info.row.original.images.find((image) => image.isThumbnail === true)?.url : null}`}
          />
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
      </span>
    ),
  }),
  colHelper.accessor("tagNumber", {
    header: "Tag No.",
    sortingFn: "auto",
  }),
  colHelper.accessor("title", {
    header: "Category",
    sortingFn: "auto",
    // cell: (info) => <span>{info.getValue() !== null ? date.format(new Date(info.getValue()!), "DD/MM/YYYY") : "Not Set"}</span>,
  }),
  colHelper.accessor("vehicle.combinedMake", {
    header: "Make & Model",
    enableSorting: false,
    cell: (info) => <div>
      <p>{info.row.original.vehicle?.combinedMake}</p>
      <p>{info.row.original.vehicle?.combinedModel}</p>
      <p>{info.row.original.vehicle?.dvlaYearOfManufacture}</p>
    </div>,
  }),
  colHelper.accessor("images", {
    id: "# of images",
    header: "Images",
    enableSorting: false,
    cell: (info) => <span className="flex justify-center text-xl">{info.getValue().length > 0 ? "✔" : "✘"}</span>,
  }),
  colHelper.accessor("offers", {
    id: "# of offers",
    header: "Offers",
    enableSorting: false,
    cell: (info) => <span className="flex justify-center text-xl">{info.getValue().length > 0 ? "✔" : "✘"}</span>,
  }),
  // colHelper.accessor("partNumber", {
  //   header: "Part No.",
  //   sortingFn: "auto",
  // }),
  colHelper.accessor("warehouseLocationId", {
    header: "Warehouse",
    sortingFn: "auto",
    cell: (info) => <WarehouseLocationFromId id={info.getValue()} lastStockChecked={info.row.original.lastStockChecked} />,
  }),
  colHelper.accessor("offers", {
    id: "offer status",
    header: "Ebay Status",
    enableSorting: false,
    cell: (info) => <ListingStatus offer={info.getValue()?.at(0)} part={info.row.original} />,
  }),
  colHelper.accessor("offers", {
    id: "order status",
    header: "Order Status",
    enableSorting: false,
    cell: (info) => <OrderStatus part={info.row.original} />,
  }),
  colHelper.accessor("partStatus", {
    header: "Part Status",
    enableSorting: false,
    cell: (info) => <PartStatusDropdown part={info.row.original} />,
  }),

];


export default function StockTable() {
  return (
    <>
      <PagedTable
        url="/part/listings"
        columns={cols}
        pageMode={PageMode.Stock}
        queryLabel="Search stock by Tag, Part No., Make, Model, Engine Code, Category, or Warehouse"
        onRowClick={(x: IPart) => openInNewTab(`stock/${x.id}`)}
        showTotal={true}
      />
    </>
  );
}
