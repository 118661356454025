import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPart, IPartCategoryDefault, IPartCategoryDefaultResponse } from "../models/Part";

export default function usePartDefaultCategories({ part }: { part: IPart }) {
  const { get, post, put, patch, postDocs } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes

  const defaultPartCategory = useQuery(
    ["parts", part.categoryId, "defaultcategory"],
    () => get<IPartCategoryDefaultResponse>(`/part/defaultcategory/${part.categoryId}`),
    {
      enabled: !!part.categoryId,
      staleTime,
    }
  );

  const saveDefaultCategory = useMutation(
    (defaults: IPartCategoryDefault) => post(`/part/defaultcategory/${part.categoryId}`, defaults),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts"]);
        queryClient.refetchQueries(["parts", "multiple"]);
        queryClient.refetchQueries(["categories"]);
      },
    }
  );

  const useDefaultPartCategory = useMutation(
    () => patch(`/part/${part.id}/defaultcategory`, {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts"]);
        queryClient.refetchQueries(["categories"]);
      },
    }
  );


  return {
    useDefaultPartCategory,
    defaultPartCategory,
    saveDefaultCategory
  };
}
