
import { RadioGroup, Field, Radio, Label } from '@headlessui/react';

export const RadioGroupInput = ({ register, registerName, options, setValue, watch, cols = "sm:col-span-1" }) => {

  const selectedValue = watch(registerName);

  return (
    <RadioGroup
      {...register(registerName)}
      value={selectedValue}
      onChange={(e) => setValue(registerName, e)}
      className="flex gap-2 w-full"
    >
      {options.map((option) => (
        <Field key={option.value} className="flex items-center gap-2 cursor-pointer">
          <Radio
            value={option.value}
            className="group flex size-5 items-center justify-center rounded-full border border-gray-700 bg-white data-[checked]:bg-gp-blue-600"
          >
            <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
          </Radio>
          <Label>{option.name}</Label>
        </Field>
      ))}
    </RadioGroup>
  );
}