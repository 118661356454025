import { useState } from "react";
import Modal from "../../layouts/Modal";
import { ContactType, IContact } from "../../models/Contact";
import { ILead } from "../../models/Lead";
import getAddress from "../../utils/getAddress";
import Card from "../shared/Card/Card";

export default function ContactDetails({ contact }: { contact: IContact }) {

  return (
    <>
      <Card title="Contact Details" bodyClassName="p-4">
        <div className="space-y-4">

          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {contact.fullname}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Contact Type</dt>
              <dd className="mt-1 text-sm text-gray-900">{ContactType[contact.contactType!]}</dd>
            </div>
          </dl>

          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Phone number</dt>
              <dd className="mt-1 text-sm text-gray-900">{contact.telephone1}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Email address</dt>
              <dd className="mt-1 text-sm text-gray-900">{contact.email}</dd>
            </div>
          </dl>

          <dl className="gap-x-4 gap-y-8">
            <div>
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900">{getAddress(contact)}</dd>
            </div>
          </dl>

        </div>
      </Card>
    </>
  );
}
