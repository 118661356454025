import { useEffect } from "react";

import date from "date-and-time";
import Card from "../shared/Card/Card";
import { IEnginesWorldwide, OfferType } from "../../models/EnginesWorldwide";

export default function EnginesWorldwideDetails({ offer }: { offer?: IEnginesWorldwide }) {

  useEffect(() => {

  }, [offer])

  let pickedUpDate = offer ? new Date(offer.pickedUp) : undefined;

  if (pickedUpDate && pickedUpDate.getFullYear() === 1) {
    pickedUpDate = undefined;
  }

  return (

    <>
      <Card title={`Engines Worldwide Offer - ${offer ? OfferType[offer.type].toUpperCase() : ""}`} bodyClassName="p-4">
        <dl className="divide-y divide-gray-200">
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Offer Id</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {offer?.enginesWorldwideId}
            </dd>
          </div>
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="flex justify-between items-center text-sm text-gray-900 col-span-2">
              <div>{offer?.status}</div>
            </dd>
          </div>
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Price</dt>
            <dd className="flex justify-between items-center text-sm text-gray-900 col-span-2">
              <div>{offer?.price ?
                new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(parseFloat(offer?.price))
                : "N/A"
              }</div>
            </dd>
          </div>
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Media Recieved</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {offer?.mediaReceived ? "Yes" : "No"}
            </dd>
          </div>
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Offered</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {offer ? `${date.format(new Date(offer.offered), "DD/MM/YYYY")}` : ""}
            </dd>
          </div>
          <div className="py-2 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">Picked Up</dt>
            <dd className="text-sm text-gray-900 col-span-2">
              {pickedUpDate ? `${date.format(pickedUpDate, "DD/MM/YYYY")}` : "Not Picked Up"}
            </dd>
          </div>
        </dl>
      </Card>
    </>
  );
}
