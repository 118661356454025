
export enum PageMode {
  Stock = 0,
  Unlisted = 1,
  UnlistedHideMissingDeleted = 11,
  UnlistedDismantled = 12,
  Listed = 2,
  Orders = 3,
  OrdersPaid = 31,
  OrdersPicked = 32,
  OrdersShipped = 33,
}