import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { useForm } from "react-hook-form";

import { UseMutationResult } from "@tanstack/react-query";

import Modal from "../../layouts/Modal";
import { ITruck } from "../../models/Truck";
import { TextInput } from "../shared/Inputs/TextInput";
import { IUnavailableDate } from "../../models/UnavailableDate";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ButtonInput } from "../shared/Inputs/ButtonInput";

type FormValues = {
  resourceId: string | null | undefined,
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  message: string
};

interface AddUnavailableDateModalProps {
  open: boolean,
  truck: ITruck | undefined,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  create: UseMutationResult<IUnavailableDate, unknown, IUnavailableDate, unknown>
};

export default function AddUnavailableDateModal({ open, setOpen, truck, create }: AddUnavailableDateModalProps) {
  const { saveHandlers } = useDefaultCRUDHandlers("Unavailable Date");
  const { register, handleSubmit, reset, } = useForm<FormValues>();

const handleClose = () => {
    setOpen(false);
    reset({});
}

  const onSubmit = (data: any) => {

    const createDate = {
      ...data,
      resourceType: 4,
      resourceId: truck?.id,
      startDate: data.startDate ? DateTime.fromFormat(data.startDate, "yyyy-MM-dd").toISO() : null,
      endDate: data.endDate ? DateTime.fromFormat(data.endDate, "yyyy-MM-dd").toISO() : null,
    };

    create.mutate(createDate, saveHandlers);

    setOpen(false);

    reset({});
  }

  useEffect(() => {
    reset({})
  }, [open, reset]);

  return (
    <>
      <Modal width="max-w-xl" open={open} setOpen={setOpen} >
        <h1 className="text-xl">Add new Unavailable Date</h1>
        <div className="mt-4">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 border-t border-gray-200">
            <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-2">

              <div className="sm:col-span-1">
                <label className="text-sm font-medium text-gray-500">
                  Start Date
                </label>
                <input {...register("startDate")} type="date" className="w-full mt-1 text-sm text-gray-900" />
              </div>

              <div className="sm:col-span-1">
                <label className="text-sm font-medium text-gray-500">
                  End Date
                </label>
                <input {...register("endDate")} type="date" className="w-full mt-1 text-sm text-gray-900" />
              </div>

              <div className="sm:col-span-2">
                <TextInput label="Message" registerName="message" register={register} />
              </div>

              <ButtonInput label={"Save"} isSubmit={true} onClick={undefined} classes={undefined} />
              <ButtonInput label={"Cancel"} isSubmit={false} onClick={() => handleClose()} classes={"!bg-gray-400 hover:!bg-gray-500"} />
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
