import { useEffect, useRef, useState } from "react"

import { DateTime } from "luxon"
import { useForm } from "react-hook-form"

import { useQueryClient } from "@tanstack/react-query"

import Avatar from "../../user/Avatar"
import useNotes from "../../../data/useNotes"
import { TextInput } from "../Inputs/TextInput"
import ConfirmModal from "../Confirm/ConfirmModal"
import { ButtonInput } from "../Inputs/ButtonInput"
import { useAuth } from "../../../contexts/AuthContext"
import ContactFromId from "../../contacts/ContactFromId"
import formatDateTime from "../../../utils/formatDateTime"
import { INewNote, INote, ResourceType } from "../../../models/Note"
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers"

interface INotesOutputProps {
  notes: INote[];
  resourceType: ResourceType;
  resourceId: string;
  date?: string;
  refetchQuery?: any[];
  shouldScroll?: boolean;
  classes?: string;
};

export default function NotesOutput({ notes, resourceType, resourceId, date = DateTime.now().toISODate()!, refetchQuery, shouldScroll = true, classes }: INotesOutputProps) {
  const { register, handleSubmit, reset } = useForm<any>();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Note");
  const queryClient = useQueryClient();
  const { claims, userId } = useAuth();
  const { create, remove } = useNotes();
  // const [isAdmin, setIsAdmin] = useState(false);
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onSubmit = (data: any) => {
    const newNote: INewNote = {
      message: data.message,
      resourceId: resourceId,
      resourceType: resourceType,
      date: date
    };

    create.mutate(newNote, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setTimeout(() => {
          queryClient.refetchQueries(refetchQuery);
        }, 200);
        reset({ message: "" });
      },
      onError: saveHandlers.onError
    });
  }

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (shouldScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    }
  }, [notes, shouldScroll])

  const deleteNote = () => {
    setDeleteLoading(true);
    if (deleteId) {
      remove.mutate(deleteId, {
        onSuccess: () => {
          setDeleteOpen(false);
          deleteHandlers.onSuccess();
          setTimeout(() => {
            queryClient.refetchQueries(refetchQuery);
          }, 200);
        },
        onError: () => {
          deleteHandlers.onError();
        },
        onSettled: () => {
          setDeleteLoading(false);
        }
      });
    }
  };

  return (
    <>
      <ConfirmModal open={deleteOpen} setOpen={setDeleteOpen}
        title={"Remove note"} message={`Are you sure you want to remove this note?`} confirmButtonText={"Remove note"}
        onConfirm={deleteNote} isLoading={deleteLoading}
      />
      <div className="max-h-[70vh] overflow-auto px-4">
        <div className="mt-2 space-y-2 divide-y">
          {notes.map((note, i) => {
            return (
              <div key={i} className="flex justify-between pt-2 break-words whitespace-pre-wrap">
                <div className="flex justify-start">
                  <div>
                    <Avatar classes="w-8 h-8 mr-2" userId={note.createdBy} />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm">
                      <span className="font-semibold"><ContactFromId contactId={note.createdBy} /></span> - {formatDateTime(note.created, DateTime.DATETIME_SHORT)}
                    </span>
                    <p className="mt-2">{note.message}</p>
                  </div>
                </div>
                <div>
                  {(userId === note.createdBy || claims?.isAdmin) && (
                    <ButtonInput label={"X"} isSubmit={false} onClick={() => { setDeleteOpen(true); setDeleteId(note.id); }} classes={"!p-1 !px-2 !bg-red-500 hover:!bg-red-700"} />
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
          <TextInput label={"New Note"} register={register} registerName={"message"} />
          <ButtonInput label={"Save"} isSubmit={true} onClick={undefined} classes="mt-2" />
        </form>
        <div ref={messagesEndRef} />
      </div>
    </>
  )
}