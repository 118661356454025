import { useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IDocument } from "../models/Document";
import { DefaultResponseWithData } from "./../models/system";

export default function useVehicleImages(vehicleId: string) {
  const { get } = useApiHelper();

  const vehicleImages = useQuery({
    queryKey: ["vehicle", vehicleId, "images"],
    queryFn: () =>
      get<DefaultResponseWithData<IDocument[]>>(
        `/vehicle/${vehicleId}/images`
      ).then((res) => res.data),
  });

  return {
    vehicleImages,
  };
}
