import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";

import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getPaginationRowModel, getSortedRowModel, RowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { IPart } from "../../models/Part";
import classNames from "../../utils/classNames";
import openInNewTab from "../../utils/openInNewTab";
import Pagination from "../shared/Tables/Pagination";
import { FormatType, OrderPaymentStatus, PaymentMethodType } from "../../models/EbayOffer";
import { ListingStatus } from "../../pages/EbayListings/ViewListing";
import formatDateTime from './../../utils/formatDateTime';
import { DateTime } from "luxon";
import OrderStatus from "./OrderStatus";
import WarehouseLocationFromId from "../warehouseLocations/WarehouseLocationFromId";
import PagedTable from "../shared/Tables/PagedTable";
import EbayPaymentStatus from "./EbayPaymentStatus";
import EbayPaymentMethod from "./EbayPaymentMethod";
import useShippingBulk from "../../data/useShippingBulk";
import useNotification from "../notifications/useNotifications";
import { PageMode } from "../../models/PagedTable";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer'}
      {...rest}
    />
  )
}

const colHelper = createColumnHelper<IPart>();

const cols = [
  {
    id: 'select',
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div className="flex justify-center items-center flex-1 p-4" onClick={(e) => e.stopPropagation()}>
        <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
        <button
          onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
          style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
          className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
        >
          {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    ),
  },
  colHelper.display({
    id: "images",
    cell: (info) => (
      <span className="flex justify-center relative" >
        {info.row.original.images?.length > 0 && info.row.original.images.some((image) => image.isThumbnail === true) ? (
          <img
            className="w-32 h-20 object-contain"
            src={`${info.row.original.images.find((image) => image.isThumbnail === true)?.url != null ? info.row.original.images.find((image) => image.isThumbnail === true)?.url : null}`}
          />
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
      </span>
    ),
  }),
  colHelper.accessor("title", {
    header: "Category",
    sortingFn: "auto",
    // cell: (info) => <span>{info.getValue() !== null ? date.format(new Date(info.getValue()!), "DD/MM/YYYY") : "Not Set"}</span>,
  }),
  colHelper.accessor("offers", {
    id: "offer title",
    header: "Offer Title",
    sortingFn: "auto",
    cell: (info) => <span>{info.getValue()?.at(0)?.title}</span>,
  }),
  colHelper.accessor("soldDate", {
    header: "Sold Date",
    sortingFn: "auto",
    cell: (info) => <span>{formatDateTime(info.getValue(), DateTime.DATETIME_SHORT)}</span>,
  }),
  colHelper.accessor("pickedOn", {
    header: "Picked Date",
    sortingFn: "auto",
    cell: (info) => <span>{formatDateTime(info.getValue(), DateTime.DATETIME_SHORT)}</span>,
  }),
  colHelper.accessor("shippedOn", {
    header: "Shipped Date",
    sortingFn: "auto",
    cell: (info) => <span>{formatDateTime(info.getValue(), DateTime.DATETIME_SHORT)}</span>,
  }),
  colHelper.accessor("tagNumber", {
    header: "Tag No.",
    sortingFn: "auto",
  }),
  colHelper.accessor("warehouseLocationId", {
    header: "Warehouse",
    sortingFn: "auto",
    cell: (info) => <WarehouseLocationFromId id={info.getValue()} lastStockChecked={info.row.original.lastStockChecked} />,
  }),
  colHelper.accessor("offers", {
    id: "payment status",
    header: "Payment Status",
    enableSorting: false,
    cell: (info) => <EbayPaymentStatus offers={info.getValue()} />,
  }),
  colHelper.accessor("offers", {
    id: "payment method",
    header: "Payment Method",
    enableSorting: false,
    cell: (info) => <EbayPaymentMethod offers={info.getValue()} />,
  }),
  colHelper.accessor("offers", {
    id: "offer status",
    header: "Status",
    enableSorting: false,
    cell: (info) => <OrderStatus part={info.row.original} />,
  }),
];

export default function EbayOrdersTable() {
  const { bulkShipping } = useShippingBulk();
  const { addNotification } = useNotification();

  const shipAll = (rows: RowModel<IPart>) => {
    let selected = rows.rows.filter(row => row.getIsSelected()).map(r => r.original.id)

    if (selected.length === 0) {
      addNotification({
        variant: "warn",
        primaryText: "No Items Selected",
      });
      return;
    }

    bulkShipping.mutate(selected, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Items Marked As Shipped",
        });
      }
    });
  }

  return (
    <PagedTable
      url="/part/listings"
      pageMode={PageMode.Orders}
      columns={cols}
      queryLabel="Search order details"
      onRowClick={x => openInNewTab(`stock/${x.id}`)}
      modeToggles={[
        { label: "Paid", pageMode: PageMode.OrdersPaid },
        { label: "Picked", pageMode: PageMode.OrdersPicked },
        { label: "Shipped", pageMode: PageMode.OrdersShipped }]}
      actionButtons={[{ label: "Mark as Shipped", onClick: shipAll }]}
    />
  );
}
