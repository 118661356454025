import { useState } from "react";

import useSingleLead from "../../../data/useSingleLead";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../../models/Lead";
import Card from "../../shared/Card/Card";

export default function DriverNotes({ lead }: { lead: ILead }) {
  const { update } = useSingleLead(lead.id);
  const { saveHandlers } = useDefaultCRUDHandlers("Driver Notes");
  const [driverNotes, setDriverNotes] = useState(lead.driverNotes);

  const handleSaveDriverNotes = () => {
    const updatedLead = {
      ...lead,
      driverNotes: driverNotes
    };

    update.mutate(updatedLead, saveHandlers)
  }

  return (
    <Card title="Driver Notes" bodyClassName="p-4">
      <div className="space-y-4">

        <textarea
          className="w-full"
          rows={5}
          defaultValue={lead.driverNotes!}
          onChange={(e) => setDriverNotes(e.target.value)}
          onBlur={() => handleSaveDriverNotes()}
        />

        <button
          className="rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
          onClick={() => handleSaveDriverNotes()}
        >
          Update
        </button>

      </div>
    </Card>
  );
}
