import { ChangeEvent, useEffect, useState } from "react";

import { DateTime } from "luxon";
import { HexColorPicker } from "react-colorful";

import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

import useTrucks from "../../data/useTrucks";
import useWilmaUsers from "../../data/useWilmaUsers";
import stringToColour from "../../utils/stringToColour";
import getLatestPoint from "../../utils/getLatestPoint";
import { useUserLookup } from "../../hooks/useUserLookup";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { IDriverTracking } from "./../../models/DriverTracking";
import useDriverTrackingMap from "../../data/useDriverTrackingMap";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import TrackingMap from "../../components/DriverTracking/TrackingMap";
import {
  DriverWithTracking,
  IDriverTrackingMapResponse,
  TrackingStatus,
} from "../../models/DriverTracking";

export default function DriverTracking() {
  const { setInfo } = usePageNameContext();
  const { trucks } = useTrucks(true);
  const { update } = useWilmaUsers();
  const { isLoading: userLoading, drivers } = useUserLookup();
  const { rangeData } = useDriverTrackingMap();
  const [fromDate, setFromDate] = useState<string>(
    DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd")
  );
  const [showColorPicker, setShowColorPicker] = useState<boolean[]>(
    Array.from({ length: 100 }, () => false)
  );
  const [customRange, setCustomRange] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [driverTracking, setDriverTracking] = useState<DriverWithTracking[]>(
    []
  );

  useEffect(() => {
    setInfo({
      name: "Driver Tracking Map",
      desc: "View the locations of current drivers and prior journey history",
    });
  }, []);

  useEffect(() => {
    if (!trucks.isLoading && trucks.data && !userLoading && isLoading) {
      //Initial call will get all of todays points so far
      getTrackingData(fromDate, driverTracking);
    }
  }, [trucks.data, userLoading, trucks.isLoading, trucks.data]);

  const getTrackingData = (
    fromDate: string,
    driverTracking: DriverWithTracking[]
  ) => {
    if (!trucks.isLoading && trucks.data && !userLoading && drivers) {
      const driverTrackingTemp: DriverWithTracking[] = [...driverTracking];

      let lastPoint;
      if (fromDate) {
        lastPoint = DateTime.fromJSDate(new Date(fromDate)).toFormat(
          "yyyy-MM-dd HH:mm:ss.S"
        );
      } else {
        lastPoint = "";
      }

      rangeData.mutate(
        { fromDate: customRange ? fromDate : lastPoint, toDate: "" },
        {
          onSuccess: (data) => {
            const tracking = data as IDriverTrackingMapResponse;
            // console.log(tracking.data)
            Object.entries(tracking.data).forEach(([key, value]) => {
              const driver = drivers!.find((driver) => driver.id === key)!;
              const truck = trucks.data?.data.find(
                (truck) => truck.driverId === value[0]?.truckId
              );

              //Check here to see if the driver is in the driverTracking array already
              //If it is, then just update the tracking array
              const driverIndex = driverTracking.findIndex(
                (driver) => driver.driver.id === key
              );

              //If it isn't, then add it to the array
              if (driver && driverIndex === -1) {
                driverTrackingTemp.push({
                  driver,
                  truck,
                  tracking: value as IDriverTracking[],
                  colour:
                    driver.mapColour == null
                      ? `${stringToColour(
                          driver.id +
                            `${driver.forename} ${driver.surname}` +
                            driver.created
                        )}`
                      : driver.mapColour,
                  show: true,
                });
              } else if (driver) {
                //Still have to check driver exists else .tracking will be undefined - stops non drivers appearing
                //don't add multiple of the same driver
                driverTrackingTemp[driverIndex].tracking.push(
                  ...(value as IDriverTracking[])
                );
              }
            });

            if (driverTrackingTemp.length > 0) {
              //Get most recent point
              const allTracking: IDriverTracking[] = [];

              // Iterate through each driver and collect all tracking points into a single array
              driverTrackingTemp.forEach((driver) => {
                allTracking.push(...driver.tracking);
              });

              // Sort the combined tracking points array in descending order of timestamp
              allTracking.sort((a, b) => {
                return (
                  new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime()
                );
              });

              // Get the most recent point (the first element after sorting)
              const mostRecentPoint = allTracking[0];

              if (!customRange)
                setFromDate(mostRecentPoint.timestamp.toString());
            }

            setDriverTracking(driverTrackingTemp);

            setIsLoading(false);
          },
        }
      );
    }
  };

  let updateDriverTimeout: NodeJS.Timeout;

  const updateDriverColour = (idx: number, colour: string) => {
    clearTimeout(updateDriverTimeout);

    updateDriverTimeout = setTimeout(() => {
      var temp = [...driverTracking];
      temp[idx].colour = colour;
      setDriverTracking(temp);

      var driver = temp[idx].driver;
      driver.mapColour = colour;
      update.mutate(driver);
    }, 2000);
  };

  const toggleCustomDate = (x: ChangeEvent<HTMLInputElement>) => {
    setCustomRange(x.target.checked);
    setDriverTracking([]);

    let d = DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd");

    setFromDate(d);
    getTrackingData(d, []);
  };

  const changeCustomDate = (x: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    setFromDate(x.target.value);
    setDriverTracking([]);
    getTrackingData(x.target.value, []);
  };

  const hideShowDriver = (idx: number, hide: boolean) => {
    var temp = [...driverTracking];
    temp[idx].show = hide;
    setDriverTracking(temp);
  };

  const hideShowColourPicker = (idx: number, hide: boolean) => {
    var temp = [...showColorPicker];
    temp[idx] = hide;
    setShowColorPicker(temp);
  };

  return trucks.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <div className="mt-8">
        <div className="pb-2">
          <input
            type="checkbox"
            checked={customRange}
            onChange={(x) => toggleCustomDate(x)}
          />
          {customRange ? (
            <>
              <label> Custom Date: </label>
              <input
                type="date"
                value={fromDate}
                onChange={(x) => changeCustomDate(x)}
              />
            </>
          ) : (
            <label> Use A Custom Date</label>
          )}
        </div>
        <div className="grid grid-cols-4 gap-x-4">
          <div className="col-span-3">
            <TrackingMap driverTracking={driverTracking} />
          </div>
          <div className="">
            <ul className="space-y-4">
              {driverTracking.map((driver, i) => {
                const recentPoint = getLatestPoint(driver);
                // console.log(recentPoint)
                return (
                  <li
                    className={
                      `p-2 w-full border-2 ` +
                      (driver.show ? "" : "bg-slate-300")
                    }
                  >
                    <div className="flex flex-row">
                      <div className="flex flex-col items-center mr-2">
                        {showColorPicker[i] ? (
                          <HexColorPicker
                            className="z-10 !absolute translate-x-[45%] -translate-y-[5%]"
                            onDoubleClick={() => hideShowColourPicker(i, false)}
                            color={driver.colour}
                            onChange={(x) => updateDriverColour(i, x)}
                          />
                        ) : (
                          <div
                            className="h-6 w-6 bg-current border-2 border-solid border-current rounded-full cursor-pointer"
                            style={{ color: driver.colour }}
                            onClick={() => hideShowColourPicker(i, true)}
                          />
                        )}
                        {driver.show ? (
                          <EyeIcon
                            className="cursor-pointer"
                            onClick={() => hideShowDriver(i, false)}
                          />
                        ) : (
                          <EyeSlashIcon
                            className="cursor-pointer"
                            onClick={() => hideShowDriver(i, true)}
                          />
                        )}
                      </div>
                      <div className="flex-col text-sm">
                        <span className="text-md font-bold">
                          {driver.driver.forename} {driver.driver.surname}
                        </span>
                        <div>
                          <span>Last location timestamp: </span>
                          <span>
                            {DateTime.fromISO(recentPoint?.timestamp!)
                              .setLocale("en-gb")
                              .toLocaleString(DateTime.TIME_WITH_SECONDS)}
                          </span>
                        </div>
                        {recentPoint && (
                          <div>
                            <span>Status: </span>
                            <span>{TrackingStatus[recentPoint.status]}</span>
                          </div>
                        )}
                        <div>
                          <span>Version: </span>
                          <span>{driver.driver.version ?? "Not found"}</span>
                        </div>
                        {driver.driver.version &&
                          !driver.driver.foregroundLocation && (
                            <div>
                              <span className="font-bold text-red-600">
                                No Foreground Location Permissions
                              </span>
                            </div>
                          )}
                        {driver.driver.version &&
                          !driver.driver.backgroundLocation && (
                            <div>
                              <span className="font-bold text-red-600">
                                No Background Location Permissions
                              </span>
                            </div>
                          )}
                        {recentPoint?.leadId && (
                          <div>
                            <a
                              className="text-blue-600 hover:text-blue-400 underline text-sm"
                              href={`leads/${recentPoint.leadId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Current Lead
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
