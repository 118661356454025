import classNames from "../../../utils/classNames"

export const CheckboxInput = ({ label, register, registerName, disabled = false, cols = "sm:col-span-1" }) => {

  return (
    <div className={cols}>
      <label className="text-sm font-medium text-gray-500">
        {label}
      </label>
      <input
        {...register(registerName)}
        disabled={disabled}
        type="checkbox"
        className={classNames(disabled ? "bg-gray-300" : "", "ml-1 border-1 border-gray-400")}
      />
    </div>
  )
}