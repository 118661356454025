import { useEffect, useState } from "react";
import useContactSingle from "../../data/useContactsSingle";
import { IContact } from "../../models/Contact";

export default function ContactFromId({ contactId }: { contactId: string }) {
  const { contact } = useContactSingle(contactId);

  useEffect(() => {

  }, [contact.isLoading, contact.data])

  if (contact.isLoading || !contact.data) return <></>;

  return (
    <span>{contact.data?.data.fullname}</span>
  )
}