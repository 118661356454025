import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ICategoryResponse, IPartCategory, IPartMapping } from "../models/Part";

export default function useMappings() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();


  const create = useMutation(
    (mapping: IPartMapping) => post(`/part/mappings`, mapping),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["categories"]);
      },
    }
  );

  const update = useMutation(
    (mapping: IPartMapping) => put(`/part/mappings/${mapping.id}`, mapping),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["categories"]);
      },
    }
  );

  return {
    create,
    update,
  };
}
