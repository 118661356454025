import { useEffect, useState } from "react";

import AutoDapSearch from "../../components/partsChecker/AutoDapSearch";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import VehicleSearch from "../../components/partsChecker/VehicleSearch";

export default function PartsChecker() {
  const { setInfo } = usePageNameContext();
  const [useSecond, setUseSecond] = useState<boolean>(false);

  useEffect(() => {
    setInfo({
      name: "Parts Checker",
      desc: "Find part numbers for a vehicle",
    });
  }, []);

  return (
    <>
      <div className="p-4 mt-4 bg-white rounded-lg shadow">
        <VehicleSearch />
      </div>

      <div className="p-4 mt-4 bg-white rounded-lg shadow">
        <ButtonInput label={"Toggle Compare"} isSubmit={false} onClick={() => setUseSecond(!useSecond)} classes={"ml-4"} />

        {!useSecond ? (
          <div>
            <AutoDapSearch />
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 divide-x-2">
            <AutoDapSearch />
            <AutoDapSearch />
          </div>
        )}
      </div>
    </>
  )
}

