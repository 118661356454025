import { DefaultResponseWithData } from "./system";
import { BaseEntity } from "./system";

export interface ITableDisplayColumnResponse extends DefaultResponseWithData<ITableDisplayColumn[]> { }

export enum TableType{
  AwaitingInventory
}

export interface ITableDisplayColumn extends BaseEntity {
  header: string,
  columnId: string,
  isMandatory: boolean,
  isHidden: boolean,
  tableType: TableType
}