export default function Tile({ label, color, value, children }: { label: string, color: string, value?: string, children?: any }) {

  return (
    <div className={`border border-slate-500 px-2 py-1 shadow-md rounded bg-${color}-100 grow pt-2 pb-4 max-w-fit`}>
      <p className={`mx-2 text-center text-${color}-600 font-semibold mb-2`}>{label}</p>
      <div className="flex flex-col justify-center">
        {
          value ? <p className={`font-bold text-center my-2 text-2xl text-${color}-600 mx-2`}>{value}</p> : null
        }
        {
          children ? <div className="flex flex-row justify-center">{children}</div> : null
        }
      </div>
    </div>
  );
};