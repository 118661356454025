import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import { TextInput } from "../../components/shared/Inputs/TextInput";
import { useForm } from "react-hook-form";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import useLeads from "../../data/useLeads";
import { useNavigate } from "react-router-dom";
import LoadingWheel from "../../components/shared/LoadingWheel";

interface FormValues {
  vrm: string;
}

export default function FindLead() {
  const { setInfo } = usePageNameContext();
  const navigate = useNavigate();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm<FormValues>({});
  const { leads } = useLeads();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setInfo({
      name: "Find a Lead",
      desc: "Search for a lead to view details and update the collection status",
    });
  }, []);

  useEffect(() => {
    if (!leads.isLoading && leads.data) {
      setIsLoading(false);
    }
  }, [leads.isLoading, leads.data,]);

  const onSubmit = (data: any) => {

    let formatted = data.vrm.replace(/\s/g, '').toUpperCase();

    const lead = leads.data?.data.leads.find((lead) => lead.vehicle?.vrm === formatted);

    if (!lead) {
      setError("Lead not found!");
      return;
    }

    navigate(`/driver/lead/${lead.id}`);
  }

  if (isLoading) {
    return (
      <LoadingWheel />
    );
  } else {
    return (
      <div className="mt-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full md:w-[15vw]">
            <TextInput label="Vehicle Registration" register={register} registerName="vrm" />
          </div>
          <ButtonInput label="Search" isSubmit={true} onClick={undefined} classes="mt-4" />
        </form>
        <p className="mt-4 text-red-500 text-lg font-base ">{error}</p>
      </div>
    );
  }
}