import { useNavigate } from "react-router-dom";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  createColumnHelper,
  ColumnDef,
} from "@tanstack/react-table";

import { IVehicle } from "../../models/Vehicle";

const colHelper = createColumnHelper<IVehicle>();

const cols = [
  colHelper.display({
    id: "image",
    cell: (info) => (
      <img
        className="w-32"
        src={`${info.row.original.vehicleImageUrl}`}
      />
    ),
  }),
  colHelper.accessor("vrm", {
    header: "VRM",
  }),
  colHelper.accessor((row) => `${row.dvlaMake} ${row.dvlaModel} ${row.dvlaVehicleDescription != null && row.dvlaVehicleDescription != "" ? "(" + row.dvlaVehicleDescription + ")" : ""}`, {
    header: "Description",
    id: "description",
  }),
];

export default function VehicleTable({ data }: { data: IVehicle[] }) {
  const navigate = useNavigate();
  const table = useReactTable({
    data: data,
    columns: cols,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="mt-8 bg-white">
      <table className="min-w-full divide-y border divide-gray-300">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="bg-gp-blue-50 text-gray-800">
              {headerGroup.headers.map((header, i) => (
                <th
                  key={header.id}
                  scope="col"
                  className={`py-3.5 px-3 text-left text-sm font-semibold lg:table-cell ${i === 0 && "w-32"
                    }`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            let rowCells = row.getVisibleCells();
            return (
              <tr
                key={row.id}
                onClick={() => navigate(row.original.vrm)}
                className="hover:bg-gray-100 hover:cursor-pointer"
              >
                {rowCells.map((cell, i) => (
                  <td key={cell.id} className={`px-2 py-4 border text-sm ${i === 0 && "bg-white"}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
