import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ICategoryPartResponse, IPartCollectionResponse } from "../models/Part";

export default function useEbayOrders() {
  const { get, post, put, del } = useApiHelper();
  const queryClient = useQueryClient();

  const orders = useQuery({
    queryKey: ["ebayOrders"],
    queryFn: () => get<IPartCollectionResponse>("/part/listings/1?sold=true"),
  });

  return {
    orders,
  };
}
