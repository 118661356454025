import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { IEbayOffer } from "../../models/EbayOffer";
import { IPart } from "../../models/Part";
import checkEbayDisabled from "../../utils/checkEbayDisabled";
import PopOutBox from "../shared/PopOutBox";

export default function TableListingError({ part }: { part: IPart }) {

  let offer = part.offers[0] as IEbayOffer;
  let result = checkEbayDisabled({ part, offer, ignoreListingTime: true });
  return (
    <div>

      {result.isDisabled &&
        <div className="text-red-500">
          <PopOutBox classes={""} icon={<XCircleIcon className="h-8 w-8" />} >
            {result.error}
          </PopOutBox>
        </div>
      }
      
      {!result.isDisabled && <p className="text-green-500"> <CheckCircleIcon className="h-8 w-8" />  </p>}
    </div>
  )
}