import { ILead } from "../../models/Lead";
import PartVehicleImages from "../leads/LeadParts/LeadImagesCarousel";

export default function CollectedTableSubComponent({ lead }: { lead: ILead }) {

  return (
    <div className="bg-gray-100 w-[77vw]">
      <PartVehicleImages lead={lead!} />
    </div>
  )
}