import { useEffect, useState } from "react";

import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getEditorDefaults } from "@pqina/pintura";
import { PinturaEditor } from "@pqina/react-pintura";

import useSinglePart from "../../data/useSinglePart";
import useVehicleImages from "../../data/useVehicleImages";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { IPart, IPartImage, IPartImageType } from "../../models/Part";
import useNotification from "../notifications/useNotifications";
import Card from "../shared/Card/Card";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import FileUpload from "../shared/Inputs/FileUpload";
import LoadingWheel from "../shared/LoadingWheel";
import { IPartImagePatchRequest } from "./../../models/Part";
import { PartImageThumb } from "./PartImageThumb";
import { VehicleImageThumb } from "./VehicleImageThumb";

import "@pqina/pintura/pintura.css";

export default function EditListingImages({ part }: { part: IPart }) {
  const { uploadPicture, patchPicture, removeImage, removePictureBg } = useSinglePart(part.id);
  const { vehicleImages } = useVehicleImages(part.vehicleId!);
  const { saveHandlers } = useDefaultCRUDHandlers("Images Reordered");
  const { deleteHandlers } = useDefaultCRUDHandlers("Image");
  const { addNotification } = useNotification();
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [isReorderingLoading, setIsReorderingLoading] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [newOrder, setNewOrder] = useState(0);
  const [editImageOpen, setEditImageOpen] = useState(false);
  const [editImage, setEditImage] = useState<Blob | undefined>(undefined);
  const [editImageId, setEditImageId] = useState<string | undefined>(undefined);
  const [editImageLoading, setEditImageLoading] = useState(false);
  const [editImageError, setEditImageError] = useState(false);
  const [toUpload, setToUpload] = useState([]);

  const updateToUploadFiles = (files: any) => setToUpload({ ...files });

  const imageTypeOptions = Object.keys(IPartImageType).filter(key => isNaN(Number(key)));

  const closeEditImage = () => {
    setEditImageOpen(false);
    setTimeout(() => setEditImage(undefined), 200);
  };

  const handleUploadDocs = (file?: File) => {
    //attach files to http request
    setUploadIsLoading(true);
    const formData = new FormData();

    if (!file) {
      for (let i = 0; i < Object.keys(toUpload).length; i++) {
        formData.append(part.id!, toUpload[i]);
      }
    } else {
      const editedFile = new File([file], `${Math.floor(Date.now() / 100)}-edited.jpeg`, { type: file.type });
      formData.append(part.id!, editedFile);
    }

    uploadPicture.mutate(formData, {
      onSuccess: () => {
        setToUpload([]);
        editImageOpen && closeEditImage();
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        setUploadIsLoading(false);
      },
    });
  };

  const handleStartStopReorder = () => {
    if (isReordering) {
      setIsReorderingLoading(true);
      //save new order
      // part patch
      let patchReqs: IPartImagePatchRequest[] = [];

      part.images.forEach((img, i) => {
        patchReqs.push({
          imageId: img.id!,
          order: img.order,
          isThumbnail: img.order === 0 ? true : false,
          useOnEbay: img.order !== null ? true : false,
        });
      });

      patchPicture.mutate(patchReqs, {
        onSuccess: () => {
          setIsReordering(false);
          saveHandlers.onSuccess();
        },
        onError: (error) => {
          console.log(error);
          saveHandlers.onError();
        },
        onSettled: () => {
          setIsReorderingLoading(false);
        }
      });
    } else {
      //start reordering
      //Reset order of all images
      part.images.forEach((img, i) => {
        img.order = null;
      });
      setNewOrder(0);
      setIsReordering(true);
    }
  };

  const handleImageTypeChange = (type: IPartImageType) => {
    if (!editImageId) return;

    let body: IPartImagePatchRequest[] = [];
    body.push({
      imageId: editImageId,
      imageType: type,
    });

    patchPicture.mutate(body, saveHandlers);
  };

  const loadEditImage = async (imgId: string) => {
    editImageError && setEditImageError(false);
    setEditImageLoading(true);
    setEditImageOpen(true);

    let imgUrl = part.images.find((img) => img.id === imgId)?.url!;

    try {
      const res = await fetch(
        //Use CORS proxy to get around CORS issue
        `https://arcane-peak-78372.herokuapp.com/${imgUrl}`,
        {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*", // Required for CORS support to work
          },
        }
      );

      if (!res.ok) {
        throw Error(res.statusText);
      }

      const blob = await res.blob();

      setEditImage(blob);
      setEditImageId(imgId);
    } catch (e) {
      console.error(e);
      setEditImageError(true);
    } finally {
      setEditImageLoading(false);
    }
  };

  const handleReorderClick = (imgId: string) => {
    //set order
    let img = part.images.find((img) => img.id === imgId)!;
    //Stops order going up forever
    if (img.order === null) {
      img.order = newOrder;
      setNewOrder(newOrder + 1);
    }
  };

  const handleRemoveImage = (imgId: string) => {
    removeImage.mutate(imgId, deleteHandlers);
  };

  useEffect(() => { }, [part.images]);

  // console.log(vehicleImages.data);

  const handleBackgroundRemove = () => {
    setEditImageLoading(true);
    removePictureBg.mutate(editImageId, {
      onSuccess: () => {
        setEditImageOpen(false);
        addNotification({
          variant: "success",
          primaryText: "Background Removed",
        });
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Unable to remove background",
        });
      },
      onSettled: () => {
        setEditImageLoading(false);
      }
    });
  }

  const editorDefaults = getEditorDefaults({
    imageWriter: {
      quality: 0.8,
      mimeType: "image/jpeg"
    }
  });

  return (
    <>
      <Modal
        open={editImageOpen}
        setOpen={setEditImageOpen}
        width={"max-w-7xl"}
      >
        {editImageOpen && (
          <div className="h-[80vh]">
            {editImageLoading ? (
              <div className="h-full flex items-center">
                <LoadingWheel />
              </div>
            ) : editImageError ? (
              <div className="h-full flex items-center">
                <p>Error loading image</p>
              </div>
            ) : (
              <div className="h-[80vh]">
                <PinturaEditor
                  {...editorDefaults}
                  src={editImage}
                  onProcess={(res) => handleUploadDocs(res.dest)}
                  utils={["crop", "finetune", "annotate", "redact"]}
                />
                <div className="flex gap-x-4 left-0 bottom-0 absolute m-8 z-10 ">
                  <ButtonInput
                    isSubmit={false}
                    onClick={handleBackgroundRemove}
                    classes={"text-black rounded-xl !border !border-solid !border-gray-200 hover:!border-gray-300"}
                  >
                    <div>
                      <FontAwesomeIcon icon={faImage} />
                      <p className={"text-xs"}>Remove<br />Background</p>
                    </div>
                  </ButtonInput>

                  <div className="flex flex-col">
                    <label className="text-sm text-gray-600">Image Type</label>
                    <select
                      className="text-sm"
                      defaultValue={part.images.find(img => img.id == editImageId)?.imageType ?? ""}
                      onChange={(e) => handleImageTypeChange(Number(e.target.value) as IPartImageType)}
                    >
                      <option value="">None</option>
                      {imageTypeOptions.map((key, index) => (
                        <option key={index} value={IPartImageType[key as any]}>
                          {key}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <ButtonInput
                  isSubmit={false}
                  label="Close"
                  onClick={editImageOpen && closeEditImage}
                  classes={"right-0 bottom-0 absolute m-8 z-10 bg-gray-400 hover:bg-gray-500 text-black rounded-xl !border !border-solid !border-gray-200 hover:!border-gray-300"}
                />
              </div>
            )}
          </div>
        )}
      </Modal>

      <Card title="Part Images" bodyClassName="p-4">
        {isReorderingLoading ? (
          <div>
            <LoadingWheel />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5">
              {part.images.length > 0 ? (
                part.images.map((image: IPartImage, i: number) => (
                  <PartImageThumb
                    image={image}
                    index={i}
                    handleEdit={loadEditImage}
                    handleReorder={handleReorderClick}
                    handleRemove={handleRemoveImage}
                    reordering={isReordering}
                  />
                ))
              ) : (
                <p className="text-center w-full py-4">No images found!</p>
              )}
            </div>

            {isReordering && <p className="text-center mt-2">Click on images to set order</p>}
            <ButtonInput
              label={isReordering ? "Save Order" : "Reorder"}
              isSubmit={false}
              onClick={handleStartStopReorder}
              disabled={part.images?.length < 1 || part.soldDate !== null}
              classes={"mt-4"}
            />
          </>
        )
        }


      </Card >

      <div className="my-4" />

      <Card title="Vehicle Images" bodyClassName="p-4">
        {vehicleImages.isLoading ? (
          <LoadingWheel />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-5">
            {vehicleImages.data
              ?.filter((image) => !image.contentType?.includes("video"))
              .filter((image) => image.thumbnail)
              .map((image, i) => (
                <VehicleImageThumb image={image} index={i} onClick={handleUploadDocs} />
              ))}
          </div>
        )}
      </Card>

      <div className="mt-4">
        {uploadIsLoading ? (
          <div className="flex items-center py-[10vh]">
            <LoadingWheel />
          </div>
        ) : (
          <>
            <FileUpload
              label={"Upload Part Photos here"}
              accept=".jpg,.png,.jpeg"
              desc="Add pictures to this part..."
              multiple
              updateFilesCb={updateToUploadFiles}
            />
            <ButtonInput
              classes=""
              label="Upload"
              isSubmit={false}
              onClick={() => handleUploadDocs()}
              disabled={toUpload.length < 1}
            />
          </>
        )}
      </div>
    </>
  );
}
