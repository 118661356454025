import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import date from "date-and-time";
import ordinal from "date-and-time/plugin/ordinal";
import { IDashboardStats } from "../../models/Dashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  interaction: {
    intersect: false,
    mode: "index"
  },
  scales: {
    y: {
      type: "linear",
      position: "left",
      //Will only return whole numbers
      ticks: { callback: function(val, index) { return Number.isInteger(val) ? this.getLabelForValue(val) : ''; }  },
      // Hide grid lines, otherwise you have separate grid lines for the 2 y axes
      grid: { display: false }
    },
    y2: {
      type: "linear",
      position: "right",
      ticks: { beginAtZero: true, color: "#16a34a" },
      grid: { display: false }
    },
    // xAxes: { ticks: { beginAtZero: true } }
  }
};

date.plugin(ordinal);

const d = new Date();

const labels = Array(30)
  .fill("")
  .map((_, i) => new Date(new Date().setDate(new Date().getDate() - (i + 1))),)
  .reverse();

// const labels = Array(7)
// .fill("")
// .map((_, i) => {
//   return date.format(
//     new Date(new Date().setDate(new Date().getDate() - (i + 1))),
//     "DDD"
//   );
// })
// .reverse();


export default function EngineExportGraph({ dashboardStats }) {
  const data = {
    labels: labels.map((day) => date.format(new Date(day), "ddd DD")),
    datasets: [
      {
        yAxisID: "y",
        label: "Engine Export Count",
        data: labels.map((day) => dashboardStats.engineExportCount[date.format(new Date(day), "DD/MM/YYYY")]),
        borderColor: "#d97706",
        backgroundColor: "#d97706",
      },
      {
        yAxisID: "y2",
        label: "Engine Export Price",
        data: labels.map((day) => dashboardStats.engineExportPrice[date.format(new Date(day), "DD/MM/YYYY")]),
        borderColor: "#16a34a",
        backgroundColor: "#16a34a",
      },
      {
        yAxisID: "y2",
        label: "Engine Export Average Price",
        data: labels.map((day) => dashboardStats.engineExportAvgPrice[date.format(new Date(day), "DD/MM/YYYY")]),
        borderColor: "#2563eb",
        backgroundColor: "#2563eb",
      },
      // {
      //   label: "Vehicles Completed",
      //   data: labels.map(() => Math.floor(Math.random() * 11)),
      //   borderColor: "#6F4E7C",
      //   backgroundColor: "#6F4E7C",
      // },
    ],
  };


  return <Line options={options} data={data} />;
}
