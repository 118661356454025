import { IPart } from "./Part";
import { BaseEntity, DefaultResponseWithData } from "./system";

export interface IEbayPoliciesResponse extends DefaultResponseWithData<IEbayAllPolicies> { }
export interface IEbayLocationsResponse extends DefaultResponseWithData<IEbayAllLocations> { }
export interface IEbayCategoriesResponse extends DefaultResponseWithData<IEbayAllCategories> { }
export interface IEbayCategoryConditionResponse extends DefaultResponseWithData<IEbayCategoryConditions> { }
export interface IEbayStoreCategoryResponse extends DefaultResponseWithData<IEbayStoreCategory[]> { }
export interface IEbayAspectsResponse extends DefaultResponseWithData<IAspect[]> { }
export interface IPartAspectsResponse extends DefaultResponseWithData<IPartAspect[]> { }


export interface IEbayOffer extends BaseEntity {
  publishedOn: string | null;
  publishedBy: string | null;
  format: FormatType;
  duration: ListingDuration;
  condition: string | null;
  conditionDescription: string | null;
  listingStarted: string | null;
  listingEnded: string | null;
  buyItNowPrice: number | null;
  auctionStartPrice: number | null;
  auctionReservePrice: number | null;
  enableBestOffer: boolean | null;
  autoAcceptPrice: number | null;
  locationKey: string;
  fulfillmentPolicyId: string;
  paymentPolicyId: string;
  returnPolicyId: string;
  sku: string;
  title: string;
  description: string;
  quantity: number;
  partDataId: string | null;
  partData: IPart | null;
  ebayId: string | null;
  categoryId: string | null;
  storeCategoryId: string | null;
  paymentStatus: OrderPaymentStatus | null;
  paymentMethod: PaymentMethodType | null;
}

export enum FormatType {
  Auction,
  FixedPrice,
}

export enum ListingDuration {
  Days1,
  Days3,
  Days5,
  Days7,
  Days10,
  Days21,
  Days30,
  GTC, //Good till cancelled
}

export enum PaymentMethodType {
  CreditCard,
  PayPal,
  CashierCheck,
  PersonalCheck,
  CashOnPickup,
  Eft,
  Ebay,
  Escrow,
}

export enum OrderPaymentStatus {
  Failed,
  FullyRefunded,
  Paid,
  PartiallyRefunded,
  Pending,
}

export interface IEbayAllPolicies {
  fulfilmentPolicies: IEbayItem[];
  paymentPolicies: IEbayItem[];
  returnPolicies: IEbayItem[];
}

export interface IEbayAllLocations {
  locations: IEbayItem[];
}

export interface IEbayAllCategories {
  categories: IEbayItem[];
}

export interface IEbayCategoryConditions {
  conditions: IEbayItem[];
}

export interface IEbayItem {
  id: string;
  name: string;
}
export interface IEbayStoreCategory {
  categoryId: string;
  categoryName: string;
  order: number | null;
  level: number | null;
}

export interface IAspect {
  localizedAspectName: string;
  aspectConstraint: AspectConstraint;
  aspectValues: AspectValue[];
  relevanceIndicator: RelevanceIndicator;
}

export interface AspectConstraint {
  aspectApplicableTo: string[];
  aspectDataType: string;
  aspectEnabledForVariations: boolean;
  aspectFormat: string;
  aspectMaxLength: number;
  aspectMode: string;
  aspectRequired: boolean;
  aspectUsage: string;
  expectedRequiredByDate: string;
  itemToAspectCardinality: string;
}

export interface AspectValue {
  localizedValue: string;
  valueConstraints: ValueConstraint[];
}

export interface RelevanceIndicator {
  searchCount: number;
}

export interface GetCategoryAspectsResponse {
  data: IAspect[];
}

export interface ValueConstraint {
  applicableForLocalizedAspectName: string;
  applicableForLocalizedAspectValues: string[];
}

export interface IPartAspect extends BaseEntity {
  name: string;
  value: string;
  partDataId: string;
  isCustom: boolean;
}

export interface CreatePartAspectRequest {
  name: string;
  value: string;
  isCustom: boolean;
}