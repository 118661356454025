import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";

export default function usePartDefaultCategoryAssignees() {
  const { get, post, put, patch, postDocs } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60; //1 minutes


  const addAssignee = useMutation(
    (body: any) => post(`/part/defaultcategory/${body.defaultCategoryId}/assignee`, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts"]);
        queryClient.refetchQueries(["categories"]);
      },
    }
  );

  const removeAssignee = useMutation(
    (assigneeId: any) => post(`/part/defaultcategory/${assigneeId}/assignee`, null),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["parts"]);
        queryClient.refetchQueries(["categories"]);
      },
    }
  );


  return {
    addAssignee,
    removeAssignee
  };
}
