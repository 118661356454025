import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../../layouts/Modal";
import { TextInput } from "../shared/Inputs/TextInput";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { NumberInput } from "../shared/Inputs/NumberInput";
import { IWarranty } from "../../models/Warranty";
import useWarranties from "../../data/useWarranties";

type FormValues = {
  name: string,
  description: string,
  days: number,
};

interface WarrantiesModalProps {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  warranty?: IWarranty;
}

export default function WarrantiesModal({ open, setOpen, warranty }: WarrantiesModalProps) {
  const { deleteHandlers, saveHandlers } = useDefaultCRUDHandlers("Warranties");
  const { update, create, remove } = useWarranties();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm<FormValues>();

  const onSubmit = (data: any) => {

    const updatedWarranty = {
      ...warranty,
      ...data
    };

    if (warranty) {
      update.mutate(updatedWarranty, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    } else {
      create.mutate(updatedWarranty, {
        onSuccess: (data) => {
          saveHandlers.onSuccess();
          setOpen(false);
          reset({});
        },
        onError: saveHandlers.onError
      });
    }
  }

  const handleDelete = () => {
    remove.mutate(warranty?.id!, {
      onSuccess: (data) => {
        deleteHandlers.onSuccess();
        setOpen(false);
        setDeleteOpen(false);
        reset({});
      },
      onError: deleteHandlers.onError
    });
  }

  useEffect(() => {

    if (warranty) {
      reset({
        name: warranty.name,
        description: warranty.description,
        days: warranty.days,
      });
    } else {
      reset({})
    }
  }, [warranty, open, reset]);

  return (
    <>
      <ConfirmModal open={deleteOpen} setOpen={setDeleteOpen}
        title={"Delete Warranty"} message={`Are you sure you want to delete this warranty?\n This action cannot be undone.`}
        confirmButtonText={"Delete"} onConfirm={handleDelete} isLoading={false}
      />
      <Modal width="max-w-2xl" open={open} setOpen={setOpen} >
        <h1 className="text-xl">{`${warranty ? "Edit" : "Add"} a Warranty`}</h1>
        <div className="mt-4">
          <form className="space-y-4 border-t border-gray-200" onSubmit={handleSubmit(onSubmit)} >
            <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-1">

              

              <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="grid col-span-10">
                  <TextInput label="Name" registerName="name" register={register} />
                </div>
                <div className="grid col-span-2">
                  <NumberInput label="Days" registerName="days" register={register} />
                </div>
                <div className="grid col-span-12">
                  <TextInput label="Description" registerName="description" richText={true} rows={4} register={register} />
                </div>
              </div>

              <ButtonInput label={`${warranty ? "Save Changes" : "Create"}`} isSubmit={true} onClick={undefined} classes={undefined} />
              {warranty !== undefined && (
                <ButtonInput label={"Delete"} isSubmit={false} onClick={() => (setDeleteOpen(true))} classes={"!bg-red-500 hover:!bg-red-600"} />
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
