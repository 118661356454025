import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { useQueryClient } from "@tanstack/react-query";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

import Card from "../../shared/Card/Card";
import { ILead } from "../../../models/Lead";
import classNames from "../../../utils/classNames";
import usePayments from "../../../data/usePayments";
import useSingleLead from "../../../data/useSingleLead";
import { useAuth } from "../../../contexts/AuthContext";
import PaymentBubble from "../../../utils/paymentBubble";
import { TextInput } from "../../shared/Inputs/TextInput";
import ConfirmModal from "../../shared/Confirm/ConfirmModal";
import useNotification from "../../notifications/useNotifications";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { CheckboxInput } from "../../shared/Inputs/CheckboxInput";
import useSingleSetting from "../../../data/useSingleSetting";

type FormValues = {
  price: number;
  commission: number;
  accountName: string;
  paymentReference: string;
  accountNumber: string;
  sortCode: string;
  chequeName: string;
  applyVAT: boolean;
};

export default function LeadPaymentDetails({ lead }: { lead: ILead }) {
  const { singleSetting } = useSingleSetting("PaymentRefPrefix");
  const { update } = useSingleLead(lead.id);
  const { sendPayment, syncPayment } = usePayments();
  const { saveHandlers } = useDefaultCRUDHandlers("Payment Details");
  const { claims } = useAuth();
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();
  const { register, getValues, handleSubmit, watch, setValue, reset } =
    useForm<FormValues>({
      defaultValues: {
        price: lead.price,
        commission: lead.commission,
        accountName: lead.accountName!,
        paymentReference: lead.paymentReference === "" || lead.paymentReference === null ? "" : lead.paymentReference!,
        accountNumber: lead.accountNumber!,
        sortCode: lead.sortCode!,
        chequeName: lead.chequeName!,
        applyVAT: lead.applyVAT,
      },
    });
  const [paymentConfirmOpen, setPaymentConfirmOpen] = useState(false);
  const [rotateChevron, setRotateChevron] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleRotate = () => setRotateChevron(!rotateChevron);

  const rotate = rotateChevron ? "rotate(360deg)" : "rotate(0)"

  const onSubmit = (data: any, saveNotification: boolean) => {

    const updatedLead = {
      ...lead,
      ...data,
      price: data.price && parseFloat(data.price),
      commission: data.commission && parseFloat(data.commission),
    };

    if (saveNotification === true) {
      update.mutate(updatedLead, saveHandlers);
    } else {
      update.mutate(updatedLead);
    }

    reset({
      price: data.price,
      commission: data.commission,
      accountName: data.accountName!,
      paymentReference: data.paymentReference!,
      accountNumber: data.accountNumber!,
      sortCode: data.sortCode!,
      chequeName: data.chequeName!,
      applyVAT: data.applyVAT,
    });
  }

  const handlePayment = () => {
    handleSubmit(data => onSubmit(data, false))();
    setPaymentConfirmOpen(true);
  }

  const sendPaymentRequest = () => {

    if (getValues("price") === 0) {
      addNotification({
        variant: "warning",
        primaryText: "Please make sure price is not zero",
      })
      return;
    }

    if (!getValues("accountName") || !getValues("accountNumber") || !getValues("sortCode")) {
      addNotification({
        variant: "warning",
        primaryText: "Please fill in all payment details",
      })
      return;
    }

    sendPayment.mutate(lead.id, {
      onSuccess: (res: any) => {
        if (res.success) {
          setPaymentConfirmOpen(false);
          saveHandlers.onSuccess();
        } else {
          addNotification({
            variant: "error",
            primaryText: res.message,
          })
        }
      },
      onError: (e) => {
        console.log("error", e);
        saveHandlers.onError();
      }
    });
  }

  const handleRefresh = () => {
    handleRotate();

    syncPayment.mutate(lead.id, {
      onSuccess: (res: any) => {
        queryClient.refetchQueries(["lead", lead.id])
        addNotification({
          variant: "success",
          primaryText: "Payment status synced",
        })
      },
      onError: (e) => {
        addNotification({
          variant: "error",
          primaryText: "Error syncing payment status",
        })
      }
    });
  }

  useEffect(() => {
    if (lead.paymentStatus !== 0 && lead.paymentStatus !== 3) setIsDisabled(true);
  }, [lead, lead.paymentStatus])

  // Watch for changes in the price and applyVAT fields
  const [vatPrice, setVatPrice] = useState(0);

  const price = watch("price");
  const applyVAT = watch("applyVAT");

  useEffect(() => {
    // TODO: Make admin level variable
    if (applyVAT) setVatPrice(price * 1.2);
  }, [price]);

  useEffect(() => {
    if (applyVAT) setVatPrice(price * 1.2);
    else setVatPrice(0);
  }, [applyVAT]);

  useEffect(() => {
    if (singleSetting.isLoading) return;
    if (lead.paymentReference !== "" && lead.paymentReference !== null) return;
    if (singleSetting.data == null || singleSetting.data == undefined) return;
    setValue("paymentReference",
      singleSetting.data?.data.value + lead.vehicle?.vrm
    );
  }, [singleSetting.isLoading]);

  return (
    <>
      <ConfirmModal open={paymentConfirmOpen} setOpen={setPaymentConfirmOpen}
        title="Raise payment?" confirmButtonText="Raise Payment"
        message={`Are you sure you want to raise this payment? \n The payment details will be locked until payment has been sent or cancelled.`}
        isLoading={false}
        onConfirm={sendPaymentRequest}
      />
      <Card bodyClassName="p-4">
        <h2 className="flex justify-between items-center text-lg font-medium pb-5 pt-2 leading-6 text-gray-900">
          <span>
            Payment Details
          </span>
          <span className="flex items-center">
            <ArrowPathIcon style={{ transform: rotate, transition: "all 0.5s linear" }} onClick={() => handleRefresh()} className="mr-3 w-5 h-5 cursor-pointer" />
            {PaymentBubble(lead.paymentStatus)}
          </span>
        </h2>
        <form onSubmit={handleSubmit(data => onSubmit(data, true))} className="space-y-4 border-t border-gray-200">

          <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-2">

            <TextInput label="Current Price (£)" registerName="price" disabled={isDisabled || !claims?.isAdmin} register={register} />
            <TextInput label="Commission (£)" registerName="commission" disabled={isDisabled || !claims?.isAdmin} register={register} />
            <TextInput label="Account Name" registerName="accountName" disabled={isDisabled} register={register} />
            <TextInput label="Payment Reference" registerName="paymentReference" disabled={isDisabled} register={register} />
            <TextInput label="Account Number" registerName="accountNumber" disabled={isDisabled} register={register} />
            <TextInput label="Sort Code" registerName="sortCode" disabled={isDisabled} register={register} />
            <div className="flex gap-x-2">
              <CheckboxInput label={`Apply VAT `} registerName="applyVAT" disabled={isDisabled || !claims?.isAdmin} register={register} />
              {vatPrice == 0 ? "" : <span className="text-gray-500"> (£{vatPrice})</span>}
            </div>

          </div>

          <div className="flex justify-between">
            <button
              className={classNames(isDisabled ? "bg-gray-600" : "bg-gp-blue-600 hover:bg-gp-blue-700", "rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm")}
              disabled={isDisabled}
              type="submit"
            >
              Update
            </button>

            <button
              className={classNames(isDisabled ? "bg-gray-600" : "bg-gp-blue-600 hover:bg-gp-blue-700", "rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm")}
              disabled={isDisabled}
              type="button"
              onClick={() => handlePayment()}
            >
              Raise Payment
            </button>
          </div>


        </form>
      </Card>
    </>
  );
}
