import { useEffect, useState } from "react";
import useSingleLead from "../../../../data/useSingleLead";
import useDefaultCRUDHandlers from "../../../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../../../models/Lead";
import useLeads from "../../../../data/useLeads";
import ContactFromId from "../../../contacts/ContactFromId";
import formatDateTime from "../../../../utils/formatDateTime";
import { DateTime } from "luxon";

export default function LeadsTableCallCount({ lead }: { lead: ILead }) {
  const { update } = useLeads(true);
  const { saveHandlers } = useDefaultCRUDHandlers("Leads");
  const [currentCallCount, setCurrentCallCount] = useState<number>(lead.callCount)

  const handleCallListClick = (e: any, isPlus: boolean) => {
    e.stopPropagation();

    let callCount = isPlus ? currentCallCount! + 1 : currentCallCount! - 1;

    //Don't want it under 0 or over 10
    if (callCount < -1 || callCount > 10) {
      return;
    }

    const updatedLead = {
      ...lead,
      callCount: callCount,
    };

    update.mutate(updatedLead, {
      onSuccess: () => {
        setCurrentCallCount(callCount);
        // saveHandlers.onSuccess();
      },
      onError: saveHandlers.onError
    });
  }

  return (
    <div className="flex items-center">

      <span className="flex flex-col items-center justify-center text-center w-24">
        <span className="">
          {currentCallCount === 10 ? ("Customer to call back") : 
          (
            currentCallCount === 0 ? ("Awaiting Call") : 
            currentCallCount === -1 ? ("Avoiding") :
            (`Called ${currentCallCount}`)
          )}
        </span>
        {(currentCallCount > 0 || currentCallCount === -1) && (
          <>
            <span className="text-xs">{lead.callCountUpdatedOn && formatDateTime(lead.callCountUpdatedOn!, DateTime.DATETIME_SHORT)}</span>
            <span className="text-xs">{lead.callCountUpdatedBy && <ContactFromId contactId={lead.callCountUpdatedBy!} />}</span>
          </>
        )}
      </span>

      <div className="flex flex-col ml-2">
        <button
          className="flex items-center justify-center w-6 h-6 text-lg font-bold rounded-full hover:bg-gray-200"
          onClick={(e) => handleCallListClick(e, true)}>+</button>
        <button
          className="flex items-center justify-center w-6 h-6 text-lg font-bold rounded-full hover:bg-gray-200"
          onClick={(e) => handleCallListClick(e, false)}>-</button>
      </div>
    </div>
  )
}
