import Card from "../../shared/Card/Card";

export default function PartsRecall() {
  return (
    <Card bodyClassName="p-4 flex flex-col divide-y gap-y-4" title="Recalled Parts">
      <div>
        <p className="text-2xl text-center">
          This vehicle has no parts that have a recall notice.
        </p>
      </div>
    </Card>
  );
}
