import { useEffect, useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import PaymentsTable from "../../components/payments/PaymentsTable";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import usePayments from "../../data/usePayments";
import useUtilities from "../../data/useUtilities";
import { ILead, PaymentStatus } from "../../models/Lead";

export default function Payments() {
  const { setInfo } = usePageNameContext();
  const { raisedPayments: allPayments } = usePayments();
  const { getTellerooAccount } = useUtilities();
  const [payments, setPayments] = useState<ILead[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [hideFailed, setHideFailed] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Payments",
      desc: "Payments that have been raised will be shown here",
    });
  }, []);

  useEffect(() => {
    if (!allPayments.isLoading ) { //&& !getTellerooAccount.isLoading

      let filter = allPayments.data?.data.filter((lead) =>
        lead.contact?.fullname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.vehicle?.vrm?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.vehicle?.postcode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.price?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.commission?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )

      if (hideFailed) {
        filter = filter?.filter((lead) => lead.paymentStatus !== PaymentStatus.Failed);
      }

      setPayments(filter);
      setIsLoading(false);
    }
  }, [allPayments.isLoading, allPayments.data, getTellerooAccount.data, getTellerooAccount.isLoading, searchTerm, hideFailed]);

  return allPayments.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <div className="mt-4 flex gap-x-5">
        <div className="rounded bg-green-100 text-green-600 p-2 px-4">
          <h1 className="text-green-600 m-0 text-xl font-semibold">{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
            getTellerooAccount?.data?.data?.balance ? getTellerooAccount?.data?.data.balance! / 100 : 0,
          )}</h1>
          <p className="m-0">Telleroo Balance</p>
        </div>

        <div className="rounded bg-red-100 text-red-600 p-2 px-4">
          <h1 className="text-red-600 m-0 text-xl font-semibold">{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
            getTellerooAccount?.data?.data?.awaitingApprovalBalance ? getTellerooAccount?.data?.data.awaitingApprovalBalance! / 100 : 0,
          )}</h1>
          <p className="m-0">Awaiting Approval</p>
        </div>
      </div>

      <div className="mt-4 flex justify-between">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 sm:text-sm"
            placeholder="Name, Price, VRM, Postcode"
          />
        </div>

        <div className="flex items-center">
          <input id="hideFailed" onChange={(e) => setHideFailed(e.target.checked)} value="hideFailed" checked={hideFailed} type="checkbox" className="mr-2 h-5 w-5" />
          <label htmlFor="hideFailed">Hide failed</label>
        </div>
      </div>
      <PaymentsTable payments={payments!} />
    </>
  );
}
