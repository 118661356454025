import { useMutation } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IAllLeadsResponse } from "../models/Lead";

export default function useLeadByVehicleId() {
  const { get } = useApiHelper();

  const lead = useMutation({
    mutationFn: (vehicleId: string) =>
      get<IAllLeadsResponse>(`/lead/partialbyvehicle?vehicleId=${vehicleId}`),
  });

  return {
    lead,
  };
}
