import { useEffect, useState } from "react";
import { IDashboardStats } from "../../models/Dashboard";
import DashboardTile from "./DashboardTile";
import classNames from "../../utils/classNames";

const tabs = [
  { name: 'Today', value: 'today' },
  { name: 'Week', value: 'week' },
  { name: 'Month', value: 'month' },
]

export default function LeadsBookedTable({ dashboardStats }: { dashboardStats: IDashboardStats }) {
  const [timeframe, setTimeframe] = useState("today");
  const [stats, setStats] = useState<{ [key: string]: number } | undefined>(undefined);

  useEffect(() => {
    if (dashboardStats) {
      switch (timeframe) {
        case "today":
          setStats(dashboardStats.bookedDay);
          break;
        case "week":
          setStats(dashboardStats.bookedWeek);
          break;
        case "month":
          setStats(dashboardStats.bookedMonth);
          break;
      }
    }
  }, [dashboardStats, timeframe])

  return (
    <DashboardTile span={2}>
      <div className="p-3">
        <div className="mb-3 w-full flex justify-between">
          <div className="prose">
            <h3>Leads booked</h3>
          </div>
          
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue={tabs.find((tab) => tab?.value === timeframe)?.name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-6" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => setTimeframe(tab.value)}
                      className={classNames(
                        tab.value === timeframe 
                          ? 'border-indigo-500 text-gp-blue-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 py-1 px-1 text-sm font-medium'
                      )}
                      aria-current={tab.value === timeframe ? 'page' : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

        </div>

        <div className="mt-6">
          <div className="flex justify-between">
            <p>User</p>
            <p>Booked</p>
          </div>

          <hr />

          <div className="mt-4 flex flex-col divide-y gap-y-2">
            {stats && Object.keys(stats).map((key) => {
              if (stats[key] !== 0) {
              return (
                <div key={key} className="flex justify-between pt-2 font-semibold">
                  <p className="">{key}</p>
                  <p className="font-bold text-xl text-gp-blue-600">{stats[key]}</p>
                </div>
              )
              }
            })}
          </div>
        </div>

      </div>
    </DashboardTile>
  )

}