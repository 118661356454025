import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import date from "date-and-time";
import { useForm } from "react-hook-form";

import { Switch } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { UseMutationResult } from "@tanstack/react-query";

import Modal from "../../layouts/Modal";
import { ITruck } from "../../models/Truck";
import classNames from "../../utils/classNames";
import LoadingWheel from "../shared/LoadingWheel";
import { TextInput } from "../shared/Inputs/TextInput";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import { useUserLookup } from "../../hooks/useUserLookup";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import AddUnavailableDateModal from "./AddUnavailableDateModel";
import { IUnavailableDate } from "../../models/UnavailableDate";
import useUnavailiableDates from "../../data/useUnavailableDates";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

type FormValues = {
  name: string;
  vrm: string;
  motDueDate: string | null | undefined;
  serviceDueDate: string | null | undefined;
  tripCount: number;
  capacity: number;
  driverId: string | null | undefined;
};

interface TruckModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  truck: ITruck | undefined;
  update: UseMutationResult<ITruck, unknown, ITruck, unknown>;
  create: UseMutationResult<ITruck, unknown, ITruck, unknown>;
}

export default function TruckModal({
  open,
  setOpen,
  truck,
  update,
  create,
}: TruckModalProps) {
  const { saveHandlers } = useDefaultCRUDHandlers("Truck");
  const { isLoading: usersLoading, drivers } = useUserLookup();
  const { unavailableDates, delUnavailableDate, createUnavailableDate } =
    useUnavailiableDates(truck?.id);
  const { register, handleSubmit, reset } = useForm<FormValues>();
  const [active, setActive] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteDate, setDeleteDate] = useState<IUnavailableDate | undefined>(
    undefined
  );

  const handleDateDelete = (date: IUnavailableDate) => {
    setDeleteDate(date);
    setDeleteOpen(true);
  };

  const confirmDateDelete = () => {
    delUnavailableDate.mutate(deleteDate!);
    setDeleteOpen(false);
  };

  const onSubmit = (data: any) => {
    const updatedTruck = {
      ...truck,
      ...data,
      active: active,
      motDueDate: data.motDueDate
        ? DateTime.fromFormat(data.motDueDate, "yyyy-MM-dd").toISO()
        : null,
      serviceDueDate: data.serviceDueDate
        ? DateTime.fromFormat(data.serviceDueDate, "yyyy-MM-dd").toISO()
        : null,
    };

    if (truck !== undefined) {
      update.mutate(updatedTruck, saveHandlers);
    } else {
      create.mutate(updatedTruck, saveHandlers);
    }

    setOpen(false);

    reset({});
  };

  useEffect(() => {
    if (truck !== undefined) {
      setActive(truck.active);
      reset({
        name: truck.name,
        vrm: truck.vehicle.vrm,
        motDueDate:
          truck.motDueDate && DateTime.fromISO(truck.motDueDate).toISODate(),
        serviceDueDate:
          truck.serviceDueDate &&
          DateTime.fromISO(truck.serviceDueDate).toISODate(),
        tripCount: truck.tripCount,
        capacity: truck.capacity,
        driverId: truck.driverId,
      });
    } else {
      setActive(true);
      reset({});
    }
  }, [truck, open, reset]);

  return (
    <>
      <Modal width="max-w-2xl" open={open} setOpen={setOpen}>
        <h1 className="text-xl">{`${truck ? "Edit" : "Add"} a Truck`}</h1>
        <div className="mt-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 border-t border-gray-200"
          >
            <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-2">
              <TextInput label="Name" registerName="name" register={register} />
              <TextInput
                label="VRM"
                disabled={truck !== undefined}
                registerName="vrm"
                register={register}
              />

              <div className="sm:col-span-1">
                <label className="text-sm font-medium text-gray-500">
                  MOT Due
                </label>
                <input
                  {...register("motDueDate")}
                  type="date"
                  className="w-full mt-1 text-sm text-gray-900"
                />
              </div>

              <div className="sm:col-span-1">
                <label className="text-sm font-medium text-gray-500">
                  Service Due
                </label>
                <input
                  {...register("serviceDueDate")}
                  type="date"
                  className="w-full mt-1 text-sm text-gray-900"
                />
              </div>

              <div className="sm:col-span-1">
                <label className="text-sm font-medium text-gray-500">
                  Trip Count
                </label>
                <input
                  {...register("tripCount")}
                  type="number"
                  defaultValue={0}
                  className="w-full mt-1 text-sm text-gray-900"
                />
              </div>

              <div className="sm:col-span-1">
                <label className="text-sm font-medium text-gray-500">
                  Capacity
                </label>
                <input
                  {...register("capacity")}
                  type="number"
                  defaultValue={0}
                  className="w-full mt-1 text-sm text-gray-900"
                />
              </div>

              {usersLoading ? (
                <LoadingWheel />
              ) : (
                <div className="sm:col-span-1">
                  <label className="text-sm font-medium text-gray-500">
                    Default Driver
                  </label>
                  <select
                    aria-label="Driver"
                    {...register("driverId")}
                    className="w-full mt-1 text-sm text-gray-900 h-[40px]"
                  >
                    {drivers?.map((driver) => (
                      <option
                        className="sm:text-sm"
                        key={driver.id}
                        value={driver.id}
                      >
                        {driver.forename} {driver.surname}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="sm:col-span-1"></div>

              <ButtonInput
                label={"Save"}
                isSubmit={true}
                onClick={undefined}
                classes={undefined}
              />

              <div className="sm:col-span-1 flex items-center justify-end">
                <label className="text-sm font-medium text-gray-500 mr-2">
                  Active
                </label>
                <Switch
                  checked={active}
                  onChange={setActive}
                  className={classNames(
                    active ? "bg-gp-blue-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gp-blue-600 focus:ring-offset-2"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    className={classNames(
                      active ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  >
                    <span
                      className={classNames(
                        active
                          ? "opacity-0 duration-100 ease-out"
                          : "opacity-100 duration-200 ease-in",
                        "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                      )}
                      aria-hidden="true"
                    >
                      <svg
                        className="h-3 w-3 text-gray-400"
                        fill="none"
                        viewBox="0 0 12 12"
                      >
                        <path
                          d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span
                      className={classNames(
                        active
                          ? "opacity-100 duration-200 ease-in"
                          : "opacity-0 duration-100 ease-out",
                        "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                      )}
                      aria-hidden="true"
                    >
                      <svg
                        className="h-3 w-3 text-gp-blue-600"
                        fill="currentColor"
                        viewBox="0 0 12 12"
                      >
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                      </svg>
                    </span>
                  </span>
                </Switch>
              </div>
              <div className="col-span-2">
                <div className="flex justify-between">
                  <label className="text-lg font-medium text-gray-500">
                    Unavailable Dates
                  </label>
                  <ButtonInput
                    label={"Add New"}
                    isSubmit={undefined}
                    onClick={() => setCreateOpen(true)}
                    classes={undefined}
                  />
                </div>
                {unavailableDates.data?.data.map((uDate) => (
                  <div
                    className={`py-2 px-2 grid grid-cols-8 bg-gp-blue-600 text-white text-sm rounded-md mt-2 cursor-default`}
                  >
                    <div className="col-span-2">
                      <span>
                        {uDate.date &&
                          date.format(new Date(uDate.date), "DD/MM/YYYY")}
                      </span>
                    </div>
                    <div className="col-span-5">
                      <span>{uDate.message}</span>
                    </div>
                    <div className="col-span-1 flex justify-end">
                      <TrashIcon
                        className="h-6 w-6 cursor-pointer"
                        onClick={() => handleDateDelete(uDate)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </form>

          <AddUnavailableDateModal
            open={createOpen}
            truck={truck}
            setOpen={setCreateOpen}
            create={createUnavailableDate}
          />
          <ConfirmModal
            open={deleteOpen}
            setOpen={setDeleteOpen}
            title="Delete Date"
            confirmButtonText="Delete"
            message={`Are you sure you would like to delete ${
              deleteDate?.date &&
              date.format(new Date(deleteDate.date), "DD/MM/YYYY")
            } \n ${deleteDate?.message}`}
            isLoading={false}
            onConfirm={confirmDateDelete}
          />
        </div>
      </Modal>
    </>
  );
}
