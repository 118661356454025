import { useEffect, useState } from 'react';

import Papa from 'papaparse';

import useLeads from '../../../../data/useLeads';
import LoadingWheel from '../../../shared/LoadingWheel';

function CSVToJSONConverter({ }) {
  const { importLeads } = useLeads();
  const [isLoading, setIsLoading] = useState(false);
  const [importSource, setImportSource] = useState("fads");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  // useEffect(() => {
  //   console.log(importSource)
  // }, [importSource])

  const handleFileChange = (event) => {
    setIsLoading(true);
    setError(false);
    setSuccess(false);
    const file = event.target.files[0];
    // console.log(file)

    Papa.parse(file, {
      header: false,
      complete: (results) => {
        console.log(results)
        var headers = getHeaders(results);
        var data = getData(results);
        // console.log(headers)
        // console.log(data)
        var csvContent = Papa.unparse({
          fields: headers,
          data: data
        })

        const parser = parseType();
        parser(csvContent);
      }
    });
  }

  //Object literals to return data based on import source
  const getHeaders = (results) => ({
    fads: results.data[2],
    motorwise: results.data[0]
  })[importSource]

  const getData = (results) => ({
    fads: results.data.slice(3),
    motorwise: results.data.slice(1)
  })[importSource]

  const parseType = () => ({
    fads: fadsParser,
    motorwise: motorwiseParser
  })[importSource]

  const saveNewLeads = (leads) => {
    console.log("to add: ", leads);

    if (!leads) {
      console.log("No leads to import");
      setIsLoading(false);
      setError(true);
      return;
    };

    var body = { leads: leads };

    // Send to API
    importLeads.mutate(body,
      {
        onSuccess: () => {
          setSuccess(true);
        },
        onError: (error) => {
          console.log(error);
          setError(true);
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  };

  const fadsParser = (csv) => {
    Papa.parse(csv, {
      header: true,
      complete: (results) => {

        let leads = [];

        results.data.forEach(lead => {
          var lead = {
            CCID: lead["CCID"],
            Source: lead["Source"],
            Website: 2,
            Price: lead["Sales Price"],
            Commission: lead["Referral Fee"],
            PaymentType: lead["Payment Type"],
            AccountName: lead["Account Name"],
            AccountNumber: lead["Account Number"],
            SortCode: lead["Sort Code"],
            PaymentReference: lead["Payment Reference"],
            CardNumber: lead["Card Number"],
            PayeeDOB: lead["Payee DOB"],
            ChequeName: lead["Cheque Name"],
            ChequeNumber: lead["Cheque Number"],
            PremiumPriceUsed: lead["Premium Price Used"],
            Scheme: lead["Scheme"],
            VehicleGrade: lead["Vehicle Grade"],
            CentralPayment: lead["Central Payment"],
            Contact: {
              Fullname: `${lead["Customer Title"]} ${lead["Customer First Name"]} ${lead["Customer Last Name"]}`,
              Telephone1: lead["Customer Telephone (main)"],
              Telephone2: lead["Customer Telephone (alternative)"],
              Email: lead["Customer Email"],
              Organisation: lead["Customer Organisation"],
              Line1: `${lead["Customer Building"]} ${lead["Customer Street"]}`,
              Line2: lead["Customer Locality"],
              Town: lead["Customer Town"],
              County: lead["Customer County"],
              Postcode: lead["Customer Postcode"],
            },
            Vehicle: {
              Vrm: lead["VRM"],
              Make: lead["Make"],
              Model: lead["Model"],
              Telephone1: lead["Location Telephone (main)"],
              Telephone2: lead["Location Telephone (alternative)"],
              Line1: `${lead["Location Building"]} ${lead["Location Street"]}`,
              Line2: lead["Location Locality"],
              Town: lead["Location Town"],
              County: lead["Location County"],
              Postcode: lead["Location Postcode"],
              VehiclePremium: lead["Premium Price Used"] === "TRUE" ? true : false,
            }
          }

          lead.Contact.Fullname = lead.Contact.Fullname.trim();
          lead.Contact.Line1 = lead.Contact.Line1.trim();

          lead.Vehicle.Line1 = lead.Vehicle.Line1.trim();

          if (lead.Vehicle.Vrm) {
            leads.push(lead);
          }
        });

        saveNewLeads(leads);
      }
    });

  };

  const motorwiseParser = (csv) => {
    Papa.parse(csv, {
      header: true,
      complete: (results) => {

        let leads = [];

        results.data.forEach(lead => {
          var lead = {
            CCID: lead["id"],
            Source: lead["siteName"],
            Website: 4,
            Price: parseInt(lead["quotedValue"]) ? lead["quotedValue"] : 0,
            Commission: 30,
            Contact: {
              Fullname: `${lead["title"]} ${lead["firstName"]} ${lead["lastName"]}`,
              Telephone1: lead["phone"],
              Telephone2: lead["alternatePhone"],
              Email: lead["email"],
              // Organisation: lead["Customer Organisation"],
              Line1: `${lead["addressLine1"]}`,
              Line2: lead["addressLine2"],
              Town: lead["addressLine3"],
              County: "",
              Postcode: lead["postcode"],
            },
            Vehicle: {
              Vrm: lead["vrm"],
              Make: lead["make"],
              Model: lead["model"],
              Telephone1: lead["phone"],
              Telephone2: lead["alternatePhone"],
              Line1: `${lead["addressLine1"]}`,
              Line2: lead["addressLine2"],
              Town: lead["addressLine3"],
              County: "",
              Postcode: lead["postcode"],
              VehiclePremium: lead["premiumCalibration"] === "1" ? true : false,
              VehicleNotes: lead["userComments"],
            }
          }

          lead.Contact.Fullname = lead.Contact.Fullname.trim();
          lead.Contact.Line1 = lead.Contact.Line1.trim();

          lead.Vehicle.Line1 = lead.Vehicle.Line1.trim();

          if (lead.Vehicle.Vrm) {
            leads.push(lead);
          }
        });

        saveNewLeads(leads);
      }
    });

  };

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <LoadingWheel />
      </div>
    )
  } else {
    return (
      <div className="flex flex-col">
        <div className="w-1/4 mb-12">
          <label className="w-full text-left">
            Import Source
          </label>
          <select className="w-full" value={importSource} onChange={(e) => setImportSource(e.target.value)}>
            <option value="fads">Fads</option>
            <option value="motorwise">Motorwise</option>
          </select>
        </div>

        <div className="mx-auto">
          <input
            id="import"
            type="file"
            className="inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-8 py-4 text-xl font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
            onChange={handleFileChange}
          />

          <div className="mt-4">
            {success && <p className="text-lg text-green-500">Import Successful!</p>}
            {error && <p className="text-lg text-red-500">Error - Import Unsuccessful</p>}
          </div>

        </div>
      </div>
    );
  }
}

export default CSVToJSONConverter;
