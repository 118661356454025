import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContactDetails from "../../components/contacts/ContactDetails";

import LoadingWheel from "../../components/shared/LoadingWheel";

import { usePageNameContext } from "../../contexts/PageTitleContext";

import useContactSingle from "../../data/useContactsSingle";

import { IContact } from "../../models/Contact";

export default function Contact() {
  const { contactId } = useParams();
  const { contact } = useContactSingle(contactId == null ? "" : contactId);
  const { setInfo } = usePageNameContext();

  useEffect(() => {
    if (!contact.isLoading) {
      setInfo({
        name: `${contact.data?.data.fullname!}`,
        desc: "View and manage the contact record here",
      });
    }
  }, [setInfo, contact.isLoading, contact.data]);

  if (contact.isLoading || contact.data == null) {
    return (
      <div className="min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
        <LoadingWheel />
      </div>
    );
  } else {
    return (
      <div className="mt-8 grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <ContactDetails contact={contact.data.data} />
        </div>
        <section aria-labelledby="statistics-title">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h2
                id="statistics-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Statistics
              </h2>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
