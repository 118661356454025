import { useForm } from "react-hook-form";
import { FormatType, ListingDuration } from "../../../models/EbayOffer";
import EbayCategoryDefaultTitle from "../EbayCategoryDefaultTitle";
import SelectInput from "../../shared/Inputs/SelectInput";
import { EbayCategoryDropdown } from "../../ebayListings/EbayCategoryDropdown";
import { EbayStoreCategoryDropdown } from "../../ebayListings/EbayStoreCategoryDropdown";
import { NumberInput } from "../../shared/Inputs/NumberInput";
import useEbay from "../../../data/useEbay";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import { useEffect, useState } from "react";
import LoadingWheel from "../../shared/LoadingWheel";

const formatOptions = [
  { id: FormatType.Auction, name: "Auction" },
  { id: FormatType.FixedPrice, name: "Buy It Now" },
];

const durationOptions = [
  // { id: ListingDuration.GTC, name: "Until Cancelled" }, //auto selected on 
  { id: ListingDuration.Days1, name: "1 Day" },
  { id: ListingDuration.Days3, name: "3 Days" },
  { id: ListingDuration.Days5, name: "5 Days" },
  { id: ListingDuration.Days7, name: "7 Days" },
  { id: ListingDuration.Days10, name: "10 Days" },
  { id: ListingDuration.Days21, name: "21 Days" },
  { id: ListingDuration.Days30, name: "30 Days" },
];


export function BulkCategoryListingDefaults({ register, getValues, setValue, watch }: { register: any, getValues: any, setValue: any, watch: any }) {
  const { policies, locations } = useEbay();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleSetFormat = (_: string, value: any) => {
    setValue(
      "listingDefaults.duration",
      (value === FormatType.FixedPrice
        ? ListingDuration.GTC as number
        : ListingDuration.Days30 as number)
    );
    setValue("listingDefaults.format", value);
  };


  useEffect(() => {
    if (!policies.isLoading && policies.data && !locations.isLoading && locations.data) {
      setIsLoading(false);
    };
  }, [policies.isLoading, locations.isLoading, policies.data, locations.data])


  if (isLoading) return <LoadingWheel />


  return (
    <div className="space-y-4 ">

      <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-2">

        <EbayCategoryDefaultTitle register={register} registerName="listingDefaults.titleStructure" getValues={getValues} setValue={setValue} />

        <SelectInput
          label={"Listing Format"}
          register={register}
          registerName={"listingDefaults.format"}
          options={formatOptions}
          setValue={handleSetFormat}
          watch={watch}
        />

        {getValues("listingDefaults.format") == FormatType.Auction ? (
          <SelectInput
            label={"Duration"}
            register={register}
            registerName={"listingDefaults.duration"}
            options={durationOptions}
            setValue={setValue}
            watch={watch}
          />
        ) : (
          <div className="sm:col-span-1 h-16">{/* Spacer */}</div>
        )}

        <EbayCategoryDropdown
          categoryId={""}
          register={register}
          registerName={"listingDefaults.ebayCategoryId"}
          setValue={setValue}
          watch={watch}
        />

        <EbayStoreCategoryDropdown
          categoryId={""}
          register={register}
          registerName={"listingDefaults.ebayStoreCategoryId"}
          setValue={setValue}
          watch={watch}
        />

        {getValues("listingDefaults.format") == FormatType.Auction ? (
          <>
            <NumberInput
              label={"Starting Price (£)"}
              register={register}
              registerName={"listingDefaults.auctionStartPrice"}
            />
            <NumberInput
              label={"Reserve Price (£)"}
              register={register}
              registerName={"listingDefaults.auctionReservePrice"}
            />
          </>
        ) : (
          <>
            <NumberInput
              label={"Buy It Now Price (£)"}
              register={register}
              registerName={"listingDefaults.buyItNowPrice"}
            />
            {watch("listingDefaults.enableBestOffer") ? (
              <NumberInput
                label={"Best Offer Auto Accept Price (£)"}
                register={register}
                registerName={"listingDefaults.autoAcceptPrice"}
              />
            ) : (
              <div className="sm:col-span-1 h-16">{/* Spacer */}</div>
            )}
          </>
        )}

        {getValues("listingDefaults.format") == FormatType.FixedPrice && (
          <>
            <div className="sm:col-span-1 mt-4">
              <input
                id="enableBestOffer"
                {...register("listingDefaults.enableBestOffer")}
                type="checkbox"
                className="mr-2 h-5 w-5"
              />
              <label htmlFor="enableBestOffer">Allow Best Offer?</label>
            </div>

            <div className="sm:col-span-1 h-16">{/* Spacer */}</div>
          </>
        )}

        <SelectInput
          label={"Shipping Policy*"}
          register={register}
          registerName={"listingDefaults.fulfillmentPolicyId"}
          options={policies.data?.data.fulfilmentPolicies ?? []}
          setValue={setValue}
          watch={watch}
        />
        <SelectInput
          label={"Payment Policy*"}
          register={register}
          registerName={"listingDefaults.paymentPolicyId"}
          options={policies.data?.data.paymentPolicies ?? []}
          setValue={setValue}
          watch={watch}
        />
        <SelectInput
          label={"Returns Policy*"}
          register={register}
          registerName={"listingDefaults.returnPolicyId"}
          options={policies.data?.data.returnPolicies ?? []}
          setValue={setValue}
          watch={watch}
        />

        <SelectInput
          label={"Location*"}
          register={register}
          registerName={"listingDefaults.locationKey"}
          options={locations.data?.data.locations ?? []}
          setValue={setValue}
          watch={watch}
        />

        <NumberInput label="Width (cm)" registerName="listingDefaults.width" register={register} step="1" />
        <NumberInput label="Height (cm)" registerName="listingDefaults.height" register={register} step="1" />
        <NumberInput label="Length (cm)" registerName="listingDefaults.length" register={register} step="1" />
        <NumberInput label="Weight (kg)" registerName="listingDefaults.weight" register={register} step="1" />
      </div>
    </div>
  );
}