import { useEffect, useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import { IPart } from "../../models/Part";
import useEbayOrders from "../../data/useEbayOrders";
import EbayOrdersTable from "../../components/ebayOrders/EbayOrdersTable";


export default function EbayOrders() {
  const { setInfo } = usePageNameContext();
  const { orders: allOrders } = useEbayOrders();
  const [orders, setOrders] = useState<IPart[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showPicked, setShowPicked] = useState(true);
  const [showShipped, setShowShipped] = useState(false);

  useEffect(() => {
    setInfo({
      name: "eBay Orders",
      desc: "View and manage sold orders from eBay",
    });
  }, []);

  useEffect(() => {
    if (!allOrders.isLoading) {
      let filter = allOrders.data?.data.rows;

      filter = filter?.filter((order) =>
        order.title?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      //Hide orders that have been picked
      if (!showPicked) {
        filter = filter?.filter((order) => order.pickedOn === null);
      }

      //Hide orders that have been shipped
      if (!showShipped) {
        filter = filter?.filter((order) => order.shippedOn === null);
      }

      setOrders(filter);
      setIsLoading(false);
    }
  }, [allOrders.isLoading, allOrders.data, searchTerm, showPicked]);

  return allOrders.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <EbayOrdersTable />
    </>
  );
}
