import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import JobSheetTable from "../../components/schedule/JobSheetTable";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { DateTime } from "luxon";
import LoadingWheel from "../../components/shared/LoadingWheel";
import PrintJobSheet from "../../components/tripManager/PrintJobSheet";

export default function Schedule({}) {
  const { setInfo } = usePageNameContext();
  const [selectedDate, setSelectedDate] = useState<string>(
    DateTime.now().toISODate()!
  );
  const [year, setYear] = useState<number>(DateTime.now().year);
  const [week, setWeek] = useState<number>(DateTime.now().weekNumber);
  const [dates, setDates] = useState<DateValueType>({
    startDate: new Date(),
    endDate: null,
  });

  useEffect(() => {
    setInfo({
      name: "Schedule",
      desc: `View and Print Job Sheets for each day`,
    });
  }, [dates, selectedDate]);

  const handleSelectedDate = (dates: any) => {
    setDates(dates);

    if (dates.startDate) {
      setYear(DateTime.fromISO(dates.startDate).year);
      setWeek(DateTime.fromISO(dates.startDate).weekNumber);
      setSelectedDate(
        DateTime.fromISO(dates.startDate).toFormat("yyyy-MM-dd'T00:00:00'")!
      );
    }
  };

  if (!selectedDate) {
    return <LoadingWheel />;
  }

  return (
    <div className="mt-4">
      <div>
        <div className="my-4 w-full sm:w-1/2 xl:w-1/4">
          <Datepicker
            inputClassName="text-sm border-gray-500 rounded-none w-full"
            toggleClassName="absolute text-black font-bold right-0 h-full px-3"
            containerClassName=""
            placeholder="Select a date"
            // showShortcuts={true}
            asSingle={true}
            useRange={false}
            value={dates}
            onChange={handleSelectedDate}
            displayFormat="DD/MM/YYYY"
          />
        </div>

        <PrintJobSheet selectedDay={selectedDate} week={week} year={year} />
      </div>

      <h1 className="text-2xl mt-4 mb-2 font-semibold">
        {DateTime.fromISO(selectedDate).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        )}
      </h1>
      <JobSheetTable selectedDay={selectedDate} week={week} year={year} />
    </div>
  );
}
