export interface CardProps {
  title?: string;
  children: React.ReactNode;
  labelId?: string;
  containerClassName?: string;
  bodyClassName?: string;
}

export default function Card({ title, labelId, children, containerClassName, bodyClassName }: CardProps) {
  return (
    <div className={containerClassName} aria-labelledby={`${labelId ?? "card"}-title`}>
      {title &&
        <div className="bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2
              id={`$${labelId ?? "card"}-title`}
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {title}
            </h2>
          </div>
          <div className={`border-t border-gray-200 ${bodyClassName}`}>
            {children}
          </div>
        </div>
      }
      {!title &&
        <div className="bg-white shadow rounded-lg">
          <div className={`${bodyClassName}`}>
            {children}
          </div>
        </div>
      }
    </div>
  );
}
