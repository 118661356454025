import Modal from "../../layouts/Modal";
import useSinglePart from "../../data/useSinglePart";
import useEbayPromotions from "../../data/useEbayPromotions";
import LoadingWheel from "../shared/LoadingWheel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCircleCheck, faPencil} from "@fortawesome/free-solid-svg-icons";
import {IPart} from "../../models/Part";
import {IPromotion} from "../../models/Promotion";
import useLocations from "../../data/useLocations";
import useWarehouseLocations from "../../data/useWarehouseLocations";
import { TextInput } from "../shared/Inputs/TextInput";
import { useForm } from "react-hook-form";
import usePartMutations from "../../data/usePartMutations";

interface PromotionPickerProps {
  open: boolean;
  setOpen: any;
  part: IPart | undefined;
}

export default function LocationPicker({open, setOpen, part} : PromotionPickerProps) {
  const { warehouseLocations } = useWarehouseLocations();
  const { patchPart } = usePartMutations();

  // const promotionClicked = (promo: IPromotion) =>{
  //   applyPromotion.mutate({part: part, promo: promo}, {
  //     onSuccess: ()=>{
  //       setOpen(false);
  //     }
  //   })
  // }

  const setLocation = (locationId: string) =>{
    patchPart.mutate({...part, warehouseLocationId: locationId}, {
      onSuccess: ()=>{
        setOpen(false);
      }
    })
  };

  const {watch, register} = useForm({
    defaultValues:{
      search: ""
    }
  }); 

  return <Modal open={open} setOpen={setOpen} width={"max-w-2xl"}>
    {
      warehouseLocations.isLoading || (warehouseLocations as any).data.data == null ?
      <LoadingWheel /> :
        <div>
          <h1 className="text-xl font-bold">Change Warehouse Locations</h1>
          <TextInput label="Search" register={register} registerName={"search"}/>
          <div className="max-h-[50vh] overflow-y-scroll mt-2">
          {
            (warehouseLocations as any).data.data.filter((x: any) => x.displayName.includes(watch("search"))).map((x : any, i:number)=>
              <div className="flex flex-row" key={i}>
                {
                  part?.warehouseLocationId != x.id ?
                    <FontAwesomeIcon className="rounded-full p-1 cursor-pointer hover:ring-2 hover:ring-blue-500"
                                     icon={faCheck}
                                     onClick={()=>setLocation(x.id)}
                    />
                    :
                    <FontAwesomeIcon className="rounded-full p-1 cursor-pointer hover:ring-2 hover:ring-blue-500"
                                     icon={faCircleCheck}
                    />
                }

                <p>{x.displayName}</p>
              </div>)
          }
          </div>
        </div>
    }
  </Modal>
}