import {
  wilmaSSOAPIUrl,
  wilmaSSOAppRegistration,
  wilmaSSODashboardUrl,
} from "../config";
import { IWilmaUser } from "../models/auth/WilmaUser";
import { DefaultResponse, DefaultResponseWithData } from "../models/system";
import { handleResponse } from "../utils/handleResponse";

export function useAuthApi() {
  const getToken = async () => {
    try {
      const tokenRes = await fetch(`${wilmaSSOAPIUrl}/v1/auth/token`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          applicationRegistrationId: wilmaSSOAppRegistration,
        }),
      });

      return await handleResponse<DefaultResponseWithData<{ token: string }>>(
        tokenRes
      );
    } catch (e: any) {
      if (e["response"] && e.response.status === 401) {
        window.location.assign(
          `${wilmaSSODashboardUrl}?ari=${wilmaSSOAppRegistration}&returnUrl=${window.location.href}`
        );
      }
    }
  };

  const getUsers = async () => {
    try {
      const usersRes = await fetch(`${wilmaSSOAPIUrl}/v1/customer/users`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return await handleResponse<DefaultResponseWithData<IWilmaUser[]>>(
        usersRes
      );
    } catch (e: any) {
      if (e["response"] && e.response.status === 401) {
        window.location.assign(
          `${wilmaSSODashboardUrl}?ari=${wilmaSSOAppRegistration}&returnUrl=${window.location.href}`
        );
      }
    }
  };

  const getUser = async () => {
    const userRes = await fetch(`${wilmaSSOAPIUrl}/v1/user`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await handleResponse<DefaultResponseWithData<IWilmaUser>>(userRes);
  };

  // true/ false and guid for user that its for
  const validatePin = async (pin: string, useAuthUser: boolean = true) => {
    const pinRes = await fetch(`${wilmaSSOAPIUrl}/v1/user/pin`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pin: pin,
        useAuthUser: useAuthUser,
      }),
    });

    return await handleResponse<DefaultResponseWithData<string>>(pinRes);
  };

  // const updatePin = async (pin: string, password: string) => {
  //   const pinRes = await fetch(`${wilmaSSOAPIUrl}/v1/user/pin`, {
  //     method: "PUT",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       pin: pin,
  //       password: password,
  //     }),
  //   });

  //   return await handleResponse<DefaultResponse>(pinRes);
  // };

  // Generate pin for user, if username is set then it will generate a pin for that user
  // else it will generate a pin for the authed user
  const generatePin = async (username: string | null, password: string) => {
    const pinRes = await fetch(`${wilmaSSOAPIUrl}/v1/user/pin/generate`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    return await handleResponse<DefaultResponseWithData<string>>(pinRes);
  }

  const createUser = async (user: IWilmaUser) => {
    try {
      const createRes = await fetch(`${wilmaSSOAPIUrl}/v1/user`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      return await handleResponse<DefaultResponseWithData<IWilmaUser>>(
        createRes
      );
    } catch (e: any) {
      if (e["response"] && e.response.status === 401) {
        window.location.assign(
          `${wilmaSSODashboardUrl}?ari=${wilmaSSOAppRegistration}&returnUrl=${window.location.href}`
        );
      }

      throw e;
    }
  };

  const updateUser = async (user: IWilmaUser) => {
    try {
      const updateRes = await fetch(`${wilmaSSOAPIUrl}/v1/user/${user.id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      return await handleResponse<DefaultResponseWithData<IWilmaUser>>(
        updateRes
      );
    } catch (e: any) {
      if (e["response"] && e.response.status === 401) {
        window.location.assign(
          `${wilmaSSODashboardUrl}?ari=${wilmaSSOAppRegistration}&returnUrl=${window.location.href}`
        );
      }

      throw e;
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      const deleteRes = await fetch(`${wilmaSSOAPIUrl}/v1/user/${userId}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return await handleResponse<DefaultResponse>(deleteRes);
    } catch (e: any) {
      if (e["response"] && e.response.status === 401) {
        window.location.assign(
          `${wilmaSSODashboardUrl}?ari=${wilmaSSOAppRegistration}&returnUrl=${window.location.href}`
        );
      }

      throw e;
    }
  };

  const logout = async () => {
    try {
      const logoutRes = await fetch(`${wilmaSSOAPIUrl}/v1/auth/logout`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          applicationRegistrationId: wilmaSSOAppRegistration,
        }),
      });

      return await handleResponse<DefaultResponse>(logoutRes);
    } catch (e: any) {
      if (e["response"] && e.response.status === 401) {
        window.location.assign(
          `${wilmaSSODashboardUrl}?ari=${wilmaSSOAppRegistration}&returnUrl=${window.location.href}`
        );
      }

      throw e;
    }
  };

  return {
    getToken,
    getUsers,
    getUser,
    validatePin,
    generatePin,
    createUser,
    updateUser,
    deleteUser,
    logout,
  };
}
