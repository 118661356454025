import { IPart, PartStatus } from "../../models/Part";


export default function PartStatusLabel({ part, width = "w-full" }: { part: IPart, width?: string }) {

  let style = "px-3 text-xs mx-auto text-center rounded-full h-10 flex justify-center items-center";
  let colour = "bg-yellow-200 text-yellow-500";

  switch (part.partStatus) {
    case PartStatus.Damaged:
    case PartStatus.Missing:
    case PartStatus.Deleted:
      colour = "bg-red-200 text-red-500";
      break;
    case PartStatus.QC:
      colour = "bg-fuchsia-200 text-fuchsia-500";
      break;
    case PartStatus.Dismantled:
      colour = "bg-blue-200 text-blue-500";
      break;
    case PartStatus.Sold:
      colour = "bg-green-200 text-green-500";
      break;
    default:
      break;
  }

  return (
    <div className={`${colour} ${style} ${width}`}>
      <p className="">{PartStatus[part.partStatus]}</p>
    </div>
  )

}