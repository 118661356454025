import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { ArrowTopRightOnSquareIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

import useLeads from "../../../data/useLeads";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import Modal from "../../../layouts/Modal";
import { ILead } from "../../../models/Lead";
import PriceFormat from "../../../utils/priceFormat";
import useNotification from "../../notifications/useNotifications";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import LoadingWheel from "../../shared/LoadingWheel";

interface IBulkCompleteResponse {
  data: string,
  message: string,
  success: boolean
}

const fakeResponse: IBulkCompleteResponse[] = [
  {
    data: "d166104f-c088-47e7-172b-08db1029e6e8",
    message: "Completed Lead Successfully!",
    success: true
  },
  {
    data: "d166104f-c088-47e7-172b-08db1029e6e8",
    message: "Unable to complete COD. \nPlease check all the requirements for this lead and try again.",
    success: false
  },
  {
    data: "d166104f-c088-47e7-172b-08db1029e6e8",
    message: "Completed Lead Successfully!",
    success: true
  },
  {
    data: "d166104f-c088-47e7-172b-08db1029e6e8",
    message: "Completed Lead Successfully!",
    success: true
  },
]

export default function BulkCompleteLeads({ leads, table, open, setOpen }: { leads: ILead[], table: any, open: boolean, setOpen: any }) {
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const { saveHandlers } = useDefaultCRUDHandlers("Leads");
  const { bulkComplete } = useLeads(true);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [completeResponse, setCompleteResponse] = useState<IBulkCompleteResponse[] | null>(null);
  const [leadsSelected, setLeadsSelected] = useState<string[]>([]);

  const handleBulkComplete = () => {

    const body = {
      leads: leadsSelected
    };
    setCompleteLoading(true);

    bulkComplete.mutate(body, {
      onSuccess: (res: any) => {
        console.log(res);
        setCompleteResponse(res);
        saveHandlers.onSuccess();
        table.resetRowSelection();
        setLeadsSelected([]);
      },
      onError: (res: any) => {
        addNotification({
          variant: "error",
          primaryText: res.message,
        })
      },
      onSettled: () => {
        setCompleteLoading(false);
      }
    });

  }

  const handleSelected = (id: string) => {
    if (leadsSelected.includes(id)) {
      setLeadsSelected(leadsSelected.filter((leadId: string) => leadId !== id));
    } else {
      setLeadsSelected([...leadsSelected, id]);
    }
  }

  useEffect(() => {
    // console.log(leadsSelected);
  }, [completeResponse, leadsSelected])

  return (
    <Modal width="max-w-4xl" open={open} setOpen={setOpen} >
      <h1 className="text-xl">Bulk Complete Leads</h1>
      <hr />

      {completeResponse ?
        (
          <div className="mt-4">
            <p>Tried to complete {completeResponse.length} lead{completeResponse.length > 1 && "s"}.</p>
            <br />

            <div className="flex flex-col gap-y-2 divide-y">
              {completeResponse.map((res: IBulkCompleteResponse) => {
                const foundLead = leads.find(lead => lead.id === res.data);

                return (
                  <div className="flex justify-between pt-2">
                    <div className="flex grow items-center gap-x-2">
                      {res.success ? <CheckCircleIcon className="text-green-500 w-7 h-7" /> : <XCircleIcon className="text-red-500 w-7 h-7" />}
                      <p className="whitespace-pre-line">{res.message}</p>
                    </div>
                    <Link
                      to={res.data} target="_blank" rel="noopener noreferrer"
                      className="underline text-blue-500 hover:text-blue-700"
                    >
                      {foundLead?.contact?.fullname} - {foundLead?.vehicle?.vrm} <ArrowTopRightOnSquareIcon className="inline-block mb-1 w-4 h-4" />
                    </Link>
                  </div>
                )
              })}
            </div>
            <div className="flex justify-end">
              <ButtonInput label="Reset" isSubmit={false} onClick={() => setCompleteResponse(null)} classes={"mt-6"} />
            </div>
          </div>
        )
        :
        (
          completeLoading ?
            (
              <div className="mt-4">
                <LoadingWheel />
              </div>
            )
            :
            (
              <div className="mt-4">
                <p>Are you sure you want to complete all of these leads?</p>
                <p className="font-semibold">This will attempt to COD and raise payments for all of these leads!</p>
                <br />
                <p>For confirmation, please select all the leads you want to be completed.</p> 
                <p className="font-semibold">{leadsSelected.length} lead{(leadsSelected.length > 1 || leadsSelected.length === 0) && "s"} selected.</p>
                <br />

                {/* Table of selected leads */}
                <div className="space-y-2 divide-y">
                  {table.getSelectedRowModel().flatRows.length > 0 &&
                    table.getSelectedRowModel().flatRows.map((row: any) =>
                      <div key={row.original.id} className="grid grid-cols-6 items-center">
                        <img
                          className="w-24 h-12 object-contain"
                          src={`${row.original.vehicle != null ? row.original.vehicle.vehicleImageUrl : null}`}
                        />
                        <span>{row.original.contact.fullname}</span>
                        <span>{row.original.vehicle.vrm}</span>
                        <span>{row.original.vehicle.postcode}</span>
                        <span>{PriceFormat(row.original.price)}</span>
                        <input type="checkbox" onChange={() => handleSelected(row.original.id)} />
                      </div>
                    )
                  }
                </div>


                <ButtonInput disabled={leadsSelected.length < 1} label={"Complete Leads"} isSubmit={false} onClick={() => handleBulkComplete()} classes={"mt-6"} />
              </div>
            )
        )}

    </Modal >
  )

}