import { useEffect, useState } from "react";
import useEbayCategories from "../../data/useEbayCategories";
import LoadingWheel from "../shared/LoadingWheel";
import ComboBoxInput from "../shared/Inputs/ComboBoxInput";

interface EbayCategoryDropdownProps {
  categoryId: string | null | undefined;
  register: any;
  registerName: string;
  setValue: any;
  watch: any;
}

export function EbayStoreCategoryDropdown({ categoryId, register, registerName, setValue, watch }: EbayCategoryDropdownProps) {
  const { storeCategories } = useEbayCategories();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!storeCategories.isLoading && storeCategories.data) {
      if (!watch(registerName)) {
        setValue(registerName, storeCategories.data[0]?.categoryId);
      }

      setLoading(false);
    }
  }, [storeCategories.data, storeCategories.isLoading])

  useEffect(() => {
    if (storeCategories.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [storeCategories.isLoading, storeCategories.data]);

  const handleCategoryChange = (registerName: string, option: any) => {
    if (option != null)
      setValue(registerName, option);
  };

  if (loading || storeCategories.isLoading || !storeCategories.data) {
    return (
      <div className="flex items-center space-x-2">
        Getting store categories from eBay...
        <span className="h-5">
          <LoadingWheel />
        </span>
      </div>
    );
  } else {

    return (
      <ComboBoxInput
        label="Store Category"
        options={storeCategories.data!}
        searchField={"categoryName"}
        valueField="categoryId"
        register={register}
        setValue={handleCategoryChange}
        registerName={registerName}
        initialValue={categoryId as string | undefined}
      />
    );
  }
}
