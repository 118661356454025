import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ICategoryPartResponse } from "../models/Part";

export default function useCategoryParts(categoryId: string) {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60 * 10; //10 minutes

  const categoryParts = useQuery({
    queryKey: ["categoryParts", categoryId],
    queryFn: () => get<ICategoryPartResponse>(`/part/categories/${categoryId}`),
    staleTime: staleTime,
    refetchInterval: staleTime,
  });

  return {
    categoryParts
  };
}
