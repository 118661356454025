import { useEffect, useState } from "react";
import useEbayCategories from "../../data/useEbayCategories";
import SelectInput from "../shared/Inputs/SelectInput";
import LoadingWheel from "../shared/LoadingWheel";
import useEbayConditions from "../../data/useEbayConditions";

interface EbayCategoryDropdownProps {
  categoryId: string;
  register: any;
  registerName: string;
  setValue: any;
  watch: any;
}

export function EbayConditionDropdown({ categoryId, register, registerName, setValue, watch }: EbayCategoryDropdownProps) {
  const { conditions } = useEbayConditions(categoryId);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!conditions.isLoading && conditions.data) {
      if (!watch(registerName)) {
        setValue(registerName, conditions.data!.conditions[0].id);
      }

      setLoading(false);
    }
  }, [conditions.data, conditions.isLoading])

  useEffect(() => {
    if (conditions.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [categoryId, conditions.isLoading]);

  if (loading || conditions.isLoading || !conditions.data) {
    return (
      <div className="flex items-center space-x-2">
        Getting conditions from eBay...
        <span className="h-6">
          <LoadingWheel />
        </span>
      </div>
    );
  } else {

    // if (watch(registerName) === undefined) {
    //   setValue(registerName, categories.data!.categories[0].id);
    // } 

    return (
      <SelectInput
        label={"Condition*"}
        register={register}
        registerName={registerName}
        options={conditions.data!.conditions}
        setValue={setValue}
        watch={watch}
      />
    );
  }
}
