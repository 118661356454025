import React from 'react';
import { IEnginesWorldwide, OfferType } from "../../models/EnginesWorldwide";


interface IEnginesWorldWideOffersProps {
  offers: IEnginesWorldwide[] | undefined;
}

const EnginesWorldWideOffers: React.FC<IEnginesWorldWideOffersProps> = ({ offers }) => {
  return (
    <div>
      {
        (offers && offers.length > 0) ?
          <>
            {offers.map((eww) =>
              <p>{OfferType[eww.type]} - {eww.status}</p>
            )}
          </>
          :
          <span>N/A</span>
      }
    </div>
  );
};

export default EnginesWorldWideOffers;
